import { call, takeLatest, select } from 'redux-saga/effects';
import {
  LOAD_SYSTEM_MESSAGES,
  systemMessages,
  LOAD_SYSTEM_MESSAGE,
  systemMessage,
  LOAD_SAVE_SYSTEM_MESSAGE,
  saveSystemMessage,
  showSuccessMessage,
} from 'actions/system-messages';
import { getSystemMessage } from 'selectors/system-messages';
import { systemMessagesApi, systemMessageApi, saveSystemMessageApi } from 'services/system-messages';
import fetchEntity from './fetch-entity';

const fetchMessages = fetchEntity.bind(null, systemMessages, systemMessagesApi);

export function* loadSystemMessages() {
  yield call(fetchMessages);
}

function* watchSystemMessages() {
  yield takeLatest(LOAD_SYSTEM_MESSAGES, loadSystemMessages);
}

// Get system message by id
const fetchMessage = fetchEntity.bind(null, systemMessage, systemMessageApi);

export function* loadSystemMessage({ id }) {
  yield call(fetchMessage, {
    id,
  });
}

function* watchSystemMessage() {
  yield takeLatest(LOAD_SYSTEM_MESSAGE, loadSystemMessage);
}

// save system message
const fetchSaveMessage = fetchEntity.bind(null, saveSystemMessage, saveSystemMessageApi);

export function* loadSaveSystemMessage({ data }) {
  yield call(fetchSaveMessage, data);
  const systemMessageData = yield select(getSystemMessage);
  if (systemMessageData && systemMessageData.saved) {
    showSuccessMessage();
  }
}

function* watchSaveSystemMessage() {
  yield takeLatest(LOAD_SAVE_SYSTEM_MESSAGE, loadSaveSystemMessage);
}

const SystemMessagesSagas = {
  watchSaveSystemMessage,
  watchSystemMessage,
  watchSystemMessages,
};

export default SystemMessagesSagas;
