import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get symptoms
export const SYMPTOMS = createRequestTypes('SYMPTOMS');
export const getSymptoms = {
  request: () => action(SYMPTOMS[REQUEST]),
  success: (params, response) =>
    action(SYMPTOMS[SUCCESS], {
      response: {
        entities: {
          symptoms: response,
        },
      },
    }),
  failure: (params, error) => action(SYMPTOMS[FAILURE], { error }),
};
export const LOAD_SYMPTOMS = 'LOAD_SYMPTOMS';
export const requestLoadSymptoms = data => action(LOAD_SYMPTOMS, data);
