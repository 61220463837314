import React from 'react';

import styled from 'styled-components';
import Layout from 'style-guide/Layout';
import Button from 'style-guide/Button';
import Space from 'style-guide/Space';
import { clearExpTimeFromStorage, clearExpClosed, clearExpTimeFromStorage2FA, clearIsFirstTimeFromStorage } from 'utils/local-storage-helpers';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HeaderLogo from './partials/HeaderLogo';
import RightMenu from './partials/RightMenu';
import MobileMenu from './partials/MobileMenu';

const { Header: header } = Layout;

const MainHeader = styled(header)`
  &&& {
    background: #fff !important;
    padding: 16px !important;
    height: 60px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05) !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    @media screen and (min-width: 480px) and (max-width: 1024px) {
      height: 60px !important;
      padding: 20px 24px !important;
    }

    @media screen and (min-width: 1024px) {
      padding: 0 32px !important;
      height: 60px !important;
    }
  }
`;

const Header = ({
  username = null,
  firstName = null,
  lastName = null,
  authMenu,
  userMenu = null,
  logoUrl,
  burgerVisible,
  setBurgerVisible,
  toggleBurger,
  onSearch,
  isWidget,
  showSearch,
  clientUrl,
  history,
  pathname,
  linkData,
  userType,
}) => {
  const { ev286FrontEndToggleDropDownUsingMockData } = useFlags();

  return (
    <MainHeader>
      {username && pathname !== '/get-app' && (
        <MobileMenu
          user={{
            name: username,
          }}
          setBurgerVisible={setBurgerVisible}
          burgerVisible={burgerVisible}
          toggleBurger={toggleBurger}
        />
      )}
      <HeaderLogo dropDown={userMenu || authMenu} onClick={logoUrl} />
      {pathname === '/get-app' ? (
        <Space>
          <Button type='link' href='https://intellihealthcorp.zendesk.com/'>
            Help
          </Button>
          |
          <Button
            type='link'
            onClick={() => {
              if (username) {
                clearExpTimeFromStorage();
                clearExpClosed();
                clearExpTimeFromStorage2FA();
                clearIsFirstTimeFromStorage();
                // For some reason, we aren't handling log out using reducers/actions and instead performing log out with calls to the API that redirects us to the sign-in page.
                if (linkData) {
                  linkData.logout();
                } else {
                  window.location.assign(`${clientUrl}api/logout`);
                }
              } else {
                history.push('/');
              }
            }}
          >
            Exit
          </Button>
        </Space>
      ) : (
        <RightMenu
          clientUrl={clientUrl}
          username={username}
          firstName={firstName}
          lastName={lastName}
          userMenu={userMenu}
          authMenu={authMenu}
          onSearch={onSearch}
          isWidget={isWidget}
          pathname={pathname}
          showSearch={showSearch}
          userType={userType}
          ev286FrontEndToggleDropDownUsingMockData={ev286FrontEndToggleDropDownUsingMockData}
        />
      )}
    </MainHeader>
  );
};

export default Header;
