import styled from 'styled-components';
import { Pagination as pagination } from 'antd';

const Pagination = styled(pagination)`
  margin-top: 24px;
  float: right !important;

  .ant-pagination-item-link {
    border: none !important;
    color: #8e8e8e;
  }

  .ant-pagination-item {
    background: #fff;
    border: 1px solid #e0e0e0 !important;
    border-radius: 2px;
    color: #3e3e3e;

    &-active {
      background: #137ac9;
      border: 1px solid #137ac9;

      a,
      &:hover a {
        color: white;
      }
    }

    &:hover {
      background: #efefef;
      color: #727272;

      a {
        color: #727272 !important;
      }
    }

    &:focus {
      background: #e0e0e0;
      color: #727272;

      a {
        color: #727272 !important;
      }
    }
  }
`;

/** @component */
export default Pagination;
