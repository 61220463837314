import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const COUNTRIES = createRequestTypes('COUNTRIES');
export const getLoadCountryAction = {
  request: params => action(COUNTRIES[REQUEST], { params }),
  success: (params, response) =>
    action(COUNTRIES[SUCCESS], {
      response: {
        entities: {
          countries: response,
        },
      },
    }),
  failure: (params, error) => action(COUNTRIES[FAILURE], { error }),
};
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const requestLoadCountries = data => action(LOAD_COUNTRIES, { data });

export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const selectCountry = countryId => action(SELECT_COUNTRY, { countryId });

export const UNSELECT_COUNTRY = 'UNSELECT_COUNTRY';
export const unSelectCountry = () => action(UNSELECT_COUNTRY);
