import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const ProgressBar = styled.div`
  background: #0c2340;
  height: 50px;
  display: flex;
  position: relative;

  .bmiq-progressBar-inner {
    background: #5189c3;
    height: 50px;
    position: relative;
    transition: width 0.2s;

    &::after {
      content: '';
      position: absolute;
      right: -10px;
      bottom: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-bottom: 9px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }

  .bmiq-progressBar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
`;
const BmiqProgressBar = ({ percentage }) => (
  <ProgressBar>
    <div className='bmiq-progressBar-inner' style={{ width: `${percentage}%` }} />
    <span className='bmiq-progressBar-text'>{percentage}% Complete</span>
  </ProgressBar>
);

BmiqProgressBar.propTypes = {
  /** to distinguish completed and non-completed sections */
  percentage: propTypes.number,
};

/** @component */
export default BmiqProgressBar;
