import { call, takeLatest, select, put } from 'redux-saga/effects';

import {
  LOAD_CREATE_MEDICATION,
  createMedication,
  LOAD_MEDICATIONS,
  getMedications,
  LOAD_MEDICATION,
  getMedication,
  LOAD_DISABLE_MEDICATION,
  disableMedication,
  LOAD_EDIT_MEDICATION,
  editMedication,
  LOAD_MEDICATIONS_FDA,
  getMedicationsFda,
  UNSET_MEDICATIONS,
} from 'actions/medications';

import {
  createMedicationApi,
  getMedicationsApi,
  getMedicationApi,
  disableMedicationApi,
  editMedicationApi,
  getMedicationsFdaApi,
} from 'services/medications';

import { getOffset } from 'selectors/pagination';
import { errorDisableMedication } from 'selectors/medications';
import fetchEntity from './fetch-entity';

// create patient medication

const fetchCreateMedication = fetchEntity.bind(null, createMedication, createMedicationApi);

export function* loadCreateMedication({ type, data }) {
  yield call(fetchCreateMedication, data);
}

function* watchCreateMedication() {
  yield takeLatest(LOAD_CREATE_MEDICATION, loadCreateMedication);
}

// get patient medications

const fetchLoadMedications = fetchEntity.bind(null, getMedications, getMedicationsApi);

export function* loadMedications(data) {
  yield call(fetchLoadMedications, data);
}

function* watchLoadMedications() {
  yield takeLatest(LOAD_MEDICATIONS, loadMedications);
}

// get single patient medication

const fetchLoadMedication = fetchEntity.bind(null, getMedication, getMedicationApi);

export function* loadMedication({ id }) {
  yield call(fetchLoadMedication, id);
}

function* watchLoadMedication() {
  yield takeLatest(LOAD_MEDICATION, loadMedication);
}

// disable patient medication
const fetchDisableMedication = fetchEntity.bind(null, disableMedication, disableMedicationApi);

export function* loadDisableMedication({ type, ...data }) {
  yield call(fetchDisableMedication, data);
  const hasError = yield select(errorDisableMedication);
  if (!hasError) {
    const offset = yield select(getOffset);
    yield call(fetchLoadMedications, { skip: offset });
  }
}

function* watchDisableMedication() {
  yield takeLatest(LOAD_DISABLE_MEDICATION, loadDisableMedication);
}

// edit patient medication
const fetchEditMedication = fetchEntity.bind(null, editMedication, editMedicationApi);

export function* loadEditMedication({ type, ...data }) {
  yield call(fetchEditMedication, data);
}

function* watchEditMedication() {
  yield takeLatest(LOAD_EDIT_MEDICATION, loadEditMedication);
}

// load medications from FDA api

const fetchLoadMedicationsFda = fetchEntity.bind(null, getMedicationsFda, getMedicationsFdaApi);

export function* loadMedicationsFda({ type, ...data }) {
  yield data.keyword ? call(fetchLoadMedicationsFda, data) : put(getMedicationsFda.success(undefined, []));
}

function* watchLoadMedicationsFda() {
  yield takeLatest(LOAD_MEDICATIONS_FDA, loadMedicationsFda);
}

// unset medications

export function* unsetMedications() {
  yield put(getMedicationsFda.success(undefined, []));
}

function* watchLoadUnsetMedications() {
  yield takeLatest(UNSET_MEDICATIONS, unsetMedications);
}

const MedicationSagas = {
  watchCreateMedication,
  watchEditMedication,
  watchLoadMedication,
  watchLoadMedications,
  watchDisableMedication,
  watchLoadMedicationsFda,
  watchLoadUnsetMedications,
};
export default MedicationSagas;
