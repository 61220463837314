import React, { createRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { requestUserLogout, requestCheckAuthentication } from 'actions/users';
import { userIsAuth } from 'selectors/users';

export class IsIdle extends React.Component {
  idleTimer = createRef();

  state = {
    timeout: 1000 * 60 * 15, // 15 minutes
    isIdle: false,
  };

  componentDidMount() {
    setInterval(
      () => {
        if (this.props.isAuth) {
          this.props.requestCheckAuthentication({ isIdle: this.state.isIdle });
        }
      },
      process.env.NODE_ENV === 'development' ? 1000 * 60 * 14 : 1000 * 15,
    ); // 15 seconds
  }

  onActive = () => {
    this.setState({ isIdle: false });
  };

  onIdle = () => {
    this.setState({ isIdle: true });
  };

  render() {
    return <IdleTimer ref={this.idleTimer} onActive={this.onActive} onIdle={this.onIdle} timeout={this.state.timeout} startOnLoad />;
  }
}

const mapStateToProps = state => ({
  isAuth: userIsAuth(state),
});

export default connect(mapStateToProps, { requestUserLogout, requestCheckAuthentication })(IsIdle);
