import { createSelector } from 'reselect';
import { userTypes } from 'bmiq-common';
import { LOAD_USER_AUTH_MANEGMENT, LOAD_USER_LOGIN, LOAD_USERS_LIST, LOAD_CHECK_AUTHENTICATION, LOAD_INIT_AUTHENTICATION } from 'actions/users';
import getEntities from './entities';
import createErrorSelector from './create-error-selector';
import createLoadingSelector from './create-loading-selector';

const { roleGroups } = userTypes;
export const getUserState = state => state.user;

export const getRegisteredUser = state => state.entities.user;

export const authenticationError = createErrorSelector(LOAD_CHECK_AUTHENTICATION)();

export const getUser = createSelector(getUserState, state => state.user.loggedInUser);
export const getUserData = createSelector(getUser, user => user && user.data);

export const getOTToken = createSelector(getUser, user => user && user.OTToken);
export const userIsAuth = createSelector(getUser, user => !!user);
export const getVerifyError = createSelector(getUser, user => user && user.verifyError);

export const errorLogin = createErrorSelector(LOAD_USER_LOGIN)();

export const loginLoading = createLoadingSelector(LOAD_USER_LOGIN)();

export const initAuthLoading = createLoadingSelector(LOAD_INIT_AUTHENTICATION)();

export const isVerificationSent = createSelector(getEntities, entities => entities.userEmailVerificationSent);

export const getUsersList = createSelector(getEntities, ({ users }) => users.data);

export const getUsersCount = createSelector(getEntities, ({ users }) => users.total);

export const getSelectedUser = createSelector(getUserState, selectedUser => selectedUser.userRoles && selectedUser.userRoles.selectedUser);

export const userAuthmanagementLoading = createLoadingSelector(LOAD_USER_AUTH_MANEGMENT)();
export const userAuthmanagementError = createErrorSelector(LOAD_USER_AUTH_MANEGMENT)();

export const getUserId = createSelector(getUserData, userData => userData && userData.id);

export const getUserType = createSelector(getUserData, userData => userData && userData.type);

export const getUserAssociations = createSelector(getUserData, userData => userData && userData.associations);
export const getProviderAssociations = createSelector(getUserAssociations, association => association && association[roleGroups.Provider]);
export const getOrgAssociations = createSelector(getUserAssociations, association => association && association[roleGroups.Organization]);

export const getPatientAssociations = createSelector(getUserAssociations, association => association && association[roleGroups.Patient]);

export const getCurrentPatient = createSelector(getPatientAssociations, association => association?.patient);

export const getCurrentOrganization = createSelector(getOrgAssociations, association => association?.organizations?.[0]);

export const getProviderOrganizations = createSelector(getProviderAssociations, association => association?.organizations);

export const getPatientOrganization = createSelector(getPatientAssociations, association => association?.organizations?.[0]);

export const getPatientsProvider = createSelector(getPatientAssociations, association => association?.providers?.[0]);

export const getCurrentProvider = createSelector(getProviderAssociations, association => association?.providers?.[0]);

export const getPatientProviders = createSelector(getProviderAssociations, association => association?.providers);

export const getUserTimeZone = createSelector(getUserData, userData => userData && userData.timezone);

export const getUserEmail = createSelector(getUserData, userData => userData && userData.email);

export const getRolePermissions = createSelector(getUserData, userData => userData && userData.user_roles);

export const getUserDetails = createSelector(getEntities, entities => entities.user && entities.user.data);

export const getUserProfile = state =>
  createSelector(getUserData, getUserDetails, (loggedInUser, user) => {
    if (user) {
      return {
        id: user.id,
        name: user.user_detail.fullName || user.email,
      };
    }
    return {
      id: loggedInUser.id,
      name: loggedInUser.user_detail.fullName || loggedInUser.email,
    };
  })(state);

export const getProfileData = state => state.entities.profile;

export const getUserName = createSelector(getEntities, ({ profile }) => (profile.userDetails && profile.userDetails.fullName) || profile.email);

export const userIsAdmin = createSelector(getUserType, userType => userType === userTypes.USER_TYPE_ADMIN);

export const userIsProvider = createSelector(getUserType, userType => userType === userTypes.USER_TYPE_PROVIDER_ADMIN);

export const userIsPatient = createSelector(getUserType, userType => userType === userTypes.USER_TYPE_PATIENT);

export const getUserMenuType = createSelector(getUserType, userType => {
  switch (userType) {
    case userTypes.USER_TYPE_ADMIN:
      return 'admin';
    case userTypes.USER_TYPE_PATIENT:
      return 'patient';
    case userTypes.USER_TYPE_ORGANIZATION_ADMIN:
      return 'organization';
    default:
      return 'provider-admin';
  }
});

export const getUsersLoading = createLoadingSelector(LOAD_USERS_LIST)();

export const getUserRoles = createSelector(getUserData, ({ roles }) => roles);

export const getUserNewEmail = createSelector(getUserState, state => state.user.newEmail);
