import { createSelector } from 'reselect';

import { LOAD_CREATE_PROVIDER_DAILY_HOURS, LOAD_PROVIDER_DAILY_HOURS } from 'actions/provider-daily-hours';

import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const providerDailyHours = createSelector(getEntities, ({ dailyHours }) => dailyHours);

export const createProviderDailyHoursLoading = createLoadingSelector(LOAD_CREATE_PROVIDER_DAILY_HOURS)();

export const createProviderDailyHoursError = createErrorSelector(LOAD_CREATE_PROVIDER_DAILY_HOURS)();

export const getProviderDailyHoursLoading = createLoadingSelector(LOAD_PROVIDER_DAILY_HOURS)();

export const getProviderDailyHoursError = createErrorSelector(LOAD_PROVIDER_DAILY_HOURS)();

export const getCalendarState = state => state.calendar;

export const getHours = createSelector(getCalendarState, ({ calendar }) => calendar && calendar.selectedHours);
