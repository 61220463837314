import styled from 'styled-components';

const VerticalGradient = styled('div')`
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.01) 1%,
    rgba(255, 255, 255, 1) 88%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
`;

/** @component */
export default VerticalGradient;
