import React from 'react';
import styled from 'styled-components';
import { Tree as Component } from 'antd';

const Tree = styled(({ ...props }) => <Component {...props} />)``;

Tree.TreeNode = Component.TreeNode;
Tree.DirectoryTree = Component.DirectoryTree;
/** @component */
export default Tree;
