import { combineReducers } from 'redux';
import { emailActions } from 'bmiq-common';

import { CHECK_AUTHENTICATION, INIT_AUTHENTICATION, SELECT_USER_ROW, UPDATE_USER, USER_AUTH_MANEGMENT, USER_LOGIN, USER_LOGOUT } from 'actions/users';
import { UPDATE_PATIENT_ACCOUNT_INFO, LOAD_UPDATE_PATIENT_ACCOUNT_INFO } from 'actions/patients';
import { SUCCESS, FAILURE } from 'actions';
import { emptyState } from 'configs/localStorage';

const user = () => {
  const initialState = {
    loggedInUser: null,
  };

  return (
    state = initialState,
    {
      type,
      record,
      loggedInUser,
      action,
      createPassword,
      timezone,
      migrateMessageShowed,
      zipCode,
      userDetailAddress,
      verifyError,
      passExpNotification,
    },
  ) => {
    switch (type) {
      case SELECT_USER_ROW:
        return {
          ...state,
          selectedUser: record,
        };
      case USER_LOGIN[SUCCESS]:
      case INIT_AUTHENTICATION[SUCCESS]:
        return {
          ...state,
          loggedInUser,
        };
      case USER_LOGOUT[SUCCESS]:
      case CHECK_AUTHENTICATION[FAILURE]:
        // For some reason, we aren't handling log out using reducers/actions and instead performing log out with calls to the API that redirects us to the sign-in page.
        // So these never get called, at least at the time of commit.
        emptyState();
        return initialState;
      case USER_AUTH_MANEGMENT[SUCCESS]: {
        const { VERIFY_SIGNUP_LONG, PASSWORD_CHANGE, IDENTITY_CHANGE } = emailActions;
        if (action === IDENTITY_CHANGE) {
          return {
            ...state,
            newEmail: loggedInUser.newEmail,
          };
        }
        if (action === VERIFY_SIGNUP_LONG || (action === PASSWORD_CHANGE && createPassword)) {
          return {
            ...state,
            loggedInUser,
          };
        }
        return state;
      }
      case UPDATE_USER[SUCCESS]: {
        return {
          ...state,
          loggedInUser: {
            data: {
              ...state.loggedInUser.data,
              migrateMessageShowed,
              passExpNotification,
            },
          },
        };
      }
      case UPDATE_PATIENT_ACCOUNT_INFO[SUCCESS]: {
        return {
          ...state,
          loggedInUser: {
            ...state.loggedInUser,
            data: {
              ...state.loggedInUser.data,
              user_detail: {
                ...state.loggedInUser.data.user_detail,
                ...userDetailAddress,
                zip_code: zipCode || state.loggedInUser.data.user_detail.zip_code,
              },
            },
          },
        };
      }
      case UPDATE_PATIENT_ACCOUNT_INFO[FAILURE]: {
        return {
          ...state,
          loggedInUser: {
            ...state.loggedInUser,
            verifyError,
          },
        };
      }
      case LOAD_UPDATE_PATIENT_ACCOUNT_INFO: {
        return {
          ...state,
          loggedInUser: {
            ...state.loggedInUser,
            verifyError: undefined,
          },
        };
      }

      default:
        return state;
    }
  };
};

const userRoles = () => {
  const initialState = {
    selectedUser: {},
  };

  return (state = initialState, { type, error, record }) => {
    switch (type) {
      case SELECT_USER_ROW:
        return {
          ...initialState,
          selectedUser: record,
        };
      default:
        return state;
    }
  };
};

export default combineReducers({
  user: user(),
  userRoles: userRoles(),
});
