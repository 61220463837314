import client from './client';

export const createLanguageApi = data => client().post('/language', data);

export const getLanguagesApi = data => client().get('/language', data);

export const getLanguageApi = id => client().get(`/language/${id}`);

export const removeLanguagesApi = id => client().delete(`/language/${id}`);

export const editLanguagesApi = data => client().put(`/language/${data.id}`, data);
