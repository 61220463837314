module.exports = {
  integrations: {
    calendar: {
      name: 'calendar',
      scopes: [
        'https://www.googleapis.com/auth/calendar',
        'https://www.googleapis.com/auth/calendar.events',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'openid',
      ],
      redirectPath: '/callbacks/google',
    },
    googleFit: {
      name: 'googleFit',
      scopes: [
        'https://www.googleapis.com/auth/fitness.body.read',
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'openid',
      ],
      redirectPath: '/account',
    },
  },
  api: {
    userInfo: {
      url: 'https://www.googleapis.com/oauth2/v2/userinfo',
      method: 'get',
    },
    weight: {
      url: 'https://www.googleapis.com/fitness/v1/users/me/dataSources/derived:com.google.weight:com.google.android.gms:merge_weight/dataPointChanges',
      method: 'get',
    },
    sheets: {
      url: 'https://sheets.googleapis.com/v4/spreadsheets',
      method: 'get',
    },
  },
};
