import { PATIENT_CONNECTED, PATIENT_DISCONNECTED_FROM_WAITING_ROOM, VV_MEETING_ENDED } from 'actions/waiting-room';

const reducer = (() => {
  const initialState = {
    connectedUsers: [],
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case PATIENT_CONNECTED:
        return {
          ...state,
          connectedUsers: [...state.connectedUsers, action.payload],
        };
      case PATIENT_DISCONNECTED_FROM_WAITING_ROOM:
        return {
          ...state,
          connectedUsers: state.connectedUsers.filter(
            (_userId, index) =>
              index !==
              state.connectedUsers.findIndex(({ userId, sessionId }) => userId === action.payload.userId && sessionId === action.payload.sessionId),
          ),
        };
      case VV_MEETING_ENDED:
        return {
          ...state,
          connectedUsers: [],
        };
      default:
        return state;
    }
  };
})();

export default reducer;
