import React from 'react';
import { Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export const Prev = ({ onClick, className, style }) => (
  <Col onClick={onClick} className={className}>
    <LeftOutlined color='white' />
  </Col>
);

export const Next = ({ onClick, className, style }) => (
  <Col onClick={onClick} className={className}>
    <RightOutlined color='white' />
  </Col>
);
