import { call, takeLatest } from 'redux-saga/effects';

import { getPatientRecommendations, LOAD_PATIENT_RECOMMENDATIONS } from 'actions/patient-recommendations';
import { getPatientRecommendationsApi } from 'services/patient-recommendations';
import fetchEntity from './fetch-entity';

/**
 * Get Patient Recommendations
 */
const fetchPatientRecommendations = fetchEntity.bind(null, getPatientRecommendations, getPatientRecommendationsApi);

export function* loadPatientRecommendations({ type, ...data }) {
  yield call(fetchPatientRecommendations, data);
}

export function* watchPatientRecommendations() {
  yield takeLatest(LOAD_PATIENT_RECOMMENDATIONS, loadPatientRecommendations);
}
const PatientRecommendationsSagas = {
  watchPatientRecommendations,
};
export default PatientRecommendationsSagas;
