import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  LOAD_FILES,
  getFiles,
  LOAD_GET_FILE_URL,
  getFileUrl,
  LOAD_UPLOAD_FILE,
  uploadFile,
  LOAD_UPLOAD_ASSESSMENT_IMAGE,
  uploadAssessmentImage,
  LOAD_ASSESSMENT_IMAGE_URL,
  getAssessmentImageUrl,
  LOAD_REMOVE_ASSESSMENT_IMAGE,
  removeAssessmentImage,
  requestUploadFile,
  unsetFile,
} from 'actions/files';
import { UPDATE_CONVERSATION_LIST, sendMessage, sendFileMessage } from 'actions/conversation-messages';
import { getFilesApi, getFileUrlApi, uploadFileApi, uploadAssessmentImageApi, removeAssessmentImageApi } from 'services/files';
import { getFile, getUploadError, getUploadedFilesIds } from 'selectors/files';
import fetchEntity from './fetch-entity';

/**
 * Load files list
 */
const fetchFiles = fetchEntity.bind(null, getFiles, getFilesApi);

export function* loadFiles({ type, ...data }) {
  yield call(fetchFiles, data);
}

function* watchLoadFiles() {
  yield takeLatest(LOAD_FILES, loadFiles);
}

/**
 * Get file url
 */
const fetchGetFileUrl = fetchEntity.bind(null, getFileUrl, getFileUrlApi);

export function* loadGetFileUrl({ id, fileName }) {
  yield call(fetchGetFileUrl, { id, fileName });
}

function* watchGetFileUrl() {
  yield takeLatest(LOAD_GET_FILE_URL, loadGetFileUrl);
}

/**
 * Upload file after UPDATE_CONVERSATION_LIST
 */

function* upload(data) {
  const file = yield select(getFile);
  if (file) {
    yield put(requestUploadFile({ file, ...data }));
    yield put(unsetFile());
  }
}

export function* uploadFileForNewConversation(data) {
  if (data.payload.fileOwner) {
    if (data.payload.currentUser === data.payload.fileOwner) {
      yield upload({ rooms: data.payload.rooms });
    }
  } else {
    yield upload({ room: data.payload.id });
  }
}

function* watchLoadCreateNewConversation() {
  yield takeLatest(UPDATE_CONVERSATION_LIST, uploadFileForNewConversation);
}

/**
 * Upload file
 */
const fetchUpload = fetchEntity.bind(null, uploadFile, uploadFileApi);

export function* loadUpload(data) {
  yield call(fetchUpload, data);
  if (!(yield select(getUploadError))) {
    const attachments = yield select(getUploadedFilesIds);
    yield put(
      data.rooms
        ? sendFileMessage({ params: { rooms: data.rooms, attachments } })
        : sendMessage({
            params: { room: data.room, message: 'file', attachments },
          }),
    );
  }
}

function* watchLoadUpload() {
  yield takeLatest(LOAD_UPLOAD_FILE, loadUpload);
}

/**
 * Upload Assessment Image
 */
const fetchUploadAssessmentImage = fetchEntity.bind(null, uploadAssessmentImage, uploadAssessmentImageApi);

export function* loadUploadAssessmentImage({ type, ...data }) {
  yield call(fetchUploadAssessmentImage, data);
}

function* watchUploadAssessmentImage() {
  yield takeLatest(LOAD_UPLOAD_ASSESSMENT_IMAGE, loadUploadAssessmentImage);
}

/**
 * Get file url
 */
const fetchAssessmentImageUrl = fetchEntity.bind(null, getAssessmentImageUrl, getFileUrlApi);

export function* loadGetAssessmentImageUrl({ id }) {
  yield call(fetchAssessmentImageUrl, { id, isAssessment: true });
}

function* watchGetAssessmentImageUrl() {
  yield takeLatest(LOAD_ASSESSMENT_IMAGE_URL, loadGetAssessmentImageUrl);
}

/**
 * Remove Assessment Image
 */
const fetchRemoveAssessmentImage = fetchEntity.bind(null, removeAssessmentImage, removeAssessmentImageApi);

export function* loadRemoveAssessmentImage({ id }) {
  yield call(fetchRemoveAssessmentImage, { id, isAssessment: true });
}

function* watchRemoveAssessmentImage() {
  yield takeLatest(LOAD_REMOVE_ASSESSMENT_IMAGE, loadRemoveAssessmentImage);
}

const FileSagas = {
  watchLoadFiles,
  watchGetFileUrl,
  watchLoadCreateNewConversation,
  watchLoadUpload,
  watchUploadAssessmentImage,
  watchGetAssessmentImageUrl,
  watchRemoveAssessmentImage,
};
export default FileSagas;
