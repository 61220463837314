import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Paragraph from 'style-guide/Paragraph';
import StreamlineIcon from 'style-guide/StreamlineIcon';
import Button from 'style-guide/Button';
import propTypes from 'prop-types';
import Text from 'style-guide/Text';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  background-color: #fff;
  padding: 24px;
  ${props =>
    props.type === 'isOverlay' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 10;
    `};
  ${props =>
    props.$smHeight &&
    css`
      height: ${props.$smHeight};
    `}

  @media (max-width: 768px) {
    padding: 16px;
    ${props =>
      props.$xsHeight &&
      css`
        height: ${props.$xsHeight};
      `}
  }
`;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #727272;
  padding: 64px;

  .blank-state-icon {
    padding-top: 16px;
    margin-bottom: 8px;
  }
`;

const BlankState = ({
  title = '',
  icon,
  type = 'standard',
  desc,
  buttonText = '',
  buttonLink = '',
  buttonDesc = '',
  buttonClassName = '',
  onClick = () => ({}),
  text,
  $smHeight,
  $xsHeight,
  disabled,
}) => (
  <Overlay type={type} $smHeight={$smHeight} $xsHeight={$xsHeight}>
    <div>
      <Container>
        <div className='blank-state-icon'>{icon && <StreamlineIcon icon={icon} circle size={30} />}</div>
        <Text level={4} type='secondary'>
          {title}
        </Text>
        <Paragraph type='secondary' level='3'>
          {text}
        </Paragraph>
        <Paragraph type='secondary' level='2'>
          {desc}
        </Paragraph>
        {!!(buttonText && onClick) && (
          <Button className={buttonClassName} disabled={disabled} onClick={onClick} type='accent'>
            {buttonText}
          </Button>
        )}
        {!!(buttonLink && onClick) && (
          <Fragment>
            <Text level='2' type='secondary'>
              {buttonDesc}
            </Text>
            <Button onClick={onClick} type='link'>
              {buttonLink}
            </Button>
          </Fragment>
        )}
      </Container>
    </div>
  </Overlay>
);

export default BlankState;

BlankState.propTypes = {
  /** Type of Blank state , default value is standard */
  type: propTypes.oneOf(['isOverlay', 'standard']),
};
