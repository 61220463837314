import { call, takeLatest, put } from 'redux-saga/effects';
import fetchEntity from './fetch-entity';
import {
  existingPermissionsAction,
  LOAD_EXISTING_PERMISSIONS,
  LOAD_PERMISSIONS,
  LOAD_REMOVE_PERMISSION,
  LOAD_SAVE_PERMISSION,
  permissionsAction,
  removePermissionsAction,
  requestLoadPermissions,
  savePermissionsAction,
} from '../actions/permissions';
import { permissionsApi, removePermissionsApi, savePermissionsApi } from '../services/permissions';

const fetchPermissions = fetchEntity.bind(null, permissionsAction, permissionsApi);

function* loadPermissions() {
  yield call(fetchPermissions, {
    existing: false,
  });
}

export function* watchPermissions() {
  yield takeLatest(LOAD_PERMISSIONS, loadPermissions);
}

const fetchExistingPermissions = fetchEntity.bind(null, existingPermissionsAction, permissionsApi);

function* loadExistingPermissions() {
  yield call(fetchExistingPermissions, {
    existing: true,
  });
}

export function* watchExistingPermissions() {
  yield takeLatest(LOAD_EXISTING_PERMISSIONS, loadExistingPermissions);
}

const fetchSavePermissions = fetchEntity.bind(null, savePermissionsAction, savePermissionsApi);

function* loadSavePermissions({ data }) {
  yield call(fetchSavePermissions, data);
  yield put(requestLoadPermissions());
}

export function* watchSavePermissions() {
  yield takeLatest(LOAD_SAVE_PERMISSION, loadSavePermissions);
}

const fetchRemovePermissions = fetchEntity.bind(null, removePermissionsAction, removePermissionsApi);

function* loadRemovePermissions({ id }) {
  yield call(fetchRemovePermissions, id);
}

export function* watchRemovePermissions() {
  yield takeLatest(LOAD_REMOVE_PERMISSION, loadRemovePermissions);
}
