import React from 'react';
import styled, { css } from 'styled-components';
import Text from '../Text';

const DefinitionList = styled.dl`
  margin-bottom: 0;

  dd {
    margin: 0;
  }

  ${props =>
    props.inline &&
    css`
      dd,
      dt {
        display: inline-block;
      }
      dt {
        margin-right: 5px;
      }
      dt::after {
        content: ':';
      }
    `}
`;

/**
 * Props is passed to the div for this component to be used inside Tooltip
 * https://github.com/ant-design/ant-design/issues/15909
 */
const Item = ({ term, definition, termHidden, defProps, termProps, ...props }) =>
  definition ? (
    <div {...props}>
      <dt hidden={termHidden}>
        <Text {...termProps}>{term}</Text>
      </dt>
      <dd>
        <Text secondary ellipsis {...defProps}>
          {definition}
        </Text>
      </dd>
    </div>
  ) : null;

DefinitionList.Item = Item;

export default DefinitionList;
