import { SET_FILE, UNSET_FILE } from 'actions/files';

export default (() => {
  const initialState = {
    file: undefined,
  };

  return (state = initialState, { type, file }) => {
    switch (type) {
      case SET_FILE:
        return {
          ...state,
          file,
        };
      case UNSET_FILE:
        return initialState;
      default:
        return state;
    }
  };
})();
