import { action } from './index';

export const CREATE_NEW_MODAL = 'CREATE_NEW_MODAL';
export const requestCreateNewModal = data => action(CREATE_NEW_MODAL, { data });

export const REMOVE_GLOBAL_MODAL = 'REMOVE_GLOBAL_MODAL';
export const requestRemoveModal = data => action(REMOVE_GLOBAL_MODAL, { data });

export const UPDATE_GLOBAL_MODAL = 'UPDATE_GLOBAL_MODAL';
export const requestUpdateModal = data => action(UPDATE_GLOBAL_MODAL, { data });

export const REMOVE_GLOBAL_MODALS = 'REMOVE_GLOBAL_MODALS';
export const requestRemoveGlobalModals = () => action(REMOVE_GLOBAL_MODALS);
