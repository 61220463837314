module.exports = {
  VERIFY_SIGNUP: 'verifySignup',
  VERIFY_SIGNUP_LONG: 'verifySignupLong',
  RESEND_VERIFY_SIGNUP: 'resendVerifySignup',
  SEND_RESET_PWD: 'sendResetPwd',
  RESET_PWD: 'resetPwd',
  RESET_PWD_LONG: 'resetPwdLong',
  PASSWORD_CHANGE: 'passwordChange',
  EMAIL_CHANGE: 'emailChange',
  IDENTITY_CHANGE: 'identityChange',
};
