import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load patients
 */
export const PATIENTS = createRequestTypes('PATIENTS');
export const getPatients = {
  request: () => action(PATIENTS[REQUEST]),
  success: (params, response) =>
    action(PATIENTS[SUCCESS], {
      response: {
        entities: {
          patients: response,
        },
      },
    }),
  failure: (params, error) => action(PATIENTS[FAILURE], { error }),
};
export const LOAD_PATIENTS = 'LOAD_PATIENTS';
export const requestLoadPatients = data => action(LOAD_PATIENTS, data);

/**
 * Update patient
 */
export const UPDATE_PATIENT = createRequestTypes('UPDATE_PATIENT');
export const updatePatient = {
  request: () => action(UPDATE_PATIENT[REQUEST]),
  success: () => action(UPDATE_PATIENT[SUCCESS]),
  failure: (params, error) => action(UPDATE_PATIENT[FAILURE], { error }),
};
export const LOAD_UPDATE_PATIENT = 'LOAD_UPDATE_PATIENT';
export const requestUpdatePatient = data => action(LOAD_UPDATE_PATIENT, data);

/**
 * Update patient account info
 */
export const UPDATE_PATIENT_ACCOUNT_INFO = createRequestTypes('UPDATE_PATIENT_ACCOUNT_INFO');
export const updatePatientAccountInfo = {
  request: () => action(UPDATE_PATIENT_ACCOUNT_INFO[REQUEST]),
  success: (params, response) =>
    action(UPDATE_PATIENT_ACCOUNT_INFO[SUCCESS], {
      zipCode: response,
      userDetailAddress: params,
    }),
  failure: (params, error) => action(UPDATE_PATIENT_ACCOUNT_INFO[FAILURE], { verifyError: error, error }),
};
export const LOAD_UPDATE_PATIENT_ACCOUNT_INFO = 'LOAD_UPDATE_PATIENT_ACCOUNT_INFO';
export const requestUpdatePatientAccountInfo = data => action(LOAD_UPDATE_PATIENT_ACCOUNT_INFO, data);

/**
 * Get patient
 */
export const PATIENT = createRequestTypes('PATIENT');
export const getPatient = {
  request: () => action(PATIENT[REQUEST]),
  success: (params, response) =>
    action(PATIENT[SUCCESS], {
      response: {
        entities: {
          patient: {
            [params.id]: response,
          },
        },
      },
    }),
  failure: (params, error) => action(PATIENT[FAILURE], { error }),
};
export const LOAD_PATIENT = 'LOAD_PATIENT';
export const requestLoadPatient = data => action(LOAD_PATIENT, data);
