import styled, { css } from 'styled-components';
import { Collapse as collapse } from 'antd';

const Collapse = styled(collapse)`
  background: #fff !important;

  .ant-collapse-item {
    background: #f8f8f8;
    border-bottom: none !important;
  }

  .ant-collapse-header {
    font-size: 16px;
    font-weight: bold;
  }

  .ant-collapse-content {
    border-top: none;
  }

  ${props =>
    props.showArrow &&
    css`
      background: #f8f8f8 !important;
    `};
  ${props =>
    props.ghost &&
    css`
      .ant-collapse-item {
        background: #ffffff;
        border-bottom: 1px solid #e8e8e8 !important;
      }
      .ant-collapse-header {
        font-weight: normal;
      }
    `}

  ${props =>
    props.ghost &&
    css`
      .ant-collapse-item {
        background: transparent !important;
      }
    `}

  ${props =>
    props.$isTitle &&
    css`
      .ant-collapse-header {
        color: #137ac9 !important;
      }
    `};
`;
/** @component */
export default Collapse;
