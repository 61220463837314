import styled from 'styled-components';

const PlainScrollBar = styled('div')`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 8px;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f8f8f8;
    padding: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b3b2b2;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc;
  }
`;

export default PlainScrollBar;
