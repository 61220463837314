module.exports = {
  active: 'Active',
  new: 'New',
  'near-completion': 'Near completion',
  'no-weight': 'No weight',
  'recent-no-show': 'Recent no show',
  'weight-increase': 'Weight increase',
  inactive: 'Inactive',
  deactivated: 'Deactivated',
  athena: {
    ACTIVE: 'a',
    INACTIVE: 'i',
  },
};
