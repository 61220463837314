import styled, { css } from 'styled-components';
import { Menu as menu } from 'antd';

const Menu = styled(menu)`
  border-right: 0 !important;

  && {
    svg {
      position: relative;
      margin-right: 4px;
    }

    ${props =>
      props.$itemPaddings &&
      css`
        .ant-menu-item,
        .ant-dropdown-menu-item {
          padding: ${props.$itemPaddings.vertical} ${props.$itemPaddings.horizontal};
        }
      `}

    .ant-menu-item {
      border-radius: 4px;
      padding: 0 16px !important;
      margin: 0 !important;

      &:hover {
        color: #137ac9;
      }
    }

    .ant-menu-item-selected {
      background: #e6f8ff !important;

      &:hover {
        background: none;
        color: #137ac9;
      }
    }

    ${props =>
      props.$bordered &&
      css`
        .ant-dropdown-menu-item {
          border-top: 1px solid #ebebeb;
        }

        .ant-dropdown-menu-item:first-child {
          border: 0;
        }
      `}

    ${props =>
      props.$noPadding &&
      css`
        padding: 0;
      `}

    ${props =>
      props.$titleContentWidth &&
      css`
        .ant-dropdown-menu-title-content {
          width: ${props.$titleContentWidth};
        }
      `}
  }
`;

/** @component */
export default Menu;
