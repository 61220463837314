const day = require('./day');
const reminderTypes = require('./reminder-types');

const calculateDailyDuration = goalDuration => {
  const hours = 23 - goalDuration.hours();
  const minutes = 60 - goalDuration.minutes();
  let returnHours = '00';
  let returnMinutes = '00';
  if (hours >= 1) {
    returnHours = `0${hours}`;
  }
  if (hours > 9) {
    returnHours = hours;
  }
  if (minutes >= 1) {
    returnMinutes = `0${minutes}`;
  }
  if (minutes > 9) {
    returnMinutes = minutes;
  }
  return returnMinutes === 60 ? `${returnHours + 1}:00` : `${returnHours}:${returnMinutes}`;
};

module.exports = {
  duration: [
    {
      id: 1,
      title: 'Daily',
      time: '24 Hours',
      duration: 5,
    },
    {
      id: 2,
      title: 'Weekly',
      time: '7 Days',
      duration: 14,
    },
    {
      id: 3,
      title: 'Monthly',
      time: '30 Days',
      duration: 21,
    },
  ],
  completionEvent: {
    registered: 'Registering',
    'assessment-complete': 'Submitting an assessment',
    'appointment-added': 'Scheduling an appointment',
    'weight-add': 'Adding a patient entered weight',
    'message-to-provider': 'Sending a message to provider',
    'exercise-logged': 'Exercise logged',
    'food-logged': 'Food logged',
    'create-goal': 'Create goal',
  },
  assignedFrom: {
    recommendation: 'recommendation',
    provider: 'provider',
  },
  statuses: {
    complete: 'complete',
    dismissed: 'dismissed',
    expired: 'expired',
    active: 'active',
    available: 'available',
  },
  modalTypes: {
    GOAL_FORM: 0,
    NEW_GOAL: 1,
    GOAL_CATEGORIES: 2,
    SUCCESS_ACTIVATE_GOAL: 3,
    GOAL_DURATIONS: 4,
    SELECT_GOAL: 5,
    CHOSEN_GOAL_CATEGORIES: 6,
    FINAL_STEP: 7,
  },

  getRemainingTime: goal => {
    const duration = day.duration(day().diff(day(goal.updatedAt)));
    switch (goal.duration) {
      case 'Daily':
        if (duration.days() >= 1) {
          return 'expired';
        }
        return calculateDailyDuration(duration);
      case 'Weekly':
        if (duration.days() >= 7) {
          return 'expired';
        }
        return 7 - duration.days() > 1 ? `${7 - duration.days()} Days` : calculateDailyDuration(duration);
      case 'Monthly':
        if (duration.days() >= 30) {
          return 'expired';
        }
        return 30 - duration.days() > 1 ? `${30 - duration.days()} Days` : calculateDailyDuration(duration);
      default:
        return null;
    }
  },

  isTimeToReminded: goal => {
    const weeklyReminded5 = day() >= day(goal.updatedAt).add(5, 'days');
    const week = {
      [reminderTypes.REMINDED_5]: weeklyReminded5,
    };
    const monthlyReminded16 = day() >= day(goal.updatedAt).add(16, 'days');
    const monthlyReminded23 = day() >= day(goal.updatedAt).add(23, 'days');
    const monthlyReminded28 = day() >= day(goal.updatedAt).add(28, 'days');
    const month = {
      [reminderTypes.REMINDED_16]: monthlyReminded16 && !monthlyReminded23 && !monthlyReminded28,
      [reminderTypes.REMINDED_23]: monthlyReminded23 && !monthlyReminded28,
      [reminderTypes.REMINDED_28]: monthlyReminded28,
    };
    switch (goal.duration) {
      case 'Weekly':
        return Object.keys(week).filter(i => week[i]);
      case 'Monthly':
        return Object.keys(month).filter(i => month[i]);
      default:
        return undefined;
    }
  },
};
