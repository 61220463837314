import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getEntities from './entities';

import { LOAD_GET_ACTIVITY_LOGS } from '../actions/activity-manager';

// loading selectors
export const getActivityLogsLoading = createLoadingSelector(LOAD_GET_ACTIVITY_LOGS)();

// error selectors
export const getActivityLogsError = createErrorSelector(LOAD_GET_ACTIVITY_LOGS)();

export const selectActivityLogs = createSelector(getEntities, entities => entities.activityLogs);

export const selectActivityLogsList = createSelector(selectActivityLogs, logsObj => logsObj.logs);

export const selectActivityLogsCount = createSelector(selectActivityLogs, logsObj => logsObj.total);

export const selectTriggerCodes = createSelector(selectActivityLogs, logsObj => logsObj.triggerCodes);

export const getFilteredTriggers = ({ triggers }) => triggers.data;

export const getCheckedTriggers = ({ triggers }) => triggers.checkedTriggers;
