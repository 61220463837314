import { takeLatest, call } from 'redux-saga/effects';

import { deduLoginApi, deduSitesApi } from 'services/dedu';
import { deduLogin, deduSites, LOAD_DEDU_LOGIN, LOAD_DEDU_SITES } from 'actions/dedu';
import fetchEntity from './fetch-entity';

/**
 * Get dedu login
 */
const fetchLoginDedu = fetchEntity.bind(null, deduLogin, deduLoginApi);

export function* loadLoginDedu(data) {
  yield call(fetchLoginDedu, data);
}

function* watchLoadLoginDedu() {
  yield takeLatest(LOAD_DEDU_LOGIN, loadLoginDedu);
}

/**
 * Get dedu sites
 */
const fetchSitesDedu = fetchEntity.bind(null, deduSites, deduSitesApi);

export function* loadSitesDedu(data) {
  yield call(fetchSitesDedu, data);
}

function* watchLoadSitesDedu() {
  yield takeLatest(LOAD_DEDU_SITES, loadSitesDedu);
}

const DeduSagas = {
  watchLoadLoginDedu,
  watchLoadSitesDedu,
};
export default DeduSagas;
