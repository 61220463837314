import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import theme from './theme';

/** @component */
const Margin = styled.div`
  ${props =>
    props.top &&
    css`
      margin-top: ${theme.spacing[props.top]};
    `}
  ${props =>
    props.bottom &&
    css`
      margin-bottom: ${theme.spacing[props.bottom]};
    `}
  ${props =>
    props.right &&
    css`
      margin-right: ${theme.spacing[props.right]};
    `}
  ${props =>
    props.left &&
    css`
      margin-left: ${theme.spacing[props.left]};
    `}
  ${props =>
    props.horizontal &&
    css`
      margin-top: ${theme.spacing[props.horizontal]};
      margin-bottom: ${theme.spacing[props.horizontal]};
    `}
  ${props =>
    props.vertical &&
    css`
      margin-right: ${theme.spacing[props.vertical]};
      margin-left: ${theme.spacing[props.vertical]};
    `}
`;

Margin.propTypes = {
  vertical: propTypes.number,
  horizontal: propTypes.number,
  top: propTypes.number,
  right: propTypes.number,
};

export { Margin };
