import socketClient from 'services/socketClient';
import { CONVERSATION_EVENTS as events, userTypes } from 'bmiq-common';
import {
  UNSUBSCRIBE_FROM_ROOM,
  LOAD_INIT_MESSAGE,
  SEND_MESSAGE,
  SEND_FILE_MESSAGE,
  CONVERSATION_ERROR,
  RECEIVE_MESSAGE,
  CONVERSATION_DISCONNECTED,
  SOCKET_CONNECTED,
  INIT_MESSAGE,
  READ_MESSAGE,
  MESSAGE_WAS_READ,
  RECONNECT_SOCKET,
  CREATE_NEW_CONVERSATION,
  UPDATE_CONVERSATION_LIST,
} from 'actions/conversation-messages';
import { CONNECT_TO_WAITING_ROOM, PATIENT_CONNECTED, PATIENT_DISCONNECTED, PATIENT_DISCONNECTED_FROM_WAITING_ROOM } from 'actions/waiting-room';
import { PUSH_NOTIFICATION } from 'actions/notifications';

const createSocketMiddleware = () => storeAPI => {
  const socket = socketClient();
  const pushVideoVisitNotification = ({ user, data }) => {
    // we are supporting only 2 user video visit for now
    if (user.type === userTypes.USER_TYPE_PATIENT && data?.isVideoCall && data?.participants?.length === 2) {
      storeAPI.dispatch({
        type: PUSH_NOTIFICATION,
        data: {
          title: 'Join a Virtual Appointment',
          description: `${data?.senderInfo.fullName || data?.senderInfo.email} has started a virtual appointment`,
          notificationType: 'video',
          btnProps: {
            conferenceId: data?.payload?.conferenceId,
            participants: data.participants,
            talkingWith: `${data?.senderInfo?.firstName} ${data?.senderInfo?.lastName?.[0] || ''}.`,
          },
        },
      });
    }
  };
  socket.on('connect', connection => {
    storeAPI.dispatch({
      type: SOCKET_CONNECTED,
      payload: connection,
    });
  });
  socket.on('disconnect', data => {
    storeAPI.dispatch({
      type: CONVERSATION_DISCONNECTED,
      payload: data,
    });
  });
  socket.on(events.INITIAL_MESSAGES, data => {
    storeAPI.dispatch({
      type: INIT_MESSAGE,
      payload: data,
    });
  });
  socket.on(events.RECEIVE_MESSAGE, data => {
    const {
      user: {
        user: { loggedInUser = {} },
      },
    } = storeAPI.getState();
    storeAPI.dispatch({
      type: RECEIVE_MESSAGE,
      payload: {
        ...data,
        currentUser: loggedInUser ? loggedInUser.data.id : null,
      },
    });
    pushVideoVisitNotification({ user: loggedInUser?.data, data: data?.result });
  });
  socket.on(events.UPDATE_CONVERSATIONS, data => {
    const {
      user: {
        user: { loggedInUser = {} },
      },
    } = storeAPI.getState();
    if (data.pushNotification) {
      pushVideoVisitNotification({
        user: loggedInUser?.data,
        data: {
          isVideoCall: true,
          participants: data.participants.map(({ userId }) => userId),
          senderInfo: data.participants.find(res => res.userId === data.owner),
          payload: data.payload,
        },
      });
    }
    storeAPI.dispatch({
      type: UPDATE_CONVERSATION_LIST,
      payload: {
        ...data,
        currentUser: loggedInUser ? loggedInUser.data.id : null,
      },
    });
  });
  socket.on(events.MESSAGE_WAS_READ, data => {
    storeAPI.dispatch({
      type: MESSAGE_WAS_READ,
      payload: data,
    });
  });
  socket.on(events.CONVERSATION_ERROR, data => {
    storeAPI.dispatch({
      type: CONVERSATION_ERROR,
      payload: data,
    });
  });
  socket.on(events.PATIENT_CONNECTED_VV, data => {
    storeAPI.dispatch({
      type: PATIENT_CONNECTED,
      payload: data,
    });
  });
  socket.on(events.PATIENT_DISCONNECTED_VV, data => {
    storeAPI.dispatch({
      type: PATIENT_DISCONNECTED_FROM_WAITING_ROOM,
      payload: data,
    });
  });

  return next => action => {
    const { type, params, data: payload } = action || {};
    const socketEmit = (event, data = params) => socket.emit(event, data);
    switch (type) {
      case RECONNECT_SOCKET: {
        socket.disconnect();
        socket.io.opts.query = {
          ...socket.io.opts.query,
        };
        if (!socket.connected) {
          socket.io.opts.query.isLogin = true;
          socket.connect();
          socket.io.opts.query.isLogin = false;
        }
        break;
      }
      case LOAD_INIT_MESSAGE: {
        socketEmit(events.SUBSCRIBE, { room: params.room });
        socketEmit(events.INIT_MESSAGES);
        next(action);
        break;
      }
      case SEND_MESSAGE: {
        socketEmit(events.SEND_MESSAGE);
        break;
      }
      case SEND_FILE_MESSAGE: {
        socketEmit(events.SEND_FILE_MESSAGE);
        break;
      }
      case READ_MESSAGE: {
        socketEmit(events.READ_MESSAGE);
        break;
      }
      case UNSUBSCRIBE_FROM_ROOM: {
        socketEmit(events.UNSUBSCRIBE);
        break;
      }
      case CREATE_NEW_CONVERSATION: {
        socketEmit(events.ADD_NEW_CONVERSATION);
        break;
      }
      case CONNECT_TO_WAITING_ROOM: {
        socketEmit(events.PATIENT_CONNECTED_VV, { data: payload });
        break;
      }
      case PATIENT_DISCONNECTED: {
        socketEmit(events.PATIENT_DISCONNECTED_VV, { data: payload });
        break;
      }
      default:
        next(action);
    }
  };
};

export default createSocketMiddleware;
