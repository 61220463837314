const gendersObject = {
  male: 1,
  female: 2,
  other: 3,
  unknown: 4,
};
const genderMapper = {
  1: 'Male',
  2: 'Female',
  3: 'Other',
  4: 'Unknown',
};
module.exports = {
  genderMapper,
  gendersObject,
};
// todo update this to store ENUM (male, female) directly in the database.
