import client from './client';

export const rolesApi = () => client().get('/roles');

export const providersRolesApi = () => client().get('/providers-roles');

export const createRolesApi = data => client().post('/roles', data);

export const updateRolesApi = data => client().put(`/roles/${data.id}`, data);

export const rolePermissionsApi = data => client().get('/roles', { params: { id: data.id } });

export const createRolePermissionsApi = data => client().post('/role-permissions', data);

export const createUserRoleApi = data => client().post('/user-roles', data);

export const removeRoleApi = id => client().delete('/roles', { params: { id } });

export const removeRolePermissionApi = ({ id, actionName }) => client().delete('/role-permissions', { params: { id, actionName } });

export const removeUserRoleApi = id => client().delete('/user-roles', { params: { id } });
