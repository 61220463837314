import client from './client';

export const permissionsApi = ({ existing = false }) => client().get('/permissions', { params: { existing } });

export const savePermissionsApi = data => client().post('/permissions', data);

export const removePermissionsApi = id =>
  client().delete('/permissions', {
    params: { id },
  });
