import Promise from 'bluebird';
import { SOCKET_ROOT } from 'configs/env-vars';
import io from 'socket.io-client';

Promise.config({
  cancellation: true,
});

// overwrite native Promise implementation with Bluebird's
window.Promise = Promise;

const socketClient = () => {
  const socket = io(SOCKET_ROOT, {
    query: {
      isLogin: false,
    },
  });
  return socket;
};
export default socketClient;
