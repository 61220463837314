import axios from 'axios';
import Promise from 'bluebird';
import { API_ROOT } from 'configs/env-vars';
import { emptyState } from 'configs/localStorage';

Promise.config({
  cancellation: true,
});

// overwrite native Promise implementation with Bluebird's
window.Promise = Promise;

const ClientServices = (
  headers = {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache, no-store',
    ...(process.env.NODE_ENV === 'production' ? { 'csrf-token': document.getElementById('csrf-token').content } : {}),
  },
) => {
  const service = axios.create({
    baseURL: API_ROOT, // url of the api
    headers,
    withCredentials: true,
  });
  service.interceptors.response.use(
    response => response,
    error => {
      const errorResponse = error.response;
      if (process.env.NODE_ENV === 'production') {
        switch (errorResponse.status) {
          case 404:
            window.location.pathname = '/not-found';
            break;
          case 403:
            window.location.pathname = '/not-permitted';
            break;
          default:
            break;
        }
      }
      if (errorResponse?.status === 401) {
        emptyState();
        window.location.reload();
      }
      throw error;
    },
  );
  return service;
};

export default ClientServices;
