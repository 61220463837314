import { SELECT_COUNTRY, UNSELECT_COUNTRY } from 'actions/countries';

export default (() => {
  const initialState = {
    countryId: undefined,
  };

  return (state = initialState, { type, countryId }) => {
    switch (type) {
      case SELECT_COUNTRY:
        return {
          ...state,
          countryId,
        };
      case UNSELECT_COUNTRY:
        return initialState;
      default:
        return state;
    }
  };
})();
