module.exports = {
  // errors events
  CONVERSATION_ERROR: 'CONVERSATION_ERROR',
  // room events
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  // message events
  INIT_MESSAGES: 'INIT_MESSAGES',
  INITIAL_MESSAGES: 'INITIAL_MESSAGES',
  SEND_MESSAGE: 'SEND_MESSAGE',
  SEND_FILE_MESSAGE: 'SEND_FILE_MESSAGE',
  RECEIVE_MESSAGE: 'RECEIVE_MESSAGE',
  READ_MESSAGE: 'READ_MESSAGE',
  MESSAGE_WAS_READ: 'MESSAGE_WAS_READ',
  ATTACH_FILE: 'ATTACH_FILE',
  LOAD_PREV_MESSAGES: 'LOAD_PREV_MESSAGES',
  PREV_MESSAGES: 'LOAD_PREV_MESSAGES',
  ARCHIVE_MESSAGES: 'ARCHIVE_MESSAGES',
  MESSAGES_ARCHIVED: 'ARCHIVE_MESSAGES',
  TYPING: 'TYPING',
  USER_TYPING: 'USER_TYPING',
  ADD_NEW_USER: 'ADD_NEW_USER',
  NEW_USER_ADDED: 'NEW_USER_ADDED',
  ADD_NEW_CONVERSATION: 'ADD_NEW_CONVERSATION',
  UPDATE_CONVERSATIONS: 'UPDATE_CONVERSATIONS',
  // waiting room events
  PATIENT_CONNECTED_VV: 'PATIENT_CONNECTED',
  PATIENT_DISCONNECTED_VV: 'PATIENT_DISCONNECTED',
};
