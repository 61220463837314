import { takeLatest, call, select, put } from 'redux-saga/effects';

import {
  LOAD_CREATE_PROGRAM,
  createProgram,
  LOAD_PROGRAMS,
  getPrograms,
  LOAD_UPDATE_PROGRAM,
  updateProgram,
  LOAD_REMOVE_PROGRAM,
  removeProgram,
  requestLoadPrograms,
} from 'actions/programs';
import { createProgramApi, getProgramsApi, updateProgramApi, removeProgramApi } from 'services/programs';
import { removeProgramError } from 'selectors/programs';
import { setOffset } from 'actions/pagination';

import fetchEntity from './fetch-entity';

/**
 * Create program
 */
const fetchCreateProgram = fetchEntity.bind(null, createProgram, createProgramApi);

function* loadCreateProgram({ type, ...data }) {
  yield call(fetchCreateProgram, data);
  yield put(setOffset(0));
  yield put(requestLoadPrograms());
}

export function* watchLoadCreateProgram() {
  yield takeLatest(LOAD_CREATE_PROGRAM, loadCreateProgram);
}

/**
 * Load programs
 */
const fetchLoadPrograms = fetchEntity.bind(null, getPrograms, getProgramsApi);

function* loadPrograms() {
  yield call(fetchLoadPrograms);
}

export function* watchLoadPrograms() {
  yield takeLatest(LOAD_PROGRAMS, loadPrograms);
}
/**
 * Remove program
 */
const fetchRemoveProgram = fetchEntity.bind(null, removeProgram, removeProgramApi);

function* loadRemoveProgram({ id }) {
  yield call(fetchRemoveProgram, id);
  if (!(yield select(removeProgramError))) {
    yield put(requestLoadPrograms());
  }
}

export function* watchLoadRemoveProgram() {
  yield takeLatest(LOAD_REMOVE_PROGRAM, loadRemoveProgram);
}

/**
 * Update program
 */
const fetchUpdateProgram = fetchEntity.bind(null, updateProgram, updateProgramApi);

function* loadUpdateProgram({ type, ...data }) {
  yield call(fetchUpdateProgram, data);
  yield put(setOffset(0));
  yield put(requestLoadPrograms());
}

export function* watchLoadUpdateProgram() {
  yield takeLatest(LOAD_UPDATE_PROGRAM, loadUpdateProgram);
}
const ProgramSagas = {
  watchLoadCreateProgram,
  watchLoadPrograms,
  watchLoadRemoveProgram,
  watchLoadUpdateProgram,
};

export default ProgramSagas;
