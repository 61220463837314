import { createSelector } from 'reselect';

import {
  LOAD_ASSESSMENT_IMAGE_URL,
  LOAD_GET_FILE_URL,
  LOAD_REMOVE_ASSESSMENT_IMAGE,
  LOAD_UPLOAD_ASSESSMENT_IMAGE,
  LOAD_UPLOAD_FILE,
} from 'actions/files';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getFiles = createSelector(getEntities, ({ files }) => files);

export const getFileUrlLoading = createLoadingSelector(LOAD_GET_FILE_URL)();
export const getFileUrlError = createErrorSelector(LOAD_GET_FILE_URL)();

export const getUploadLoading = createLoadingSelector(LOAD_UPLOAD_FILE)();
export const getUploadError = createErrorSelector(LOAD_UPLOAD_FILE)();

export const getAssessmentImageUrlLoading = createLoadingSelector(LOAD_ASSESSMENT_IMAGE_URL)();
export const getAssessmentImageUrlError = createErrorSelector(LOAD_ASSESSMENT_IMAGE_URL)();

export const assessmentImageUploading = createLoadingSelector(LOAD_UPLOAD_ASSESSMENT_IMAGE)();
export const assessmentImageUploadingError = createErrorSelector(LOAD_UPLOAD_ASSESSMENT_IMAGE)();

export const removeAssessmentImageLoading = createLoadingSelector(LOAD_REMOVE_ASSESSMENT_IMAGE)();
export const removeAssessmentImageError = createErrorSelector(LOAD_REMOVE_ASSESSMENT_IMAGE)();

export const getFile = ({ file }) => file.file;

export const getUploadedFilesIds = createSelector(getEntities, entities => entities.uploadedFilesIds);

export const getAssessmentImageUrl = createSelector(getEntities, ({ assessmentImageUrl }) => assessmentImageUrl);
