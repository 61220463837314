import React from 'react';
import DOMPurify from 'dompurify';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { Row, Col } from 'antd';
import themes from '../theme';
import Avatar from '../Avatar';
import Text from '../Text';
import AntMessage from '../Message';

const Box = styled.div`
  &.text-in-message {
    display: block;
    word-wrap: break-word;
    white-space: pre-line;
  }

  ${props =>
    props.bg &&
    css`
      background: ${props.bg === 'primary' ? themes['primary-color'] : themes['text-color-inactive']};
      margin: ${props.bg === 'primary' ? '8px 16px' : '4px 24px'};
    `};
  ${props =>
    props.position &&
    css`
      float: ${props.position};
    `};
  ${props =>
    props.width &&
    css`
      @media screen and (max-width: 576px) {
        width: 130px;
      }
      width: ${props.width};
    `};
  ${props =>
    props.textColor &&
    css`
      .ant-typography {
        color: ${props.textColor === 'white' ? '#fff' : themes['text-color-primary']} !important;
      }
    `};
  border-radius: 4px;
  padding: 0 8px 8px 8px;
`;

const Arrow = styled.div`
  ${props =>
    props.bg &&
    css`
      background: ${props.bg === 'primary' ? themes['primary-color'] : themes['text-color-inactive']};
    `};
  ${props =>
    props.position &&
    css`
      right: ${props.right};
    `};
  position: relative;
  transform: matrix(-0.71, -0.59, -0.85, 0.71, 0, 0);
  top: 7px;
  width: 8.12px;
  height: 8.12px;
`;

const FileLink = styled('div')`
  cursor: pointer;
  word-break: break-word;
  text-align: center;
  font-size: 14px;
  ${props =>
    props.color &&
    css`
      color: ${props.color === 'white' ? ' #fff' : themes['text-color-primary']};
    `}

  &:hover {
    text-decoration: underline;
  }
`;
const VideoCallWrapper = styled('span')`
  color: ${props => props.color || 'blue'};
  cursor: pointer;
  text-decoration: underline;
`;
// eslint-disable-next-line consistent-return
const transform = (node, index) => {
  if (node.type === 'tag' && node.name === 'a') {
    node.attribs.target = '_blank';
    node.attribs.style = `${node.attribs.style ? `${node.attribs.style};` : ''} text-decoration: underline;`;
    return convertNodeToElement(node, index, transform);
  }
};

const getRight = (position, pathname) => {
  if (pathname === '/messages/') {
    return position === 'right' ? '-100.5%' : '2%';
  }
  return position === 'right' ? '-101%' : '6%';
};

const getWidth = (attachments, loading, pathname) => {
  if (pathname === '/messages/') {
    return '700px';
  }
  return attachments?.length === 1 || loading ? '161px' : '222px';
};

const getMessage = ({
  pathname,
  message,
  bg,
  textColor,
  attachments,
  loading,
  position,
  requestGetFileUrl,
  isVideoCall,
  isMe,
  onVideoClick,
  isOneOfUsersDisabled,
}) => (
  <Row>
    <Col xs={24}>
      <Box position={position} bg={bg} textColor={textColor} width={getWidth(attachments, loading, pathname)}>
        <Arrow position={position} bg={bg} right={getRight(position, pathname)} />
        {loading ? <LoadingMessage textColor={textColor} /> : null}
        {message ? (
          <Text level={2} className='text-in-message'>
            {!isVideoCall ? (
              ReactHtmlParser(DOMPurify.sanitize(message, { ALLOWED_TAGS: ['a', 'br'] }), {
                decodeEntities: true,
                transform,
              })
            ) : (
              <VideoCallWrapper
                onClick={() => {
                  if (isOneOfUsersDisabled) {
                    AntMessage.error('Sorry, but you cannot start a virtual visit with a deactivated patient.');
                    return;
                  }
                  onVideoClick();
                }}
                color={isMe ? 'white' : 'blue'}
              >
                Click to join the virtual visit
              </VideoCallWrapper>
            )}
          </Text>
        ) : null}
        {attachments.map(({ id, fileName, icon }) => (
          <div>
            <FileLink onClick={() => requestGetFileUrl(id, fileName)} color={textColor} title={fileName}>
              {icon}
              <div title={fileName}>{fileName}</div>
            </FileLink>
          </div>
        ))}
      </Box>
    </Col>
  </Row>
);

const LoadingMessage = ({ textColor }) => (
  <Row type='flex' justify='center'>
    <LoadingOutlined size={20} color={textColor} />
  </Row>
);

const ChatMessage = ({
  pathname,
  message,
  bg,
  textColor,
  sender,
  newMessage,
  attachments,
  loading,
  requestGetFileUrl,
  isVideoCall,
  onVideoClick,
  isOneOfUsersDisabled,
}) => {
  const nameObject = sender && sender.name && sender.name.split(' ');
  return sender ? (
    <Row>
      <Col span={2} style={{ paddingLeft: 15, maxWidth: '35px' }}>
        <Avatar style={{ marginTop: 20 }}>{nameObject && `${nameObject[0].charAt(0)}${nameObject[1].charAt(0)}`}</Avatar>
      </Col>
      <Col span={22}>
        <Row>
          <Col offset={2} span={16}>
            <Text style={{ position: 'relative', color: 'grey' }}>{sender.name}</Text>
          </Col>
        </Row>
        {getMessage({
          pathname,
          message,
          bg,
          textColor,
          attachments,
          loading,
          position: 'left',
          requestGetFileUrl,
          isVideoCall,
          onVideoClick,
          isOneOfUsersDisabled,
        })}
      </Col>
    </Row>
  ) : (
    getMessage({
      pathname,
      message,
      bg,
      textColor,
      attachments,
      loading,
      position: 'right',
      requestGetFileUrl,
      isVideoCall,
      isMe: true,
      onVideoClick,
      isOneOfUsersDisabled,
    })
  );
};

ChatMessage.propTypes = {
  /** The message that should be displayed */
  message: propTypes.string,
  /** the background of message box */
  bg: propTypes.oneOf(['primary', 'secondary']),
  /** the position of message box */
  position: propTypes.oneOf(['right', 'left']),
  /** the color of the text in the box */
  textColor: propTypes.oneOf(['white', 'grey']),
  /** attachment list  */
  attachments: propTypes.array,
  /** When true show loading message */
  loading: propTypes.bool,
};

ChatMessage.defaultProps = {
  bg: 'secondary',
  position: 'left',
  textColor: 'grey',
  attachments: [],
  loading: false,
  onVideoClick: () => {},
};

/** @component */
export default ChatMessage;
