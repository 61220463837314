module.exports = {
  availableColumns: [
    {
      id: 1,
      content: 'Name',
      rowKey: 'patient',
      csvKey: 'name',
      csvLabel: 'Name',
      exportable: true,
      locked: true,
    },
    {
      id: 2,
      content: 'Status',
      rowKey: 'status',
      csvKey: 'status',
      csvLabel: 'Status',
      exportable: true,
      locked: false,
    },
    {
      id: 3,
      content: 'Baseline Wt. (Source: Patient)',
      rowKey: 'baseline_weight',
      csvKey: 'baseline_weight',
      csvLabel: 'Baseline Wt.',
      exportable: true,
      locked: false,
    },
    {
      id: 4,
      content: 'Last Wt. (Source: Patient)',
      rowKey: 'last_weight',
      csvKey: 'last_weight',
      csvLabel: 'Last Wt.',
      exportable: true,
      locked: false,
    },
    {
      id: 5,
      content: 'Wt. Change % (Source: Patient)',
      rowKey: 'weight_change',
      csvKey: 'weight_change',
      csvLabel: 'Wt. Change %',
      exportable: true,
      locked: false,
    },
    {
      id: 6,
      content: 'Record Updated',
      rowKey: 'last_contact',
      csvKey: 'last_contact',
      csvLabel: 'Record Updated',
      exportable: true,
      locked: false,
    },
    {
      id: 7,
      content: 'Tags',
      rowKey: 'tags',
      csvKey: 'tags',
      csvLabel: 'Tags',
      exportable: true,
      locked: false,
    },
    {
      id: 8,
      content: 'Providers',
      rowKey: 'providers',
      csvKey: 'providers',
      csvLabel: 'Providers',
      exportable: true,
      locked: false,
    },
    {
      id: 9,
      content: 'Organization',
      rowKey: 'organization',
      csvKey: 'organization',
      csvLabel: 'Organization',
      exportable: true,
      locked: false,
    },
    {
      id: 10,
      content: 'Program',
      rowKey: 'program',
      csvKey: 'program',
      csvLabel: 'Program',
      exportable: true,
      locked: false,
    },
    {
      id: 11,
      content: 'Actions',
      rowKey: 'actions',
      exportable: false,
      locked: true,
    },
    {
      id: 12,
      content: 'Identifier',
      rowKey: 'ehr',
      csvKey: 'ehr',
      csvLabel: 'EHR ID',
      exportable: true,
      locked: false,
    },
    {
      id: 13,
      content: 'Test User',
      rowKey: 'isTestUser',
      exportable: false,
      locked: false,
    },
    {
      id: 14,
      content: 'Birth Date',
      rowKey: 'birthDate',
      csvKey: 'birthDate',
      csvLabel: 'Birth Date',
      exportable: true,
      locked: false,
    },
    {
      id: 15,
      content: 'Phone Number',
      rowKey: 'mobilePhone',
      csvKey: 'phone',
      csvLabel: ['Mobile Phone', 'Home Phone'],
      exportable: true,
      locked: false,
    },
    {
      id: 16,
      content: 'Email',
      rowKey: 'email',
      csvKey: 'email',
      csvLabel: 'Email',
      exportable: true,
      locked: false,
    },
    {
      id: 17,
      content: 'Last Login',
      rowKey: 'lastLogin',
      csvKey: 'lastLogin',
      csvLabel: 'Last Login',
      exportable: true,
      locked: false,
    },
    {
      id: 18,
      content: 'Organization Tags',
      rowKey: 'organizationTags',
      csvKey: 'organizationTags',
      csvLabel: 'Organization Tags',
      exportable: true,
      locked: false,
    },
  ],
  conditionalColumns: [
    {
      id: 19,
      content: 'AthenaNet Identifier',
      rowKey: 'athenaId',
      csvKey: 'athenaId',
      csvLabel: 'AthenaNet Identifier',
      exportable: true,
      locked: false,
    },
  ],
  columns: {
    patient: 1,
    status: 2,
    baseline_weight: 3,
    last_weight: 4,
    weight_change: 5,
    last_contact: 6,
    tags: 7,
    providers: 8,
    organization: 9,
    program: 10,
    action: 11,
    ehr: 12,
    isTestUser: 13,
    birthDate: 14,
    mobilePhone: 15,
    email: 16,
    lastLogin: 17,
    organizationTags: 18,
    athenaId: 19,
  },
};
