import { PUSH_NOTIFICATION, REMOVE_PUSH_NOTIFICATION } from 'actions/notifications';
import day from 'utils/day';

export default (() => {
  const initialState = {
    notifications: [],
    pushNotifications: [],
  };

  return (state = initialState, { type, data }) => {
    switch (type) {
      case PUSH_NOTIFICATION: {
        const notificationExists = state.pushNotifications.some(
          ({ title, description, notificationType }) =>
            title === data.title && description === data.description && notificationType === data.notificationType,
        );
        return notificationExists
          ? { ...state }
          : {
              ...state,
              pushNotifications: [
                ...state.pushNotifications,
                {
                  id: `${day().unix()}`,
                  ...data,
                },
              ],
            };
      }
      case REMOVE_PUSH_NOTIFICATION:
        return {
          ...state,
          pushNotifications: state.pushNotifications.filter(res => res.id !== data.id),
        };
      default:
        return state;
    }
  };
})();
