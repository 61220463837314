import { combineReducers } from 'redux';
import entities from './entities';
import loading from './loading';
import error from './error';
import role from './role';
import user from './user';
import language from './language';
import country from './country';
import _state from './state';
import zipCode from './zip-code';
import providers from './providers';
import pagination from './pagination';
import filter from './filter';
import appointments from './appointments';
import conversations from './conversations';
import file from './file';
import graphOptions from './graph-options';
import globalModal from './global-modal';
import notifications from './notifications';
import waitingRoom from './waiting-room';
import triggers from './triggers';

const appReducer = combineReducers({
  entities,
  loading,
  error,
  user,
  role,
  language,
  country,
  _state,
  zipCode,
  providers,
  pagination,
  filter,
  appointments,
  conversations,
  file,
  graphOptions,
  globalModal,
  notifications,
  waitingRoom,
  triggers,
});

const Reducers = (state, action) => appReducer(state, action);
export default Reducers;
