import styled from 'styled-components';
import Text from '../Text';

const DraggableCardWrapper = styled('div')`
  background: #f8f8f8;
  border: 1px solid #efefef;
  border-radius: 4px;
  position: relative;
  padding: 16px 8px;
  margin-bottom: 8px;

  &.disabled {
    opacity: 0.6;
  }

  > span {
    padding-right: 40px;
  }

  .draggable-card-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #adadad;
    font-size: 24px;
  }
`;

const DraggableCard = ({ title, icon, disabled }) => (
  <DraggableCardWrapper className={disabled && 'disabled'}>
    <div className='draggable-card-icon'>{icon}</div>
    <Text ellipsis>{title}</Text>
  </DraggableCardWrapper>
);
/** @component */
export default DraggableCard;
