import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

export const useRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  return { history, location, params };
};
export const useQueryParams = () => {
  const location = useLocation();
  return queryString.parse(location.search);
};

export const useRouterParams = useParams;

export const useRouterHistory = useHistory;

export const useRouterLocation = useLocation;

export const useRouterMatch = useRouteMatch;
