import React, { PureComponent } from 'react';
import propTypes from 'prop-types';

import Form from '../Form';
import Modal from '../Modal';

const FormItem = Form.Item;
class FormModal extends PureComponent {
  componentDidUpdate(prevProps) {
    const { error, modalProps, form, validateForm } = this.props;
    if (this.props.modalProps.visible && !prevProps.modalProps.visible) {
      this.props.onOpen();
    }
    if (!modalProps.okButtonProps.loading && prevProps.modalProps.okButtonProps.loading) {
      if (error && error !== prevProps.error) {
        validateForm(form, error);
        return;
      }
      this.props.onSuccess();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        okText={this.props.okText || 'Submit'}
        okType='primary'
        onOk={this.handleSubmit}
        onCancel={this.cancel}
        afterClose={this.props.form.resetFields}
        {...this.props.modalProps}
      >
        <Form onSubmit={this.handleSubmit}>
          {this.props.fields.map(field => (
            <FormItem key={field.name} {...this.props.serverValidation(field.name, this.props.form, this.props.error)} {...field.formItemProps}>
              {getFieldDecorator(field.name, {
                onChange: () => {
                  if (field.resetFieldsOnChange) {
                    const resettingFields = {};
                    this.props.fields.forEach(item => {
                      resettingFields[item.name] = undefined;
                    });
                    this.props.form.setFieldsValue(resettingFields);
                  }
                },
                initialValue: field.initialValue,
                rules: field.rules ? [...field.rules] : [],
                ...field.fieldDecoratorOptions,
              })(field.component)}
            </FormItem>
          ))}
        </Form>
      </Modal>
    );
  }

  cancel = () => {
    this.props.clearData();
    this.props.hideModal();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.request({
          ...this.props.row,
          ...values,
        });
      }
    });
    this.props.hideModal();
  };

  handleCancel = () => {
    this.props.resetOnCancel && this.props.form.resetFields();
    this.props.hideModal(this.props.modalName);
  };
}

FormModal.propTypes = {
  /**  */
  onOpen: propTypes.func,
  /**  */
  onSuccess: propTypes.func,
  /**  */
  fields: propTypes.array,
  /**  */
  error: propTypes.oneOfType([propTypes.object, propTypes.bool]),
  /** Clearing selected row data from state */
  clearData: propTypes.func,
  /**  */
  hideModal: propTypes.func,
  /** Back end validation error
   @param {boolean} visible <br/>
   @param {object} okButtonProps
   @param {string} title
   */
  modalProps: propTypes.object,
  /**  */
  validateForm: propTypes.func,
  /**  */
  serverValidation: propTypes.func,
};

FormModal.defaultProps = {
  clearData: () => {},
  modalProps: {
    title: '',
    visible: false,
    okButtonProps: {
      loading: false,
    },
  },
  error: undefined,
  onOpen: () => {},
  onSuccess: () => {},
  validateForm: () => {},
  serverValidation: () => {},
  fields: [],
  hideModal: () => undefined,
};
const WrappedComponent = Form.create()(FormModal);

/** @component */
export default WrappedComponent;
