import React from 'react';
import Select from '../Select';

const { Option } = Select;

const BmiqAutoComplete = props => (
  <Select mode='multiple' filterOption={false} {...props}>
    {props.filteredValues.map(item => (
      <Option key={item.id}>{item.name}</Option>
    ))}
  </Select>
);

BmiqAutoComplete.defaultProps = {
  filteredValues: [],
};

/** @component */
export default BmiqAutoComplete;
