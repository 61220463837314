const themes = {
  // eslint-disable-next-line no-undef
  ...THEME_VARIABLES,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  spacing: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '1rem',
    4: '1.5rem',
    5: '1.2rem',
  },
};

export default themes;
