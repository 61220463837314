import React from 'react';
import styled from 'styled-components';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import day from 'utils/day';

const datePicker = generatePicker(dayjsGenerateConfig);
const StyledDatePicker = styled(datePicker)`
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  box-shadow: none;
  display: block;

  input {
    font-size: 16px !important;
    padding-top: 4px;
    padding-bottom: 4px;
    height: auto;
  }

  .ant-input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;

    :hover {
      border-color: #3696d6;
    }
  }
`;
const disabledAfterDate = (current, timezone) =>
  timezone ? day(current).tz(timezone, true).isAfter(day().tz(timezone).endOf('day'), 'day') : current.isAfter(day().endOf('day'));
const disabledBeforeDate = (current, timezone) =>
  timezone ? day(current).tz(timezone, true).isBefore(day().tz(timezone).startOf('day'), 'day') : current.isBefore(day().startOf('day'));

const Dot = styled.span`
  background-color: #00f;
  border-radius: 50%;
  display: block;
  height: 4px;
  left: -2px;
  margin: 0 auto;
  position: relative;
  top: -4px;
  width: 4px;
`;

const DatePicker = React.forwardRef(({ disabledAfter, disabledBefore, timezone, ...props }, ref) => (
  <StyledDatePicker
    {...props}
    disabledDate={current => {
      if (disabledAfter) return disabledAfterDate(current, timezone);
      if (disabledBefore) return disabledBeforeDate(current, timezone);
      return null;
    }}
    ref={ref}
  />
));

DatePicker.RangePicker = datePicker.RangePicker;
DatePicker.Dot = Dot;

/** @component */
export default DatePicker;
