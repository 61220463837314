import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import 'antd/es/calendar/style';

const CalendarGenerated = generateCalendar(dayjsGenerateConfig);
const Calendar = styled(({ disableHeaderBorder, disableDefaultSelect, small, ...props }) => <CalendarGenerated {...props} />)`
  && {
    ${props =>
      props.disableHeaderBorder &&
      `
      .ant-fullcalendar{
        border: none;
      }
    `}
    ${props =>
      props.disableDefaultSelect &&
      css`
        .ant-fullcalendar-selected-day {
          .ant-fullcalendar-value {
            background: transparent;
            color: rgba(0, 0, 0, 0.65);
          }
        }
        .ant-fullcalendar-month-panel-selected-cell {
          .ant-fullcalendar-value {
            background: transparent;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      `}
    ${props =>
      props.small &&
      css`
        .ant-fullcalendar-value {
          height: 55px !important;
          width: 32px !important;
        }
      `}
  }
`;

Calendar.defaultProps = {
  disableHeaderBorder: false,
};

Calendar.propTypes = {
  /** Customize the display of the date cell, the returned content will be appended to the cell */
  dateCellRender: propTypes.func,
  /** Customize the display of the date cell, the returned content will override the cell */
  dateFullCellRender: propTypes.func,
  /** Function that specifies the dates that cannot be selected */
  disabledDate: propTypes.func,
  /** Whether to display in full-screen */
  fullscreen: propTypes.bool,
  /** The calendar's locale */
  locale: propTypes.object,
  /** Customize the display of the month cell, the returned content will be appended to the cell */
  monthCellRender: propTypes.func,
  /** Callback for when panel changes */
  onPanelChange: propTypes.func,
  /** Callback for when a date is selected */
  onSelect: propTypes.func,
  /** Callback for when date changes */
  onChange: propTypes.func,
  /** render custom header in panel */
  headerRender: propTypes.func,
};

/** @component */
export default Calendar;
