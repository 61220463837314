import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useRedux } from 'hooks/reduxHooks';
import { useRouterHistory } from 'hooks/routerHooks';
import { getUserData } from 'selectors/users';
import { setIsFirstTimeStorage } from 'utils/local-storage-helpers';
import Img from '../Img';
import Button from '../Button';

const { Paragraph, Title } = Typography;

const OrientationSlide = ({ data, message, next, route }) => {
  const [, select] = useRedux();
  const userData = select(getUserData);
  const { firstName } = userData.user_detail;
  const history = useRouterHistory();
  const nextPage = () => {
    if (data.btnText !== 'Finish') {
      return next();
    }
    setIsFirstTimeStorage();
    return history.push(route);
  };
  return (
    <Row>
      <Col md={12} xs={24} className='slide-illustration-block'>
        <div className='slide-illustration'>
          <Img className={data.title !== 'Hello' ? 'slide-image' : ''} srcSet={data.img} retina />
        </div>
      </Col>
      <Col md={12} xs={24} className='slide-content'>
        <div className='slide-content-wrapper'>
          <Title level={3}>{data.title === 'Hello' ? `${data.title}, ${firstName}` : data.title}</Title>
          <Paragraph>{!!message && message}</Paragraph>
          <div className='button-wrapper'>
            <Button onClick={() => nextPage()} key='submitButton' type='accent' block>
              {data.btnText}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default OrientationSlide;
