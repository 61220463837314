import { createSelector } from 'reselect';

import { LOAD_CREATE_PROVIDER_WEEKLY_HOURS, LOAD_PROVIDER_WEEKLY_HOURS } from 'actions/provider-weekly-hours';

import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const providerWeeklyHours = createSelector(getEntities, ({ weeklyHours }) => weeklyHours);

export const createProviderWeeklyHoursLoading = createLoadingSelector(LOAD_CREATE_PROVIDER_WEEKLY_HOURS)();

export const createProviderWeeklyHoursError = createErrorSelector(LOAD_CREATE_PROVIDER_WEEKLY_HOURS)();

export const getProviderWeeklyHoursLoading = createLoadingSelector(LOAD_PROVIDER_WEEKLY_HOURS)();

export const getProviderWeeklyHoursError = createErrorSelector(LOAD_PROVIDER_WEEKLY_HOURS)();
