import client from './client';

export const createAssessmentApi = data => client().post('/assessments', data);

export const getAssessmentsApi = params => client().get('/assessments', { params });

export const getAssessmentApi = id => client().get(`/assessments/${id}`);

export const deleteAssessmentApi = id => client().delete(`/assessments/${id}`);

export const editAssessmentApi = data => client().put(`/assessments/${data.id}`, data);

export const publishAssessmentApi = ({ id, active }) => client().patch(`/assessments/${id}`, { active });
