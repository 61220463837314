import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'antd';

const { TextArea: Component } = Input;
const Textarea = forwardRef(({ ...props }, ref) => <Component {...props} ref={ref} />);

const TextArea = styled(Textarea)`
  &&,
  textarea {
    resize: none;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    display: block;
    width: 100%;

    &:hover {
      border-color: #3696d6 !important;
    }

    @media (max-width: 767px) {
      font-size: 16px !important;
    }

    ${props =>
      props.noScroll &&
      css`
        ::-webkit-scrollbar {
          display: none !important;
        }
      `}
  }
`;

/** @component */

export default TextArea;
