import React from 'react';
import Img from '../Img';
import * as logos from './logos';

const sizes = {
  '1x': '25%',
  '2x': '50%',
  '3x': '75%',
};
const evolve = ({ type = 0, level = 0, size = '1x', width = null, style = {}, ...props }) => {
  const imgSrc = logos[`evolve${type}${level}`];

  return <Img {...props} src={imgSrc} alt='' style={style} width={width || sizes[size]} />;
};

const intellihealth = ({ type = 0, level = 0, size = '1x', ...props }) => {
  const imgSrc = logos[`intellihealth${type}${level}`];
  return <Img {...props} src={imgSrc} alt='' width={sizes[size]} />;
};

const flyte = ({ type = 0, level = 0, size = '1x', ...props }) => {
  const imgSrc = logos[`flyte${type}${level}`];
  return <Img {...props} src={imgSrc} alt='' width={sizes[size]} />;
};

/** @component */
const BrandEvolve = {
  Evolve: evolve,
  IntelliHealth: intellihealth,
  Flyte: flyte,
};

export default BrandEvolve;
