import styled from 'styled-components';
import { Slider as slider } from 'antd';

const Slider = styled(slider)`
  && {
    .ant-slider-handle {
      border-color: #73bbf2 !important;
      background-color: #73bbf2 !important;
    }

    .ant-slider-track {
      background-color: #73bbf2;
    }
  }
`;

/** @component */
export default Slider;
