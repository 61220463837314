import { PageHeader as ph } from 'antd';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

const PageHeader = styled(ph)`
  ${props =>
    props.$noEllipsis &&
    css`
      .ant-page-header-heading-title {
        white-space: initial;
      }
    `}
`;

PageHeader.propTypes = {
  /* custom title text */
  title: propTypes.node,
  /* custom subTitle text */
  subTitle: propTypes.element,
  /* custom back icon, if false the back icon will not be displayed */
  backIcon: propTypes.element,
  /* Tag list next to title */
  tags: propTypes.array,
  /* Operating area, at the end of the line of the title line */
  extra: propTypes.node,
  /* breadcrumb config */
  breadcrumb: propTypes.func,
  /* PageHeader's footer, generally used to render TabBar */
  footer: propTypes.element,
  /* back icon click event */
  onBack: propTypes.func,
};

/** @component */
export default PageHeader;
