import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load providers
 */
export const PROVIDERS = createRequestTypes('PROVIDERS');
export const getProviders = {
  request: () => action(PROVIDERS[REQUEST]),
  success: (params, response) =>
    action(PROVIDERS[SUCCESS], {
      response: {
        entities: {
          providers: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDERS[FAILURE], { error }),
};
export const LOAD_PROVIDERS = 'LOAD_PROVIDERS';
export const requestLoadProviders = data => action(LOAD_PROVIDERS, data);

export const SET_FILTERED_PROVIDERS = 'SET_FILTERED_PROVIDERS';
export const setFilteredProviders = filteredProviders => action(SET_FILTERED_PROVIDERS, { filteredProviders });

/**
 * Load single provider
 */
export const PROVIDER = createRequestTypes('PROVIDER');
export const getProvider = {
  request: () => action(PROVIDER[REQUEST]),
  success: (params, response) =>
    action(PROVIDER[SUCCESS], {
      response: {
        entities: {
          provider: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDER[FAILURE], { error }),
};
export const LOAD_PROVIDER = 'LOAD_PROVIDER';
export const requestLoadProvider = id => action(LOAD_PROVIDER, { id });

/**
 * Remove provider
 */
export const REMOVE_PROVIDER = createRequestTypes('REMOVE_PROVIDER');
export const removeProvider = {
  request: () => action(REMOVE_PROVIDER[REQUEST]),
  success: () => action(REMOVE_PROVIDER[SUCCESS]),
  failure: (params, error) => action(REMOVE_PROVIDER[FAILURE], { error }),
};
export const LOAD_REMOVE_PROVIDER = 'LOAD_REMOVE_PROVIDER';
export const requestRemoveProvider = data => action(LOAD_REMOVE_PROVIDER, data);

export const RESET_PROVIDER = 'RESET_PROVIDER';
export const resetProvider = () => action(RESET_PROVIDER);

export const LOAD_RELOAD_PROVIDER = 'LOAD_RELOAD_PROVIDER';

export const reloadProviderData = () => action(LOAD_RELOAD_PROVIDER);

/**
 * Get Provider availability
 */
export const PROVIDER_AVAILABILITY = createRequestTypes('PROVIDER_AVAILABILITY');
export const getProviderAvailability = {
  request: () => action(PROVIDER_AVAILABILITY[REQUEST]),
  success: (params, response) =>
    action(PROVIDER_AVAILABILITY[SUCCESS], {
      response: {
        entities: {
          providerAvailability: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDER_AVAILABILITY[FAILURE], { error }),
};
export const LOAD_PROVIDER_AVAILABILITY = 'LOAD_PROVIDER_AVAILABILITY';
export const requestLoadProviderAvailability = params => action(LOAD_PROVIDER_AVAILABILITY, params);
