module.exports = {
  OPERATOR_MATCHING: {
    'Less Than': '<',
    'Greater Than': '>',
    Equals: '',
    Between: '-',
  },
  MEASUREMENT: {
    Percentage: '%',
    Grams: 'g',
    None: 'None',
    Calories: 'cal',
  },
  ACTIVITY_LEVELS: ['Sedentary', 'Lightly Active', 'Moderately Active', 'Very Active'],
  ACTIVITY_FACTOR_MULTIPLIERS: {
    Sedentary: {
      Male: 1,
      Female: 1,
    },
    'Lightly Active': {
      Male: 1.11,
      Female: 1.12,
    },
    'Moderately Active': {
      Male: 1.25,
      Female: 1.27,
    },
    'Very Active': {
      Male: 1.48,
      Female: 1.45,
    },
  },
  NUTRIENT_PER_CALORIE_MULTIPLIERS: {
    Fat: 9,
    Protein: 4,
    Carbs: 4,
  },
  calculateNutritionTargets(rule, calorieAmount, nutrientPerCalorie) {
    let minValue;
    let maxValue;
    let amount;
    if (rule.operator === 'Between') {
      minValue =
        rule.measurement === 'Percentage' ? Math.floor(Math.floor((rule.minValue * calorieAmount) / 100) / nutrientPerCalorie) : rule.minValue;
      maxValue =
        rule.measurement === 'Percentage' ? Math.floor(Math.floor((rule.maxValue * calorieAmount) / 100) / nutrientPerCalorie) : rule.maxValue;
    } else {
      amount = rule.measurement === 'Percentage' ? Math.floor(Math.floor((rule.value * calorieAmount) / 100) / nutrientPerCalorie) : rule.value;
    }
    return { minValue, maxValue, amount };
  },
  calculateCaloriesWithoutActivity({ gender, age, weight, height }) {
    if ([age, weight, height].some(Number.isNaN)) {
      throw new Error('age, weight and height must be numbers ');
    }
    return 9.99 * weight + 6.25 * height - 4.92 * age + (gender === 'Male' ? 5 : -161);
  },
};
