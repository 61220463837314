import client from './client';

export const createProviderApi = data => client().post('/providers', data);

export const getProvidersApi = data =>
  client().get('/providers', {
    params: data,
  });

export const getSingleProviderApi = ({ id, ...params }) => client().get(`/providers/${id}`, { params });

export const removeProviderApi = ({ id, activate }) => client().delete(`/providers/${id}`, { params: { activate } });

export const updateProviderApi = data => client().put(data.providerId ? `/providers/${data.providerId}` : '/providers', data);

export const npiProviderApi = ({ number }) =>
  client().get('/npi', {
    params: {
      number,
    },
  });
export const getProviderAvailabilityApi = ({ providerId, params }) => client().get(`/providers/${providerId}/availability`, { params });
