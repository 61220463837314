import client from './client';

export const createProgramApi = data => client().post('/programs', data);

export const getProgramsApi = data => client().get('/programs', { params: data });

export const getProgramApi = id => client().get(`/programs/${id}`);

export const removeProgramApi = id => client().delete(`/programs/${id}`);

export const updateProgramApi = data => client().put(`/programs/${data.id}`, data);
