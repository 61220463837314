import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const MESSAGES = createRequestTypes('MESSAGES');
export const CONVERSATIONS_SUCCESS = MESSAGES[SUCCESS];
export const MESSAGES_SUCCESS = MESSAGES[SUCCESS];
export const getMessages = {
  request: () => action(MESSAGES[REQUEST]),
  success: (params, response) => action(MESSAGES[SUCCESS], { response }),
  failure: (params, error) => action(MESSAGES[FAILURE], { error }),
};

export const requestLoadMessages = data => action(LOAD_MESSAGES, data);
