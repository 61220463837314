import { call, takeLatest, put, select } from 'redux-saga/effects';

import { LOAD_ACTION_ITEMS, getActionItems, LOAD_EDIT_ACTION, editAction, requestLoadActionItems } from 'actions/action-items';

import { getActionItemsApi, editActionApi } from 'services/action-items';
import { errorEditingAction } from 'selectors/action-items';

import fetchEntity from './fetch-entity';

// get actions

const fetchLoadActionItems = fetchEntity.bind(null, getActionItems, getActionItemsApi);

function* loadActionItems({ params }) {
  yield put(getActionItems.failure(undefined, false));
  yield call(fetchLoadActionItems, { params });
}

export function* watchLoadActions() {
  yield takeLatest(LOAD_ACTION_ITEMS, loadActionItems);
}

// edit action
const fetchEditActionItem = fetchEntity.bind(null, editAction, editActionApi);

export function* loadEditAction({ type, ...data }) {
  yield call(fetchEditActionItem, data);
  const isError = yield select(errorEditingAction);
  if (!isError) {
    yield put(requestLoadActionItems({ params: data.queryParams }));
  }
}

export function* watchEditAction() {
  yield takeLatest(LOAD_EDIT_ACTION, loadEditAction);
}

const ActionItemSagas = {
  watchEditAction,
  watchLoadActions,
};
export default ActionItemSagas;
