import styled, { css } from 'styled-components';
import { Radio as Component } from 'antd';

const Radio = styled(Component)`
  && {
    ${props =>
      props.$block &&
      css`
        display: block;
      `}
    ${props =>
      !props.disabled &&
      css`
        span:last-child {
          word-break: break-all;
          white-space: normal;
          font-size: 16px;
        }
        .ant-radio-inner {
          border-color: #137ac9 !important;
          &::after {
            background-color: #137ac9 !important;
          }
        }
      `}
  }
`;

const RadioGroup = styled(Component.Group)`
  ${props =>
    !props.$inline &&
    css`
      display: block;
      width: 100%;

      .ant-radio-wrapper {
        display: ${props.display || 'block'};
        border-top: 1px solid #ebebeb;
        padding: 12px 0px;
        :nth-child(1) {
          border-top: 0px solid #ebebeb;
        }
      }
    `}
`;

Radio.Group = RadioGroup;
/** @component */
export default Radio;
