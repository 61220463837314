module.exports = {
  MEDICAL_STATUS: {
    Active: 1,
    Inactive: 2,
    Resolved: 3,
  },
  MEDICAL_STATUS_MAPPER: {
    1: 'Active',
    2: 'Inactive',
    3: 'Resolved',
  },
};
