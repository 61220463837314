import React from 'react';
import styled, { css } from 'styled-components';
import { Avatar as Component } from 'antd';

const StyledAvatar = styled(({ nested, offline, ...props }) => <Component {...props} />)`
  background: #3e3e3e !important;
  ${props =>
    props.offline &&
    css`
      background: #e0e0e0 !important;
      color: #adadad !important;
      img {
        filter: grayscale(100%);
      }
    `}
  ${props =>
    props.nested &&
    css`
      border: 4px solid #fff !important;
      border-radius: 50% !important;
      position: absolute !important;
      top: 100% !important;
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
      left: ${props.order * 25}px !important;
      box-sizing: content-box !important;
    `}
`;

const Avatar = ({ ...props }) => <StyledAvatar {...props} />;
Avatar.Group = Component.Group;

/** @component */
export default Avatar;
