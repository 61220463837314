import { createSelector } from 'reselect';

import { LOAD_CREATE_STATES, LOAD_REMOVE_STATES, LOAD_GET_STATES, LOAD_UPDATE_STATE_TRANSLATION } from 'actions/states';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getCreateStateLoading = createLoadingSelector(LOAD_CREATE_STATES)();

export const getUpdateStateLoading = createLoadingSelector(LOAD_UPDATE_STATE_TRANSLATION)();

export const getUpdateStateError = createErrorSelector(LOAD_UPDATE_STATE_TRANSLATION)();

export const getStateCreateError = createErrorSelector(LOAD_CREATE_STATES)();

export const getRemoveStateLoading = createLoadingSelector(LOAD_REMOVE_STATES)();

export const getStatesTranslations = createSelector(getEntities, entities => entities.states);

export const getStatesError = createErrorSelector(LOAD_GET_STATES)();

export const getStatesLoading = createLoadingSelector(LOAD_GET_STATES)();

export const getSelectedState = ({ _state }) => _state.stateId;

export const getRemoveStateError = createErrorSelector(LOAD_REMOVE_STATES)();

export const getCreatedState = createSelector(getEntities, ({ createdState }) => createdState);
