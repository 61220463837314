module.exports = {
  organization_user_type: '1',
  provider_user_type: '2',
  organization_patient_type: '3',
  provider_patient_type: '4',
  getModelAndFieldNames(type) {
    const allModels = {
      1: {
        modelName: 'organizations_users',
        fieldName: 'organizationId',
        typeName: 'organization-admin',
      },
      2: {
        modelName: 'providers_users',
        fieldName: 'providerId',
        typeName: 'provider-admin',
      },
      3: {
        modelName: 'organizations_patients',
        fieldName: 'organizationId',
        typeName: 'organization-admin',
      },
      4: {
        modelName: 'providers_patients',
        fieldName: 'providerId',
        typeName: 'provider-admin',
      },
    };
    return allModels[type];
  },
};
