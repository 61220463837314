import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'style-guide/Button';
import Badge from 'style-guide/Badge';
import StreamlineIcon from 'style-guide/StreamlineIcon';

const BurgerMenu = styled.div`
  max-width: 50px;
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const MobileMenu = ({ toggleBurger, burgerVisible, setBurgerVisible }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && burgerVisible) {
        setBurgerVisible(v => !v);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [burgerVisible, setBurgerVisible]);

  return (
    <BurgerMenu ref={wrapperRef}>
      <Badge dot size='small' color='#73bbf2' offset={[0, 35]}>
        <Button type='link' onClick={toggleBurger}>
          <StreamlineIcon icon='navigation-menu' size={20} color='#D0D0D0' />
        </Button>
      </Badge>
    </BurgerMenu>
  );
};

export default MobileMenu;
