import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const GET_GLOBAL_VARIABLES = createRequestTypes('GET_GLOBAL_VARIABLES');
export const getGlobalVariables = {
  request: params => action(GET_GLOBAL_VARIABLES[REQUEST]),
  success: (params, response) =>
    action(GET_GLOBAL_VARIABLES[SUCCESS], {
      response: {
        entities: {
          globalVariables: response,
        },
      },
    }),
  failure: (params, error) => action(GET_GLOBAL_VARIABLES[FAILURE], { error }),
};
export const LOAD_GET_GLOBAL_VARIABLES = 'LOAD_GET_GLOBAL_VARIABLES';
export const requestLoadGlobalVariables = data => action(LOAD_GET_GLOBAL_VARIABLES, data);

export const GLOBAL_VARIABLE = createRequestTypes('GLOBAL_VARIABLE');
export const getGlobalVariable = {
  request: () => action(GLOBAL_VARIABLE[REQUEST]),
  success: (params, response) =>
    action(GLOBAL_VARIABLE[SUCCESS], {
      response: {
        entities: {
          globalVariable: response,
        },
      },
    }),
  failure: (params, error) => action(GLOBAL_VARIABLE[FAILURE], { error }),
};
export const LOAD_GLOBAL_VARIABLE = 'LOAD_GLOBAL_VARIABLE';
export const requestLoadGlobalVariable = id => action(LOAD_GLOBAL_VARIABLE, { id });

export const STORE_GLOBAL_VARIABLE = 'STORE_GLOBAL_VARIABLE';
export const requestStoreGlobalVariable = data => action(STORE_GLOBAL_VARIABLE, data);
