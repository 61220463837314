import React from 'react';
import Text from '../Text';
import Paragraph from '../Paragraph';

const ViewItem = ({ value, text }) => (
  <div>
    <Text type='secondary' strong level={3}>
      {text}
    </Text>
    <Paragraph level={3} $hasPadding>
      {value}
    </Paragraph>
  </div>
);

const EditItem = ({ children, text }) => (
  <div>
    <Text type='secondary' strong level={3}>
      {text}
    </Text>
    {children}
  </div>
);

const Items = {
  ViewItem,
  EditItem,
};

export default Items;
