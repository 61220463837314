import dayjs from 'dayjs';
import queryString from 'query-string';
import convert from 'convert-units';
import day from 'utils/day';

/**
 * Parse Url
 * @param {String} url Router location search
 * @returns {Object}
 */
export const parseUrl = url => queryString.parse(url);

/**
 * Formats datetime
 * @param {String} date
 * @param {String} format
 * @returns (String)
 */
export const getFormattedDate = (date, format) => day.utc(new Date(date)).format(format);

/**
 * Converts the string to lowercase letters and removes spaces
 * @param {String} time
 * @returns (String)
 */
export const getFormattedTime = time => (time ? time.toLowerCase().replace(/\s/, '') : '');

/**
 * Convert 12 hours to 24
 * @param {String} time
 * @returns {String} Time in 24 hour format
 */
export const convertTime12to24 = time => day(time, 'h:mm A').format('HH:mm:ss');

export const truncateString = (string, limit = 10, more = '...') =>
  `${string.substring(0, limit)}${string.length && string.length > limit ? more : ''}`;

/**
 * Deep array of objects search
 * @param {Object} obj Object for search
 * @param {*} key Searchable key
 * @param {*} val Searchable value
 */
export const deepSearch = (obj, key, val) => {
  let objects = [];
  for (const i in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(i)) {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (typeof obj[i] === 'object') {
      /**
       *
       * @param {Object} obj
       * @param {*} key
       * @param {*} val
       */
      objects = objects.concat(deepSearch(obj[i], key, val));
    }
    // if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
    else if ((i === key && typeof obj[i] === 'string' && obj[i].toLowerCase().search(val) >= 0) || (i === key && val === '')) {
      //
      objects.push(obj);
    } else if (typeof obj[i] === 'string' && obj[i].toLowerCase().search(val) >= 0 && key === '') {
      // only add if the object is not already in the array
      if (objects.lastIndexOf(obj) === -1) {
        objects.push(obj);
      }
    }
  }
  return objects;
};
/**
 * Validate email
 */
export const validateEmail = email => /\S+@\S+\.\S+/.test(email);

/**
 * Converting between quantities in different units
 */
export const convertTo = (value, from, to) => value && convert(value).from(from).to(to);

/**
 * Format phone numbers
 */
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

/**
 * Format timezone
 */
export const formatTimezone = ({ offset, label }) => `(GMT${offset > 0 ? '+' : ''}${offset}) ${label}`;

/**
 * Detect if browser is safari
 */
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Manual Copy To ClipBoard custom string
 */
export const copyStringToClipBoard = string => {
  const el = document.createElement('textarea');
  el.value = string;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

/**
 * Manual Copy To ClipBoard custom string
 */
export const copyStringToClipBoardNew = target => {
  const targetArea = document.getElementById(target);
  const dietaryDefaultTitle = targetArea?.querySelector('#dietary')?.innerHTML;
  const activityDefaultTitle = targetArea?.querySelector('#activity')?.innerHTML;
  if (targetArea.querySelector('#vitals')) {
    targetArea.querySelector('#vitals').innerHTML = '<br />---Vitals---';
  }
  if (targetArea.querySelector('#dietary')) {
    targetArea.querySelector('#dietary').innerHTML = `<br />---${dietaryDefaultTitle}---`;
  }
  if (targetArea.querySelector('#activity')) {
    targetArea.querySelector('#activity').innerHTML = `<br />---${activityDefaultTitle}---`;
  }
  if (targetArea.querySelector('#medical')) {
    targetArea.querySelector('#medical').innerHTML = '<br />---Medical---';
  }
  if (targetArea.querySelector('#weight-history')) {
    targetArea.querySelector('#weight-history').innerHTML = '<br />---Weight History---';
  }
  if (targetArea.querySelector('#dietary-history')) {
    targetArea.querySelector('#dietary-history').innerHTML = '<br />---Dietary History---';
  }
  if (targetArea.querySelector('#social-history')) {
    targetArea.querySelector('#social-history').innerHTML = '<br />---Social History---';
  }
  if (targetArea.querySelector('#sleep-history')) {
    targetArea.querySelector('#sleep-history').innerHTML = '<br />---Sleep History---';
  }
  const r = document.createRange();
  r.selectNode(targetArea);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(r);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
  if (targetArea.querySelector('#vitals')) {
    targetArea.querySelector('#vitals').innerHTML = 'Vitals';
  }
  if (targetArea.querySelector('#dietary')) {
    targetArea.querySelector('#dietary').innerHTML = dietaryDefaultTitle;
  }
  if (targetArea.querySelector('#activity')) {
    targetArea.querySelector('#activity').innerHTML = activityDefaultTitle;
  }
  if (targetArea.querySelector('#medical')) {
    targetArea.querySelector('#medical').innerHTML = 'Medical';
  }
  if (targetArea.querySelector('#weight-history')) {
    targetArea.querySelector('#weight-history').innerHTML = 'Weight History';
  }
  if (targetArea.querySelector('#dietary-history')) {
    targetArea.querySelector('#dietary-history').innerHTML = 'Dietary History';
  }
  if (targetArea.querySelector('#social-history')) {
    targetArea.querySelector('#social-history').innerHTML = 'Social History';
  }
  if (targetArea.querySelector('#sleep-history')) {
    targetArea.querySelector('#sleep-history').innerHTML = 'Sleep History';
  }
};

/**
 * Manual Copy To ClipBoard custom string for new Patient Summary Extract
 */
export const copyStringToClipBoardPatientSummary = target => {
  const targetArea = document.getElementById(target);
  const dietaryDefaultTitle = targetArea?.querySelector('#dietary')?.innerHTML;
  const activityDefaultTitle = targetArea?.querySelector('#activity')?.innerHTML;
  if (targetArea.querySelector('#referredBy')) {
    targetArea.querySelector('#referredBy').innerHTML = '<br /><strong>Referred By</strong><br/>';
  }
  if (targetArea.querySelector('#vitals')) {
    targetArea.querySelector('#vitals').innerHTML = '<br /><strong>VITALS</strong>';
  }
  if (targetArea.querySelector('#foodRecall')) {
    targetArea.querySelector('#foodRecall').innerHTML = '<br /><strong>FOOD RECALL</strong>';
  }

  if (targetArea.querySelector('#dietary')) {
    targetArea.querySelector('#dietary').innerHTML = `<br />---${dietaryDefaultTitle}---`;
  }
  if (targetArea.querySelector('#activity')) {
    targetArea.querySelector('#activity').innerHTML = `<br />---${activityDefaultTitle}---`;
  }
  if (targetArea.querySelector('#dietary-history')) {
    targetArea.querySelector('#dietary-history').innerHTML = '<br />---Dietary History---';
  }
  if (targetArea.querySelector('#sleep-history')) {
    targetArea.querySelector('#sleep-history').innerHTML = '<br />---Sleep History---';
  }
  const r = document.createRange();
  r.selectNode(targetArea);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(r);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
};

/**
 * This method will check if the Lab Results value is normal, low or high from Ref. Range
 * @param {*} value Lab Results value
 */
export const getRefRange = value => {
  const { Low: low, High: high } = value || {};
  if (low || high) {
    return high ? `${(low && `${low}-`) || '<'}${high}` : `>${low}`;
  }
  return '';
};
/**
 * Insert item in array after given index
 * Example:
 * const arr = [1,2,4,5];
 * console.log(insert(arr, 1, 3));
 * Result: [1,2,3,4,5]
 * @param {Array} arr Items
 * @param {Number} index Array index number that item should be inserted
 * @param {Any} newItem Item that should be inserted in array list
 * @returns {Array} New array
 */
export const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

/**
 * Find index by given id in array of object
 * @param {Array} arr Array of objects (in object id value is required)
 * @param {Number} itemId Object id
 * @returns {Number} Number of index
 */
export const findIndexById = (arr, itemId) => arr.findIndex(({ id }) => id === itemId);

/**
 * Swap Array elements
 * @param {Array} array
 * @param {Number} from From array index
 * @param {Number} to To array index
 * @returns {Array}
 */
export const swapElement = (array, from, to) => {
  const items = [...array];
  const tmp = items[from];
  items[from] = items[to];
  items[to] = tmp;
  return items;
};

/**
 * Helper function that takes an uncertain date object and returns that date object as a dayjs object.
 * If the date object is false-y (null or undefined), this will a date from many years ago (epoch).
 * @param {Date} possibleDateObject
 * @returns {dayjs}
 */
export const generateDayJsObject = possibleDateObject => (possibleDateObject ? dayjs(possibleDateObject) : dayjs(0));
