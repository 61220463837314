import React from 'react';
import { Link } from 'react-router-dom';
import { Title, Img } from 'style-guide';

import { Row, Col } from 'antd';
import { SYSTEM_MESSAGE_HANDLERS, userTypes } from 'bmiq-common';
import { useSystemMessage } from 'hooks/useSystemMessages';
import { useRouter } from 'hooks/routerHooks';
import flyteLogo from 'assets/imgs/flyte-full-logo.svg';
import NotFoundPageWrapper from './styled/NotFoundPageWrapper';

const NotFoundPage = ({ isWhiteScreen = false, isAthena }) => {
  const {
    params: { userType },
  } = useRouter();

  const errorMessages = useSystemMessage([
    SYSTEM_MESSAGE_HANDLERS.messaging.notFoundPage,
    SYSTEM_MESSAGE_HANDLERS.messaging.errorPagesHeader,
    SYSTEM_MESSAGE_HANDLERS.messaging.whiteScreenPagesHeader,
    SYSTEM_MESSAGE_HANDLERS.messaging.whiteScreenPagesMessage,
    SYSTEM_MESSAGE_HANDLERS.athena.patientNotFoundPage,
    SYSTEM_MESSAGE_HANDLERS.athena.providerNotFoundPage,
    SYSTEM_MESSAGE_HANDLERS.athena.organizationNotEnabled,
  ]);
  const messageHandler = isWhiteScreen ? 'whiteScreen.page.message' : 'not.found.page';
  const headerHandler = isWhiteScreen ? 'whiteScreen.pages.header' : 'error.pages.header';
  let athenaHandler = null;
  switch (userType) {
    case userTypes.USER_TYPE_PATIENT:
      athenaHandler = SYSTEM_MESSAGE_HANDLERS.athena.patientNotFoundPage;
      break;
    case userTypes.USER_TYPE_PROVIDER_ADMIN:
      athenaHandler = SYSTEM_MESSAGE_HANDLERS.athena.providerNotFoundPage;
      break;
    case userTypes.USER_TYPE_ORGANIZATION_ADMIN:
      athenaHandler = SYSTEM_MESSAGE_HANDLERS.athena.organizationNotEnabled;
      break;
    default:
      break;
  }
  const message = isAthena ? athenaHandler : messageHandler;

  return (
    <NotFoundPageWrapper>
      <Row justify='center' align='middle' className='inner-wrapper'>
        <Col sm={24} md={12} className='section'>
          <div className='content-wrapper'>
            <Img src={flyteLogo} className='logo' alt='Intellihealth logo' style={{ width: '200px' }} />
            <Title level={3}>{errorMessages[headerHandler]?.message}</Title>
            <p>{errorMessages[message]?.message}</p>
            <Link to='/'>Return to dashboard</Link>
          </div>
        </Col>
      </Row>
    </NotFoundPageWrapper>
  );
};
export default NotFoundPage;
