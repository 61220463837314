import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get all languages

export const LANGUAGES = createRequestTypes('LANGUAGES');
export const getLanguagesAction = {
  request: () => action(LANGUAGES[REQUEST]),
  success: (params, response) =>
    action(LANGUAGES[SUCCESS], {
      response: {
        entities: {
          languages: response,
        },
      },
    }),
  failure: (params, error) => action(LANGUAGES[FAILURE], { error }),
};
export const LOAD_LANGUAGES = 'LOAD_LANGUAGES';
export const requestLoadLanguages = () => action(LOAD_LANGUAGES);

// get single language

export const LANGUAGE = createRequestTypes('LANGUAGE');
export const getLanguageAction = {
  request: () => action(LANGUAGE[REQUEST]),
  success: (params, response) =>
    action(LANGUAGE[SUCCESS], {
      response: {
        entities: {
          language: response,
        },
      },
    }),
  failure: (params, error) => action(LANGUAGE[FAILURE], { error }),
};
export const LOAD_LANGUAGE = 'LOAD_LANGUAGE';
export const requestLoadLanguage = id => action(LOAD_LANGUAGE, { id });

// change language

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const changeLanguage = activeLanguage => action(CHANGE_LANGUAGE, { activeLanguage });
