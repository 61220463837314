import React, { PureComponent, Fragment } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import StreamlineIcon from 'style-guide/StreamlineIcon';
import Cursor from 'style-guide/Cursor';
import Input from 'style-guide/Input/Widget';
import themes from 'style-guide/theme';
import { userTypes } from 'bmiq-common';
import SwitchUserDropdown from '../../../components/SwitchUser/SwitchUserDropdown';
import UserProfile from './UserProfile';

const SearchArea = styled.div`
  padding-top: 0;
  height: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    height: 100%;
    width: ${props => (props.isInput ? '100%' : '70px')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1.5px solid #ebebeb;
    border-left: 1.5px solid #ebebeb;

    .notification {
      width: 24px;
      height: 24px;
    }
  }
`;

const UserArea = styled.div`
  display: none;
  height: 100%;

  .userMobileDropdown {
    display: none;
    height: 100%;
    margin-left: 25px;
    justify-content: center;
    align-items: center;
  }

  .userLargeDropdown {
    display: none;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 24px;
  }

  @media screen and (min-width: 768px) {
    display: inline-block;

    .userMobileDropdown {
      display: flex;
    }
  }

  @media screen and (min-width: 1024px) {
    .userMobileDropdown {
      display: none;
    }

    .userLargeDropdown {
      display: flex;
    }
  }
`;

class RightMenu extends PureComponent {
  state = {
    isSearching: false,
    searchValue: '',
  };

  render() {
    const { username, firstName, lastName, userMenu, authMenu, clientUrl, ev286FrontEndToggleDropDownUsingMockData, userType } = this.props;

    return (
      <Fragment>
        {this.state.isSearching && (
          <Col style={{ height: '100%', flex: 1, marginLeft: 71 }}>
            <SearchArea isInput>
              <Input
                $searchInput
                style={{ height: '100%' }}
                level={1}
                autoFocus
                value={this.state.searchValue}
                placeholder='Search'
                prefix={<StreamlineIcon icon='search' size={20} color='#5189C3' />}
                suffix={
                  <Cursor type='pointer' onClick={() => this.setState(this.state.searchValue ? { searchValue: '' } : { isSearching: false })}>
                    <CloseOutlined />
                  </Cursor>
                }
                onChange={e => this.setState({ searchValue: e.target.value })}
                onPressEnter={() =>
                  this.props.isWidget
                    ? window.location.assign(`${clientUrl}search?text=${this.state.searchValue}`)
                    : this.props.onSearch(this.state.searchValue)
                }
              />
            </SearchArea>
          </Col>
        )}
        <Col>
          {username ? (
            <Row type='flex' justify='space-between' style={{ height: '100%' }}>
              <Col style={{ height: '100%' }}>
                <Row type='flex' justify='end' style={{ height: '100%' }}>
                  {ev286FrontEndToggleDropDownUsingMockData &&
                    (userType === userTypes.USER_TYPE_ADMIN || userType === userTypes.USER_TYPE_ORGANIZATION_ADMIN) && (
                      <SwitchUserDropdown userMenu={userMenu} />
                    )}
                  {this.props.showSearch && !this.state.isSearching && (
                    <SearchArea>
                      <Cursor type='pointer' data-testid='show-search' onClick={() => this.setState({ isSearching: true })}>
                        <StreamlineIcon icon='search' size={20} color={themes['stroke-icon']} />
                      </Cursor>
                    </SearchArea>
                  )}
                  <UserArea>
                    <UserProfile userMenu={userMenu} username={username} firstName={firstName} lastName={lastName} />
                  </UserArea>
                </Row>
              </Col>
            </Row>
          ) : (
            authMenu
          )}
        </Col>
      </Fragment>
    );
  }
}
export default RightMenu;
