import React from 'react';
import FullCalendar from '@fullcalendar/react';
import CalendarDayGridPlugin from '@fullcalendar/daygrid';
import TimeGridPlugin from '@fullcalendar/timegrid';
import CalendarInteractionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import propTypes from 'prop-types';
import { SCHEDULER_LICENSE_KEY } from 'configs/env-vars';

import './calendar.less';

const Calendar = React.forwardRef((props, ref) => <FullCalendar {...props} ref={ref} schedulerLicenseKey={SCHEDULER_LICENSE_KEY} />);
const CalendarSmall = React.forwardRef((props, ref) => (
  <div className='fc-small'>
    <FullCalendar {...props} ref={ref} />
  </div>
));

Calendar.propTypes = {
  /** Customize the display of the date cell, the returned content will be appended to the cell */
  dateCellRender: propTypes.func,
  /** Customize the display of the date cell, the returned content will override the cell */
  dateFullCellRender: propTypes.func,
  /** The date selected by default  moment instance */
  defaultValue: propTypes.object,
  /** Function that specifies the dates that cannot be selected */
  disabledDate: propTypes.func,
  /** Whether to display in full-screen */
  fullscreen: propTypes.bool,
  /** The calendar's locale */
  locale: propTypes.object,
  /** The display mode of the calendar */
  mode: propTypes.oneOf(['month', 'year']),
  /** Customize the display of the month cell, the returned content will be appended to the cell */
  monthCellRender: propTypes.func,
  /** Customize the display of the month cell, the returned content will override the cell */
  monthFullCellRender: propTypes.func,
  /** to set valid range */
  validRange: propTypes.array,
  /** The current selected date moment instance */
  value: propTypes.object,
  /** Callback for when panel changes */
  onPanelChange: propTypes.func,
  /** Callback for when a date is selected */
  onSelect: propTypes.func,
  /** Callback for when date changes */
  onChange: propTypes.func,
  /** render custom header in panel */
  headerRender: propTypes.func,
};

export {
  Calendar,
  CalendarSmall,
  CalendarDayGridPlugin,
  CalendarInteractionPlugin,
  TimeGridPlugin,
  resourceTimeGridPlugin,
  resourceDayGridPlugin,
  resourceTimeLinePlugin,
  momentTimezonePlugin,
};
