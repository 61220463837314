import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import { adaptPhoneNumberWithDB } from 'utils/phone-number-helper';

const PhoneNumberInputWrapper = styled.div`
  .phone-input {
    width: 100% !important;
  }
`;
const PhoneNumberInput = ({ number, setNumber, objKey, disableDropdown, setSomethingChanged = () => {}, placeholder = '(555) 555-5555' }) => (
  <PhoneNumberInputWrapper>
    <PhoneInput
      inputClass='phone-input'
      disableDropdown={disableDropdown}
      country='us'
      countryCodeEditable={Boolean(number?.match(/^\+[0,2-9]\d+/))}
      onlyCountries={['us']}
      placeholder={placeholder}
      value={number}
      inputStyle={{
        paddingLeft: '40px',
        fontSize: '16px',
        borderRadius: 0,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
      }}
      buttonStyle={{
        borderRadius: 0,
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        background: 'white',
      }}
      onChange={num => {
        setNumber({ [objKey]: adaptPhoneNumberWithDB(num) });
        setSomethingChanged(!!num);
      }}
    />
  </PhoneNumberInputWrapper>
);

/** @component */
export default PhoneNumberInput;
