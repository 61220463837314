import { createSelector } from 'reselect';

import {
  LOAD_MEDICATIONS,
  LOAD_CREATE_MEDICATION,
  LOAD_DISABLE_MEDICATION,
  LOAD_EDIT_MEDICATION,
  LOAD_MEDICATION,
  LOAD_MEDICATIONS_FDA,
} from 'actions/medications';

import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

import getEntities from './entities';

// loading selectors
export const createMedicationLoading = createLoadingSelector(LOAD_CREATE_MEDICATION)();

export const getMedicationsLoading = createLoadingSelector(LOAD_MEDICATIONS)();

export const getMedicationLoading = createLoadingSelector(LOAD_MEDICATION)();

export const disableMedicationLoading = createLoadingSelector(LOAD_DISABLE_MEDICATION)();

export const editMedicationLoading = createLoadingSelector(LOAD_EDIT_MEDICATION)();

export const getMedicationsFdaLoading = createLoadingSelector(LOAD_MEDICATIONS_FDA)();

// error selectors
export const errorLoadingMedications = createErrorSelector(LOAD_MEDICATIONS)();

export const errorLoadingMedication = createErrorSelector(LOAD_MEDICATION)();

export const errorCreatingMedication = createErrorSelector(LOAD_CREATE_MEDICATION)();

export const errorDisableMedication = createErrorSelector(LOAD_DISABLE_MEDICATION)();

export const errorEditingMedication = createErrorSelector(LOAD_EDIT_MEDICATION)();

export const errorLoadingMedicationsFda = createErrorSelector(LOAD_MEDICATIONS_FDA)();

export const getMedications = createSelector(getEntities, entities => entities.medications.data);

export const getMedicationsCount = createSelector(getEntities, entities => entities.medications.total);

export const getMedication = createSelector(getEntities, entities => entities.medication);

export const getCreatedMedication = createSelector(getEntities, entities => entities.createdMedication);

export const getMedicationsFda = createSelector(getEntities, ({ medicationsFda }) => medicationsFda);
