import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import message from 'style-guide/Message';

const defaultFunctionValue = () => {};

/*
  !important when passing onSuccess and onError to this hook
  remember to wrap them in useCallback.
*/

const useRequest = ({
  request = defaultFunctionValue,
  onSuccess = defaultFunctionValue,
  onError = defaultFunctionValue,
  showErrorMessage = true,
  customErrorMessage = '',
  initialData = null,
  featureFlags = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(initialData);
  const isMounted = useRef(true);
  const isFeatureEnabled = useMemo(() => (featureFlags.length ? featureFlags.every(flag => flag === true) : true), [featureFlags]);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  const makeRequest = useCallback(
    async (params = undefined) => {
      try {
        if (isFeatureEnabled) {
          setLoading(true);
          const { data } = await request(params);
          if (isMounted.current) {
            setResponse(data);
            await onSuccess(data);
          }
        }
      } catch (e) {
        if (isMounted.current) {
          setError(e);
          showErrorMessage && message.error(customErrorMessage || e?.response?.data?.message);
          await onError(e);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    },
    [customErrorMessage, showErrorMessage, onError, onSuccess, request, isFeatureEnabled],
  );

  return {
    response,
    loading,
    error,
    makeRequest,
  };
};

export default useRequest;
