module.exports = [
  {
    id: 1,
    name: 'Vigorous',
  },
  {
    id: 2,
    name: 'Moderate',
  },
  {
    id: 3,
    name: 'Low',
  },
];
