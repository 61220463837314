import styled, { css } from 'styled-components';

const Component = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  ${props =>
    props.isGradient &&
    css`
      background: linear-gradient(90deg, ${props.theme[`light-blue-start-2`]} 0%, ${props.theme[`dark-blue-end-2`]} 100%);
    `}
`;

const Content = ({ ...props }) => <Component {...props} />;

/** @component */
export default Content;
