import { call, takeLatest, select, put } from 'redux-saga/effects';
import day from 'utils/day';

import {
  userLogin,
  LOAD_USER_LOGIN,
  LOAD_USERS_LIST,
  usersList,
  userLogout,
  LOAD_USER_LOGOUT,
  userAuthmanagement,
  LOAD_USER_AUTH_MANEGMENT,
  LOAD_CHECK_AUTHENTICATION,
  checkAuthentication,
  LOAD_INIT_AUTHENTICATION,
  initAuthentication,
  requestCheckAuthentication,
  LOAD_UPDATE_USER,
  updateUser,
} from 'actions/users';
import { reConnectSocket } from 'actions/conversation-messages';
import {
  userAuthmanagementApi,
  userLoginApi,
  usersListApi,
  userLogoutApi,
  checkAuthenticationApi,
  updateUserApi,
  initAuthenticationApi,
} from 'services/users';
import { userIsAuth } from 'selectors/users';
import fetchEntity from './fetch-entity';

const fetchLogin = fetchEntity.bind(null, userLogin, userLoginApi);

export function* loadUserLogin({ type, ...data }) {
  yield call(fetchLogin, { ...data, strategy: 'local', timezone: day.tz.guess() });
  // todo add in test
  if (process.env.NODE_ENV !== 'development') {
    yield put(requestCheckAuthentication());
  }
  yield put(reConnectSocket());
}

function* watchUserLogin() {
  yield takeLatest(LOAD_USER_LOGIN, loadUserLogin);
}

const fetchUsersList = fetchEntity.bind(null, usersList, usersListApi);

export function* loadUsersList(data) {
  yield call(fetchUsersList, data);
}

function* watchUsersList() {
  yield takeLatest(LOAD_USERS_LIST, loadUsersList);
}

const fetchLogout = fetchEntity.bind(null, userLogout, userLogoutApi);

export function* loadUserLogout() {
  yield call(fetchLogout);
}

function* watchUserLogout() {
  yield takeLatest(LOAD_USER_LOGOUT, loadUserLogout);
}

/*
 * Check authentication
 */
const fetchCheckAuth = fetchEntity.bind(null, checkAuthentication, checkAuthenticationApi);

export function* loadFetchCheckAuth({ data }) {
  if (yield select(userIsAuth)) {
    yield call(fetchCheckAuth, data);
  }
}

function* watchLoadFetchCheckAuth() {
  yield takeLatest(LOAD_CHECK_AUTHENTICATION, loadFetchCheckAuth);
}
/*
 * Init authentication
 */
const fetchInitAuth = fetchEntity.bind(null, initAuthentication, initAuthenticationApi);

export function* loadInitCheckAuth(data) {
  if (data.refresh || !(yield select(userIsAuth))) {
    yield call(fetchInitAuth, data);
  }
}

function* watchLoadInitAuth() {
  yield takeLatest(LOAD_INIT_AUTHENTICATION, loadInitCheckAuth);
}

/**
 * User Authmanagement
 */
const fetchUserAuthmanagement = fetchEntity.bind(null, userAuthmanagement, userAuthmanagementApi);

export function* loadUserAuthmanagement({ type, ...data }) {
  yield call(fetchUserAuthmanagement, data);
  yield put(reConnectSocket());
}
function* watchUserAuthmanagement() {
  yield takeLatest(LOAD_USER_AUTH_MANEGMENT, loadUserAuthmanagement);
}

/**
 * Update user
 */
const fetchUpdateUser = fetchEntity.bind(null, updateUser, updateUserApi);

export function* loadUpdateUser({ type, ...data }) {
  yield call(fetchUpdateUser, data);
}

function* watchLoadUpdateUser() {
  yield takeLatest(LOAD_UPDATE_USER, loadUpdateUser);
}

const UserSagas = {
  watchUserAuthmanagement,
  watchLoadFetchCheckAuth,
  watchLoadUpdateUser,
  watchUserLogin,
  watchUserLogout,
  watchUsersList,
  watchLoadInitAuth,
};
export default UserSagas;
