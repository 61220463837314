const transmissions = {
  MEDICATIONS: 'Medications',
  DIAGNOSIS: 'Diagnosis',
  ALLERGIES: 'Allergies',
  SYMPTOMS: 'Symptoms',
  VITALS: 'Vitals',
  PROGRESS_NOTES: 'Progress Notes',
  LAB_RESULTS: 'Lab Results',
};

module.exports = {
  LOG_STATUSES: {
    skipped: '0', // We don't persist this, but it's used in code
    inProgress: '1',
    success: '2',
    failed: '3',
  },
  LOG_STATUSES_MAPPER: {
    1: 'In Progress',
    2: 'Success',
    3: 'Failed',
  },
  DATA_MODELS: {
    'Clinical Summary': ['PatientQueryResponse', 'PatientQuery', 'PatientPush'],
    Notes: ['New'],
    Order: ['New'],
  },
  TRANSMISSIONS: transmissions,
  SEND_RECEIVE_TRANSMISSIONS: [
    {
      name: transmissions.MEDICATIONS,
      send: true,
      receive: true,
    },
    {
      name: transmissions.DIAGNOSIS,
      send: true,
      receive: true,
    },
    {
      name: transmissions.ALLERGIES,
      send: true,
      receive: true,
    },
    {
      name: transmissions.SYMPTOMS,
      send: false,
      receive: true,
    },
    {
      name: transmissions.VITALS,
      send: true,
      receive: true,
    },
    {
      name: transmissions.PROGRESS_NOTES,
      send: true,
      receive: true,
    },
    {
      name: transmissions.LAB_RESULTS,
      send: false,
      receive: true,
    },
  ],
};
