module.exports = {
  STATUS: {
    new: 'new',
    inProgress: 'in progress',
    completed: 'completed',
  },
  VARIABLE_TYPES: {
    integer: 'integer',
    string: 'string',
    date: 'date',
    // time: 'time',
    // dateTime: 'Date and Time',
    array: 'array',
  },
  SOURCE_PRIORITY: {
    clinical: '1',
    ehr: '2',
    deviceEntry: '3',
    patientEntry: '4',
  },
  SOURCE_PRIORITY_MAPPER: {
    1: 'Provider',
    2: 'EHR',
    3: 'Device',
    4: 'Patient',
  },
  VARIABLE_UNIT_STANDARDS: {
    mass: 'lb',
    length: 'in',
  },
  VARIABLE_UNIT_TYPES: {
    mass: 'Mass',
    length: 'Length',
  },
  RESERVED_VARIABLES: {
    weight: 'weight',
    height: 'height',
    medications: 'medications',
    diagnosis_symptoms: 'diagnosis_symptoms',
    bmi: 'bmi',
    currentWeight: 'currentWeight',
    highestWeight: 'highestWeight',
    highestWeightYear: 'Date_HighWeightYear',
    lowestWeight: 'lowestWeight',
    lowestWeightYear: 'Date_LowWeightYear',
    regular_meals: 'Regular meals',
    biggest_daily_meal: 'Biggest Daily Meal',
    eating_triggers: 'eating_triggers',
    after_dinner_eating: 'After dinner eating',
    number_of_steps: 'Number of steps',
    activity_level: 'Activity level',
    barriers_to_activity: 'Barriers to activity',
    cigarette_use: 'Cigarette use',
    alcohol_use: 'Alcohol use',
    diagnosis: 'diagnosis',
    symptoms: 'symptoms',
    allergies: 'allergies',
    regular_sleep_hours: 'regular_sleep_hours',
    weight_gain_events: 'Weight Gain Events',
    low_weight_held: 'Low weight held',
    female_concerns: 'Female concerns',
    cancer_types: 'Cancer types',
    skipped_breakfast_reason: 'Skipped Breakfast Reason',
    skipped_lunch_reason: 'Skipped Lunch Reason',
    skipped_dinner_reason: 'Skipped Dinner Reason',
    dietary_strategies: 'Dietary Strategies',
    DOB: 'DOB',
  },
  FORMULA_CUSTOM_HELPERS: {
    dateHelper: ['NowDate'],
  },
  VITAL_UNIT_OF_MEASURES: {
    IMPERIAL: 'Imperial',
    METRIC: 'Metric',
  },
  MILESTONE_WEIGHT: {
    baseline_weight: 'BASELINE_WEIGHT',
    highest_weight: 'HIGHEST_WEIGHT',
  },
};
