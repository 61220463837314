import { call, takeLatest, put, select } from 'redux-saga/effects';
import fetchEntity from './fetch-entity';
import {
  createRolePermissions,
  createRoles,
  updateRoles,
  unselectRole,
  createUserRole,
  LOAD_CREATE_ROLE_PERMISSIONS,
  LOAD_CREATE_ROLES,
  LOAD_UPDATE_ROLES,
  LOAD_CREATE_USER_ROLE,
  LOAD_REMOVE_ROLE,
  LOAD_REMOVE_ROLE_PERMISSION,
  LOAD_REMOVE_USER_ROLE,
  LOAD_ROLES,
  LOAD_PROVIDERS_ROLES,
  LOAD_ROLES_PERMISSIONS,
  removeRole,
  removeRolePermission,
  removeUserRole,
  requestLoadRoles,
  rolePermissions,
  roles,
  providersRoles,
} from '../actions/roles';
import {
  rolesApi,
  createRolesApi,
  updateRolesApi,
  rolePermissionsApi,
  createRolePermissionsApi,
  createUserRoleApi,
  removeRoleApi,
  removeRolePermissionApi,
  removeUserRoleApi,
  providersRolesApi,
} from '../services/roles';
import { requestUserList } from '../actions/users';
import { errorUpdating, getProvidersRoles } from '../selectors/roles';

const fetchRoles = fetchEntity.bind(null, roles, rolesApi);

function* loadRoles() {
  yield call(fetchRoles);
}

export function* watchRoles() {
  yield takeLatest(LOAD_ROLES, loadRoles);
}

const fetchProvidersRoles = fetchEntity.bind(null, providersRoles, providersRolesApi);

function* loadProvidersRoles() {
  if (!getProvidersRoles.length) {
    yield call(fetchProvidersRoles);
  }
}

export function* watchProvidersRoles() {
  yield takeLatest(LOAD_PROVIDERS_ROLES, loadProvidersRoles);
}

const fetchCreateRoles = fetchEntity.bind(null, createRoles, createRolesApi);

function* loadCreateRoles({ data }) {
  yield call(fetchCreateRoles, data);
}

const fetchUpdateRoles = fetchEntity.bind(null, updateRoles, updateRolesApi);

function* loadUpdateRoles({ data }) {
  yield call(fetchUpdateRoles, data);
  const hasError = yield select(errorUpdating);
  if (!hasError) {
    yield put(unselectRole());
  }
}

export function* watchCreateRoles() {
  yield takeLatest(LOAD_CREATE_ROLES, loadCreateRoles);
}

export function* watchUpdateRoles() {
  yield takeLatest(LOAD_UPDATE_ROLES, loadUpdateRoles);
}

const fetchRolePermissions = fetchEntity.bind(null, rolePermissions, rolePermissionsApi);

function* loadRolePermissions({ data }) {
  yield call(fetchRolePermissions, data);
  // yield put(hideModal(LOAD_ROLES_PERMISSIONS));
}

export function* watchRolePermissions() {
  yield takeLatest(LOAD_ROLES_PERMISSIONS, loadRolePermissions);
}

const fetchCreateRolePermissions = fetchEntity.bind(null, createRolePermissions, createRolePermissionsApi);

function* loadCreateRolePermissions({ data }) {
  yield call(fetchCreateRolePermissions, data);
  yield put(requestLoadRoles());
}

export function* watchCreateRolePermissions() {
  yield takeLatest(LOAD_CREATE_ROLE_PERMISSIONS, loadCreateRolePermissions);
}

const fetchCreateUserRole = fetchEntity.bind(null, createUserRole, createUserRoleApi);

function* loadCreateUserRole({ data }) {
  yield call(fetchCreateUserRole, data);
  yield put(requestUserList());
}

export function* watchCreateUserRole() {
  yield takeLatest(LOAD_CREATE_USER_ROLE, loadCreateUserRole);
}

/**
 * Remove role
 */

const fetchRemoveRole = fetchEntity.bind(null, removeRole, removeRoleApi);

function* loadRemoveRole({ id }) {
  yield call(fetchRemoveRole, id);

  yield put(requestLoadRoles());
}

export function* watchRemoverRole() {
  yield takeLatest(LOAD_REMOVE_ROLE, loadRemoveRole);
}

/**
 * Remove role permissions
 */

const fetchRemoveRolePermissions = fetchEntity.bind(null, removeRolePermission, removeRolePermissionApi);

function* loadRemoveRolePermissions(data) {
  yield call(fetchRemoveRolePermissions, data);
}

export function* watchRemoverRolePermissions() {
  yield takeLatest(LOAD_REMOVE_ROLE_PERMISSION, loadRemoveRolePermissions);
}

/**
 * Remove user role
 */

const fetchRemoveUserRole = fetchEntity.bind(null, removeUserRole, removeUserRoleApi);

function* loadRemoveUserRole({ id }) {
  yield call(fetchRemoveUserRole, id);
}

export function* watchRemoveUserRole() {
  yield takeLatest(LOAD_REMOVE_USER_ROLE, loadRemoveUserRole);
}
