import { call, takeLatest } from 'redux-saga/effects';

import {
  LOAD_NEXT_QUESTION,
  getNextQuestion,
  LOAD_QUESTIONNAIRES,
  getQuestionnaires,
  LOAD_COMPLETED_QUESTIONNAIRES,
  getCompletedQuestionnaires,
} from 'actions/patient-assessments';

import { getPatientAssessmentsApi, getNextQuestionApi } from 'services/patient-assessments';

import fetchEntity from './fetch-entity';

/**
 * Get Next Question
 */
const fetchNextQuestion = fetchEntity.bind(null, getNextQuestion, getNextQuestionApi);

export function* loadNextQuestion({ type, ...data }) {
  yield call(fetchNextQuestion, data);
}

export function* watchNextQuestion() {
  yield takeLatest(LOAD_NEXT_QUESTION, loadNextQuestion);
}

/**
 * Load Questionnaires
 */
const fetchQuestionnaires = fetchEntity.bind(null, getQuestionnaires, getPatientAssessmentsApi);

export function* loadQuestionnaires({ type, ...data }) {
  yield call(fetchQuestionnaires, data);
}

export function* watchLoadQuestionnaires() {
  yield takeLatest(LOAD_QUESTIONNAIRES, loadQuestionnaires);
}

/**
 * Load Completed Questionnaires
 */
const fetchCompletedQuestionnaires = fetchEntity.bind(null, getCompletedQuestionnaires, getPatientAssessmentsApi);

export function* loadCompletedQuestionnaires({ type, ...data }) {
  yield call(fetchCompletedQuestionnaires, data);
}

export function* watchLoadCompletedQuestionnaires() {
  yield takeLatest(LOAD_COMPLETED_QUESTIONNAIRES, loadCompletedQuestionnaires);
}

const PatientAssessmentsSagas = {
  watchNextQuestion,
  watchLoadQuestionnaires,
  watchLoadCompletedQuestionnaires,
};
export default PatientAssessmentsSagas;
