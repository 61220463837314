import React from 'react';
import styled from 'styled-components';
import { Tabs as tabs } from 'antd';

const { TabPane } = tabs;
const Component = styled(tabs)`
  .ant-tabs-tab {
    padding: 8px;
  }

  > .ant-tabs-nav .ant-tabs-nav-list,
  > div > .ant-tabs-nav .ant-tabs-nav-list {
    flex: unset;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 700;
  }
`;

const Tabs = ({ ...props }) => <Component {...props} />;

Tabs.TabPane = TabPane;

/** @component */
export default Tabs;
