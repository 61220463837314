module.exports = {
  deviceIntegrations: {
    televital: {
      name: 'teleVital',
      connectionParam: 'deviceId',
    },
  },
  dataAndDeviceTypes: {
    weight: {
      key: 1,
      isSupported: true,
    },
    bloodPressure: {
      key: 2,
      isSupported: true,
    },
    bloodGlucose: {
      key: 3,
      isSupported: false,
      parameters: {
        fasting: {
          key: 1,
          name: 'Fasting',
        },
        postMeal: {
          key: 2,
          name: 'Post Meal',
        },
        random: {
          key: 3,
          name: 'Random',
        },
      },
    },
    spo2: {
      key: 4,
      isSupported: false,
    },
    temperature: {
      key: 5,
      isSupported: false,
      parameters: {
        oral: {
          key: 1,
          name: 'Oral',
        },
        armpit: {
          key: 2,
          name: 'Armpit',
        },
        forehead: {
          key: 3,
          name: 'Forehead Thermometer',
        },
        ear: {
          key: 4,
          name: 'Ear Thermometer',
        },
      },
    },
    cgm: {
      key: 6,
      isSupported: false,
    },
  },
  deviceConnectionStatus: {
    1: 'connected',
    2: 'disconnected',
  },
  deviceConnectionStatusMapper: {
    connected: 1,
    disconnected: 2,
  },
  measurementUnits: {
    mass: 'lb',
    length: 'in',
    bloodPressure: 'mm Hg',
    pulseRate: 'bpm',
    bloodGlucose: 'mg/dl',
    temperature: 'F',
    bloodOxygen: '%',
  },
  events: {
    MARK_PATIENT_AS_RPM: 'mark_patient_as_rpm',
    UN_MARK_PATIENT_AS_RPM: 'un_mark_patient_as_rpm',
    RPM_PROVIDER_UPDATED: 'rpm_provider_updated',
    CONSENT_AWAITING: 'consent_awaiting',
    CONSENT_RECEIVED: 'consent_received',
    CONSENT_REMOVED: 'consent_removed',
    CONSENT_REJECTED: 'consent_rejected',
    DEVICE_CONNECTED: 'device_connected',
    SERVICE_INITIATION: 'service_initiation',
    RPM_DATA_TRANSMISSION: 'rpm_data_transmission',
    RPM_READINGS_THRESHOLD_NOT_MET: 'rpm_readings_threshold_not_met',
    RPM_TREATMENT_SERVICE_MONTHLY: 'rpm_treatment_service_monthly',
    RPM_TREATMENT_SERVICE_PER_20_MINUTE: 'rpm_treatment_service_per_20_minute',
    RPM_DATA_ANALYSIS: 'rpm_data_analysis',
  },
  statuses: {
    PENDING: 0,
    COMPLETE: 1,
    REJECTED: 2,
    ARCHIVED: 3,
    READY: 4,
  },
  statusesMapper: {
    0: 'Pending', // In progress and is not ready for a note to be added
    1: 'Complete', // Note is signed
    2: 'Rejected',
    3: 'Archived',
    4: 'Ready', // Is ready for the note to be added
  },
  cptCodes: {
    CPT_SERVICE_INITIATION: '99453',
    CPT_RPM_THRESHOLD_MET: '99454',
    CPT_TREATMENT_SERVICE_MONTHLY: '99457',
    CPT_TREATMENT_SERVICE_PER_20_MINUTE: '99458',
    CPT_DATA_ANALYSIS: '99091',
  },
  timer: {
    start: 'start',
    stop: 'stop',
  },
};
