import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { requestInitAuthentication } from 'actions/users';
// import { loadState } from 'configs/localStorage';
import { useRedux } from 'hooks/reduxHooks';

export default React.memo(() => {
  const [dispatch] = useRedux();
  useEffect(() => {
    if (Cookies.get('p12') || Cookies.get('p3')) {
      dispatch(requestInitAuthentication());
    }
  }, [dispatch]);
  return '';
});
