/*
Source for Withings 'appli' values : https://developer.withings.com/developer-guide/v3/data-api/keep-user-data-up-to-date/
Each appli represents a measurement group -> meastypes are subvalues of these groups
*/

module.exports = {
  MEASUREMENT_GROUPS: {
    1: 'Weight',
    4: 'Blood Pressure',
  },
  WITHINGS_DEVICES: {
    BP: 'BLOOD PRESSURE CUFF',
    SCALE: 'SCALE',
    KIT: 'KIT',
  },
  WITHINGS_SIGNED_OFF_ORDER_STATUS: {
    VERIFIED: 'VERIFIED',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    ERROR: 'ERROR',
  },
};
