import React from 'react';
import styled from 'styled-components';
import Title from '../Title';

const StyledSimpleCard = styled('div')`
  padding: 16px;
  border-radius: 4px !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  background: #fff;
`;

const SimpleCard = ({ label, children, ...props }) => (
  <div {...props}>
    {label ? <Title level={4}>{label}</Title> : null}
    <StyledSimpleCard>{children}</StyledSimpleCard>
  </div>
);

export default SimpleCard;
