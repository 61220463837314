import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get recommendations
export const RECOMMENDATIONS = createRequestTypes('RECOMMENDATIONS');
export const getRecommendations = {
  request: () => action(RECOMMENDATIONS[REQUEST]),
  success: (params, response) =>
    action(RECOMMENDATIONS[SUCCESS], {
      response: {
        entities: {
          recommendations: response,
        },
      },
    }),
  failure: (params, error) => action(RECOMMENDATIONS[FAILURE], { error }),
};
export const LOAD_RECOMMENDATIONS = 'LOAD_RECOMMENDATIONS';
export const requestLoadRecommendations = data => action(LOAD_RECOMMENDATIONS, data);

// load more recommendations
export const MORE_RECOMMENDATIONS = createRequestTypes('MORE_RECOMMENDATIONS');
export const getLoadMoreRecommendations = {
  request: () => action(MORE_RECOMMENDATIONS[REQUEST]),
  success: (params, response) =>
    action(MORE_RECOMMENDATIONS[SUCCESS], {
      response: {
        entities: {
          recommendations: {
            ...response,
            data: [...params.existingData, ...response.data],
          },
        },
      },
    }),
  failure: (params, error) => action(MORE_RECOMMENDATIONS[FAILURE], { error }),
};
export const LOAD_MORE_RECOMMENDATIONS = 'LOAD_MORE_RECOMMENDATIONS';
export const requestLoadMoreRecommendations = data => action(LOAD_MORE_RECOMMENDATIONS, data);

// reset recommendations
export const RESET_RECOMMENDATIONS = 'RESET_RECOMMENDATIONS';
export const resetRecommendations = () => action(RESET_RECOMMENDATIONS);

// create recommendation
export const CREATE_RECOMMENDATION = createRequestTypes('CREATE_RECOMMENDATION');
export const createRecommendation = {
  request: () => action(CREATE_RECOMMENDATION[REQUEST]),
  success: (params, response) =>
    action(CREATE_RECOMMENDATION[SUCCESS], {
      response: {
        entities: {
          createdRecommendation: response,
        },
      },
    }),
  failure: (params, error) => action(CREATE_RECOMMENDATION[FAILURE], { error }),
};
export const LOAD_CREATE_RECOMMENDATION = 'LOAD_CREATE_RECOMMENDATION';
export const requestCreateRecommendation = data => action(LOAD_CREATE_RECOMMENDATION, { data });

// delete recommendation
export const DELETE_RECOMMENDATION = createRequestTypes('DELETE_RECOMMENDATION');
export const deleteRecommendation = {
  request: () => action(DELETE_RECOMMENDATION[REQUEST]),
  success: () => action(DELETE_RECOMMENDATION[SUCCESS]),
  failure: (params, error) => action(DELETE_RECOMMENDATION[FAILURE], { error }),
};

export const LOAD_DELETE_RECOMMENDATION = 'LOAD_DELETE_RECOMMENDATION';
export const requestDeleteRecommendation = data => action(LOAD_DELETE_RECOMMENDATION, data);
