export const ADMIN_MENUS = [
  {
    groupName: 'Hospitals and Patients',
    items: [
      {
        name: 'Organizations',
        icon: 'greek-building',
        url: '/organizations',
      },
      {
        name: 'Patients',
        icon: 'patient-protection',
        url: '/patients',
      },
      {
        name: 'Providers',
        icon: 'professions-man-doctor-2',
        url: '/providers',
      },
    ],
  },
  {
    groupName: 'Lists',
    items: [
      {
        name: 'Countries',
        icon: 'romance-pride-lesbian-lgbt-global-globe',
        url: '/libraries/countries',
      },
      {
        name: 'State',
        icon: 'earth-edit',
        url: '/libraries/states',
      },
      {
        name: 'Zip Codes',
        icon: 'mailbox-house',
        url: '/libraries/zip',
      },
      {
        name: 'EHR Platforms',
        icon: 'hospital-building-1',
        url: '/ehr-platforms',
      },
      {
        name: 'Provider Interests',
        icon: 'love-it-check',
        url: '/libraries/provider-interests',
      },
      {
        name: 'Clinical Specialty',
        icon: 'hospital-first-aid',
        url: '/libraries/clinical-specialty',
      },
      {
        name: 'Provider Degrees',
        icon: 'study-virtual-classroom-2',
        url: '/libraries/provider-degrees',
      },
      {
        name: 'Patient Goals',
        icon: 'dating-target-1',
        url: '/patient-goals',
      },
      {
        name: 'Goal Categories',
        icon: 'cursor-target-1',
        url: '/goal-categories',
      },
      {
        name: 'Insurance Plans',
        icon: 'insurance-hands',
        url: '/libraries/insurance-plans',
      },
      {
        name: 'Insurance Affiliation',
        icon: 'travel-insurance-cover',
        url: '/libraries/insurance-affiliation',
      },
      {
        name: 'Organization Interests',
        icon: 'real-estate-action-building-heart',
        url: '/libraries/organization-interests',
      },
      {
        name: 'Organization types',
        icon: 'real-estate-dimensions-building-ruler',
        url: '/libraries/organization-types',
      },
      {
        name: 'Patient Availability',
        icon: 'time-exercise-time-2',
        url: '/libraries/patient-availabilities',
      },
      {
        name: 'Medications',
        icon: 'pill',
        url: '/medications',
      },
      {
        name: 'Diagnosis/Symptoms',
        icon: 'monitor-heart-beat-search',
        url: '/symptoms',
      },
      {
        name: 'Programs',
        icon: 'task-list-pen',
        url: '/programs',
      },
      {
        name: 'Devices',
        icon: 'diet-scale',
        url: '/devices',
      },
      {
        name: 'Living Plate',
        icon: 'asian-food-noodles-bowl',
        url: '/recipe-administration',
      },
      {
        name: 'Recipe Tags',
        icon: 'food-delivery-food-bill-history-ticket',
        url: '/recipe-tags',
      },
      {
        name: 'Domo Embeds',
        icon: 'ui-browser-slider',
        url: '/domo-embeds',
      },
      {
        name: 'Domo Embed sections',
        icon: 'folder-code',
        url: '/domo-embed-sections',
      },
      {
        name: 'Nutrition Goals',
        icon: 'chef-gear-pizza',
        url: '/nutrition-goals',
      },
    ],
  },
  {
    groupName: 'Tools',
    items: [
      {
        name: 'Email Tracking',
        icon: 'shopping-direct-mail-advertising-envelope-megaphone',
        url: '/email-tracking',
      },
      {
        name: 'StyleGuide',
        icon: 'audio-book-art',
        url: 'https://staging.d2nyqzv165wru6.amplifyapp.com',
        blank: true,
      },
      {
        name: 'Activity Logs',
        icon: 'filter-text',
        url: '/activity-logs',
      },
      {
        name: 'Learning center',
        icon: 'online-learning-online-course-2',
        url: '/customize-learning',
      },
      {
        name: 'Cron Job',
        icon: 'server-clock',
        url: '/cron-job',
      },
      {
        name: 'Visits',
        icon: 'time-monthly-1',
        url: '/visits',
      },
      {
        name: 'EHR Integration',
        icon: 'electronics-integrated-circuit',
        url: '/ehr-logs',
      },
      {
        name: 'Unsynced Athena Appointments',
        icon: 'filter-text',
        url: '/unsynced-appts',
      },
      {
        name: 'Billable Events',
        icon: 'accounting-invoice-hand',
        url: '/rpm-logs',
      },
      {
        name: 'Withings Error Dashboard',
        icon: 'filter-text',
        url: '/withings-error',
      },
      {
        name: 'Empanelment',
        icon: 'calendar',
        url: '/empanelment',
      },
      {
        name: 'Linked-Users',
        icon: 'human-resources-workflow',
        url: '/linked-users',
      },
      {
        name: 'Textract Logs',
        icon: 'textract-error',
        url: `/textract-logs`,
      },
      {
        name: 'Textract Lookup Master',
        icon: 'textract-lookup-master',
        url: `/textract-lookup-master`,
      },
    ],
  },
  {
    groupName: 'Assessments',
    items: [
      {
        name: 'Assessments',
        icon: 'list-quill',
        url: '/assessments',
      },
      {
        name: 'Variables',
        icon: 'user-female-teacher-math',
        url: '/global-variables',
      },
      {
        name: 'Recommendations',
        icon: 'check-shield',
        url: '/recommendations',
      },
    ],
  },
  {
    groupName: 'Settings',
    items: [
      {
        name: 'Users',
        icon: 'multiple-users-3',
        url: '/users',
      },
      {
        name: 'Roles Permissions',
        icon: 'multiple-users-2',
        url: '/roles-permissions',
      },
      {
        name: 'Languages',
        icon: 'translate',
        url: '/languages',
      },
      {
        name: 'System messages',
        icon: 'messages-bubble-forward-all',
        url: '/system-messages',
      },
    ],
  },
];

export const DEFAULT_LANGUAGE_ID = 1; // English
