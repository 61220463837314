import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';

import Button from '../Button';
import StreamlineIcon from '../StreamlineIcon';

const Component = styled.div`
  display: none;

  @media (max-width: 767.98px) {
    display: block;
  }

  background: #fff;
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
`;

const StyledFooter = ({ ...props }) => <Component {...props} />;

const FooterNavigation = ({ history, items }) => (
  <StyledFooter id='footer-navigation'>
    <Row justify='space-around'>
      {items.map(item => {
        const goTo = () => (item.absolute ? window.open(item.url, '_blank') : history.push(item.url));
        return (
          item.showAtBottom && (
            <Col key={item.key}>
              <Button type='link' onClick={goTo} key={item.key} icon={<StreamlineIcon icon={item.icon} size='24px' />} />
            </Col>
          )
        );
      })}
    </Row>
  </StyledFooter>
);
/** @component */
export default FooterNavigation;
