import { takeLatest, call } from 'redux-saga/effects';

import { syncCalendars } from 'constants/calendars';
import { LOAD_CALENDAR_AUTH_URL, getCalendarAuthUrl, LOAD_SYNC_CALENDAR, getSyncCalendar } from 'actions/calendars';
import { getOutlookAuthUrlApi, syncOutlookCalendarApi } from 'services/outlook';
import { getOAuthUrl, syncCalendar } from 'services/google';
import fetchEntity from './fetch-entity';

export function* loadCalendarAuthUrl({ type, calendarType }) {
  let api;
  switch (calendarType) {
    case syncCalendars.CALENDARS_GOOGLE.name:
      api = getOAuthUrl;
      break;
    case syncCalendars.CALENDARS_OUTLOOK.name:
      api = getOutlookAuthUrlApi;
      break;
    default:
      break;
  }
  const fetchGetCalendarAuthUrl = fetchEntity.bind(null, getCalendarAuthUrl, api);
  yield call(fetchGetCalendarAuthUrl);
}

function* watchLoadCalendarAuthUrl() {
  yield takeLatest(LOAD_CALENDAR_AUTH_URL, loadCalendarAuthUrl);
}

/**
 * Sync Calendar
 */

export function* loadSyncCalendar({ type, calendarType, ...data }) {
  let api;
  switch (calendarType) {
    case syncCalendars.CALENDARS_GOOGLE.name:
      api = syncCalendar;
      break;
    case syncCalendars.CALENDARS_OUTLOOK.name:
      api = syncOutlookCalendarApi;
      break;
    default:
      break;
  }
  const fetchSyncCalendar = fetchEntity.bind(null, getSyncCalendar, api);
  yield call(fetchSyncCalendar, data);
}

function* watchLoadSyncCalendar() {
  yield takeLatest(LOAD_SYNC_CALENDAR, loadSyncCalendar);
}

const CalendarSagas = {
  watchLoadCalendarAuthUrl,
  watchLoadSyncCalendar,
};
export default CalendarSagas;
