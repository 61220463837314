import client from './client';

export const createOrganizationApi = data => client().post('/organizations', data);

export const getOrganizationsApi = (params = {}) => client().get('/organizations', { params });

export const getOrganizationApi = ({ id, ...params }) => client().get(`/organizations/${id}`, { params });

export const removeOrganizationApi = id => client().delete(`/organizations/${id}`);

export const updateOrganizationApi = data => client().put(`/organizations/${data.id}`, data);
