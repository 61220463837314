module.exports = {
  messaging: {
    chatMultipleParticipants: 'messaging.chat.multiple.participants',
    assessmentSubmit: 'assessment.submit',
    questionDeleteRestriction: 'question.delete.restriction',
    questionnaireVersionChange: 'questionnaire.version.change',
    retakeQuestionnaire: 'questionnaire.retake',
    deactivatePatient: 'deactivate.patient',
    activatePatient: 'activate.patient',
    deleteProgressNote: 'delete.progress.note',
    notFoundPage: 'not.found.page',
    notPermittedPage: 'not.permitted.page',
    errorPagesHeader: 'error.pages.header',
    whiteScreenPagesHeader: 'whiteScreen.pages.header',
    whiteScreenPagesMessage: 'whiteScreen.page.message',
    // recommendations
    recommendationOutputRemoveWarning: 'reccomendation.output.remove.warning',
    recommendationRemoveWarning: 'reccomendation.remove.warning',
    recommendationRemoveConditionWarning: 'reccomendation.remove.logic.condition.question',
    recommendationLogicValidationError: 'reccomendation.logic.validation.error',
    recommendationOutputValidationError: 'reccomendation.output.validation.error',
    recommendationOutputDuplicatesError: 'reccomendation.output.duplicates.error',
    recommendationAssessmentVersionChangeError: 'reccomendation.assessment.version.change.error',
    recommendationBanner: 'recommendation.page.banner',
    // schedule settings
    useRegularHours: 'use.regular.hours',
    deleteScheduleSettings: 'delete.schedule.settings',
    // login page system messages
    passwordLocked: 'password.locked.message',
    expiredToken: 'expired.token.message',
    invalidToken: 'invalid.token.message',
    incorrectFormatToken: 'incorrect.format.token.message',
    userNotFound: 'user.not.found',
    passwordPWNED: 'password.pwned',
    // patient account verification
    secureAccountMessageTitle: 'secure.account.message.title',
    secureAccountMessage: 'secure.account.message',
    // rpm logs table
    rpmLogsTableBlankMessage: 'rpm.logs.blank',
  },
  summary: {
    summaryBanner: 'summary.page.banner',
    viewingAsPatientBanner: 'viewing.as.patient.banner',
  },
  assessment: {
    organizationChangeWarning: 'assessment.mappedData.warning',
    organizationChangeError: 'assessment.recommendation.error',
    verifyExplanation: 'assessment.verifyExplanation',
    confirmVerify: 'assessment.confirmVerify',
    providerTitle: 'assessment.providerTitle',
    reviewExplanation: 'assessment.reviewExplanation',
  },
  goal: {
    goalsBanner: 'goals.page.banner',
    dismissedGoal: 'goal.dismiss.success',
    dismissConfirmationGoal: 'goal.dismiss.confirmation',
    activate: 'goal.activate',
    complete: 'goal.complete',
  },
  orientation: {
    welcome: 'orientation.welcome',
    dashboard: 'orientation.dashboard',
    questionnaire: 'orientation.questionnaire',
    recommendation: 'orientation.recommendation',
    learning: 'orientation.learning',
    goals: 'orientation.goals',
    logging: 'orientation.logging',
  },
  orientationProvider: {
    welcome: 'orientation.provider.welcome',
    dashboard: 'orientation.provider.dashboard',
    patientList: 'orientation.provider.patientList',
    patientRecord: 'orientation.provider.patientRecord',
    appointments: 'orientation.provider.appointments',
    learning: 'orientation.provider.learning',
  },
  insights: {
    information: 'insights.information',
  },
  appointments: {
    maximumProvidersSelected: 'maximum.providers.selected',
    deactivatedParticipants: 'appointments.deactivated.participants',
  },
  programs: {
    parentOnlyCourses: 'programs.parentOnly',
    inheritedCourses: 'programs.inherited',
    childOnlyCourses: 'programs.childOnly',
  },
  global: {
    banner: 'global.banner',
    migratedPatientsBanner: 'migrated.patient.dashboard.banner',
  },
  sideBar: {
    title: 'sideBarContent.title',
    description: 'sideBarContent.description',
  },
  logging: {
    food: 'logging.food.blank',
  },
  googleFit: {
    updatesModalTitle: 'googleFit.updatedModal.title',
    updatesModalContent: 'googleFit.updatedModal.content',
    isConnectedMessage: 'googleFit.connected.message',
    uniqueEmail: 'googleFit.unique.email',
  },
  updateSmsConfigs: {
    smsConfigsTitle: 'updateSmsConfigs.updatedModal.title',
    smsConfigsContent: 'updateSmsConfigs.updatedModal.content',
  },
  twoFactorAuthentication: {
    enabledConfirm: '2fa.enable.confirm',
    google: '2fa.google',
    email: '2fa.email',
    configureDescription: '2fa.configure.description',
    organizationDescription: '2fa.organization.description',
  },
  vpnConfiguration: {
    invalidIp: 'vpn.error',
  },
  athena: {
    patientNotFoundPage: 'athena.patient.not.found.page',
    providerNotFoundPage: 'athena.provider.not.found.page',
    organizationNotEnabled: 'athena.organization.not.enabled',
  },
};
