import { createSelector } from 'reselect';
import { LOAD_CREATE_ROLES, LOAD_ROLES, LOAD_UPDATE_ROLES, LOAD_PROVIDERS_ROLES } from 'actions/roles';
import { requestTypes } from 'constants/actions';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getRoles = createSelector(getEntities, entities => entities.roles || []);

export const getRoleState = state => state.role;

export const getRolesLoading = createLoadingSelector(LOAD_ROLES)();

export const errorLoading = createErrorSelector(LOAD_ROLES)();

export const getProvidersRoles = createSelector(getEntities, entities => entities.providersRoles);

export const getProvidersRolesLoading = createLoadingSelector(LOAD_PROVIDERS_ROLES)();

export const errorProvidersLoading = createErrorSelector(LOAD_PROVIDERS_ROLES)();

export const errorCreating = createErrorSelector(LOAD_CREATE_ROLES)();

export const errorUpdating = createErrorSelector(LOAD_UPDATE_ROLES)();

export const getSelectedRole = createSelector(getRoleState, selectedRole => selectedRole.role && selectedRole.role.selectedRole);

export const getSelectedRoleDetails = createSelector(getSelectedRole, getRoles, (selectedRole, allRoles) => {
  if (selectedRole && selectedRole.id) {
    return allRoles.find(item => item.id === selectedRole.id);
  }
  return {};
});

export const getSelectedRolePermissions = createSelector(getSelectedRoleDetails, selectedRoleDetails => {
  if (selectedRoleDetails && selectedRoleDetails.role_permissions && selectedRoleDetails.role_permissions.length) {
    return selectedRoleDetails.role_permissions.reduce((acc, item) => {
      const permissionsGroup = requestTypes.reduce((accumulator, action) => {
        if (item[action]) {
          return accumulator.concat({
            name: `${item.permission.name}:${action}`,
            id: item.permission.id,
            rolePermissionId: item.id,
          });
        }
        return accumulator;
      }, []);
      return acc.concat(permissionsGroup);
    }, []);
  }
  return [];
});
