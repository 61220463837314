module.exports = {
  syncCalendars: {
    CALENDARS_APPLE: {
      key: 'ical',
      name: 'Apple iCal',
    },
    CALENDARS_GOOGLE: {
      key: 'google-calendar',
      name: 'Google Calendar',
    },
    CALENDARS_OUTLOOK: {
      key: 'outlook',
      name: 'Outlook',
    },
  },
  calendarViews: [
    {
      key: 'day',
      value: 'timeGridDay',
      multipleValue: 'resourceTimeGridDay',
    },
    {
      key: 'week',
      value: 'timeGridWeek',
      multipleValue: 'timeGridWeek',
    },
    {
      key: 'month',
      value: 'dayGridMonth',
      multipleValue: 'dayGridMonth',
    },
  ],
  eventViews: {
    CALENDAR: 'calendar',
    DETAILS: 'details',
    EDIT: 'edit',
  },
};
