import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  max-width: 100%;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 50%;
    width: 50%;
  }
`;

const ImgComp = ({ src, srcSet = undefined, ...props }) => <Img src={src || srcSet} srcSet={srcSet} {...props} />;
/** @component */
export default ImgComp;
