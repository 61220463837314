import React, { Fragment } from 'react';
import UserMobileDropdown from './UserMobileDropdown';
import UserLargeDropdown from './UserLargeDropdown';

const UserProfile = ({ username, firstName, lastName, userMenu, hideArrow }) => (
  <Fragment>
    <UserMobileDropdown className='userMobileDropdown' dropDown={userMenu} />
    <UserLargeDropdown
      className='userLargeDropdown'
      hideArrow={hideArrow}
      userMenu={userMenu}
      username={username}
      firstName={firstName}
      lastName={lastName}
    />
  </Fragment>
);

export default UserProfile;
