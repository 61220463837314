import { createSelector } from 'reselect';
import { LOAD_COUNTRIES } from 'actions/countries';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getEntities from './entities';

export const getCountriesLoading = createLoadingSelector(LOAD_COUNTRIES)();

export const getCountriesError = createErrorSelector(LOAD_COUNTRIES)();

export const getCountryLoading = createLoadingSelector(LOAD_COUNTRIES)();

export const getCountries = createSelector(getEntities, entities => entities.countries);

export const getSelectedCountry = ({ country }) => country.countryId;
