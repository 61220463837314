import { loadState } from 'configs/localStorage';

export const setProviderToStorage = provider => localStorage.setItem('provider', JSON.stringify(provider));
export const getProviderFromStorage = () => JSON.parse(localStorage.getItem('provider')) || {};
export const clearProviderFromStorage = () => localStorage.removeItem('provider');

export const setAppointmentTypeToStorage = type => localStorage.setItem('appointmentType', JSON.stringify(type));
export const getAppointmentTypeFromStorage = () => JSON.parse(localStorage.getItem('appointmentType')) || {};
export const clearAppointmentTypeFromStorage = () => localStorage.removeItem('appointmentType');

export const setAppointmentDateToStorage = date => localStorage.setItem('appointmentDate', JSON.stringify(date));
export const getAppointmentDateFromStorage = () => JSON.parse(localStorage.getItem('appointmentDate')) || {};
export const clearAppointmentDateFromStorage = () => localStorage.removeItem('appointmentDate');

export const setIsFirstTimeStorage = () => localStorage.setItem('isFirstTime', 'true');
export const getIsFirstTimeFromStorage = () => localStorage.getItem('isFirstTime');
export const clearIsFirstTimeFromStorage = () => localStorage.removeItem('isFirstTime');

export const setExpTimeStorage = day => localStorage.setItem('expDate', day);
export const getExpTimeFromStorage = () => localStorage.getItem('expDate');
export const clearExpTimeFromStorage = () => localStorage.removeItem('expDate');

export const setExpTimeStorage2FA = day => localStorage.setItem('expDate2FA', day);
export const getExpTimeFromStorage2FA = () => localStorage.getItem('expDate2FA');
export const clearExpTimeFromStorage2FA = () => localStorage.removeItem('expDate2FA');

export const setExpClosed = () => localStorage.setItem('closed', true);
export const getExpClosed = () => localStorage.getItem('closed');
export const clearExpClosed = () => localStorage.removeItem('closed');

export const getUserTimeZone = () => {
  const { userTimezone } = loadState() || {};
  return userTimezone;
};

export const setDraftMessagesToStorage = messages => localStorage.setItem('draft', JSON.stringify(messages));
export const getDraftMessagesFromStorage = () => JSON.parse(localStorage.getItem('draft')) || [];
export const clearDraftMessagesFromStorage = () => localStorage.removeItem('draft');

export const setBannersSettingsToStorage = bannersSettings => localStorage.setItem('bannersSettings', JSON.stringify(bannersSettings));
export const getBannersSettingsFromStorage = () => JSON.parse(localStorage.getItem('bannersSettings')) || {};
export const clearBannersSettingsFromStorage = () => localStorage.removeItem('bannersSettings');

export const setConversation = conversation => localStorage.setItem('activeConversation', JSON.stringify(conversation));
export const getActiveConversation = () => JSON.parse(localStorage.getItem('activeConversation')) || {};
export const clearActiveConversation = () => localStorage.removeItem('activeConversation');
