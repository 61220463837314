/**
 * Build user data specific for launchDarkly
 * @important Do not add any HIPAA data information in this object as it is going to be shared with Launch Darkly
 * @param {Object} user User object
 * @param {Number} user.id User id
 * @param {String} user.email User email
 * @param {String} user.currentIpAddress User current Ip address
 * @param {Boolean} user.isTestUser User test flag identifier
 * @param {String} user.type User type
 * @param {Object} user.associations User associations
 * @param {String} hostUrl Host url
 * @returns {Object} User data
 */
module.exports = (user, hostUrl) => {
  const { id, email, currentIpAddress = '', isTestUser = false, type, associations = {} } = user;
  const { hostname = 'unknown' } = new URL(hostUrl);
  return {
    key: `user_${id}`,
    ip: currentIpAddress,
    email,
    custom: {
      hostname,
      isTestUser,
      userType: type,
      organizations: Object.values(associations)?.reduce((acc, { organizations = [] }) => [...acc, ...organizations?.map(({ name }) => name)], []),
    },
  };
};
