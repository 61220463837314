import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import { patientAssessmentsData } from 'bmiq-common';

import { LOAD_CREATE_PATIENT_RECORD, LOAD_PATIENT_RECORDS, LOAD_REMOVE_PATIENT_RECORD } from 'actions/patient-records';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getEntities from './entities';

export const getGraphOptions = ({ graphOptions }) => graphOptions;

const { SOURCE_PRIORITY_MAPPER } = patientAssessmentsData;

export const getPatientRecordsData = createSelector(getEntities, ({ patientRecords }) => patientRecords);

export const getPatientRecordParams = createSelector(getEntities, ({ patientRecords }) => patientRecords?.requested || null);

export const getPatientRecords = createSelector(getPatientRecordsData, getGraphOptions, (patientRecordsData, { sources, period }) => {
  const initialObject = Object.values(patientAssessmentsData.SOURCE_PRIORITY).reduce((acc, item) => ({ ...acc, [item]: [] }), {});
  return {
    baselineWeight: patientRecordsData?.baselineWeight ? patientRecordsData.baselineWeight : {},
    graph: patientRecordsData?.data.reduce(
      (acc, { priority, dateTime, value }) => ({
        ...acc,
        [priority]: [...acc[priority], [new Date(dateTime).getTime(), +value]],
      }),
      initialObject,
    ),
    table: patientRecordsData?.data.reduce((acc, { id, priority, dateTime, value }) => {
      const utcDateTime = moment(dateTime);
      const month = moment(dateTime).utc().format('MMMM YYYY');
      return {
        ...acc,
        [month]: [
          ...(acc[month] || []),
          {
            id,
            date: utcDateTime.format('MM/DD/YYYY'),
            time: utcDateTime.format('h:mm A'),
            source: SOURCE_PRIORITY_MAPPER[priority],
            weight: value,
          },
        ],
      };
    }, {}),
  };
});

export const getPatientRecordsForGraph = createSelector(getPatientRecordsData, patientRecordsData => {
  const initialObject = Object.values(patientAssessmentsData.SOURCE_PRIORITY).reduce((acc, item) => ({ ...acc, [item]: [] }), {});
  /* Initial Object Final Structure
     {
       1: [], Clinical
       2: [], EHR
       3: [], Device Entry
       4: [], Patient Entry
     }
    */
  return patientRecordsData.reduce(
    (acc, { priority, dateTime, value }) => ({
      ...acc,
      [priority]: [...acc[priority], [new Date(dateTime).getTime(), +value]],
    }),
    initialObject,
  );
});

export const loadPatientRecordLoading = createLoadingSelector(LOAD_PATIENT_RECORDS)();
export const loadPatientRecordError = createErrorSelector(LOAD_PATIENT_RECORDS)();

export const createPatientRecordLoading = createLoadingSelector(LOAD_CREATE_PATIENT_RECORD)();
export const createPatientRecordError = createErrorSelector(LOAD_CREATE_PATIENT_RECORD)();

export const removePatientRecordLoading = createLoadingSelector(LOAD_REMOVE_PATIENT_RECORD)();
export const removePatientRecordError = createErrorSelector(LOAD_REMOVE_PATIENT_RECORD)();
