import styled, { css } from 'styled-components';
import { InputNumber as inputNumber } from 'antd';

const InputNumber = styled(inputNumber)`
  ${props =>
    props.block &&
    css`
      width: 100%;
    `}
  font-size: 16px !important;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  box-shadow: none;
  padding-top: 4px;
  padding-bottom: 4px;

  :hover {
    background: #f8f8f8;
    border-color: #fff #fff #ebebeb;
  }

  :focus {
    box-shadow: none;
    border-color: #40a9ff;
    background: #fff;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-group-addon {
    border: none;
    background: none;
  }
`;
/** @component */
export default InputNumber;
