import { createSelector } from 'reselect';

import {
  LOAD_CREATE_ORGANIZATION,
  LOAD_ORGANIZATION,
  LOAD_ORGANIZATIONS,
  LOAD_REMOVE_ORGANIZATION,
  LOAD_UPDATE_ORGANIZATION,
} from 'actions/organizations';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const createOrganizationLoading = createLoadingSelector(LOAD_CREATE_ORGANIZATION)();
export const createOrganizationError = createErrorSelector(LOAD_CREATE_ORGANIZATION)();

export const getOrganizationsLoading = createLoadingSelector(LOAD_ORGANIZATIONS)();
export const getOrganizationsError = createErrorSelector(LOAD_ORGANIZATIONS)();

export const getOrganizationLoading = createLoadingSelector(LOAD_ORGANIZATION)();
export const getOrganizationError = createErrorSelector(LOAD_ORGANIZATION)();

export const removeOrganizationLoading = createLoadingSelector(LOAD_REMOVE_ORGANIZATION)();
export const removeOrganizationError = createErrorSelector(LOAD_REMOVE_ORGANIZATION)();

export const updateOrganizationLoading = createLoadingSelector(LOAD_UPDATE_ORGANIZATION)();
export const updateOrganizationError = createErrorSelector(LOAD_UPDATE_ORGANIZATION)();

export const getOrganizationsList = createSelector(getEntities, ({ organizations }) => organizations.rows);

export const getOrganizationsCount = createSelector(getEntities, ({ organizations }) => organizations.count);

export const getOrganizationUsers = createSelector(getEntities, entities => entities.organizationUsers);

export const getSingleOrganization = createSelector(getEntities, ({ organization }) => organization);
