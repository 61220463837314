/**
 * Format Phone Number to USA format
 * @param {String} phone
 * @returns {String}
 */
export const getFormattedPhoneNumber = (phone, isHomeNumber = false) => {
  // remove all unnecessary chars
  if (phone?.length) {
    if (isHomeNumber) {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    phone = phone?.[0] !== '+' ? `+1${phone}` : phone;
    // reformat to +1 (123) 123-123
    return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
  }
  return 'Invalid Input';
};

/**
 * Adapt Phone Number to USA format
 * @param {String} phone
 * @returns {String}
 */
export const adaptPhoneNumberWithDB = phone => {
  // starts with +, but not +1
  if (phone?.match(/^\+[0,2-9]\d+/)) {
    // For full phone numbers, this means non-US country code, so leave it for initial display
    if (phone?.length > 11) {
      return phone;
    }
    const newPhone = phone.slice(1);
    return `+1${newPhone}`;
  }
  if (!phone?.startsWith('+')) {
    if (phone?.startsWith('1')) {
      return `+${phone}`;
    }
    // If the phone number comes in with a non-US area code, replace it with +1
    if (phone?.length === 11) {
      const newPhone = phone.slice(1);
      return `+1${newPhone}`;
    }
    return `+1${phone}`;
  }

  return phone;
};
