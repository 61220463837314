const day = require('dayjs');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const isBetween = require('dayjs/plugin/isBetween');
const utc = require('dayjs/plugin/utc');
const timezonePlugin = require('dayjs/plugin/timezone');
const localeData = require('dayjs/plugin/localeData');
const weekday = require('dayjs/plugin/weekday');
const duration = require('dayjs/plugin/duration');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const minMax = require('dayjs/plugin/minMax');

day.extend(utc);
day.extend(timezonePlugin);
day.extend(customParseFormat);
day.extend(isBetween);
day.extend(localeData);
day.extend(weekday);
day.extend(duration);
day.extend(isSameOrBefore);
day.extend(isSameOrAfter);
day.extend(minMax);

module.exports = day;
