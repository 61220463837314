import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load files
 */
export const FILES = createRequestTypes('FILES');
export const getFiles = {
  request: () => action(FILES[REQUEST]),
  success: (params, response) =>
    action(FILES[SUCCESS], {
      response: {
        entities: {
          files: response,
        },
      },
    }),
  failure: (params, error) => action(FILES[FAILURE], { error }),
};
export const LOAD_FILES = 'LOAD_FILES';
export const requestLoadFiles = data => action(LOAD_FILES, data);

/**
 * Get file url
 */
export const GET_FILE_URL = createRequestTypes('GET_FILE_URL');
export const getFileUrl = {
  request: () => action(GET_FILE_URL[REQUEST]),
  success: () => action(GET_FILE_URL[SUCCESS]),
  failure: (params, error) => action(GET_FILE_URL[FAILURE], { error }),
};
export const LOAD_GET_FILE_URL = 'LOAD_GET_FILE_URL';
export const requestGetFileUrl = params => action(LOAD_GET_FILE_URL, params);

/**
 * Upload file
 */
export const UPLOAD_FILE = createRequestTypes('UPLOAD_FILE');
export const uploadFile = {
  request: () => action(UPLOAD_FILE[REQUEST]),
  success: (params, response) =>
    action(UPLOAD_FILE[SUCCESS], {
      response: {
        entities: {
          uploadedFilesIds: response,
        },
      },
    }),
  failure: (params, error) => action(UPLOAD_FILE[FAILURE], { error }),
};

export const LOAD_UPLOAD_FILE = 'LOAD_UPLOAD_FILE';
export const requestUploadFile = data => action(LOAD_UPLOAD_FILE, data);

export const SET_FILE = 'SET_FILE';
export const setFile = file => action(SET_FILE, { file });

export const UNSET_FILE = 'UNSET_FILE';
export const unsetFile = () => action(UNSET_FILE);

/**
 * Upload Assessment Image
 */
export const UPLOAD_ASSESSMENT_IMAGE = createRequestTypes('UPLOAD_ASSESSMENT_IMAGE');
export const uploadAssessmentImage = {
  request: () => action(UPLOAD_ASSESSMENT_IMAGE[REQUEST]),
  success: (params, response) =>
    action(UPLOAD_ASSESSMENT_IMAGE[SUCCESS], {
      response: {
        entities: {
          assessmentImageUrl: response,
        },
      },
    }),
  failure: (params, error) => action(UPLOAD_ASSESSMENT_IMAGE[FAILURE], { error }),
};

export const LOAD_UPLOAD_ASSESSMENT_IMAGE = 'LOAD_UPLOAD_ASSESSMENT_IMAGE';
export const requestUploadAssessmentImage = data => action(LOAD_UPLOAD_ASSESSMENT_IMAGE, data);

/**
 * Get Assessment Image Url
 */
export const GET_ASSESSMENT_IMAGE_URL = createRequestTypes('GET_ASSESSMENT_IMAGE_URL');
export const getAssessmentImageUrl = {
  request: () => action(GET_ASSESSMENT_IMAGE_URL[REQUEST]),
  success: (params, response) =>
    action(GET_ASSESSMENT_IMAGE_URL[SUCCESS], {
      response: {
        entities: {
          assessmentImageUrl: response,
        },
      },
    }),
  failure: (params, error) => action(GET_ASSESSMENT_IMAGE_URL[FAILURE], { error }),
};
export const LOAD_ASSESSMENT_IMAGE_URL = 'LOAD_ASSESSMENT_IMAGE_URL';
export const requestAssessmentImageUrl = params => action(LOAD_ASSESSMENT_IMAGE_URL, params);

/**
 * Remove Assessment Image
 */
export const REMOVE_ASSESSMENT_IMAGE = createRequestTypes('REMOVE_ASSESSMENT_IMAGE');
export const removeAssessmentImage = {
  request: () => action(REMOVE_ASSESSMENT_IMAGE[REQUEST]),
  success: () =>
    action(REMOVE_ASSESSMENT_IMAGE[SUCCESS], {
      response: {
        entities: {
          assessmentImageUrl: '',
        },
      },
    }),
  failure: (params, error) => action(REMOVE_ASSESSMENT_IMAGE[FAILURE], { error }),
};
export const LOAD_REMOVE_ASSESSMENT_IMAGE = 'LOAD_REMOVE_ASSESSMENT_IMAGE';
export const requestRemoveAssessmentImage = params => action(LOAD_REMOVE_ASSESSMENT_IMAGE, params);
