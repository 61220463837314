import client from './client';

export const createActionApi = data => client().post('/action-items', data);

export const editActionApi = data => client().put('/action-items', data);

export const getActionItemsApi = ({ params = {} }) => client().get('/action-items', { params });

export const getActionItemApi = id => client().get(`/action-items/${id}`);

export const removeActionItemApi = id => client().delete(`/action-items/${id}`);
