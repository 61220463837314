module.exports = {
  LOG_STATUSES: {
    inProgress: 1,
    accepted: 2, // accepted from third party api, in future we will have all type of statuses
    error: 3,
    skipped: 4,
  },
  TYPES: {
    sms: 1,
    // email: 2, not integrated yet
  },
  smsFromNumbers: {
    testMagicNumber: '+15005550006', // @see https://www.twilio.com/docs/iam/test-credentials?code-sample=code-attempt-to-purchase-an-invalid-number&code-language=Node.js&code-sdk-version=3.x
    mainPhoneNumber: '+13476901312',
  },
  temporarySmsTemplates: {
    // will be removed after final version of notifications
    mobilePhoneVerification: 'Your FlyteHealth mobile code is: {{verificationCode}}',
    resetPassword: 'We received your request to reset your password. Click here {{link}}',
    weightLogged7:
      'Logging weight 2x a week is a key way to recognize progress. Update your provider and keep your journey going with a weight log: {{link}}',
    weightLogged14:
      'Logging weight 2x a week is a key way to recognize progress. Update your provider and keep your journey going with a weight log: {{link}}',
    foodLogged7: 'Consistent food logging is a significant predictor of long-term weight loss. Log your meals and snacks at {{link}}. You can do it!',
    foodLogged14:
      'Consistent food logging is a significant predictor of long-term weight loss. Log your meals and snacks at {{link}}. You can do it!',
    patientMessage: 'Your provider sent you a secure message! Log in to FlyteHealth to read it: {{link}}',
    providerMessage: 'You have one or more unread messages from your FlyteHealth patients. Log in to read: {{link}}',
    appointmentScheduled: 'You have a new appointment scheduled with {{user}} on {{date}} at {{userLocalTime}}.',
    appointmentCancelled: 'Your {{date}} appointment with {{provider}} has been cancelled. Go to {{link}} to reschedule.',
    appointmentReScheduledPatient:
      'Your appointment with {{user}} is rescheduled for {{newDate}} at {{userLocalTime}}. View instructions at {{link}}',
    reminderWeek1: 'Complete all your FlyteHealth questionnaires for your personalized plan at {{link}} We’re in this together!',
    reminderWeek2: 'Even 1 goal a week will help you achieve long term success! Set your FlyteHealth goal at {{link}}.',
    reminderWeek3: 'Track progress for weight management success! Use FlyteHealth’s quick food tracker at {{link}}. You’ve got this! ',
    reminderWeek4: 'FlyteHealth’s tips are key to weight management! Complete 1 course a week to build tools for success at {{link}}.',
    createGoalReminder: 'Ready to create a new goal? Set your FlyteHealth goal at {{link}}. Small steps become significant milestones!',
    completeGoalReminder:
      "How's your goal going? Update your progress at {{link}}. Manageable weekly goals lead to long-term results. Take it one week at a time!",
    questionnaireReminder: 'Complete all your FlyteHealth questionnaires at {{link}} to create your personalized plan. We’re in this together!',
    twoFaCode: 'Your FlyteHealth code is {{twoFaCode}}. This code will be valid for 1 hour.',
    updateProfile: 'Hi! Your FlyteHealth profile has been updated. If it was not you, go to {{link}} to reset your password immediately.',
    patientReadings: 'Log in to FlyteHealth to review remote monitoring progress! {{countRPMPatients}} patients have new readings! {{link}}',
    upcomingAppointments: 'Just a reminder about your appointment with {{providerFullName}} on {{date}} at {{userLocalTime}}.',
    patientAssignmentToProvider: 'Login to meet your new patient, {{patientFullName}}. {{link}}',
    deviceUsageReminder:
      'Use your device then check FlyteHealth to review your health progress!  You had {{lastreadingscount}} readings last week. Try for more this week! {{link}}',
    appointmentQuestionnairesReminder: 'Please complete all pending questionnaires',
  },
};
