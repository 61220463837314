import Cookies from 'js-cookie';

export const loadState = () => {
  try {
    const serializedState = Cookies.get('userInfoData');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(window.atob(serializedState));
  } catch (e) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const { user } = state;
    if (user.user.loggedInUser) {
      const serializedState = window.btoa(
        JSON.stringify(
          {
            userTimezone: user?.user?.loggedInUser?.data?.timezone,
          },
          null,
          0,
        ),
      );
      Cookies.set('userInfoData', serializedState, { secure: process.env.NODE_ENV === 'production' });
    }
  } catch (e) {
    // ignore errors
  }
};

export const emptyState = () => {
  localStorage.clear();
  Cookies.remove('userInfoData');
  Cookies.remove('p12');
  Cookies.remove('p3');
};
