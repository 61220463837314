import streamlineSVGIcons from './streamline-icons';

// props will take SVG element attributes like height, width, color, fill etc.
// not the StreamlineIcon component's props
const aliases = {
  'delete-2': { newName: 'system-error', props: { color: '#f44336' } },
  'alert-circle': { newName: 'system-warning', props: { color: '#ffc107' } },
  'information-circle-bold': { newName: 'system-info', props: { color: '#3f51b5' } },
  'check-circle-1': { newName: 'system-success', props: { color: '#4caf50' } },
};

const aliasedIcons = Object.entries(aliases).reduce((icons, [icon, iconAlias]) => {
  const IconComponent = icons[icon];
  const iconComponentProps = aliases[icon].props ? aliases[icon].props : {};

  return {
    ...icons,
    [iconAlias.newName]: props => <IconComponent {...props} {...iconComponentProps} />,
  };
}, streamlineSVGIcons);

// this is for those icons which are more useful when customized than the default ones
// an example would be the system- icons which have specific colors
export default aliasedIcons;
