import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  LOAD_PATIENT_RECORDS,
  LOAD_CREATE_PATIENT_RECORD,
  LOAD_REMOVE_PATIENT_RECORD,
  REMOVE_ALL_RECORDS,
  getPatientRecords,
  createPatientRecord,
  removePatientRecord,
  requestPatientRecords,
} from 'actions/patient-records';
import { getPatientRecordsApi, removePatientRecordsApi } from 'services/patient-records';
import { createVitalApi } from 'services/patient-vitals';
import { createPatientRecordError, removePatientRecordError } from 'selectors/patient-records';
import fetchEntity from './fetch-entity';

/**
 * Get Patient Records
 */
const fetchPatientRecords = fetchEntity.bind(null, getPatientRecords, getPatientRecordsApi);

export function* loadPatientRecords({ type, ...data }) {
  yield call(fetchPatientRecords, data);
}

export function* watchPatientRecords() {
  yield takeLatest(LOAD_PATIENT_RECORDS, loadPatientRecords);
}

/**
 * Create Patient Record
 */
const fetchCreatePatientRecord = fetchEntity.bind(null, createPatientRecord, createVitalApi);

export function* loadCreatePatientRecord({ type, ...data }) {
  yield call(fetchCreatePatientRecord, data);
  if (!(yield select(createPatientRecordError))) {
    yield put(requestPatientRecords({ params: { variable: 'weight' } }));
  }
}

export function* watchCreatePatientRecord() {
  yield takeLatest(LOAD_CREATE_PATIENT_RECORD, loadCreatePatientRecord);
}

/**
 * Create Patient Record
 */
const fetchRemovePatientRecord = fetchEntity.bind(null, removePatientRecord, removePatientRecordsApi);

export function* loadRemovePatientRecord({ id }) {
  yield call(fetchRemovePatientRecord, id);
  if (!(yield select(removePatientRecordError))) {
    yield put(requestPatientRecords({ params: { variable: 'weight' } }));
  }
}

export function* watchRemovePatientRecord() {
  yield takeLatest(LOAD_REMOVE_PATIENT_RECORD, loadRemovePatientRecord);
}

function* loadRemoveAllPatientRecords() {
  yield put(getPatientRecords.success(undefined, null));
}

export function* watchRemoveAllPatientRecords() {
  yield takeLatest(REMOVE_ALL_RECORDS, loadRemoveAllPatientRecords);
}

const PatientSagas = {
  watchPatientRecords,
  watchCreatePatientRecord,
  watchRemovePatientRecord,
  watchRemoveAllPatientRecords,
};
export default PatientSagas;
