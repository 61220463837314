import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RightOutlined, LeftOutlined, CalendarOutlined } from '@ant-design/icons';
import day from 'utils/day';
import Button from 'style-guide/Button';
import ButtonsGroup from 'style-guide/Button/ButtonsGroup';
import DatePicker from 'style-guide/DatePicker';
import Text from 'style-guide/Text';

const StyledDatePicker = styled.div`
  .ant-picker {
    padding: 0 !important;
    border: none !important;
    display: inline-block;
  }

  .ant-picker-input {
    display: none !important;
  }

  .ant-btn-group button:nth-child(2) {
    margin: 0 8px;
  }
`;

const DatePickerBlock = ({ options, setOptions, onChange, ...props }) => {
  const [pickerEvent, setPickerEvent] = useState({ initiator: '', visible: false });
  const dateRender = props.dateRender ? { dateRender: props.dateRender } : {};
  const date = options.dateRange === 7 ? day(options?.date).isoWeekday(6) : options?.date;
  const currentDate = options.dateRange === 7 ? day().isoWeekday(6) : day(); // 6, i.e. next Saturday
  // we need to empty initiator after clicking outside of datepicker, because in other case the datepicker will not open in first click of button.
  useEffect(() => {
    const timerId = setInterval(() => {
      if (pickerEvent.initiator === 'datepicker.onOpenChange') {
        setPickerEvent({ ...pickerEvent, initiator: '' });
      }
    }, 200);

    return () => clearInterval(timerId);
  });

  return (
    <StyledDatePicker>
      <ButtonsGroup>
        <Button
          type='link'
          onClick={() =>
            setOptions(o => ({
              date: o?.date?.clone().subtract(options.dateRange, 'days'),
            }))
          }
        >
          <LeftOutlined />
        </Button>
        <Button
          type='link'
          onClick={() => {
            /* We need this because when datepicker is already opened,
          we close it with onOpenChange,
          so we need to prevent the else case work. */
            if (pickerEvent.initiator === 'datepicker.onOpenChange' && pickerEvent.visible === false) {
              /* We need to empty initiator, because in other case
          it will open the datepicker again. */
              setPickerEvent({ initiator: '', visible: pickerEvent.visible });
            } else {
              setPickerEvent({
                initiator: 'button',
                visible: true,
              });
            }
          }}
        >
          <Text level={3}>
            <CalendarOutlined />{' '}
            {options.dateRange === 7 ? `${date?.clone().subtract(6, 'days').format('MMM DD')} - ${date?.format('MMM DD')}` : date.format('MMM DD')}
          </Text>
        </Button>
        <DatePicker
          value={options.date}
          format='MM/DD/YYYY'
          open={pickerEvent.visible}
          onChange={selectedDate => {
            onChange(selectedDate);
            setPickerEvent({
              initiator: 'datepicker.onChange',
              visible: false,
            });
          }}
          onOpenChange={() =>
            setPickerEvent({
              initiator: 'datepicker.onOpenChange',
              visible: false,
            })
          }
          picker={options.dateRange === 7 ? 'week' : 'date'}
          {...dateRender}
          {...props}
        />
        <Button
          type='link'
          disabled={options?.date.diff(currentDate, 'days') === 0}
          onClick={() =>
            setOptions(dateOptions => ({
              date: dateOptions?.date?.clone().add(options.dateRange, 'days'),
            }))
          }
        >
          <RightOutlined />
        </Button>
      </ButtonsGroup>
    </StyledDatePicker>
  );
};

export default DatePickerBlock;
