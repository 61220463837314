const userTypesFormatting = {
  USER_TYPE_PATIENT: {
    key: 'patient',
    name: 'Patient',
  },
  USER_TYPE_ADMIN: {
    key: 'admin',
    name: 'Admin',
  },
  USER_TYPE_PROVIDER_ADMIN: {
    key: 'provider-admin',
    name: 'Provider Admin',
  },
  USER_TYPE_ORGANIZATION_ADMIN: {
    key: 'organization-admin',
    name: 'Organization Admin',
  },
  USER_TYPE_DEDU: {
    key: 'dedu',
    name: 'Dialog EDU user',
  },
};

const userRoles = {
  Admin: 'Admin',
  OrganizationAdmin: 'OrganizationAdmin',
  ProviderAdmin: 'ProviderAdmin',
  Providers: 'Providers',
  Patients: 'Patient',
  Dedu: 'Dedu',
};

const roleGroups = {
  Admin: 'Admin',
  Organization: 'Organization',
  Provider: 'Provider',
  Patient: 'Patient',
};

const testUser = [
  {
    title: 'Yes',
    value: true,
    name: 'Yes',
  },
  {
    title: 'No',
    value: false,
    name: 'No',
  },
];

module.exports = {
  USER_TYPE_PATIENT: userTypesFormatting.USER_TYPE_PATIENT.key,
  USER_TYPE_ADMIN: userTypesFormatting.USER_TYPE_ADMIN.key,
  USER_TYPE_PROVIDER_ADMIN: userTypesFormatting.USER_TYPE_PROVIDER_ADMIN.key,
  USER_TYPE_ORGANIZATION_ADMIN: userTypesFormatting.USER_TYPE_ORGANIZATION_ADMIN.key,
  USER_TYPE_DEDU: userTypesFormatting.USER_TYPE_DEDU.key,
  userTypesFormatting,
  userRoles,
  roleGroups,
  testUser,
};
