import { createSelector } from 'reselect';

import { LOAD_RECOMMENDATIONS, LOAD_CREATE_RECOMMENDATION, LOAD_DELETE_RECOMMENDATION, LOAD_MORE_RECOMMENDATIONS } from 'actions/recommendations';

import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

import getEntities from './entities';

// loading selectors
export const createRecommendationLoading = createLoadingSelector(LOAD_CREATE_RECOMMENDATION)();

export const getRecommendationsLoading = createLoadingSelector(LOAD_RECOMMENDATIONS)();

export const getMoreRecommendationsLoading = createLoadingSelector(LOAD_MORE_RECOMMENDATIONS)();

export const deleteRecommendationLoading = createLoadingSelector(LOAD_DELETE_RECOMMENDATION)();

// error selectors
export const errorLoadingRecommendations = createErrorSelector(LOAD_RECOMMENDATIONS)();

export const errorCreatingRecommendation = createErrorSelector(LOAD_CREATE_RECOMMENDATION)();

export const errorDeletingRecommendation = createErrorSelector(LOAD_DELETE_RECOMMENDATION)();

export const getRecommendations = createSelector(getEntities, entities => entities.recommendations);
export const getRecommendation = createSelector(getEntities, entities => entities.recommendation);

export const getCreatedRecommendation = createSelector(getEntities, entities => entities.createdRecommendation);
