import client from './client';

export const createMedicationApi = data => client().post('/medications', data);

export const getMedicationsApi = ({ skip, limit, keyword }) => client().get('/medications', { params: { $skip: skip, limit, keyword } });

export const getMedicationApi = id => client().get(`/medications/${id}`);

export const disableMedicationApi = data => client().patch(`/medications/${data.id}`, data);

export const editMedicationApi = data => client().put(`/medications/${data.id}`, data);

export const getMedicationsFdaApi = params => client().get('/fda', { params });
