import { call, takeLatest } from 'redux-saga/effects';

import { LOAD_SYMPTOMS, getSymptoms } from 'actions/symptoms';

import { getSymptomsApi } from 'services/symptoms';

import fetchEntity from './fetch-entity';

// get patient symptoms

const fetchLoadSymptoms = fetchEntity.bind(null, getSymptoms, getSymptomsApi);

export function* loadSymptoms(data) {
  yield call(fetchLoadSymptoms, data);
}

function* watchLoadSymptoms() {
  yield takeLatest(LOAD_SYMPTOMS, loadSymptoms);
}

const SymptomsSagas = {
  watchLoadSymptoms,
};
export default SymptomsSagas;
