const maxDecimals = {
  field: 'maxDecimals',
  value: null,
};
const maxDigit = {
  field: 'maxDigit',
  value: null,
};
const multipleSelection = {
  field: 'multipleSelection',
  value: null,
};
const allowOther = {
  field: 'allowOther',
  value: null,
};
const dateTime = {
  field: 'date/time',
  value: 'Date',
};
const text = {
  field: 'text',
  value: 'Short Answer',
};
const itemSearch = {
  field: 'item-search',
  value: 'Diagnosis',
};
const dateFormat = {
  field: 'dateFormat',
  value: 'MM/DD/YYYY',
};

module.exports = [
  {
    name: 'content',
    clearName: 'Content',
    operators: [],
    config: [],
  },
  {
    name: 'yes/no',
    clearName: 'Yes/No',
    operators: ['Is', 'Is Not', 'Is Answered'],
    config: [],
  },
  {
    name: 'multiple-choice',
    clearName: 'Multiple Choice',
    operators: ['Is', 'Is Not', 'Contains', 'Does Not Contain', 'Is Answered'],
    config: [multipleSelection, allowOther],
  },
  {
    name: 'dropdown',
    clearName: 'Dropdown',
    operators: ['Is', 'Is Not', 'Is Answered'],
    config: [],
  },
  {
    name: 'number',
    clearName: 'Number',
    operators: ['Is', 'Is Not', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal', 'Is Answered'],
    config: [maxDecimals, maxDigit],
  },
  {
    name: 'date/time',
    clearName: 'Date/Time',
    operators: ['Is', 'Is Not', 'Before', 'After', 'Is Answered'],
    config: [dateTime, dateFormat],
  },
  {
    name: 'text',
    clearName: 'Text',
    operators: ['Is', 'Is Not', 'Contains', 'Does Not Contain', 'Is Answered'],
    config: [text],
  },
  {
    name: 'scale',
    clearName: 'Scale',
    operators: ['Is', 'Is Not', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal', 'Is Answered'],
    config: [],
  },
  {
    name: 'item-search',
    clearName: 'Item Search',
    operators: ['Is', 'Is Not', 'Contains', 'Does Not Contain', 'Is Answered'],
    config: [multipleSelection, allowOther, itemSearch],
  },
];
