module.exports = {
  PHYSICIAN: { id: 5, slug: 'provider_physician_admin' },
  NURSE_PRACTITIONER: { id: 6, slug: 'provider_np_admin' },
  PHYSICIAN_ASSISTANT: { id: 7, slug: 'provider_physician_assistant_admin' },
  NURSE: { id: 8, slug: 'provider_nurse_admin' },
  ADMIN_ASSISTANT: { id: 9, slug: 'provider_admin_assistant_admin' },
  MEDICAL_ASSISTANT: { id: 10, slug: 'provider_medical_assistant_admin' },
  DIETITIAN: { id: 11, slug: 'provider_rd_admin' },
  MIDWIFE: { id: 12, slug: 'test-provider-role' },
};
