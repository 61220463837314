import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get provider daily hours
export const PROVIDER_DAILY_HOURS = createRequestTypes('PROVIDER_DAILY_HOURS');
export const getProviderDailyHours = {
  request: () => action(PROVIDER_DAILY_HOURS[REQUEST]),
  success: (params, response) =>
    action(PROVIDER_DAILY_HOURS[SUCCESS], {
      response: {
        entities: {
          dailyHours: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDER_DAILY_HOURS[FAILURE], { error }),
};
export const LOAD_PROVIDER_DAILY_HOURS = 'LOAD_PROVIDER_DAILY_HOURS';
export const requestLoadProviderDailyHours = providerId => action(LOAD_PROVIDER_DAILY_HOURS, { providerId });

// create provider daily hours
export const CREATE_PROVIDER_DAILY_HOURS = createRequestTypes('CREATE_PROVIDER_DAILY_HOURS');
export const createProviderDailyHours = {
  request: () => action(CREATE_PROVIDER_DAILY_HOURS[REQUEST]),
  success: () => action(CREATE_PROVIDER_DAILY_HOURS[SUCCESS]),
  failure: (params, error) => action(CREATE_PROVIDER_DAILY_HOURS[FAILURE], { error }),
};
export const LOAD_CREATE_PROVIDER_DAILY_HOURS = 'LOAD_CREATE_PROVIDER_DAILY_HOURS';
export const requestCreateProviderDailyHours = data => action(LOAD_CREATE_PROVIDER_DAILY_HOURS, { data });
