import day from 'utils/day';
import { appointments as appointmentsConstants } from 'bmiq-common';

export const onViewChange = (value, setCalendarView, setTitle, calendar, saveCalendarPreferences = () => {}, activeProviders = []) => {
  setCalendarView(value);
  saveCalendarPreferences({ calendarView: value, selectedProviders: activeProviders.map(({ id }) => id) });
  const date = day.tz(calendar.getDate());
  switch (value) {
    case 'timeGridWeek':
      setTitle(`${date.startOf('week').format('MMM D')} - ${date.endOf('week').format('D, YYYY')}`);
      break;
    case 'dayGridMonth':
      setTitle(date.format('MMMM YYYY'));
      break;
    default:
      setTitle(date.format(' MMM DD, YYYY'));
  }
};

export const formatDate = (timeStr, calendarDay) => {
  if (!timeStr || !calendarDay) return new Date();
  const timeString = [timeStr.slice(0, -2), ' ', timeStr.slice(-2)].join('');
  const date = day(calendarDay).format('MM-DD-YYYY');
  return day(`${date} ${timeString}`, 'MM-DD-YYYYTHH:mm').format();
};

export const validHours = (startTime, endTime) => day(startTime, 'hh:mma').isBefore(day(endTime, 'hh:mma'));

export const validateStartTime = (startTime, endTime) => {
  if (startTime && endTime && !validHours(startTime, endTime)) {
    return {
      help: 'Please input valid time',
      validateStatus: 'error',
    };
  }
  return {};
};

/**
 * Get Current and upcoming months List
 * @returns {Object} Months List
 */
export const currentAndUpcomingMonths = () => {
  const monthsQt = [...Array(12)];
  return monthsQt
    .map((res, i) => ({
      month: day().month(i).format('MMMM'),
      year: day().format('YYYY'),
    }))
    .filter((_res, i) => i >= day().format('M') - 1);
};

export const isToday = date => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

export const formatAppointmentTitle = participants => {
  const { participantTypes } = appointmentsConstants;

  return participants[participantTypes.PATIENT].length > 1 ? 'Multi-Patient' : participants[participantTypes.PATIENT][0].fullName;
};

export const availableColorKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9];
export const groupedColorProvider = ({ key }) => ({
  borderColor: `rgba(${colorNameList[key]}, 0)`,
  backgroundColor: `rgba(${colorNameList[key]}, .7)`,
  checkboxColor: `rgb(${colorNameList[key]})`,
  textColor: '#fff',
});
const colorNameList = [
  '204,88,76',
  '81,137,195',
  '139,83,158',
  '98,149,83',
  '241,176,84',
  '251,234,96',
  '228,123,158',
  '166,108,84',
  '117,110,187',
  '90,185,165',
  '163,212,101',
  '226,127,87',
];
