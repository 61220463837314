import { call, takeLatest, select, put } from 'redux-saga/effects';

import {
  LOAD_RECOMMENDATIONS,
  getRecommendations,
  LOAD_MORE_RECOMMENDATIONS,
  getLoadMoreRecommendations,
  LOAD_CREATE_RECOMMENDATION,
  createRecommendation,
  LOAD_DELETE_RECOMMENDATION,
  deleteRecommendation as deleteRecommendationAction,
  RESET_RECOMMENDATIONS,
} from 'actions/recommendations';

import { getRecommendationsApi, createRecommendationApi, deleteRecommendationApi } from 'services/recommendations';

import { errorDeletingRecommendation, getRecommendations as getRecommendationsSelector } from 'selectors/recommendations';

import fetchEntity from './fetch-entity';

// get recommendations

const fetchLoadRecommendations = fetchEntity.bind(null, getRecommendations, getRecommendationsApi);

export function* loadRecommendations({ type, ...data }) {
  yield put(deleteRecommendationAction.failure(null, undefined));
  yield call(fetchLoadRecommendations, { params: { ...data } });
}

function* watchLoadRecommendations() {
  yield takeLatest(LOAD_RECOMMENDATIONS, loadRecommendations);
}

// load more recommendations
const fetchLoadMoreRecommendations = fetchEntity.bind(null, getLoadMoreRecommendations, getRecommendationsApi);

export function* loadMoreRecommendations({ type, ...data }) {
  yield put(deleteRecommendationAction.failure(null, undefined));
  const existingData = yield select(getRecommendationsSelector);
  yield call(fetchLoadMoreRecommendations, { params: { ...data }, existingData: existingData.data });
}

function* watchLoadMoreRecommendations() {
  yield takeLatest(LOAD_MORE_RECOMMENDATIONS, loadMoreRecommendations);
}
/**
 * Reset recommendations
 */
export function* loadResetRecommendations() {
  yield put(getRecommendations.success(undefined, { data: [] }));
}

function* watchResetRecommendations() {
  yield takeLatest(RESET_RECOMMENDATIONS, loadResetRecommendations);
}

// create recommendation

const fetchCreateRecommendation = fetchEntity.bind(null, createRecommendation, createRecommendationApi);

function* loadCreateRecommendation({ type, data }) {
  yield call(fetchCreateRecommendation, data);
}

function* watchCreateRecommendation() {
  yield takeLatest(LOAD_CREATE_RECOMMENDATION, loadCreateRecommendation);
}

// delete recommendation

const fetchDeleteRecommendation = fetchEntity.bind(null, deleteRecommendationAction, deleteRecommendationApi);

function* deleteRecommendation({ id, type }) {
  const existingData = yield select(getRecommendationsSelector);
  const removingRecIndex = existingData.data.findIndex(x => x.id === id);
  yield call(fetchDeleteRecommendation, id);
  if (!(yield select(errorDeletingRecommendation))) {
    existingData.data.splice(removingRecIndex, 1);
    yield put(
      getRecommendations.success(null, {
        ...existingData,
        total: existingData.total - 1,
        data: existingData.data,
      }),
    );
  }
}

function* watchDeleteRecommendation() {
  yield takeLatest(LOAD_DELETE_RECOMMENDATION, deleteRecommendation);
}

const RecommendationSagas = {
  watchLoadRecommendations,
  watchCreateRecommendation,
  watchDeleteRecommendation,
  watchResetRecommendations,
  watchLoadMoreRecommendations,
};

export default RecommendationSagas;
