import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip as Component } from 'antd';
import './Tooltip.less';

const Tooltip = styled(({ fullWidth, ...props }) => <Component {...props} />)`
  && {
    ${props =>
      props.fullWidth &&
      css`
        display: block !important;
        width: 100%;
      `};
  }
`;

/** @component */
export default Tooltip;
