import styled from 'styled-components';

export default styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .inner-wrapper {
    width: 930px;
    z-index: 999;
    overflow: hidden;
  }

  .section {
    background: #fff;
    height: 75vh;
    padding: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .logo {
    width: 122px;
    margin: 24px;
    z-index: 9999;
  }

  .content-wrapper {
    display: block;
    width: 100%;
    text-align: center;
  }

  .content-wrapper h3 {
    font-size: 36px;
    margin-bottom: 32px;
    margin-top: 0 !important;
  }

  .content-wrapper .ant-btn-link {
    padding-left: 0;
    padding-right: 0;
    color: #3e3e3e;
  }

  .content-wrapper .ant-btn + .ant-btn {
    margin-top: 24px;
  }

  .content-wrapper .form-field {
    margin-bottom: 24px;
    text-align: left;
  }

  .content-wrapper .error {
    color: #ff0;
    margin-bottom: 24px;
    display: block;
  }

  .content-wrapper .instructions {
    text-align: center;
    margin-bottom: 32px;
    display: block;
  }

  @media (max-width: 480px) {
    .inner-wrapper {
      width: 90%;
    }

    .section {
      height: auto;
      border-radius: 10px;
      padding: 32px;
    }

    .content-wrapper .ant-btn-link {
      margin-bottom: 0;
    }
  }
`;
