import { call, takeLatest, put, select } from 'redux-saga/effects';

import {
  getGlobalVariables,
  LOAD_GET_GLOBAL_VARIABLES,
  getGlobalVariable,
  LOAD_GLOBAL_VARIABLE,
  STORE_GLOBAL_VARIABLE,
} from 'actions/global-variables';

import { getGlobalVariablesApi, getGlobalVariableApi } from 'services/global-variables';

import { getGlobalVariables as getGlobalVariablesSelector, getGlobalVariable as getGlobalVariableSelector } from 'selectors/global-variables';

import fetchEntity from './fetch-entity';

// get all
const fetchLoadGlobalVariables = fetchEntity.bind(null, getGlobalVariables, getGlobalVariablesApi);

function* loadGlobalVariables({ type, ...data }) {
  const globalVariables = yield select(getGlobalVariablesSelector);
  if (!globalVariables.length) {
    yield call(fetchLoadGlobalVariables, data);
  }
}

export function* watchLoadGlobalVariables() {
  yield takeLatest(LOAD_GET_GLOBAL_VARIABLES, loadGlobalVariables);
}

// get single
const fetchLoadGlobalVariable = fetchEntity.bind(null, getGlobalVariable, getGlobalVariableApi);

function* loadGlobalVariable({ id }) {
  const globalVariable = yield select(getGlobalVariableSelector);
  if (Number(globalVariable.id) !== Number(id)) {
    yield call(fetchLoadGlobalVariable, id);
  }
}

export function* watchLoadGlobalVariable() {
  yield takeLatest(LOAD_GLOBAL_VARIABLE, loadGlobalVariable);
}

function* loadStoreGlobalVariable({ res }) {
  yield put(getGlobalVariables.success(undefined, { data: res }));
}

export function* watchStoreLoadGlobalVariable() {
  yield takeLatest(STORE_GLOBAL_VARIABLE, loadStoreGlobalVariable);
}
