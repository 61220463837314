import propTypes from 'prop-types';
import React from 'react';
import logo from '../assets/evolve-logo.png';

const Logo = ({ width, height }) => (
  <img
    style={{
      height,
      width,
      zIndex: 10,
      position: 'relative',
    }}
    src={logo}
    srcSet={`${logo} 2x`}
    alt='Logo'
  />
);

Logo.protoTypes = {
  width: propTypes.string,
  height: propTypes.string,
};
Logo.defaultProps = {
  width: '61px',
  height: '61px',
};

export default Logo;
