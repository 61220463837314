import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isRequired } from 'utils/errors';

export const useReduxAction = (action = () => isRequired('action'), data = {}, dependencies = [], condition = true, cleanUp = () => {}) => {
  useEffect(() => {
    if (condition) {
      action(data);
    }
    return cleanUp;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [...dependencies]);
};

export const useRedux = () => [useDispatch(), useSelector];

export const useReduxDispatch = ({
  action = () => isRequired('a valid Redux action'),
  selector = () => isRequired('a valid Redux Selector'),
  actionData = {},
  dependencies = [],
  condition = true,
  cleanUp = () => {},
  loadingSelector = () => false,
  errorSelector = null,
}) => {
  const [dispatch, select] = useRedux();
  const data = select(selector);
  useEffect(() => {
    if (condition) {
      dispatch(action(actionData));
    }
    return cleanUp;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [...dependencies, action, condition, dispatch]);
  const isLoading = select(loadingSelector);
  const error = errorSelector ? select(errorSelector) : undefined;
  return [data, isLoading, error];
};
