import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Menu as menu } from 'antd';

const { ItemGroup, Item } = menu;

const StyledMenu = styled(menu)`
  .ant-menu-item-selected {
    background: none !important;
  }

  .ant-menu-item-group-title {
    color: #0c2340;
    font-weight: bold;
  }

  .ant-menu-item {
    padding-left: 8px !important;
    margin-left: 8px;
    border-bottom: 1px solid #ebebeb;
  }

  .ant-menu {
    background: none !important;
    border-right: none !important;
  }
`;

export interface MenuItem {
  key: string;
  name: string;
  dataTestId: string;
  onClick: () => void;
}

export interface MenuItemContainer {
  key: string;
  title: string;
  menuItems: MenuItem[];
}

export interface VerticalMenuProps {
  menuItemContainers: MenuItemContainer[];
  notifications?: boolean;
  defaultKey?: string;
}

const VerticalMenu = ({ menuItemContainers = [], notifications = false, defaultKey = '' }: VerticalMenuProps) => {
  const defaultScroll = notifications ? 'notifications' : '';
  const [scrollTo, setScrollTo] = useState(defaultScroll);
  const [current, setCurrent] = useState(defaultKey);
  const [activeKey, setActiveKey] = useState(menuItemContainers[0]?.key ?? '');
  const toElement = document.getElementById(scrollTo);
  useEffect(() => {
    toElement && toElement.scrollIntoView({ behavior: 'smooth' });
  }, [menuItemContainers, scrollTo, toElement]);

  return (
    <StyledMenu mode='inline' selectedKeys={[current]} style={{ background: 'none', borderRight: 'none' }}>
      {menuItemContainers.map(menuItemContainer => (
        <ItemGroup
          key={menuItemContainer.key}
          title={<span style={activeKey === menuItemContainer.key ? { color: '#137AC9' } : {}}>{menuItemContainer.title}</span>}
        >
          {menuItemContainer.menuItems.map(menuItem => (
            <Item
              key={menuItem.key}
              onClick={() => {
                setScrollTo(menuItem.dataTestId);
                menuItem.onClick();
                setCurrent(menuItem.key);
                setActiveKey(menuItemContainer.key);
              }}
              data-testid={menuItem.dataTestId}
            >
              {menuItem.name}
            </Item>
          ))}
        </ItemGroup>
      ))}
    </StyledMenu>
  );
};

export default VerticalMenu;
