import { takeLatest, call, select } from 'redux-saga/effects';

import {
  LOAD_PATIENTS,
  getPatients,
  LOAD_PATIENT,
  getPatient,
  LOAD_UPDATE_PATIENT,
  updatePatient,
  LOAD_UPDATE_PATIENT_ACCOUNT_INFO,
  updatePatientAccountInfo,
} from 'actions/patients';
import { getPatientApi, getPatientsApi, updatePatientApi, updatePatientAccountInfoStatusApi } from 'services/patients';
import { getSinglePatient, getPatientError } from 'selectors/patients';
import fetchEntity from './fetch-entity';

/**
 * Get patients list
 */
const fetchGetPatients = fetchEntity.bind(null, getPatients, getPatientsApi);

export function* loadGetPatients({ type, ...data }) {
  yield call(fetchGetPatients, data);
}

function* watchLoadGetPatients() {
  yield takeLatest(LOAD_PATIENTS, loadGetPatients);
}

/**
 * Update patient
 */
const fetchUpdatePatient = fetchEntity.bind(null, updatePatient, updatePatientApi);

export function* loadUpdatePatient({ type, ...data }) {
  yield call(fetchUpdatePatient, data);
}

function* watchLoadUpdatePatient() {
  yield takeLatest(LOAD_UPDATE_PATIENT, loadUpdatePatient);
}

/**
 * Update account info
 */
const fetchUpdatePatientAccountInfo = fetchEntity.bind(null, updatePatientAccountInfo, updatePatientAccountInfoStatusApi);

export function* loadUpdatePatientAccountInfo({ type, ...data }) {
  yield call(fetchUpdatePatientAccountInfo, data);
}

function* watchLoadUpdatePatientAccountInfo() {
  yield takeLatest(LOAD_UPDATE_PATIENT_ACCOUNT_INFO, loadUpdatePatientAccountInfo);
}

/**
 * Get patient
 */
const fetchLoadPatient = fetchEntity.bind(null, getPatient, getPatientApi);

export function* loadPatient(data) {
  yield call(fetchLoadPatient, data);
  if (!(yield select(getPatientError))) {
    yield select(getSinglePatient);
  }
}

function* watchLoadPatient() {
  yield takeLatest(LOAD_PATIENT, loadPatient);
}

const PatientSagas = {
  watchLoadGetPatients,
  watchLoadUpdatePatient,
  watchLoadPatient,
  watchLoadUpdatePatientAccountInfo,
};
export default PatientSagas;
