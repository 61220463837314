module.exports = {
  NEVER_REMINDED: 'never-reminded',
  NO_WEIGHT_REMINDED: 'no-weight-reminded',
  NO_FOOD_REMINDED: 'no-food-reminded',
  COMPLETE_GOAL_REMINDED: 'complete-goal-reminded',
  CREATE_GOAL_REMINDED: 'create-goal-reminded',
  MIGRATION_WELCOMED: 'migration-welcomed',
  REMINDED_2: 'reminded-2',
  REMINDED_5: 'reminded-5',
  REMINDED_7: 'reminded-7',
  REMINDED_14: 'reminded-14',
  REMINDED_16: 'reminded-16',
  REMINDED_23: 'reminded-23',
  REMINDED_28: 'reminded-28',
  REMINDED_30: 'reminded-30',
  PATIENT_WEEK_1: 'patient-week-1',
  PATIENT_WEEK_2: 'patient-week-2',
  PATIENT_WEEK_3: 'patient-week-3',
  PATIENT_WEEK_4: 'patient-week-4',
};
