import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import themes from 'style-guide/theme';
import streamlineSVGIcons from './customIcons';
import { CircleWrapper, BadgeWrapper } from './iconWrappers';

const streamlineIcon = ({ icon, size, circle, badge, ...otherProps }) => {
  const StreamlineIconComponent = streamlineSVGIcons[icon];

  if (!StreamlineIconComponent) {
    // eslint-disable-next-line no-console
    console.warn(`Streamline icon is not found: ${icon}`);
    return null;
  }

  const iconComponent = <StreamlineIconComponent height={size} width={size} {...otherProps} />;
  const parsedSize = parseInt(size, 10);

  if (circle) {
    return (
      <CircleWrapper $size={parsedSize} $borderColor={otherProps.color || otherProps.$fill}>
        {iconComponent}
      </CircleWrapper>
    );
  }

  if (badge) {
    const badgeSize = parsedSize + 40;
    return <BadgeWrapper $size={badgeSize}>{iconComponent}</BadgeWrapper>;
  }

  return iconComponent;
};

const StreamlineIcon = styled(streamlineIcon)`
  ${props =>
    (props.$stroke || props.$fill) &&
    css`
      * {
        stroke: ${props.$stroke}!important;
        fill: ${props.$fill === true ? props.color : props.$fill}!important;
      }
    `}
`;

StreamlineIcon.defaultProps = {
  size: '16px',
  color: themes['stroke-icon'] || 'currentColor',
};

StreamlineIcon.displayName = 'StreamlineIcon';

StreamlineIcon.propTypes = {
  // Specifies icon to render. See streamline-icons/index.js and customIcons.js for available icon slugs or look up one in Storybook
  icon: PropTypes.string.isRequired,
  // Specifies icon size in pixels. This is optional as there is a default value
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Specifies icon color
  color: PropTypes.string,
  // Specifies whether to fill the icon with the `color` (in case $fill is `true`) or specifies the fill color
  $fill: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // Specifies whether icon should be rendered in a circle
  circle: PropTypes.bool,
  // Specifies whether icon should be rendered with a badge (blue star) background
  badge: PropTypes.bool,
};

export default StreamlineIcon;
