// TODO replace an array to an object
module.exports = {
  PLACEMENT: [
    'Goal',
    'Top line',
    'Exercise',
    'Nutrition',
    'Sleep',
    'Lifestyle',
    'Diagnosis',
    'Symptoms',
    'Allergies',
    'Medical',
    'Medication history',
    'Weight',
    'Fitness',
    'Eating Triggers',
    'Learn more',
  ],
  QUILLS: [
    {
      id: 1,
      name: 'Medication',
      key: 'medication_output',
    },
    {
      id: 2,
      name: 'Dosage',
      key: 'dosage_output',
    },
    {
      id: 3,
      name: 'Indication/Contraindications',
      key: 'indication_contraindication_output',
    },
    {
      id: 4,
      name: 'Warning',
      key: 'warning_output',
    },
  ],
  ROLE: ['Provider', 'Patient'],
  OPERATORS: [
    {
      type: 'integer',
      operators: ['Is', 'Is Not', 'Greater Than', 'Less Than', 'Greater Than Or Equal', 'Less Than Or Equal'],
    },
    {
      type: 'string',
      operators: ['Is', 'Is Not', 'Contains', 'Does Not Contain'],
    },
    {
      type: 'date',
      operators: ['Is', 'Is Not', 'Before', 'After'],
    },
    {
      type: 'array',
      operators: ['Is', 'Is Not', 'Contains', 'Does Not Contain'],
    },
  ],
  MEDICATION_TYPES: {
    1: 'Consider',
    2: 'Caution',
    3: 'Avoid',
  },

  MEDICATION_TYPES_MAPPER: {
    Consider: 1,
    Caution: 2,
    Avoid: 3,
  },
};
