const patientAssessmentsData = require('./patient-assessments-data');

module.exports = {
  /**
   * Calculate BMI
   * @param {string} unitMeasure unit of measurement: Imperial or metric
   * @param {number} weight
   * @param {number} height
   * @returns {number} calculated bmi
   */
  calculateBMI(unitMeasure, weight, height) {
    if (weight && height) {
      if (unitMeasure === patientAssessmentsData.VITAL_UNIT_OF_MEASURES.IMPERIAL && +height !== 0) {
        return (703 * weight) / height ** 2;
      }
      return weight / (height / 100) ** 2;
    }
    return undefined;
  },
};
