import styled, { css } from 'styled-components';

const Label = styled.span`
  ${props =>
    props.color &&
    css`
      background: ${props.color};
      color: white;
      font-size: 12px;
      font-weight: bold;
      border-radius: 10px;
      padding: 0px 8px;
      display: inline-block;
    `};
`;

/** @component */
export default Label;
