import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb as Breadcrumbs } from 'antd';

const { Item } = Breadcrumbs;

const Breadcrumb = ({ names, urls }) => (
  <Breadcrumbs>
    {names.map((name, index) => (
      <Item key={index}>
        {
          // Here we check if the element is the last one, if so we don't render it as a link
          index + 1 !== names.length ? <NavLink to={urls[index]}>{name}</NavLink> : name
        }
      </Item>
    ))}
  </Breadcrumbs>
);

export default Breadcrumb;
