import { PureComponent } from 'react';

class Container extends PureComponent {
  render() {
    return this.props.children;
  }
}

/** @component */
export default Container;
