import { createSelector } from 'reselect';

import { LOAD_APPOINTMENT, LOAD_CREATE_APPOINTMENT, LOAD_APPOINTMENTS } from 'actions/appointments';

import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const Appointments = createSelector(getEntities, ({ appointments }) => appointments);

export const appointmentData = createSelector(getEntities, entities => entities.appointment);

export const createAppointmentLoading = createLoadingSelector(LOAD_CREATE_APPOINTMENT)();

export const createAppointmentError = createErrorSelector(LOAD_CREATE_APPOINTMENT)();

export const getAppointmentsLoading = createLoadingSelector(LOAD_APPOINTMENTS)();

export const getAppointmentsError = createErrorSelector(LOAD_APPOINTMENTS)();

export const getAppointmentLoading = createLoadingSelector(LOAD_APPOINTMENT)();

export const getAppointmentError = createErrorSelector(LOAD_APPOINTMENT)();

export const getFilteredAppointments = ({ appointments }) => appointments.appointments.filteredAppointments;

export const getSelectedAppointment = ({ appointments }) => appointments.appointment.appointment;
