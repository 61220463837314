import { Space } from 'antd';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

const StyledSpace = styled(Space)`
  ${props =>
    props.$block &&
    css`
      width: 100%;
      /* by default Space component gets display: inline-flex value */
      display: flex;
    `}
`;

StyledSpace.defaultProps = {
  // keep the default behaviour
  $block: false,
};

StyledSpace.propTypes = {
  $block: propTypes.bool,
};

export default StyledSpace;
