import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const CALENDAR_AUTH_URL = createRequestTypes('CALENDAR_AUTH_URL');
export const getCalendarAuthUrl = {
  request: () => action(CALENDAR_AUTH_URL[REQUEST]),
  success: (params, response) =>
    action(CALENDAR_AUTH_URL[SUCCESS], {
      response: {
        entities: {
          calendarAuthUrl: response,
        },
      },
    }),
  failure: (params, error) => action(CALENDAR_AUTH_URL[FAILURE], { error }),
};

export const LOAD_CALENDAR_AUTH_URL = 'LOAD_CALENDAR_AUTH_URL';
export const requestCalendarAuthUrl = calendarType => action(LOAD_CALENDAR_AUTH_URL, { calendarType });

/**
 * Sync Calendar
 */
export const SYNC_CALENDAR = createRequestTypes('SYNC_CALENDAR');
export const getSyncCalendar = {
  request: () => action(SYNC_CALENDAR[REQUEST]),
  success: () => action(SYNC_CALENDAR[SUCCESS]),
  failure: (params, error) => action(SYNC_CALENDAR[FAILURE], { error }),
};

export const LOAD_SYNC_CALENDAR = 'LOAD_SYNC_CALENDAR';
export const requestSyncCalendar = data => action(LOAD_SYNC_CALENDAR, data);
