import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get provider weekly hours
export const PROVIDER_WEEKLY_HOURS = createRequestTypes('PROVIDER_WEEKLY_HOURS');
export const getProviderWeeklyHours = {
  request: () => action(PROVIDER_WEEKLY_HOURS[REQUEST]),
  success: (params, response) =>
    action(PROVIDER_WEEKLY_HOURS[SUCCESS], {
      response: {
        entities: {
          weeklyHours: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDER_WEEKLY_HOURS[FAILURE], { error }),
};
export const LOAD_PROVIDER_WEEKLY_HOURS = 'LOAD_PROVIDER_WEEKLY_HOURS';
export const requestLoadProviderWeeklyHours = providerId => action(LOAD_PROVIDER_WEEKLY_HOURS, { providerId });

// create provider weekly hours
export const CREATE_PROVIDER_WEEKLY_HOURS = createRequestTypes('CREATE_PROVIDER_WEEKLY_HOURS');
export const createProviderWeeklyHours = {
  request: () => action(CREATE_PROVIDER_WEEKLY_HOURS[REQUEST]),
  success: () => action(CREATE_PROVIDER_WEEKLY_HOURS[SUCCESS]),
  failure: (params, error) => action(CREATE_PROVIDER_WEEKLY_HOURS[FAILURE], { error }),
};
export const LOAD_CREATE_PROVIDER_WEEKLY_HOURS = 'LOAD_CREATE_PROVIDER_WEEKLY_HOURS';
export const requestCreateProviderWeeklyHours = data => action(LOAD_CREATE_PROVIDER_WEEKLY_HOURS, { data });
