import { combineReducers } from 'redux';
import { CHANGE_LANGUAGE } from 'actions/language';

const language = () => {
  const initialState = {
    activeLanguage: '0',
  };

  return (state = initialState, { type, error, activeLanguage }) => {
    switch (type) {
      case CHANGE_LANGUAGE:
        return {
          ...initialState,
          activeLanguage,
        };
      default:
        return state;
    }
  };
};

export default combineReducers({
  language: language(),
});
