import { action } from './index';

export const CONNECT_TO_WAITING_ROOM = 'CONNECT_TO_WAITING_ROOM';
export const PATIENT_CONNECTED = 'PATIENT_CONNECTED';
export const PATIENT_DISCONNECTED = 'PATIENT_DISCONNECTED';
export const PATIENT_DISCONNECTED_FROM_WAITING_ROOM = 'PATIENT_DISCONNECTED_FROM_WAITING_ROOM';
export const VV_MEETING_ENDED = 'VV_MEETING_ENDED';

export const connectToWaitingRoom = data => action(CONNECT_TO_WAITING_ROOM, data);
export const patientDisconnected = data => action(PATIENT_DISCONNECTED, data);
export const vvMeetingEnded = () => action(VV_MEETING_ENDED);
