import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Select as Component } from 'antd';

const Select = styled(
  forwardRef(({ parentContainer = true, ...props }, ref) => (
    <Component getPopupContainer={triggerNode => (parentContainer ? triggerNode.parentElement : document.body)} {...props} ref={ref} />
  )),
)`
  font-size: 16px !important;

  && {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    display: block;

    .ant-select-selector {
      padding-top: 4px;
      padding-bottom: 4px;
      height: auto;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      :hover {
        border-color: #3696d6 !important;
      }
    }

    .anticon.anticon-close {
      svg {
        margin: 0;
      }
    }

    .athena-sync-select {
      white-space: pre;
    }
  }
`;

Select.Option = Component.Option;
Select.OptGroup = Component.OptGroup;
/** @component */
export default Select;
