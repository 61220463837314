import { createSelector } from 'reselect';

import { LOAD_PATIENT, LOAD_PATIENTS, LOAD_UPDATE_PATIENT, LOAD_UPDATE_PATIENT_ACCOUNT_INFO } from 'actions/patients';

import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getPatientLoading = createLoadingSelector(LOAD_PATIENT)();
export const getPatientError = createErrorSelector(LOAD_PATIENT)();

export const getPatientsLoading = createLoadingSelector(LOAD_PATIENTS)();
export const getPatientsError = createErrorSelector(LOAD_PATIENTS)();

export const updatePatientLoading = createLoadingSelector(LOAD_UPDATE_PATIENT)();
export const updatePatientError = createErrorSelector(LOAD_UPDATE_PATIENT)();

export const updatePatientAccountLoading = createLoadingSelector(LOAD_UPDATE_PATIENT_ACCOUNT_INFO)();
export const updatePatientAccountError = createErrorSelector(LOAD_UPDATE_PATIENT_ACCOUNT_INFO)();

export const getPatientsList = createSelector(getEntities, ({ patients }) => patients.rows);

export const getPatientsTotalCount = createSelector(getEntities, ({ patients }) => patients.count);

export const getSinglePatient = createSelector(getEntities, ({ patient }) => patient);

export const getPatientsListForMessaging = createSelector(getPatientsList, patients => patients);
