import client from './client';

export const getDomoEmbedsApi = params => client().get('/domo', { params });

export const getDomoEmbedApi = ({ id, ...params }) => client().get(`/domo/${id}`, { params });

export const createDomoEmbedApi = data => client().post('/domo', data);

export const updateDomoEmbedApi = ({ id, ...data }) => client().put(`/domo/${id}`, data);

export const removeDomoEmbedApi = id => client().delete(`/domo/${id}`);
