import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const ZIP_CODES = createRequestTypes('ZIP_CODES');
export const getLoadZipCodes = {
  request: params => action(ZIP_CODES[REQUEST], { params }),
  success: (params, response) =>
    action(ZIP_CODES[SUCCESS], {
      response: {
        entities: {
          zipCodes: response,
        },
      },
    }),
  failure: (params, error) => action(ZIP_CODES[FAILURE], { error }),
};
export const LOAD_ZIP_CODES = 'LOAD_ZIP_CODES';
export const requestLoadZipCodes = data => action(LOAD_ZIP_CODES, { data });

export const CREATE_ZIP_CODE = createRequestTypes('CREATE_ZIP_CODE');
export const getCreateZipCodesAction = {
  request: () => action(CREATE_ZIP_CODE[REQUEST]),
  success: (params, response) => action(CREATE_ZIP_CODE[SUCCESS]),
  failure: (params, error) => action(CREATE_ZIP_CODE[FAILURE], { error }),
};
export const LOAD_CREATE_ZIP_CODES = 'LOAD_CREATE_ZIP_CODES';
export const requestCreateZipCodes = data => action(LOAD_CREATE_ZIP_CODES, { data });

export const REMOVE_ZIP_CODE = createRequestTypes('REMOVE_ZIP_CODE');
export const removeZipCodesAction = {
  request: () => action(REMOVE_ZIP_CODE[REQUEST]),
  success: (params, response) => action(REMOVE_ZIP_CODE[SUCCESS]),
  failure: (params, error) => action(REMOVE_ZIP_CODE[FAILURE], { error }),
};
export const LOAD_REMOVE_ZIP_CODE = 'LOAD_REMOVE_ZIP_CODE';
export const requestRemoveZipCodes = id => action(LOAD_REMOVE_ZIP_CODE, id);

export const EDIT_ZIP_CODE = createRequestTypes('EDIT_ZIP_CODE');
export const editZipCode = {
  request: () => action(EDIT_ZIP_CODE[REQUEST]),
  success: () => action(EDIT_ZIP_CODE[SUCCESS]),
  failure: (params, error) => action(EDIT_ZIP_CODE[FAILURE], { error }),
};

export const LOAD_EDIT_ZIP_CODE = 'LOAD_EDIT_ZIP_CODE';
export const requestEditZipCode = data => action(LOAD_EDIT_ZIP_CODE, data);

export const SELECT_ZIP_CODE = 'SELECT_ZIP_CODE';
export const selectZipCode = zipCodeId => action(SELECT_ZIP_CODE, { zipCodeId });

export const UNSELECT_ZIP_CODE = 'UNSELECT_ZIP_CODE';
export const unSelectZipCode = () => action(UNSELECT_ZIP_CODE);

export const GET_ZIP_CODE_OFFSET = 'GET_ZIP_CODE_OFFSET';
export const setZipCodesOffset = offset => action(GET_ZIP_CODE_OFFSET, { offset });
