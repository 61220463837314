import { call, takeLatest, select, put } from 'redux-saga/effects';

import {
  LOAD_APPOINTMENT,
  getAppointment,
  LOAD_CREATE_APPOINTMENT,
  createAppointment,
  LOAD_APPOINTMENTS,
  getAppointments,
  requestLoadAppointments,
  selectAppointment,
  setAppointments,
} from 'actions/appointments';

import { getAppointmentsApi, createAppointmentApi, appointmentApi } from 'services/appointments';

import { createAppointmentError, appointmentData, Appointments } from 'selectors/appointments';

import fetchEntity from './fetch-entity';

// get provider appointments
const fetchLoadAppointments = fetchEntity.bind(null, getAppointments, getAppointmentsApi);

export function* loadAppointments({ params }) {
  yield call(fetchLoadAppointments, params);
  yield put(setAppointments(yield select(Appointments)));
}

function* watchLoadAppointments() {
  yield takeLatest(LOAD_APPOINTMENTS, loadAppointments);
}

// create provider appointment
const fetchCreateAppointment = fetchEntity.bind(null, createAppointment, createAppointmentApi);

export function* loadCreateAppointment({ data }) {
  yield call(fetchCreateAppointment, data);
  if (!(yield select(createAppointmentError))) {
    const { providerIds } = data;
    yield put(requestLoadAppointments({ params: { providerId: providerIds } }));
  }
}

function* watchCreateAppointment() {
  yield takeLatest(LOAD_CREATE_APPOINTMENT, loadCreateAppointment);
}

// get appointment
const fetchLoadAppointment = fetchEntity.bind(null, getAppointment, appointmentApi);

export function* loadAppointment({ id }) {
  yield call(fetchLoadAppointment, id);
  yield put(selectAppointment(yield select(appointmentData)));
}

function* watchLoadAppointment() {
  yield takeLatest(LOAD_APPOINTMENT, loadAppointment);
}

const AppointmentSagas = {
  watchLoadAppointments,
  watchCreateAppointment,
  watchLoadAppointment,
};
export default AppointmentSagas;
