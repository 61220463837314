import React from 'react';
import { createActivitylogsApi } from 'services/activity-logs';
import { getUserId } from 'selectors/users';
import { connect } from 'react-redux';
import NotFoundPage from './Main/NotFoundPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      // todo Arthur this may be changed into new relic noticeError
      createActivitylogsApi({
        triggerChapter: 'errors',
        triggerName: 'frontEndError',
        userId: this.props.loggedInUserId,
        messageArgs: { errorMsg: error.message, errorStack: errorInfo.componentStack },
        dataChanged: [],
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <NotFoundPage isWhiteScreen />;
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  loggedInUserId: getUserId(state),
});

export default connect(mapStateToProps, {})(ErrorBoundary);
