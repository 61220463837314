import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

const LinerGradient = styled.div`
  && {
    ${props =>
      props.width &&
      css`
        width: ${props.width};
      `};
    ${props =>
      props.height &&
      css`
        height: ${props.height};
      `};
    position: relative;
    background: linear-gradient(
      90deg,
      ${props => `${props.theme[`light-blue-start-${props.level}`]} 0%,
      ${props.theme[`dark-blue-end-${props.level}`]} 100%`}
    );
    ${props =>
      props.radius &&
      css`
        border-radius: ${props.radius};
      `}
    ${props =>
      props.gradient &&
      css`
        background: ${props.gradient} !important;
      `}
    ${props =>
      props.zIndex &&
      css`
        z-index: ${props.zIndex} !important;
      `}
  }
`;
LinerGradient.propTypes = {
  width: propTypes.string,
  height: propTypes.string,
  start: propTypes.string,
  end: propTypes.string,
  radius: propTypes.string,
  gradient: propTypes.string,
  zIndex: propTypes.string,
};
LinerGradient.defaultProps = {
  width: '100%',
  height: '100px',
  start: 'rgb(19,81,118)',
  end: 'rgba(47,149,227,1)',
  radius: '0px',
};

/** @component */
export default LinerGradient;
