import client from './client';

export const createCountryApi = data => client().post('/countries', data);

export const getLoadCountriesApi = data => client().get('/countries', { params: data });

export const getCountryApi = id => client().get(`/countries_translations/${id}`);

export const removeCountriesApi = id => client().delete(`/countries/${id}`);

export const updateCountryTranslationApi = data => client().put(`/countries_translations/${data.id}`, data);

export const createCountryTranslationApi = data => client().post('/countries_translations', data);
