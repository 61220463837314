import { FILTER_TRIGGERS, CHECK_TRIGGERS } from 'actions/activity-manager';

export default (() => {
  const initialState = {
    data: [],
    checkedTriggers: [],
  };

  return (state = initialState, { type, data, checkedTriggers }) => {
    switch (type) {
      case FILTER_TRIGGERS:
        return {
          ...state,
          data: data.sort(),
        };
      case CHECK_TRIGGERS:
        return {
          ...state,
          checkedTriggers,
        };
      default:
        return state;
    }
  };
})();
