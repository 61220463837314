import { getEmailTracking, LOAD_EMAIL_TRACKING } from 'actions/email-tracking';
import { call, takeLatest } from 'redux-saga/effects';
import { getEmailTrackingsApi } from 'services/email-tracking';

import fetchEntity from './fetch-entity';

// loading emails
const fetchEmailTracking = fetchEntity.bind(null, getEmailTracking, getEmailTrackingsApi);

function* loadEmailTrackings(data) {
  yield call(fetchEmailTracking, data);
}

export function* watchLoadEmailTrackings() {
  yield takeLatest(LOAD_EMAIL_TRACKING, loadEmailTrackings);
}
