import React from 'react';
import styled from 'styled-components';
import Brand from 'style-guide/Brand';

const LogoArea = styled.div`
  img {
    display: inline-block;
    height: 29px;
    width: auto;
    line-height: 0;
  }

  @media screen and (min-width: 1024px) {
    img {
      height: 38px;
    }
  }
`;

const LogoContent = styled.div`
  cursor: pointer;

  @media screen and (min-width: 480px) {
    display: inline-block;
  }
`;

const HeaderLogo = ({ onClick = () => undefined }) => (
  <LogoContent>
    <LogoArea>
      <Brand.Flyte onClick={onClick} type={1} size='3x' level={1} />
    </LogoArea>
  </LogoContent>
);

export default HeaderLogo;
