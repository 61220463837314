import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const Component = ({ type, ...props }) => <span {...props} />;

const Cursor = styled(Component)`
  cursor: ${props => props.type};
`;

Cursor.propTypes = {
  /** css type for cursor style  */
  type: propTypes.string,
};

Cursor.defaultProps = {
  type: 'pointer',
};

/** @component */
export default Cursor;
