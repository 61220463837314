import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

day.extend(utc);
day.extend(timezone);
day.extend(customParseFormat);
day.extend(relativeTime);
day.extend(isoWeek);
day.extend(localizedFormat);
day.extend(isBetween);
day.extend(isSameOrBefore);

export default day;
