import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Create organization
 */
export const CREATE_ORGANIZATION = createRequestTypes('CREATE_ORGANIZATION');
export const createOrganization = {
  request: () => action(CREATE_ORGANIZATION[REQUEST]),
  success: () => action(CREATE_ORGANIZATION[SUCCESS]),
  failure: (params, error) => action(CREATE_ORGANIZATION[FAILURE], { error }),
};
export const LOAD_CREATE_ORGANIZATION = 'LOAD_CREATE_ORGANIZATION';
export const requestCreateOrganization = data => action(LOAD_CREATE_ORGANIZATION, data);

/**
 * Get organizations
 */
export const ORGANIZATIONS = createRequestTypes('ORGANIZATIONS');
export const getOrganizations = {
  request: () => action(ORGANIZATIONS[REQUEST]),
  success: (params, response) =>
    action(ORGANIZATIONS[SUCCESS], {
      response: {
        entities: {
          organizations: response,
        },
      },
    }),
  failure: (params, error) => action(ORGANIZATIONS[FAILURE], { error }),
};
export const LOAD_ORGANIZATIONS = 'LOAD_ORGANIZATIONS';
export const requestLoadOrganizations = data => action(LOAD_ORGANIZATIONS, data);

/**
 * Load Single Organization
 */
export const ORGANIZATION = createRequestTypes('ORGANIZATION');
export const getOrganization = {
  request: () => action(ORGANIZATION[REQUEST]),
  success: (params, response) =>
    action(ORGANIZATION[SUCCESS], {
      response: {
        entities: {
          organization: response,
        },
      },
    }),
  failure: (params, error) => action(ORGANIZATION[FAILURE], { error }),
};
export const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
export const requestLoadOrganization = id => action(LOAD_ORGANIZATION, { id });

/**
 * Remove Organization
 */
export const REMOVE_ORGANIZATION = createRequestTypes('REMOVE_ORGANIZATION');
export const removeOrganization = {
  request: () => action(REMOVE_ORGANIZATION[REQUEST]),
  success: () => action(REMOVE_ORGANIZATION[SUCCESS]),
  failure: (params, error) => action(REMOVE_ORGANIZATION[FAILURE], { error }),
};
export const LOAD_REMOVE_ORGANIZATION = 'LOAD_REMOVE_ORGANIZATION';
export const requestRemoveOrganization = data => action(LOAD_REMOVE_ORGANIZATION, data);

/**
 * Update Organization
 */
export const UPDATE_ORGANIZATION = createRequestTypes('UPDATE_ORGANIZATION');
export const updateOrganization = {
  request: () => action(UPDATE_ORGANIZATION[REQUEST]),
  success: () => action(UPDATE_ORGANIZATION[SUCCESS]),
  failure: (params, error) => action(UPDATE_ORGANIZATION[FAILURE], { error }),
};
export const LOAD_UPDATE_ORGANIZATION = 'LOAD_UPDATE_ORGANIZATION';
export const requestUpdateOrganization = data => action(LOAD_UPDATE_ORGANIZATION, data);
