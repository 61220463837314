import React from 'react';
import propTypes from 'prop-types';
import { notification } from 'antd';
import StreamlineIcon from '../StreamlineIcon';
import Text from '../Text';
import Button from '../Button';
import './notification.less';

const smallBoxHeight = 39;
const largeBoxHeight = 50;

const colors = {
  success: {
    background: '#F4FAF5',
    border: '#DCEFDD',
  },
  error: {
    background: '#FFF0F2',
    border: '#FFD6DA',
  },
  info: {
    background: '#F4F5FB',
    border: '#DADDF1',
  },
  warning: {
    background: '#FFFBF0;',
    border: '#FFF2CC',
  },
  question: {
    background: '#F6F8F9',
    border: '#DCE2E5',
  },
};

const Open = ({ key = '', type = 'open', message, description = '', size = 'large', duration = 0, icon = '', btn = '', onClick = () => {} }) => {
  let height = '';
  if (!description) {
    height = size === 'small' ? smallBoxHeight : largeBoxHeight;
  }
  notification[type === 'question' ? 'open' : type]({
    key,
    icon: <StreamlineIcon icon={icon || `system-${type}`} size={20} />,
    message: (
      <Text style={{ fontWeight: 'bold' }} level={3} color='#0C2340'>
        {message}
      </Text>
    ),
    style: {
      backgroundColor: colors[type] && colors[type].background,
      height,
      borderWidth: colors[type] && '2px',
      borderStyle: colors[type] && 'solid',
      borderColor: colors[type] && colors[type].border,
    },
    description: (
      <Text style={{ fontWeight: 'normal' }} level={4} color='#3E3E3E'>
        {description}
      </Text>
    ),
    btn: (
      <Button type='accent' style={{ minWidth: '77px', minHeight: '40px' }} onClick={() => notification.close(key)}>
        {btn}
      </Button>
    ),
    onClick,
    duration,
    className: [size === 'small' ? 'notification-small-size' : '', type === 'open' && !icon ? 'notification-description' : ''].join(' '),
  });
};
notification.propTypes = {
  /** Distance from the bottom of the viewport, when placement is bottomRight or bottomLeft (unit: pixels). */
  bottom: propTypes.number,
  /** Customized close button */
  btn: propTypes.element,
  /** Customized CSS class */
  className: propTypes.string,
  /** The content of notification box (required) */
  description: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** Time in seconds before Notification is closed. When set to 0 or null, it will never be closed automatically */
  duration: propTypes.number,
  /** Return the mount node for Notification */
  getContainer: propTypes.func,
  /** Customized icon */
  icon: propTypes.element,
  /** The unique identifier of the Notification */
  key: propTypes.string,
  /** The title of notification box (required) */
  message: propTypes.oneOfType([propTypes.string, propTypes.object]),
  /** Specify a function that will be called when the close button is clicked */
  onClose: propTypes.func,
  /** Specify a function that will be called when the notification is clicked */
  onClick: propTypes.func,
  /** Position of Notification, can be one of topLeft topRight bottomLeft bottomRight */
  placement: propTypes.string,
  /** Distance from the top of the viewport, when placement is topRight or topLeft (unit: pixels). */
  top: propTypes.number,
  /** custom close icon */
  closeIcon: propTypes.element,
};
/** @component */
export default Open;
