import client from './client';

export const createStateApi = data => client().post('/states', data);

export const getStatesApi = data =>
  client().get('/states', {
    params: data,
  });

export const getRemoveStatesApi = data => client().delete(`/states/${data.id}`);

export const updateStateTranslationApi = data => client().put(`/states_translations/${data.id}`, data);

export const createStateTranslationsApi = data => client().post('/states_translations', data);
