import { takeLatest, call } from 'redux-saga/effects';

import { getMessagesApi } from 'services/messages';

import { getMessages, LOAD_MESSAGES } from 'actions/messages';

import fetchEntity from './fetch-entity';

/**
 * Get Messages
 */
const fetchGetMessages = fetchEntity.bind(null, getMessages, getMessagesApi);

export function* loadGetMessages({ params }) {
  yield call(fetchGetMessages, { ...params });
}

function* watchLoadGetMessages() {
  yield takeLatest(LOAD_MESSAGES, loadGetMessages);
}

const MessagesSagas = {
  watchLoadGetMessages,
};
export default MessagesSagas;
