import { call, takeLatest, select } from 'redux-saga/effects';
import { getLanguagesAction, LOAD_LANGUAGES, getLanguageAction, LOAD_LANGUAGE } from 'actions/language';

import { getLanguagesApi, getLanguageApi } from 'services/language';
import { getLanguages, getLanguage } from 'selectors/languages';
import fetchEntity from './fetch-entity';

// load languages

const fetchLoadLanguagesAction = fetchEntity.bind(null, getLanguagesAction, getLanguagesApi);

function* loadLanguages() {
  const languages = yield select(getLanguages);
  if (!languages.length) {
    yield call(fetchLoadLanguagesAction);
  }
}

export function* watchLoadLanguages() {
  yield takeLatest(LOAD_LANGUAGES, loadLanguages);
}

// load language

const fetchLoadLanguageAction = fetchEntity.bind(null, getLanguageAction, getLanguageApi);

function* loadLanguage({ type, id }) {
  const language = yield select(getLanguage);
  if (Number(language.id) !== Number(id)) {
    yield call(fetchLoadLanguageAction, id);
  }
}

export function* watchLoadLanguage() {
  yield takeLatest(LOAD_LANGUAGE, loadLanguage);
}
