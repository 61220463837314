import {
  INIT_MESSAGE,
  CONVERSATION_ERROR,
  CONVERSATION_DISCONNECTED,
  RECEIVE_MESSAGE,
  SOCKET_CONNECTED,
  UPDATE_CONVERSATION_LIST,
  LOAD_INIT_MESSAGE,
} from 'actions/conversation-messages';
import { CONVERSATIONS_SUCCESS, SET_ACTIVE_CONVERSATION } from 'actions/conversations';
import { MESSAGES_SUCCESS } from 'actions/messages';

export default (() => {
  const initialState = {
    rooms: {},
    error: null,
    loading: false,
    connected: false,
    activeConversation: null,
  };

  return (state = initialState, action) => {
    switch (action.type) {
      case CONVERSATIONS_SUCCESS:
        return {
          ...state,
          rooms: action.response.data.reduce(
            (acc, res) => ({
              ...acc,
              [res.id]: {
                id: res.id,
                date: res.date,
                messages: {
                  ...res.messages,
                  data: res.messages.data.reverse(),
                },
                participants: res.participants,
              },
            }),
            [],
          ),
        };
      case LOAD_INIT_MESSAGE:
        return {
          ...state,
          loading: true,
        };
      case INIT_MESSAGE:
        return !state.rooms[action.payload.room]
          ? {
              ...state,
              loading: false,
            }
          : {
              ...state,
              loading: false,
              rooms: {
                ...state.rooms,
                [action.payload.room]: {
                  ...state.rooms[action.payload.room],
                  messages: {
                    ...action.payload.result,
                    data: action.payload.result.data.reverse(),
                  },
                },
              },
            };
      case SOCKET_CONNECTED:
        return {
          ...state,
          loading: false,
          connected: true,
        };
      case SET_ACTIVE_CONVERSATION:
        return {
          ...state,
          activeConversation: action.room,
          loading: false,
        };
      case UPDATE_CONVERSATION_LIST:
        return {
          ...state,
          loading: false,
          activeConversation:
            action.payload.switchRoom && action.payload.owner === action.payload.currentUser ? action.payload.id : state.activeConversation,
          rooms: action.payload.messages
            ? {
                ...state.rooms,
                [action.payload.id]: {
                  id: action.payload.id,
                  date: action.payload.updatedAt,
                  messages: {
                    data: action.payload.messages,
                    hasNext: false,
                  },
                  participants: action.payload.participants,
                },
              }
            : { ...state.rooms },
        };
      case RECEIVE_MESSAGE:
        return !state.rooms[action.payload.room]
          ? {
              ...state,
              loading: false,
            }
          : {
              ...state,
              loading: false,
              activeConversation:
                action.payload.switchRoom && action.payload.user && action.payload.owner === (action.payload.currentUser || action.payload.user.id)
                  ? action.payload.room
                  : state.activeConversation,
              rooms: {
                ...state.rooms,
                [action.payload.room]: {
                  ...state.rooms[action.payload.room],
                  date: action.payload.updatedAt,
                  messages: {
                    ...state.rooms[action.payload.room].messages,
                    data: [...state.rooms[action.payload.room].messages.data, action.payload.result],
                  },
                },
              },
            };
      case CONVERSATION_ERROR:
        return {
          ...state,
          loading: false,
          error: {
            code: action.payload.code,
            message: action.payload.message,
          },
        };
      // handle prev messages action
      case MESSAGES_SUCCESS:
        return {
          ...state,
          loading: false,
          rooms: {
            ...state.rooms,
            [action.response.conversationId]: {
              ...state.rooms[action.response.conversationId],
              messages: {
                ...action.response,
                data: [...action.response.data.reverse(), ...state.rooms[action.response.conversationId].messages.data],
              },
            },
          },
        };
      case CONVERSATION_DISCONNECTED:
        return {
          ...state,
          loading: false,
          connected: false,
          error: {
            code: 500,
            message: 'Socket Disconnected',
          },
        };
      default:
        return state;
    }
  };
})();
