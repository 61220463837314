import { createSelector } from 'reselect';

import { LOAD_LANGUAGES, LOAD_LANGUAGE } from 'actions/language';

import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getEntities from './entities';

// loading selectors

export const getLanguagesLoading = createLoadingSelector(LOAD_LANGUAGES)();

export const getLanguageLoading = createLoadingSelector(LOAD_LANGUAGE)();

// error selectors

export const languagesLoadingError = createErrorSelector(LOAD_LANGUAGES)();

export const languageLoadingError = createErrorSelector(LOAD_LANGUAGE)();

// entity selectors
export const getLanguages = createSelector(getEntities, entities => entities.languages);

export const getSelectedLanguage = state => state.language.language.activeLanguage;

export const getCreatedLanguage = createSelector(getEntities, entities => entities.createdLanguage);

export const getLanguage = createSelector(getEntities, entities => entities.language);
