import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Get Provider Organizations
 */
export const PROVIDER_ORGANIZATIONS = createRequestTypes('PROVIDER_ORGANIZATIONS');
export const getProviderOrganizations = {
  request: () => action(PROVIDER_ORGANIZATIONS[REQUEST]),
  success: (params, response) =>
    action(PROVIDER_ORGANIZATIONS[SUCCESS], {
      response: {
        entities: {
          providerOrganizations: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDER_ORGANIZATIONS[FAILURE], { error }),
};
export const LOAD_PROVIDER_ORGANIZATIONS = 'LOAD_PROVIDER_ORGANIZATIONS';
export const requestLoadProviderOrganizations = data => action(LOAD_PROVIDER_ORGANIZATIONS, data);
