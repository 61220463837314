import { createSelector } from 'reselect';
import { LOAD_GET_GLOBAL_VARIABLES, LOAD_GLOBAL_VARIABLE } from 'actions/global-variables';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

// loading selectors

export const getGlobalVariablesLoading = createLoadingSelector(LOAD_GET_GLOBAL_VARIABLES)();

export const getGlobalVariableLoading = createLoadingSelector(LOAD_GLOBAL_VARIABLE)();

// error selectors
export const errorLoadingGlobalVariables = createErrorSelector(LOAD_GET_GLOBAL_VARIABLES)();

export const errorLoadingGlobalVariable = createErrorSelector(LOAD_GLOBAL_VARIABLE)();

export const getGlobalVariables = createSelector(getEntities, entities => entities.globalVariables);
export const getGlobalVariablesData = createSelector(getGlobalVariables, variables => variables.data.vars);
export const getGlobalAtVariablesData = createSelector(getGlobalVariables, variables => variables.data.atVars);
export const getGlobalVariablesDataTotal = createSelector(getGlobalVariables, variables => variables.total);
export const getGlobalVariable = createSelector(getEntities, entities => entities.globalVariable);

export const getCreatedGlobalVariable = createSelector(getEntities, entities => entities.createdGlobalVariable);
