module.exports = {
  /**
   * Extract data necessary for Pendo for a given user
   * Only send Account/Organization data if one and only one organization is associated with the user
   * @param {Object} userData
   * @returns {{visitor: Object, account: Object}} Visitor/Agent Account/Organization data for Pendo
   */
  extractPendoData(userData) {
    const roleKey = Object.prototype.hasOwnProperty.call(userData, 'roles') ? 'roles' : 'user_roles'; // `roles` is used in bmiq-server and `user_roles` is used in bmiq-client
    const userRole = userData[roleKey][0];
    const { patient, organizations } = userData.associations[userRole.group];
    const hasMultipleOrganizations = organizations.length > 1;
    return {
      visitor: {
        id: userData.id,
        name: userData.fullName,
        email: userData.email,
        type: userData.type,
        role: userRole.name,
        createdAt: userData.createdAt,
        verifiedDate: userData.verifiedDate,
        programName: patient?.programName,
        groupName: userRole.group,
        isTestUser: userData.isTestUser,
        multipleOrganization: hasMultipleOrganizations,
        organizationIds: organizations.map(({ id }) => id),
        organizationNames: organizations.map(({ name }) => name),
        organizationCreatedAts: organizations.map(({ createdAt }) => createdAt),
        organizationDeactivationDates: organizations.map(({ active, updatedAt }) => !active && updatedAt),
      },
      account: {
        id: hasMultipleOrganizations ? null : organizations[0]?.id,
        organizationName: hasMultipleOrganizations ? null : organizations[0]?.name,
        createdAt: hasMultipleOrganizations ? null : organizations[0]?.createdAt,
        deactivationDate: hasMultipleOrganizations ? null : !organizations[0]?.active && organizations[0]?.updatedAt,
      },
    };
  },
};
