const day = require('./day');

module.exports = {
  BUILD_LOGIC: 'BUILD_LOGIC',
  SELECT_QUESTION: 'SELECT_QUESTION',
  SELECT_ANSWER: 'SELECT_ANSWER',
  SELECT_OPERATOR: 'SELECT_OPERATOR',
  SELECT_BINDING: 'SELECT_BINDING',
  SELECT_ACTION: 'SELECT_ACTION',
  SELECT_TO: 'SELECT_TO',
  ADD_CONDITION: 'ADD_CONDITION',
  REMOVE_CONDITION: 'REMOVE_CONDITION',
  REMOVE_LOGIC: 'REMOVE_LOGIC',
  COPY_LOGIC: 'COPY_LOGIC',
  getSatisfaction: {
    Is: (answer, conditionAnswer, question) => {
      if (question.questionType === 'scale') {
        const scaleAnswer = question.answers.findIndex(a => a === conditionAnswer);
        return answer === scaleAnswer;
      }
      if (conditionAnswer === 'other') {
        if (Array.isArray(answer) && answer.length === 1) {
          return !question.answers.includes(answer[0]);
        }
        return Array.isArray(answer) ? false : !question.answers.includes(answer);
      }
      if (Array.isArray(answer) && answer.length === 1) {
        return answer[0] === conditionAnswer;
      }
      return answer === conditionAnswer;
    },
    'Is Not': (answer, conditionAnswer, question) => {
      if (question.questionType === 'scale') {
        const scaleAnswer = question.answers.findIndex(a => a === conditionAnswer);
        return answer !== scaleAnswer;
      }
      if (conditionAnswer === 'other') {
        if (Array.isArray(answer)) {
          return answer.every(ans => question.answers.includes(ans));
        }
        return question.answers.includes(answer);
      }
      if (Array.isArray(answer) && answer.length === 1) {
        return answer[0] !== conditionAnswer;
      }
      return answer !== conditionAnswer;
    },
    Contains: (answer, conditionAnswer, question) =>
      conditionAnswer === 'other' && Array.isArray(answer)
        ? answer.some(ans => !question.answers.includes(ans))
        : answer && answer.includes(conditionAnswer),
    'Does Not Contain': (answer, conditionAnswer, question) =>
      conditionAnswer === 'other' && Array.isArray(answer)
        ? answer.every(ans => question.answers.includes(ans))
        : answer && !answer.includes(conditionAnswer),
    'Greater Than': (answer, conditionAnswer, question) => {
      if (question.questionType === 'scale') {
        const scaleAnswer = question.answers.findIndex(a => a === conditionAnswer);
        return answer > scaleAnswer;
      }
      return answer > conditionAnswer;
    },
    'Less Than': (answer, conditionAnswer, question) => {
      if (question.questionType === 'scale') {
        const scaleAnswer = question.answers.findIndex(a => a === conditionAnswer);
        return answer < scaleAnswer;
      }
      return answer < conditionAnswer;
    },
    'Greater Than Or Equal': (answer, conditionAnswer, question) => {
      if (question.questionType === 'scale') {
        const scaleAnswer = question.answers.findIndex(a => a === conditionAnswer);
        return answer >= scaleAnswer;
      }
      return answer >= conditionAnswer;
    },
    'Less Than Or Equal': (answer, conditionAnswer, question) => {
      if (question.questionType === 'scale') {
        const scaleAnswer = question.answers.findIndex(a => a === conditionAnswer);
        return answer <= scaleAnswer;
      }
      return answer <= conditionAnswer;
    },
    Before: (answer, conditionAnswer) => day(answer).isBefore(conditionAnswer),
    After: (answer, conditionAnswer) => day(answer).isAfter(conditionAnswer),
    'Is Answered': answer => answer !== null,
  },
};
