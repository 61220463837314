import { combineReducers } from 'redux';
import { SELECT_ROLE, UNSELECT_ROLE } from 'actions/roles';

const role = () => {
  const initialState = {
    selectedRole: {},
  };

  return (state = initialState, { type, error, record }) => {
    switch (type) {
      case SELECT_ROLE:
        return {
          ...initialState,
          selectedRole: record,
        };
      case UNSELECT_ROLE:
        return {
          ...initialState,
        };
      default:
        return state;
    }
  };
};

export default combineReducers({
  role: role(),
});
