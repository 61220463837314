import React, { Suspense } from 'react';
import { Spin } from 'style-guide';

export default function asyncComponent(importComponent) {
  const Component = React.lazy(importComponent);
  return props => {
    const fallback = (
      <Spin wrapperClassName='full-height' tip='loading'>
        <div style={{ height: '100%', width: '100%' }} />
      </Spin>
    );

    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };
}
