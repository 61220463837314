import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const LOAD_CONVERSATIONS = 'LOAD_CONVERSATIONS';
export const CONVERSATIONS = createRequestTypes('CONVERSATIONS');
export const CONVERSATIONS_SUCCESS = CONVERSATIONS[SUCCESS];
export const getConversations = {
  request: () => action(CONVERSATIONS[REQUEST]),
  success: (params, response) => action(CONVERSATIONS[SUCCESS], { response }),
  failure: (params, error) => action(CONVERSATIONS[FAILURE], { error }),
};

export const requestLoadConversations = data => action(LOAD_CONVERSATIONS, data);

export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION';
export const selectConversation = data => action(SET_ACTIVE_CONVERSATION, { room: data });
