import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load domo embeds
 */
export const DOMO_EMBEDS = createRequestTypes('DOMO_EMBEDS');
export const getDomoEmbeds = {
  request: () => action(DOMO_EMBEDS[REQUEST]),
  success: (params, response) =>
    action(DOMO_EMBEDS[SUCCESS], {
      response: {
        entities: {
          domoEmbeds: response,
        },
      },
    }),
  failure: (params, error) => action(DOMO_EMBEDS[FAILURE], { error }),
};
export const LOAD_DOMO_EMBEDS = 'LOAD_DOMO_EMBEDS';
export const requestDomoEmbeds = data => action(LOAD_DOMO_EMBEDS, data);
