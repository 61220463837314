import styled, { css } from 'styled-components';
import { Table as table } from 'antd';

const Table = styled(table)`
  ${props =>
    props.alignRight === 3 &&
    css`
      .ant-table-cell:nth-child(n + 3) {
        text-align: right;
      }
      .ant-table-cell:last-child {
        text-align: left;
      }
    `}
  ${props =>
    props.alignRight === 2 &&
    css`
      .ant-table-cell:nth-child(n + 2) {
        text-align: right;
      }
    `}
  && {
    .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
      padding-right: 23px;
    }

    .ant-table-filter-trigger-container {
      display: flex;
      align-items: center;
      text-align: center;
      top: 0 !important;
      right: 0 !important;
    }

    ${props =>
      props.titleText === 'bold' &&
      css`
        th.ant-table-cell {
          font-weight: bold;
        }
      `}

    .ant-table-cell {
      vertical-align: top;
      background: white;
      border-bottom: 1px solid #ebebeb;
    }

    td {
      cursor: ${props => (props.onRow ? 'pointer' : 'default')};
    }
  }

  tbody {
    .ant-table-cell:last-child {
      svg {
        color: #8e8e8e;
        font-size: 18px;

        :hover {
          color: #3e3e3e;
        }
      }
    }
  }

  .ant-table-cell .ant-table {
    margin: 0 !important;
  }

  ${props =>
    props.$hasShadow &&
    css`
      .ant-table {
        box-shadow: 0 0 8px rgb(0 0 0 / 15%);
      }
    `}
  ${props =>
    props.borderNone &&
    css`
      td,
      th {
        border: none !important;
      }
    `}
`;

/** @component */
export default Table;
