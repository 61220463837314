import client from './client';

export const createRecommendationApi = data => client().post('/recommendations', data);

export const getRecommendationsApi = ({ params }) => client().get('/recommendations', params);

export const getRecommendationApi = id => client().get(`/recommendations/${id}`);

export const deleteRecommendationApi = id => client().delete(`/recommendations/${id}`);

export const editRecommendationApi = data => client().put(`/recommendations/${data.id}`, data);

export const publishRecommendationApi = ({ id, active }) => client().patch(`/recommendations/${id}`, { active });
