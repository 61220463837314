module.exports = {
  CHANGE_ASSESSMENT: 'CHANGE_ASSESSMENT',
  CHANGE_QUESTION: 'CHANGE_QUESTION',
  CHANGE_ANSWER: 'CHANGE_ANSWER',
  ADD_QUESTION: 'ADD_QUESTION',
  ADD_ANSWER: 'ADD_ANSWER',
  REMOVE_QUESTION: 'REMOVE_QUESTION',
  REMOVE_ANSWER: 'REMOVE_ANSWER',
  COPY_QUESTION: 'COPY_QUESTION',
  QUESTION_CONFIG: 'QUESTION_CONFIG',
  CHANGE_ORDER: 'CHANGE_ORDER',
  LOGIC_JUMP: 'LOGIC_JUMP',
  ADD_VARIABLE: 'ADD_VARIABLE',
  ADD_VARIABLE_UNIT: 'ADD_VARIABLE_UNIT',
};
