import { DEFAULT_PAGINATION_LIMIT } from 'configs/env-vars';

export const calculateOffset = (skip, limit = DEFAULT_PAGINATION_LIMIT) => (skip - 1) * limit;

/**
 *  changes the page to previous when the last item is removed from current page
 */
export const changePage = (count, offset) => {
  const modifiedCount = count - 1;
  if (offset >= 10 && offset === modifiedCount) {
    return offset - 10;
  }
  return offset;
};
