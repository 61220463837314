import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get medications
export const MEDICATIONS = createRequestTypes('MEDICATIONS');
export const getMedications = {
  request: () => action(MEDICATIONS[REQUEST]),
  success: (params, response) =>
    action(MEDICATIONS[SUCCESS], {
      response: {
        entities: {
          medications: response,
        },
      },
    }),
  failure: (params, error) => action(MEDICATIONS[FAILURE], { error }),
};
export const LOAD_MEDICATIONS = 'LOAD_MEDICATIONS';
export const requestLoadMedications = data => action(LOAD_MEDICATIONS, data);

// get single medication
export const MEDICATION = createRequestTypes('MEDICATION');
export const getMedication = {
  request: () => action(MEDICATION[REQUEST]),
  success: (params, response) =>
    action(MEDICATION[SUCCESS], {
      response: {
        entities: {
          medication: response,
        },
      },
    }),
  failure: (params, error) => action(MEDICATION[FAILURE], { error }),
};
export const LOAD_MEDICATION = 'LOAD_MEDICATION';
export const requestLoadMedication = id => action(LOAD_MEDICATION, { id });

// create medication
export const CREATE_MEDICATION = createRequestTypes('CREATE_MEDICATION');
export const createMedication = {
  request: params => action(CREATE_MEDICATION[REQUEST]),
  success: (params, response) =>
    action(CREATE_MEDICATION[SUCCESS], {
      response: {
        entities: {
          createdMedication: response,
        },
      },
    }),
  failure: (params, error) => action(CREATE_MEDICATION[FAILURE], { error }),
};
export const LOAD_CREATE_MEDICATION = 'LOAD_CREATE_MEDICATION';
export const requestCreateMedication = data => action(LOAD_CREATE_MEDICATION, { data });

// disable medication
export const DISABLE_MEDICATION = createRequestTypes('DISABLE_MEDICATION');
export const disableMedication = {
  request: () => action(DISABLE_MEDICATION[REQUEST]),
  success: (params, response) => action(DISABLE_MEDICATION[SUCCESS]),
  failure: (params, error) => action(DISABLE_MEDICATION[FAILURE], { error }),
};

export const LOAD_DISABLE_MEDICATION = 'LOAD_DISABLE_MEDICATION';
export const requestDisableMedication = data => action(LOAD_DISABLE_MEDICATION, data);

// edit medication
export const EDIT_MEDICATION = createRequestTypes('EDIT_MEDICATION');
export const editMedication = {
  request: params => action(EDIT_MEDICATION[REQUEST]),
  success: (params, response) => action(EDIT_MEDICATION[SUCCESS]),
  failure: (params, error) => action(EDIT_MEDICATION[FAILURE], { error }),
};

export const LOAD_EDIT_MEDICATION = 'LOAD_EDIT_MEDICATION';
export const requestEditMedication = data => action(LOAD_EDIT_MEDICATION, data);

// load medications from FDA api
export const MEDICATIONS_FDA = createRequestTypes('MEDICATIONS_FDA');
export const getMedicationsFda = {
  request: () => action(MEDICATIONS_FDA[REQUEST]),
  success: (params, response) =>
    action(MEDICATIONS_FDA[SUCCESS], {
      response: {
        entities: {
          medicationsFda: response,
        },
      },
    }),
  failure: (params, error) => action(MEDICATIONS_FDA[FAILURE], { error }),
};
export const LOAD_MEDICATIONS_FDA = 'LOAD_MEDICATIONS_FDA';
export const requestLoadMedicationsFda = data => action(LOAD_MEDICATIONS_FDA, data);

// unset medications
export const UNSET_MEDICATIONS = 'UNSET_MEDICATIONS';
export const unsetMedications = () => action(UNSET_MEDICATIONS);
