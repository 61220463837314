import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// Single sign on to Dialog EDU
export const DEDU_LOGIN = createRequestTypes('DEDU_LOGIN');
export const deduLogin = {
  request: () => action(DEDU_LOGIN[REQUEST]),
  success: (params, response) =>
    action(DEDU_LOGIN[SUCCESS], {
      response: {
        entities: {
          deduLoginUrl: response,
        },
      },
    }),
  failure: (params, error) => action(DEDU_LOGIN[FAILURE], { error }),
};

export const LOAD_DEDU_LOGIN = 'LOAD_DEDU_LOGIN';
export const requestDeduLogin = data => action(LOAD_DEDU_LOGIN, data);

// List of organization sites in DEDU
export const DEDU_SITES = createRequestTypes('DEDU_SITES');
export const deduSites = {
  request: () => action(DEDU_SITES[REQUEST]),
  success: (params, response) =>
    action(DEDU_SITES[SUCCESS], {
      response: {
        entities: {
          deduSites: response,
        },
      },
    }),
  failure: (params, error) => action(DEDU_SITES[FAILURE], { error }),
};

// List of organization sites in DEDU
export const DEDU_COURSES = createRequestTypes('DEDU_COURSES');
export const deduCourses = {
  request: () => action(DEDU_COURSES[REQUEST]),
  success: (params, response) =>
    action(DEDU_COURSES[SUCCESS], {
      response: {
        entities: {
          deduCourses: response,
        },
      },
    }),
  failure: (params, error) => action(DEDU_COURSES[FAILURE], { error }),
};

export const LOAD_DEDU_SITES = 'LOAD_DEDU_SITES';
export const requestDeduSites = data => action(LOAD_DEDU_SITES, data);
