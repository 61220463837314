/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// issue: https://github.com/facebook/create-react-app/issues/9906

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, Themes } from 'style-guide';
import App from 'components/App';
import store from './configs/configureStore';
import 'antd/dist/antd.less';
import './assets/fonts/stylesheet.css';

ReactDOM.render(
  <ThemeProvider theme={Themes}>
    <App store={store} />
  </ThemeProvider>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}

if (process.env.NODE_ENV !== 'production') {
  // Initialize development tools for non-production environments, when enabled

  // Axe is an accessibility testing engine
  if (process.env.REACT_APP_SHOW_AXE_WARNINGS === 'true') {
    // eslint-disable-next-line global-require
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }

  // Mock server allows testing UI without an API server
  if (process.env.REACT_APP_MOCK_SERVER === 'true') {
    // eslint-disable-next-line global-require
    const { worker } = require('./__mocks__/browser');
    worker.start();
  }
}
