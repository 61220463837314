import { CREATE_NEW_MODAL, REMOVE_GLOBAL_MODAL, UPDATE_GLOBAL_MODAL, REMOVE_GLOBAL_MODALS } from 'actions/global-modal';
import day from 'utils/day';

const createNewModal = (data, state) => {
  if (data.unique && state.modals.some(({ modalType }) => modalType === data.modalType)) {
    state.modals.find(res => res.modalType === data.modalType).minimized = false;
    return {
      ...state,
    };
  }
  return {
    ...state,
    modals: [
      ...state.modals,
      {
        ...data,
        id: `${day().unix()}_${data.modalType}`,
        active: true,
        position: 0,
        minimized: false,
      },
    ],
  };
};
export default (() => {
  const initialState = {
    modals: [],
    loading: false,
    error: null,
  };

  return (state = initialState, { type, data }) => {
    switch (type) {
      case CREATE_NEW_MODAL:
        return createNewModal(data, state);
      case REMOVE_GLOBAL_MODAL:
        return {
          ...state,
          modals: state.modals.filter(res => res.id !== data.id),
        };
      case UPDATE_GLOBAL_MODAL:
        return {
          ...state,
          modals: state.modals.map(res => {
            if (res.id === data.id) {
              return {
                ...res,
                ...data,
              };
            }
            return res;
          }),
        };
      case REMOVE_GLOBAL_MODALS:
        return {
          ...state,
          modals: state.modals.filter(modal => modal.modalType === 'videoVisits'),
        };
      default:
        return state;
    }
  };
})();
