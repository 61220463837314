import { createSelector } from 'reselect';

import { LOAD_PROVIDERS, LOAD_PROVIDER, LOAD_REMOVE_PROVIDER, LOAD_PROVIDER_AVAILABILITY } from 'actions/providers';
import { genders } from 'bmiq-common';
import { convertTime12to24 } from 'utils/helper-methods';
import { convertTimezones } from 'utils/userDay';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getProvidersLoading = createLoadingSelector(LOAD_PROVIDERS)();
export const getProvidersError = createErrorSelector(LOAD_PROVIDERS)();

export const getProviderLoading = createLoadingSelector(LOAD_PROVIDER)();
export const getProviderError = createErrorSelector(LOAD_PROVIDER)();

export const removeProviderLoading = createLoadingSelector(LOAD_REMOVE_PROVIDER)();
export const removeProviderError = createErrorSelector(LOAD_REMOVE_PROVIDER)();

export const getProviderAvailabilityLoading = createLoadingSelector(LOAD_PROVIDER_AVAILABILITY)();
export const providerAvailabilityError = createErrorSelector(LOAD_PROVIDER_AVAILABILITY)();

export const getProvidersList = createSelector(getEntities, ({ providers }) => providers.rows);
export const getProviderAvailability = createSelector(getEntities, ({ providerAvailability }) =>
  providerAvailability.data
    ? providerAvailability.data.map(time => ({
        ...time,
        userDate: convertTimezones(`${time.date} ${convertTime12to24(time.start)}`, 'utc').format('YYYY-MM-DD'),
        userStartTime: convertTimezones(`${time.date} ${convertTime12to24(time.start)}`, 'utc').format('hh:mm A'),
        userEndTime: convertTimezones(`${time.date} ${convertTime12to24(time.end)}`, 'utc').format('hh:mm A'),
      }))
    : [],
);
export const getProvidersCount = createSelector(getEntities, ({ providers }) => providers.count);

export const getSingleProvider = createSelector(getEntities, ({ provider }) => provider);

export const getProviderIdFromStorage = () => localStorage.getItem('pId');

export const getFilteredProviders = ({ providers }) => providers.filteredProviders;

export const getNpiDoctor = createSelector(getEntities, entities => {
  if (entities.npiProvider && entities.npiProvider.result_count > 0) {
    const [individualsDoctor] = entities.npiProvider.results;
    const { first_name: firstName, last_name: lastName, gender: sex } = individualsDoctor.basic;
    const [address] = individualsDoctor.addresses;

    const { telephone_number: contactPhone } = address;

    const genderType = {
      f: 'female',
      m: 'male',
    };
    return {
      firstName,
      lastName,
      gender: sex ? genders[genderType[sex.toLowerCase()]] : undefined,
      contactPhone,
    };
  }

  return {};
});
