import { call, takeLatest, select, put } from 'redux-saga/effects';

import {
  LOAD_ASSESSMENTS,
  getAssessments,
  LOAD_DELETE_ASSESSMENT,
  deleteAssessment as deleteAssessmentAction,
  STORE_ASSESSMENTS,
} from 'actions/assessments';

import { getAssessmentsApi, deleteAssessmentApi } from 'services/assessments';

import { errorDeletingAssessment } from 'selectors/assessments';

import fetchEntity from './fetch-entity';

// get assessments

const fetchLoadAssessments = fetchEntity.bind(null, getAssessments, getAssessmentsApi);

function* loadAssessments({ params }) {
  yield call(fetchLoadAssessments, params);
}

export function* watchLoadAssessments() {
  yield takeLatest(LOAD_ASSESSMENTS, loadAssessments);
}

// delete assessment

const fetchDeleteAssessment = fetchEntity.bind(null, deleteAssessmentAction, deleteAssessmentApi);

function* deleteAssessment({ id }) {
  yield call(fetchDeleteAssessment, id);
  if (!(yield select(errorDeletingAssessment))) {
    yield call(fetchLoadAssessments, { referenceId: 0 });
  }
}

export function* watchDeleteAssessment() {
  yield takeLatest(LOAD_DELETE_ASSESSMENT, deleteAssessment);
}

function* storeAssessmentData({ res }) {
  yield put(getAssessments.success(undefined, res));
}

export function* watchStoreAssessmentData() {
  yield takeLatest(STORE_ASSESSMENTS, storeAssessmentData);
}
