import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load roles
 */
export const ROLES = createRequestTypes('ROLES');
export const roles = {
  request: () => action(ROLES[REQUEST]),
  success: (params, response) =>
    action(ROLES[SUCCESS], {
      response: {
        entities: {
          roles: response,
        },
      },
    }),
  failure: (params, error) => action(ROLES[FAILURE], { error }),
};
export const LOAD_ROLES = 'LOAD_ROLES';
export const requestLoadRoles = () => action(LOAD_ROLES);
/**
 * Load providers roles
 */
export const PROVIDERS_ROLES = createRequestTypes('PROVIDERS_ROLES');
export const providersRoles = {
  request: () => action(PROVIDERS_ROLES[REQUEST]),
  success: (params, response) =>
    action(PROVIDERS_ROLES[SUCCESS], {
      response: {
        entities: {
          providersRoles: response,
        },
      },
    }),
  failure: (params, error) => action(PROVIDERS_ROLES[FAILURE], { error }),
};
export const LOAD_PROVIDERS_ROLES = 'LOAD_PROVIDERS_ROLES';
export const requestLoadProvidersRoles = () => action(LOAD_PROVIDERS_ROLES);

/**
 * Create roles
 */
export const CREATE_ROLES = createRequestTypes('CREATE_ROLES');
export const createRoles = {
  request: () => action(CREATE_ROLES[REQUEST]),
  success: (params, response) =>
    action(CREATE_ROLES[SUCCESS], {
      response: {
        entities: {
          roles: response,
        },
      },
    }),
  failure: (params, error) => action(CREATE_ROLES[FAILURE], { error }),
};
export const LOAD_CREATE_ROLES = 'LOAD_CREATE_ROLES';
export const requestCreateRoles = data => action(LOAD_CREATE_ROLES, { data });

/**
 * Update roles
 */
export const UPDATE_ROLES = createRequestTypes('UPDATE_ROLES');
export const updateRoles = {
  request: () => action(UPDATE_ROLES[REQUEST]),
  success: (params, response) =>
    action(UPDATE_ROLES[SUCCESS], {
      response: {
        entities: {
          roles: response,
        },
      },
    }),
  failure: (params, error) => action(UPDATE_ROLES[FAILURE], { error }),
};
export const LOAD_UPDATE_ROLES = 'LOAD_UPDATE_ROLES';
export const requestUpdateRoles = data => action(LOAD_UPDATE_ROLES, { data });

export const SELECT_ROLE = 'SELECT_ROLE';
export const selectRole = record => action(SELECT_ROLE, { record });

export const UNSELECT_ROLE = 'UNSELECT_ROLE';
export const unselectRole = () => action(UNSELECT_ROLE);

/**
 * Create roles
 */
export const ROLE_PERMISSIONS = createRequestTypes('ROLE_PERMISSIONS');
export const rolePermissions = {
  request: () => action(ROLE_PERMISSIONS[REQUEST]),
  success: (params, response) =>
    action(ROLE_PERMISSIONS[SUCCESS], {
      response: {
        entities: {
          rolePermissions: {
            [params.id]: response,
          },
        },
      },
    }),
  failure: (params, error) => action(ROLE_PERMISSIONS[FAILURE], { error }),
};
export const LOAD_ROLES_PERMISSIONS = 'LOAD_ROLES_PERMISSIONS';
export const requestRolePermissions = data => action(LOAD_ROLES_PERMISSIONS, { data });

/**
 * Create role permissions
 */
export const CREATE_ROLE_PERMISSIONS = createRequestTypes('CREATE_ROLE_PERMISSIONS');
export const createRolePermissions = {
  request: () => action(CREATE_ROLE_PERMISSIONS[REQUEST]),
  success: (params, response) =>
    action(CREATE_ROLE_PERMISSIONS[SUCCESS], {
      response: {
        entities: {
          rolePermissions: {
            [params.id]: response,
          },
        },
      },
    }),
  failure: (params, error) => action(CREATE_ROLE_PERMISSIONS[FAILURE], { error }),
};
export const LOAD_CREATE_ROLE_PERMISSIONS = 'LOAD_CREATE_ROLE_PERMISSIONS';
export const requestCreateRolePermissions = data => action(LOAD_CREATE_ROLE_PERMISSIONS, { data });

export const CREATE_USER_ROLE = createRequestTypes('CREATE_USER_ROLE');
export const createUserRole = {
  request: () => action(CREATE_USER_ROLE[REQUEST]),
  success: (params, response) => action(CREATE_USER_ROLE[SUCCESS]),
  failure: error => action(CREATE_USER_ROLE[FAILURE]),
};
export const LOAD_CREATE_USER_ROLE = 'LOAD_CREATE_USER_ROLE';
export const requestCreateUserRole = data => action(LOAD_CREATE_USER_ROLE, { data });

export const REMOVE_ROLE = createRequestTypes('REMOVE_ROLE');
export const removeRole = {
  request: () => action(REMOVE_ROLE[REQUEST]),
  success: (params, response) => action(REMOVE_ROLE[SUCCESS]),
  failure: error => action(REMOVE_ROLE[FAILURE]),
};
export const LOAD_REMOVE_ROLE = 'LOAD_REMOVE_ROLE';
export const requestRemoveRole = id => action(LOAD_REMOVE_ROLE, { id });

export const REMOVE_ROLE_PERMISSION = createRequestTypes('REMOVE_ROLE_PERMISSION');
export const removeRolePermission = {
  request: () => action(REMOVE_ROLE_PERMISSION[REQUEST]),
  success: (params, response) =>
    action(REMOVE_ROLE_PERMISSION[SUCCESS], {
      response: {
        entities: {
          roles: response,
        },
      },
    }),
  failure: error => action(REMOVE_ROLE_PERMISSION[FAILURE]),
};
export const LOAD_REMOVE_ROLE_PERMISSION = 'LOAD_REMOVE_ROLE_PERMISSION';
export const requestRemoveRolePermission = data => action(LOAD_REMOVE_ROLE_PERMISSION, data);
export const REMOVE_USER_ROLE = createRequestTypes('REMOVE_USER_ROLE');
export const removeUserRole = {
  request: () => action(REMOVE_USER_ROLE[REQUEST]),
  success: (params, response) =>
    action(REMOVE_USER_ROLE[SUCCESS], {
      response: {
        entities: {
          users: response.data,
        },
      },
    }),
  failure: error => action(REMOVE_USER_ROLE[FAILURE]),
};

export const LOAD_REMOVE_USER_ROLE = 'LOAD_REMOVE_USER_ROLE';
export const requestRemoveUserRole = id => action(LOAD_REMOVE_USER_ROLE, { id });
