const complexFilters = {
  equal_to: {
    id: 'equal_to',
    title: 'Equal to',
  },
  greater_than: {
    id: 'greater_than',
    title: 'Greater than',
  },
  less_than: {
    id: 'less_than',
    title: 'Less than',
  },
  contains: {
    id: 'contains',
    title: 'Contains',
  },
  does_not_contain: {
    id: 'does_not_contain',
    title: 'Does not contain',
  },
  range: {
    id: 'range',
    title: 'Range',
  },
};

module.exports = complexFilters;
