import { message } from 'style-guide';
import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';
// Get ALl system Messages
export const SYSTEM_MESSAGES = createRequestTypes('SYSTEM_MESSAGES');
export const systemMessages = {
  request: () => action(SYSTEM_MESSAGES[REQUEST]),
  success: (params, response) =>
    action(SYSTEM_MESSAGES[SUCCESS], {
      response: {
        entities: {
          systemMessages: response,
        },
      },
    }),
  failure: () => action(SYSTEM_MESSAGES[FAILURE]),
};
export const LOAD_SYSTEM_MESSAGES = 'LOAD_SYSTEM_MESSAGES';
export const requestSystemMessages = () => action(LOAD_SYSTEM_MESSAGES);

// Get ALl system Messages
export const SYSTEM_MESSAGE = createRequestTypes('GET_SYSTEM_MESSAGES');
export const systemMessage = {
  request: () => action(SYSTEM_MESSAGE[REQUEST]),
  success: (params, response) =>
    action(SYSTEM_MESSAGE[SUCCESS], {
      response: {
        entities: {
          systemMessage: {
            ...response,
            saved: false,
          },
        },
      },
    }),
  failure: () => action(SYSTEM_MESSAGE[FAILURE]),
};
export const LOAD_SYSTEM_MESSAGE = 'LOAD_SYSTEM_MESSAGE';
export const requestMessage = id => action(LOAD_SYSTEM_MESSAGE, { id });

//  Save system message
export const SAVE_SYSTEM_MESSAGE = createRequestTypes('SAVE_SYSTEM_MESSAGE');
export const saveSystemMessage = {
  request: () => action(SAVE_SYSTEM_MESSAGE[REQUEST]),
  success: (params, response) =>
    action(SAVE_SYSTEM_MESSAGE[SUCCESS], {
      response: {
        entities: {
          systemMessage: {
            ...response,
            saved: true,
          },
        },
      },
    }),
  failure: () => action(SAVE_SYSTEM_MESSAGE[FAILURE]),
};
export const LOAD_SAVE_SYSTEM_MESSAGE = 'LOAD_SAVE_SYSTEM_MESSAGE';
export const requestSaveMessage = data => action(LOAD_SAVE_SYSTEM_MESSAGE, { data });

export const showSuccessMessage = () => message.success('Successfully saved');
