import { call, takeLatest } from 'redux-saga/effects';
import { getLoadCountryAction, LOAD_COUNTRIES } from 'actions/countries';
import { getLoadCountriesApi } from 'services/countries';
import fetchEntity from './fetch-entity';

const fetchLoadCountriesAction = fetchEntity.bind(null, getLoadCountryAction, getLoadCountriesApi);

function* loadCountries({ data }) {
  yield call(fetchLoadCountriesAction, data);
}

export function* watchLoadCountries() {
  yield takeLatest(LOAD_COUNTRIES, loadCountries);
}
