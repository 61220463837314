const APPETITE = {
  question: 'Do you ever feel that your appetite is excessive or do you ever feel hungry again within one to two hours of eating?',
  assessment: 'Food Cravings and Appetite Questionnaire',
};

const DAYTIME_SLEEPINESS = {
  question: 'Do you often feel tired, fatigued or sleepy during the day?',
  assessment: 'Sleep Questionnaire',
};

const AFTER_DINNER_CALORIES = {
  question: 'What percentage of your daily calories do you consume after dinnertime (not counting your dinner meal)?',
  assessment: 'Food Cravings and Appetite Questionnaire',
};

const DRINK_EAT_PATTERN = {
  question: 'Do you drink or eat any of the following?',
  assessment: 'Current Eating Patterns and Preferences Questionnaire',
};

const GRAZING = {
  question: 'Do you graze (eat food throughout the day instead of just during meals and snacks)?',
  assessment: 'Food Cravings and Appetite Questionnaire',
};

const CRAVINGS = {
  question: 'Do you have cravings for certain foods?',
  assessment: 'Food Cravings and Appetite Questionnaire',
};

const SNORING = {
  question: 'Do you snore at night? (Ask someone who has slept in the same room as you if you’re unsure.)',
  assessment: 'Sleep Questionnaire',
};

const INSOMNIA = {
  question: 'Do you have insomnia, or poor sleep or feel that you don’t get enough sleep?',
  assessment: 'Sleep Questionnaire',
};

const OSA = {
  question: 'Have you been diagnosed with sleep apnea?',
  assessment: 'Sleep Questionnaire',
};
const TREATING_APNEA = {
  question: 'Do you feel that you are treating your sleep apnea adequately (positional therapy, mask, device, other)?',
  assessment: 'Sleep Questionnaire',
};
const APNEA = {
  question: 'Have you ever been told that you have pauses in breathing during sleep (apnea)?',
  assessment: 'Sleep Questionnaire',
};

const BINGE = {
  question: 'Do you ever feel out of control when you are eating or eat large amounts of food in a short time (e.g., 2 hours or less) (binge)?',
  assessment: 'Food Cravings and Appetite Questionnaire',
};

const SMOKING_LESS = {
  question: 'Are you interested in smoking less or quitting?',
  assessment: 'Lifestyle Factors Questionnaire',
};

const DRINKING_LESS = {
  question: 'Are you interested in drinking less?',
  assessment: 'Lifestyle Factors Questionnaire',
};

const ALLERGIC_MEDICATIONS = {
  question: 'Please indicate the medication(s) you are allergic to.',
  assessment: 'Medical History Questionnaire',
};

const HIGHEST_WEIGHT = {
  question: 'What year was your highest weight?',
  assessment: 'Weight History Questionnaire',
};

const LOWEST_WEIGHT = {
  question: 'What year was your lowest weight held?',
  assessment: 'Weight History Questionnaire',
};

module.exports = {
  APPETITE,
  DAYTIME_SLEEPINESS,
  AFTER_DINNER_CALORIES,
  DRINK_EAT_PATTERN,
  GRAZING,
  CRAVINGS,
  SNORING,
  INSOMNIA,
  APNEA,
  TREATING_APNEA,
  OSA,
  BINGE,
  SMOKING_LESS,
  DRINKING_LESS,
  ALLERGIC_MEDICATIONS,
  HIGHEST_WEIGHT,
  LOWEST_WEIGHT,
};
