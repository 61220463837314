import { call, takeLatest, put, select } from 'redux-saga/effects';
import { has } from 'lodash';

import {
  getCreateZipCodesAction,
  LOAD_CREATE_ZIP_CODES,
  LOAD_ZIP_CODES,
  getLoadZipCodes,
  LOAD_REMOVE_ZIP_CODE,
  removeZipCodesAction,
  requestLoadZipCodes,
  LOAD_EDIT_ZIP_CODE,
  editZipCode,
  setZipCodesOffset,
} from 'actions/zipCodes';
import { createZipCodesApi, getZipCodesApi, removeZipCodeApi, editZipCodeApi } from 'services/zipCodes';
import { getCreateZipCodeError, getZipCodes, getRemoveZipCodeError, getOffset } from 'selectors/zipCodes';
import { getSelectedState } from 'selectors/states';
import fetchEntity from './fetch-entity';

const fetchCreateZipCodesAction = fetchEntity.bind(null, getCreateZipCodesAction, createZipCodesApi);

function* loadCreateZipCodes({ data }) {
  yield call(fetchCreateZipCodesAction, data);
  if (!(yield select(getCreateZipCodeError))) {
    const { count } = yield select(getZipCodes);
    const modifiedCount = count + 1;
    const offset = yield select(getOffset);
    if (modifiedCount === offset + 11) {
      yield put(setZipCodesOffset(offset + 10));
    }
    yield put(requestLoadZipCodes({ offset: yield select(getOffset) }));
  }
}

export function* watchLoadCrateZipCodes() {
  yield takeLatest(LOAD_CREATE_ZIP_CODES, loadCreateZipCodes);
}

const fetchGetZipCodesAction = fetchEntity.bind(null, getLoadZipCodes, getZipCodesApi);

function* loadZipCodes({ data }) {
  if (data && !has(data, 'offset') && !data.stateId && !(yield select(getSelectedState))) {
    yield put(getLoadZipCodes.success(undefined, { zips: [] }));
  } else {
    yield call(fetchGetZipCodesAction, data);
  }
}

export function* watchLoadZipCodes() {
  yield takeLatest(LOAD_ZIP_CODES, loadZipCodes);
}

const fetchRemoveZipCodesAction = fetchEntity.bind(null, removeZipCodesAction, removeZipCodeApi);

function* removeZipCodes({ id }) {
  yield call(fetchRemoveZipCodesAction, id);
  if (!(yield select(getRemoveZipCodeError))) {
    const { count } = yield select(getZipCodes);
    const modifiedCount = count - 1;
    const offset = yield select(getOffset);
    if (offset >= 10 && offset === modifiedCount) {
      yield put(setZipCodesOffset(offset - 10));
    }
    yield put(requestLoadZipCodes({ offset: yield select(getOffset) }));
  }
}

export function* watchRemoveZipCodes() {
  yield takeLatest(LOAD_REMOVE_ZIP_CODE, removeZipCodes);
}

const fetchEditZipCodesAction = fetchEntity.bind(null, editZipCode, editZipCodeApi);

function* editZipCodes(data) {
  yield call(fetchEditZipCodesAction, data);
  const offset = yield select(getOffset);
  yield put(requestLoadZipCodes({ offset }));
}

export function* watchEditZipCodes() {
  yield takeLatest(LOAD_EDIT_ZIP_CODE, editZipCodes);
}
