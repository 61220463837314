import { useSelector } from 'react-redux';

import {
  getUserData,
  getCurrentProvider,
  getCurrentPatient,
  getCurrentOrganization,
  getUserTimeZone,
  getPatientOrganization,
  getUserType,
  getRolePermissions,
  getPatientsProvider,
  getProviderOrganizations,
} from 'selectors/users';

export const useLoggedInUser = () => {
  const user = useSelector(getUserData);
  return user;
};

export const useLoggedInUserType = () => {
  const userType = useSelector(getUserType);
  return userType;
};

export const useLoggedInUserRole = () => {
  const userRole = useSelector(getRolePermissions);
  return userRole;
};

export const useCurrentProvider = () => {
  const provider = useSelector(getCurrentProvider);
  return provider;
};

export const useCurrentPatient = () => {
  const patient = useSelector(getCurrentPatient);
  return patient;
};

export const useCurrentUserTimeZone = () => {
  const timezone = useSelector(getUserTimeZone);
  return timezone;
};

export const useCurrentPatientOrg = () => {
  const organization = useSelector(getPatientOrganization);
  return organization || {};
};

export const useCurrentOrganization = () => {
  const organization = useSelector(getCurrentOrganization);
  return organization || {};
};

export const usePatientOrganization = () => {
  const organization = useSelector(getPatientOrganization) || {};
  return organization;
};

export const usePatientProvider = () => {
  const provider = useSelector(getPatientsProvider) || {};
  return provider;
};

export const useProviderOrganizations = () => {
  const organization = useSelector(getProviderOrganizations) || [];
  return organization;
};
