import { SELECT_ZIP_CODE, UNSELECT_ZIP_CODE, GET_ZIP_CODE_OFFSET } from 'actions/zipCodes';

export default (() => {
  const initialState = {
    zipCodeId: undefined,
    offset: 0,
  };

  return (state = initialState, { type, zipCodeId, offset }) => {
    switch (type) {
      case SELECT_ZIP_CODE:
        return {
          ...state,
          zipCodeId,
        };
      case UNSELECT_ZIP_CODE:
        return initialState;
      case GET_ZIP_CODE_OFFSET:
        return {
          ...state,
          offset,
        };
      default:
        return state;
    }
  };
})();
