import { LOAD_EDIT_ACTION, LOAD_ACTION_ITEMS } from 'actions/action-items';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getEntities from './entities';

// loading selectors
export const editActionLoading = createLoadingSelector(LOAD_EDIT_ACTION)();
export const getActionItemsLoading = createLoadingSelector(LOAD_ACTION_ITEMS)();

// error selectors
export const errorEditingAction = createErrorSelector(LOAD_EDIT_ACTION)();
export const getActionItemsError = createErrorSelector(LOAD_ACTION_ITEMS)();

export const getActionItems = createSelector(getEntities, ({ actionItems }) => actionItems);

export const getActionItem = createSelector(
  getEntities,
  ({ actionItem }) => actionItem || {}, // because of deepmerge issue we need to add default value here
);
