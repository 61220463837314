import { SET_FILTERED_PROVIDERS } from 'actions/providers';

export default (() => {
  const initialState = {
    filteredProviders: [],
    offset: 0,
  };

  return (state = initialState, { type, filteredProviders, offset }) => {
    switch (type) {
      case SET_FILTERED_PROVIDERS:
        return { filteredProviders };
      default:
        return state;
    }
  };
})();
