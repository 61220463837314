import { SET_FILTER } from 'actions/filters';

export default (() => {
  const initialState = {};

  return (state = initialState, { type, filters }) => {
    switch (type) {
      case SET_FILTER:
        return {
          ...state,
          filters,
        };
      default:
        return state;
    }
  };
})();
