import { LOAD_ICON_LIST } from 'actions/icons';

import { createSelector } from 'reselect';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';
import getEntities from './entities';

// loading selectors
export const getIconLoading = createLoadingSelector(LOAD_ICON_LIST)();

// error selectors
export const getIconError = createErrorSelector(LOAD_ICON_LIST)();

export const getIcons = createSelector(getEntities, ({ icons }) => icons);
