import React from 'react';

import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Menu from 'style-guide/Menu';
import Text from 'style-guide/Text';
import { getSwitchUsers, createSwitchUsersApi } from 'services/switch-user';

import useRequest from 'hooks/useRequest';
import useGetRequest from 'hooks/useGetRequest';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Nav = () => {
  const { ev286FrontEndToggleDropDownUsingMockData } = useFlags();

  const switchUserData = useGetRequest({
    request: getSwitchUsers,
    featureFlags: [ev286FrontEndToggleDropDownUsingMockData],
    initialData: [],
  });

  const { makeRequest: onMenuClick } = useRequest({
    request: createSwitchUsersApi,
    onSuccess: () => {
      window.location.reload();
    },
  });

  return (
    <Menu>
      <Text level={1} secondary marginLeft={5} style={{ lineHeight: '35px' }}>
        CHANGE LOG IN TO
      </Text>
      {Array.isArray(switchUserData.data) &&
        switchUserData.data?.map(item => (
          <Menu.Item key={item.userId} value={item.email} onClick={() => onMenuClick({ userId: item.userId })}>
            {item.email}
          </Menu.Item>
        ))}
    </Menu>
  );
};

const SwitchUserDropdown = () => (
  <div>
    <Dropdown overlay={<Nav />}>
      <div
        style={{
          cursor: 'pointer',
          margin: '20px 10px 0 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text level={1} secondary style={{ lineHeight: '5px' }}>
          LOGGED IN AS
        </Text>
        <Text level={3} style={{ lineHeight: '35px' }}>
          Admin for Flyte <DownOutlined />
        </Text>
      </div>
    </Dropdown>
  </div>
);

export default SwitchUserDropdown;
