import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw, AtomicBlockUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './index.less';

import ColorPicker from './ColorPicker';
import Button from '../Button';

class RichEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
  };

  shouldUpdate = true;

  recommendationIcons = ['icon-Verified', 'icon-Success', 'icon-Error', 'icon-Warning'];

  customChunkRenderer = (_nodeName, node) => {
    if (node?.className?.includes('editor-icon')) {
      return {
        type: 'ICON',
        mutability: 'IMMUTABLE',
        data: {
          className: node.children?.[0]?.className,
        },
      };
    }
    return null;
  };

  initializeEditorState = () => {
    const initialContent = this.props.initialValue.substring(0, 3) === '<p>' ? this.props.initialValue : `<p>${this.props.initialValue}</p>`;
    const contentBlock = htmlToDraft(initialContent, this.customChunkRenderer);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState,
    });
  };

  componentDidMount() {
    this.initializeEditorState();
  }

  componentDidUpdate() {
    if (this.props.initialValue && this.shouldUpdate) {
      this.initializeEditorState();
      this.shouldUpdate = false;
    }
  }

  onEditorStateChange = editorState => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContent, {}, false, ({ type, data }) => {
      if (type === 'IMAGE') {
        const alignment = data.alignment || 'none';
        const textAlign = alignment === 'none' ? 'center' : alignment;
        return `
          <div style="text-align:${textAlign}" class="editor-image-wrapper">
            <img src="${data.src}" alt="${data.alt}" style="height: ${data.height}; width: ${data.width}"/>
          </div>
        `;
      }
      if (type === 'ICON') {
        return `<span class="editor-icon">
          <span class="${data.className}">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
        </span>`;
      }
      return '';
    });
    this.setState({
      editorState,
    });
    if (html !== this.props.initialValue && editorState.getCurrentContent()) {
      const content = html.substring(0, 3) === '<p>' ? html : `<p>${html}</p>`;
      this.props.onChange(content);
    }
  };

  blockRenderer = contentBlock => {
    const { editorState } = this.state;

    const type = contentBlock.getType();
    if (type === 'atomic') {
      const contentState = editorState.getCurrentContent();
      const entityKey = contentBlock.getEntityAt(0);
      if (entityKey) {
        const entity = contentState.getEntity(entityKey);
        if (entity && entity.type === 'ICON') {
          return {
            component: () => (
              <span className='editor-icon'>
                <span className={entity.data.className}>
                  <span className='path1' />
                  <span className='path2' />
                  <span className='path3' />
                </span>
              </span>
            ),
            editable: false,
          };
        }
      }
    }
    return null;
  };

  render() {
    const { editorState } = this.state;
    return (
      <div
        className={this.props.className}
        onClick={() => {
          if (!editorState.getSelection().getHasFocus()) {
            this.setState({ editorState: EditorState.moveFocusToEnd(editorState) });
          }
        }}
        onKeyPress={e => {
          if (e.key === 'Enter' && !editorState.getSelection().getHasFocus()) {
            this.setState({ editorState: EditorState.moveFocusToEnd(editorState) });
          }
        }}
        role='button'
        tabIndex='0'
      >
        <Editor
          toolbarHidden={this.props.toolbarHidden}
          readOnly={this.props.readOnly}
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          wrapperClassName={this.props.wrapperClassName}
          editorClassName={this.props.editorClassName}
          toolbarClassName={this.props.toolbarClassName}
          placeholder={this.props.placeholder}
          customBlockRenderFunc={this.blockRenderer}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: this.props.mentions,
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'image',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'emoji',
              'remove',
              'history',
            ],
            image: {
              uploadEnabled: true,
              uploadCallback,
              previewImage: true,
              urlEnabled: false,
            },
            colorPicker: {
              component: ColorPicker,
            },
            fontFamily: {
              options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Centra2'],
            },
            ...this.props.toolbar,
          }}
          toolbarCustomButtons={[
            ...(this.props.showRecommendationIcons ? this.recommendationIcons.map(icon => <RecommendationIcon className={icon} key={icon} />) : []),
            ...this.props.toolbarCustomButtons,
          ]}
          editorRef={this.props.editorRef}
        />
      </div>
    );
  }
}

RichEditor.defaultProps = {
  initialValue: '',
  placeholder: '',
  onChange: () => {},
  mentions: [],
  wrapperClassName: 'editor-wrapper',
  editorClassName: 'evolve-rich-editor',
  toolbarCustomButtons: [],
  toolbar: {},
  showRecommendationIcons: true,
};

async function uploadCallback(file) {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  return { data: { link: base64 } };
}

function RecommendationIcon({ className, ...props }) {
  const addIcon = () => {
    const { editorState, onChange } = props;
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('ICON', 'MUTABLE', {
        className,
      })
      .getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
    onChange(newEditorState);
  };
  return (
    <Button type='link' className='rdw-option-wrapper' onClick={addIcon}>
      <span className={className}>
        <span className='path1' />
        <span className='path2' />
        <span className='path3' />
      </span>
    </Button>
  );
}

export default RichEditor;
