const searchedContent = {
  articles: 'articles',
  courses: 'courses',
  messaging: 'messaging',
  patientData: 'patientData',
  recipes: 'recipes',
  all: 'all',
};

module.exports = {
  searchedContent,
};
