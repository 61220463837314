import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Scrollbars as ScrollComponent } from 'react-custom-scrollbars';

const Scrollbars = styled(ScrollComponent)`
  width: 100% !important;
  height: ${props => props.height || 457}px !important;

  .thumb {
    z-index: 2;
    background: #7b7b7b;
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    height: 78px;
  }

  .track {
    overflow-x: hidden;
    position: absolute;
    width: 8px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 10px;
    background: #bcbcbc;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f8f8f8;
    padding: 2px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b3b2b2;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc;
  }
`;

const Scroll = forwardRef(({ setRef, ...props }, ref) => (
  <Scrollbars {...props} ref={setRef || ref} style={{ width: '100%', ...props.styles }}>
    {props.content}
  </Scrollbars>
));

/** @component */
export default Scroll;
