import { call, takeLatest, select, put } from 'redux-saga/effects';

import {
  LOAD_CREATE_PROVIDER_DAILY_HOURS,
  LOAD_PROVIDER_DAILY_HOURS,
  createProviderDailyHours,
  getProviderDailyHours,
} from 'actions/provider-daily-hours';

import { createProviderDailyHoursApi, getProviderDailyHoursApi } from 'services/provider-daily-hours';

import { createProviderDailyHoursError, providerDailyHours } from 'selectors/provider-daily-hours';

import fetchEntity from './fetch-entity';

// create provider daily hours

const fetchCreateProviderDailyHours = fetchEntity.bind(null, createProviderDailyHours, createProviderDailyHoursApi);

function* loadCreateProviderDailyHours({ data }) {
  yield call(fetchCreateProviderDailyHours, data);
  if (!(yield select(createProviderDailyHoursError))) {
    const [{ providerId, clear, day }] = data;
    yield call(fetchLoadProviderDailyHours, providerId);
    if (clear) {
      const newData = yield select(providerDailyHours);
      if (newData[day]) {
        delete newData[day];
        yield put(getProviderDailyHours.success(undefined, { ...newData }));
      }
    }
  }
}

export function* watchCreateProviderDailyHours() {
  yield takeLatest(LOAD_CREATE_PROVIDER_DAILY_HOURS, loadCreateProviderDailyHours);
}

// get provider daily hours

const fetchLoadProviderDailyHours = fetchEntity.bind(null, getProviderDailyHours, getProviderDailyHoursApi);

function* loadProviderDailyHours({ providerId }) {
  yield put(getProviderDailyHours.success(undefined, null));
  yield call(fetchLoadProviderDailyHours, providerId);
}

export function* watchLoadProviderDailyHours() {
  yield takeLatest(LOAD_PROVIDER_DAILY_HOURS, loadProviderDailyHours);
}
