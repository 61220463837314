import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import message from 'style-guide/Message';

const defaultFunctionValue = () => {};

/*
  !important when passing onSuccess, onError and params to this hook
  remember to wrap them in useCallback/useMemo accordingly
*/

const useGetRequest = ({
  request = defaultFunctionValue,
  params,
  onSuccess = defaultFunctionValue,
  onError = defaultFunctionValue,
  initialData,
  showErrorMessage = true,
  condition = true,
  featureFlags = [],
  cleanUp = defaultFunctionValue,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(initialData);
  const [shouldReFetch, setShouldReFetch] = useState(false);
  const reFetch = useCallback(() => setShouldReFetch(v => !v), []);
  const isMounted = useRef(true);

  const isFeatureEnabled = useMemo(() => (featureFlags.length ? featureFlags.every(flag => flag === true) : true), [featureFlags]);
  useEffect(() => {
    async function getRequest() {
      try {
        setLoading(true);
        const { data: response } = await request(params);
        if (isMounted.current) {
          setData(response);
          await onSuccess(response);
        }
      } catch (e) {
        if (isMounted.current) {
          setError(e);
          if (showErrorMessage) {
            message.error(e?.response?.data?.message);
          }
          await onError(e);
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    }

    if (condition && isFeatureEnabled) {
      getRequest().catch(console.error);
    }
    return () => {
      cleanUp();
      // !important Todo we should consider adding axios request cancellation!
    };
  }, [shouldReFetch, condition, onError, onSuccess, params, request, showErrorMessage, cleanUp, isFeatureEnabled]);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  return {
    data,
    loading,
    error,
    reFetch,
  };
};

export default useGetRequest;
