import React from 'react';
import styled from 'styled-components';
import { Upload as upload } from 'antd';
import { UploadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import Button from '../Button';
import Paragraph from '../Paragraph';

const Dragger = styled(upload.Dragger)`
  padding: 48px;
  text-align: center;

  .cloud-upload-icon {
    font-size: 42px;
    line-height: 1;
  }

  button {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const StyledUpload = ({ types }) => (
  <Dragger>
    <div className='cloud-upload-icon'>
      <CloudUploadOutlined />
    </div>
    <Button type='accent'>
      {' '}
      Upload <UploadOutlined />
    </Button>
    <Paragraph level={2}>
      Accepted file types include:
      {types.map((type, index) => (
        <span key={index}>
          {type}
          {index < types.length - 1 ? ',' : null}{' '}
        </span>
      ))}
    </Paragraph>
  </Dragger>
);

/** @component */
export default StyledUpload;
