import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * User login
 */
export const USER_LOGIN = createRequestTypes('USER_LOGIN');
export const userLogin = {
  request: () => action(USER_LOGIN[REQUEST]),
  success: (params, response) =>
    action(USER_LOGIN[SUCCESS], {
      loggedInUser: response,
    }),
  failure: (params, error) => action(USER_LOGIN[FAILURE], { error }),
};
export const LOAD_USER_LOGIN = 'LOAD_USER_LOGIN';
export const requestUserLogin = data => action(LOAD_USER_LOGIN, data);

/**
 * Users list
 */
export const USERS_LIST = createRequestTypes('USERS_LIST');
export const usersList = {
  request: () => action(USERS_LIST[REQUEST]),
  success: (params, response) =>
    action(USERS_LIST[SUCCESS], {
      response: {
        entities: {
          users: response,
        },
      },
    }),
  failure: error => action(USERS_LIST[FAILURE]),
};
export const LOAD_USERS_LIST = 'LOAD_USERS_LIST';
export const requestUserList = data => action(LOAD_USERS_LIST, data);

export const SELECT_USER_ROW = 'SELECT_USER_ROW';
export const selectUserRow = record => action(SELECT_USER_ROW, { record });

/**
 * User logout
 */
export const USER_LOGOUT = createRequestTypes('USER_LOGOUT');
export const userLogout = {
  request: () => action(USER_LOGOUT[REQUEST]),
  success: (params, response) => action(USER_LOGOUT[SUCCESS]),
  failure: error => action(USER_LOGOUT[FAILURE]),
};
export const LOAD_USER_LOGOUT = 'LOAD_USER_LOGOUT';
export const requestUserLogout = () => action(LOAD_USER_LOGOUT);

/**
 * User Authmanagement
 */
export const USER_AUTH_MANEGMENT = createRequestTypes('USER_AUTH_MANEGMENT');
export const userAuthmanagement = {
  request: () => action(USER_AUTH_MANEGMENT[REQUEST]),
  success: (params, response) =>
    action(USER_AUTH_MANEGMENT[SUCCESS], {
      ...params,
      loggedInUser: response,
    }),
  failure: (params, error) => action(USER_AUTH_MANEGMENT[FAILURE], { error }),
};
export const LOAD_USER_AUTH_MANEGMENT = 'LOAD_USER_AUTH_MANEGMENT';
export const requestUserAuthmanagement = data => action(LOAD_USER_AUTH_MANEGMENT, data);

/**
 * Check authentication
 */
export const CHECK_AUTHENTICATION = createRequestTypes('CHECK_AUTHENTICATION');
export const checkAuthentication = {
  request: () => action(CHECK_AUTHENTICATION[REQUEST]),
  success: (params, response) => action(CHECK_AUTHENTICATION[SUCCESS]),
  failure: (params, error) => action(CHECK_AUTHENTICATION[FAILURE], { error }),
};
export const LOAD_CHECK_AUTHENTICATION = 'LOAD_CHECK_AUTHENTICATION';
export const requestCheckAuthentication = data => action(LOAD_CHECK_AUTHENTICATION, { data });
/**
 * Init user data
 */
export const INIT_AUTHENTICATION = createRequestTypes('INIT_AUTHENTICATION');
export const initAuthentication = {
  request: () => action(INIT_AUTHENTICATION[REQUEST]),
  success: (params, response) =>
    action(INIT_AUTHENTICATION[SUCCESS], {
      loggedInUser: response,
    }),
  failure: (params, error) => action(INIT_AUTHENTICATION[FAILURE], { error }),
};
export const LOAD_INIT_AUTHENTICATION = 'LOAD_INIT_AUTHENTICATION';
export const requestInitAuthentication = data => action(LOAD_INIT_AUTHENTICATION, data);

/**
 * Update user
 */
export const UPDATE_USER = createRequestTypes('UPDATE_USER');
export const updateUser = {
  request: () => action(UPDATE_USER[REQUEST]),
  success: (params, response) =>
    action(UPDATE_USER[SUCCESS], {
      migrateMessageShowed: response.migrateMessageShowed,
      passExpNotification: response.passExpNotification,
    }),
  failure: (params, error) => action(UPDATE_USER[FAILURE], { error }),
};
export const LOAD_UPDATE_USER = 'LOAD_UPDATE_USER';
export const requestUpdateUser = data => action(LOAD_UPDATE_USER, data);
