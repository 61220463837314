import { useMemo } from 'react';
import { useRedux } from 'hooks/reduxHooks';
import { getSystemMessages } from 'selectors/system-messages';

export const useSystemMessage = handlers => {
  const [, select] = useRedux();
  const data = select(getSystemMessages);
  const systemMessages = useMemo(
    () => data.data.filter(item => handlers.includes(item.handler)).reduce((acc, item) => ({ ...acc, [item.handler]: item }), {}),
    [data?.data, handlers],
  );

  return systemMessages;
};
