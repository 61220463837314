import { all, fork } from 'redux-saga/effects';
import users from './users';
import * as roles from './roles';
import * as permissions from './permissions';
import patient from './patients';
import * as languages from './languages';
import conversations from './conversations';
import messages from './messages';
import * as statesTranslations from './states';
import * as countries from './countries';
import * as zipCodes from './zipCodes';
import providers from './providers';
import * as organizations from './organizations';
import * as translations from './translations';
import organizationProviders from './organization-providers';
import * as emailTracking from './email-tracking';
import * as assessments from './assessments';
import recommendations from './recommendations';
import * as filters from './filters';
import * as providerWeeklyHours from './provider-weekly-hours';
import * as providerDailyHours from './provider-daily-hours';
import * as globalVariables from './global-variables';
import actionItems from './action-items';
import * as activityLogs from './activity-logs';
import systemMessages from './system-messages';
import appointments from './appointments';
import calendars from './calendars';
import files from './files';
import dedu from './dedu';
import medications from './medications';
import symptoms from './symptoms';
import patientAssessments from './patient-assessments';
import patientRecords from './patient-records';
import programs from './programs';
import patientRecommendations from './patient-recommendations';
import domo from './domo';
import icons from './icons';

const combinedSagas = {
  ...users,
  ...roles,
  ...permissions,
  ...patient,
  ...languages,
  ...statesTranslations,
  ...countries,
  ...zipCodes,
  ...providers,
  ...organizations,
  ...translations,
  ...organizationProviders,
  ...emailTracking,
  ...assessments,
  ...filters,
  ...providerWeeklyHours,
  ...providerDailyHours,
  ...appointments,
  ...calendars,
  ...conversations,
  ...messages,
  ...systemMessages,
  ...files,
  ...dedu,
  ...medications,
  ...symptoms,
  ...patientAssessments,
  ...globalVariables,
  ...actionItems,
  ...patientRecords,
  ...recommendations,
  ...activityLogs,
  ...programs,
  ...patientRecommendations,
  ...domo,
  ...icons,
};

export default function* rootSaga() {
  yield all(Object.values(combinedSagas).map(fork));
}
