import React, { Component } from 'react';
import { BlockPicker } from 'react-color';
import StreamlineIcon from '../StreamlineIcon';
import Popover from '../Popover';
import Button from '../Button';

class ColorPicker extends Component {
  stopPropagation = event => {
    event.stopPropagation();
  };

  onChange = color => {
    const { onChange } = this.props;
    onChange('color', color.hex);
  };

  render() {
    return (
      <div className='color-picker-content'>
        <div className='rdw-option-wrapper'>
          <Popover
            content={
              <Button type='link' onClick={this.stopPropagation}>
                <BlockPicker color={this.props.currentState.color} onChangeComplete={this.onChange} />
              </Button>
            }
            trigger='click'
          >
            <StreamlineIcon className='color-picker-icon' icon='color-painting-palette' size={16} />
          </Popover>
        </div>
      </div>
    );
  }
}

export default ColorPicker;
