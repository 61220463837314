import client from './client';

export const createGlobalVariableApi = data => client().post('/patient-data-variables', data);

export const getGlobalVariablesApi = data => client().get('/patient-data-variables', { params: data });

export const removeGlobalVariableApi = id => client().delete(`/patient-data-variables/${id}`);

export const editGlobalVariableApi = data => client().put(`/patient-data-variables/${data.id}`, data);

export const patchGlobalVariableApi = data => client().patch(`/patient-data-variables/${data.id}`, data);

export const getGlobalVariableApi = id => client().get(`/patient-data-variables/${id}`);
