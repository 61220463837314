import { action } from './index';

export const LOAD_INIT_MESSAGE = 'LOAD_INIT_MESSAGE';
export const SOCKET_EVENT = 'SOCKET_EVENT';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const UNSUBSCRIBE_FROM_ROOM = 'UNSUBSCRIBE_FROM_ROOM';
export const SUBSCRIBE_TO_ROOM = 'SUBSCRIBE_TO_ROOM';
export const INIT_MESSAGE = 'INIT_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_FILE_MESSAGE = 'SEND_FILE_MESSAGE';
export const CONVERSATION_ERROR = 'CONVERSATION_ERROR';
export const CONVERSATION_DISCONNECTED = 'CONVERSATION_DISCONNECTED';
export const READ_MESSAGE = 'READ_MESSAGE';
export const MESSAGE_WAS_READ = 'MESSAGE_WAS_READ';
export const RECONNECT_SOCKET = 'RECONNECT_SOCKET';
export const CREATE_NEW_CONVERSATION = 'CREATE_NEW_CONVERSATION';
export const UPDATE_CONVERSATION_LIST = 'UPDATE_CONVERSATION_LIST';

export const requestInitMessages = ({ params }) => action(LOAD_INIT_MESSAGE, { params });

export const initMessages = data => action(INIT_MESSAGE, data);
export const receiveMessage = data => action(RECEIVE_MESSAGE, data);
export const sendMessage = data => action(SEND_MESSAGE, data);
export const sendFileMessage = data => action(SEND_FILE_MESSAGE, data);
export const readMessage = data => action(READ_MESSAGE, data);

export const conversationError = data => action(CONVERSATION_ERROR, data);
export const socketDisconnected = data => action(CONVERSATION_DISCONNECTED, data);
export const createNewConversation = data => action(CREATE_NEW_CONVERSATION, data);

export const socketEvent = data => action(SOCKET_EVENT, data);
export const socketConnected = data => action(SOCKET_CONNECTED, data);
export const unsubscribeFromRoom = data => action(UNSUBSCRIBE_FROM_ROOM, data);
export const reConnectSocket = data => action(RECONNECT_SOCKET, data);
