import { SET_GRAPH_OPTIONS, UNSET_GRAPH_OPTIONS } from 'actions/patient-records';

export default (() => {
  const initialState = {
    source: 'Any',
    sources: ['Any', 'Patient', 'Device', 'Provider', 'EHR'],
    period: '3 Months',
    unit: 'Wt. Change (LBS)',
  };

  return (state = initialState, { type, ...options }) => {
    switch (type) {
      case SET_GRAPH_OPTIONS:
        return {
          ...state,
          ...options,
        };
      case UNSET_GRAPH_OPTIONS:
        return initialState;
      default:
        return state;
    }
  };
})();
