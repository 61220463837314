import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const CREATE_STATES = createRequestTypes('CREATE_STATES');
export const getCreateStatesAction = {
  request: () => action(CREATE_STATES[REQUEST]),
  success: (params, response) =>
    action(CREATE_STATES[SUCCESS], {
      response: {
        entities: {
          createdState: response,
        },
      },
    }),
  failure: (params, error) => action(CREATE_STATES[FAILURE], { error }),
};
export const LOAD_CREATE_STATES = 'LOAD_CREATE_STATES';
export const requestCreateStates = data => action(LOAD_CREATE_STATES, { data });

export const GET_STATES = createRequestTypes('GET_STATES');
export const getLoadStatesAction = {
  request: params => action(GET_STATES[REQUEST], { params }),
  success: (params, response) =>
    action(GET_STATES[SUCCESS], {
      response: {
        entities: {
          states: response,
        },
      },
    }),
  failure: (params, error) => action(GET_STATES[FAILURE], { error }),
};
export const LOAD_GET_STATES = 'LOAD_GET_STATES';
export const requestLoadStates = data => action(LOAD_GET_STATES, { data });

export const REMOVE_STATES = createRequestTypes('REMOVE_STATES');
export const getRemoveStatesAction = {
  request: data => action(REMOVE_STATES[REQUEST], { data }),
  success: (params, response) => action(REMOVE_STATES[SUCCESS]),
  failure: (params, error) => action(REMOVE_STATES[FAILURE], { error }),
};
export const LOAD_REMOVE_STATES = 'LOAD_REMOVE_STATES';
export const requestRemoveState = data => action(LOAD_REMOVE_STATES, { data });

export const UPDATE_STATE_TRANSLATION = createRequestTypes('UPDATE_STATE_TRANSLATION');
export const updateStatesTranslationAction = {
  request: data => action(UPDATE_STATE_TRANSLATION[REQUEST], { data }),
  success: (params, response) => action(UPDATE_STATE_TRANSLATION[SUCCESS]),
  failure: (params, error) => action(UPDATE_STATE_TRANSLATION[FAILURE], { error }),
};
export const LOAD_UPDATE_STATE_TRANSLATION = 'LOAD_UPDATE_STATE_TRANSLATION';
export const requestUpdateState = data => action(LOAD_UPDATE_STATE_TRANSLATION, { data });

export const SELECT_STATE = 'SELECT_STATE';
export const selectState = stateId => action(SELECT_STATE, { stateId });

export const UNSELECT_STATE = 'UNSELECT_STATE';
export const unSelectState = () => action(UNSELECT_STATE);

// Get selected country
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const selectCountry = countryId => action(SELECT_COUNTRY, countryId);
