import React from 'react';
import Dropdown from 'style-guide/Dropdown';
import Avatar from 'style-guide/Avatar';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const UserLargeDropdown = ({ userMenu, firstName, lastName, username, hideArrow }) => (
  <div className='userLargeDropdown'>
    <Dropdown overlay={userMenu}>
      <div
        style={{
          cursor: 'pointer',
        }}
      >
        {firstName && lastName ? (
          <Avatar style={{ verticalAlign: 'middle' }}>
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </Avatar>
        ) : (
          <UserOutlined />
        )}{' '}
        {username} {!hideArrow && <DownOutlined />}
      </div>
    </Dropdown>
  </div>
);

export default UserLargeDropdown;
