import styled from 'styled-components';
import { Form as form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const Form = styled(form)`
  .ant-legacy-form-item label {
    font-weight: bold;
    color: #727272;
    text-transform: uppercase;
  }

  .icon-sm svg {
    height: 25px;
    width: 25px;
  }
`;

/** @component */
export default Form;
