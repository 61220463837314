import { call, takeLatest, select, put } from 'redux-saga/effects';

import {
  LOAD_CREATE_PROVIDER_WEEKLY_HOURS,
  LOAD_PROVIDER_WEEKLY_HOURS,
  createProviderWeeklyHours,
  getProviderWeeklyHours,
} from 'actions/provider-weekly-hours';

import { createProviderWeeklyHoursApi, getProviderWeeklyHoursApi } from 'services/provider-weekly-hours';

import { createProviderWeeklyHoursError, providerWeeklyHours } from 'selectors/provider-weekly-hours';

import fetchEntity from './fetch-entity';

// create provider weekly hours

const fetchCreateProviderWeeklyHours = fetchEntity.bind(null, createProviderWeeklyHours, createProviderWeeklyHoursApi);

function* loadCreateProviderWeeklyHours({ data }) {
  yield call(fetchCreateProviderWeeklyHours, data);
  if (!(yield select(createProviderWeeklyHoursError))) {
    const [{ providerId, clear, day }] = data;
    yield call(fetchLoadProviderWeeklyHours, providerId);
    if (clear) {
      const newData = yield select(providerWeeklyHours);
      delete newData[day];
      yield put(getProviderWeeklyHours.success(undefined, { ...newData }));
    }
  }
}

export function* watchCreateProviderWeeklyHours() {
  yield takeLatest(LOAD_CREATE_PROVIDER_WEEKLY_HOURS, loadCreateProviderWeeklyHours);
}

// get provider weekly hours

const fetchLoadProviderWeeklyHours = fetchEntity.bind(null, getProviderWeeklyHours, getProviderWeeklyHoursApi);

function* loadProviderWeeklyHours({ providerId }) {
  yield call(fetchLoadProviderWeeklyHours, providerId);
}

export function* watchLoadProviderWeeklyHours() {
  yield takeLatest(LOAD_PROVIDER_WEEKLY_HOURS, loadProviderWeeklyHours);
}
