import { takeLatest, call } from 'redux-saga/effects';

import { getConversationsApi } from 'services/conversations';
import { getConversations, LOAD_CONVERSATIONS } from 'actions/conversations';
import fetchEntity from './fetch-entity';

/**
 * Get conversations
 */
const fetchGetConversations = fetchEntity.bind(null, getConversations, getConversationsApi);

export function* loadGetConversations({ params }) {
  yield call(fetchGetConversations, params);
}

function* watchLoadGetConversations() {
  yield takeLatest(LOAD_CONVERSATIONS, loadGetConversations);
}

const ConversationSagas = {
  watchLoadGetConversations,
};
export default ConversationSagas;
