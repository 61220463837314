module.exports = [
  'Aerobics',
  'Ballroom dancing',
  'Barre',
  'Basketball',
  'Bicycling',
  'Boxing',
  'Calisthenics',
  'Chores (other)',
  'Cleaning',
  'Cross Country Skiing',
  'Crossfit',
  'Cycling',
  'Elliptical machine',
  'Fitness class (other)',
  'Football',
  'Gardening',
  'Golfing (without a cart)',
  'Gymnastics',
  'Hiking',
  'Hockey',
  'Ice Skating',
  'Jogging',
  'Jumping rope',
  'Kayaking',
  'Kickboxing',
  'Martial arts',
  'Mowing lawn',
  'Pilates',
  'Qi Gong',
  'Racketball',
  'Raking leaves',
  'Resistance bands',
  'Roller blading',
  'Rowing',
  'Running',
  'Skiing, downhill',
  'Snowboarding',
  'Soccer',
  'Spinning',
  'Sports (other)',
  'Squash',
  'Stair Climbing',
  'Step aerobics',
  'Stretching',
  'Surfing',
  'Swimming laps',
  'Tai chi',
  'Tennis',
  'Volleyball',
  'Walking',
  'Water aerobics',
  'Weight lifting',
  'Wrestling',
  'Yoga',
  'Zumba',
];
