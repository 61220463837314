import React, { Fragment } from 'react';
import Breadcrumb from '../Breadcrumb';

const BreadcrumbAction = props => (
  <Fragment>
    {props.action ? <Breadcrumb names={[...props.names, props.action]} urls={props.urls} /> : <Breadcrumb names={props.names} urls={props.urls} />}
  </Fragment>
);

export default BreadcrumbAction;
