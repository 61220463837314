import { call, takeLatest } from 'redux-saga/effects';

import { getDomoEmbeds, LOAD_DOMO_EMBEDS } from 'actions/domo';
import { getDomoEmbedsApi } from 'services/domo';
import fetchEntity from './fetch-entity';

/**
 * Load Domo embeds
 */
const fetchDomoEmbeds = fetchEntity.bind(null, getDomoEmbeds, getDomoEmbedsApi);

export function* loadDomoEmbeds({ type, ...data }) {
  yield call(fetchDomoEmbeds, data);
}

function* watchDomoEmbeds() {
  yield takeLatest(LOAD_DOMO_EMBEDS, loadDomoEmbeds);
}

const DomoSagas = {
  watchDomoEmbeds,
};
export default DomoSagas;
