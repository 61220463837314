import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const EMAIL_TRACKING = createRequestTypes('EMAIL_TRACKING');
export const getEmailTracking = {
  request: () => action(EMAIL_TRACKING[REQUEST]),
  success: (params, response) =>
    action(EMAIL_TRACKING[SUCCESS], {
      response: {
        entities: {
          emailTracking: response,
        },
      },
    }),
  failure: (params, error) => action(EMAIL_TRACKING[FAILURE], { error }),
};
export const LOAD_EMAIL_TRACKING = 'LOAD_EMAIL_TRACKING';
export const requestEmailTracking = data => action(LOAD_EMAIL_TRACKING, data);
