import styled from 'styled-components';
import Button from './index';

const { Group } = Button;
const ButtonGroup = styled(Group)`
  > .ant-btn {
    border-radius: 0 !important;
  }

  > .ant-btn:first-child:not(:last-child),
  > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  /* stylelint-disable-next-line */
  > .ant-btn:last-child:not(:first-child),
  > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;
/** @component */
export default ButtonGroup;
