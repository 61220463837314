import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get insurance plans
export const ICON_LIST = createRequestTypes('ICON_LIST');
export const getIconsList = {
  request: () => action(ICON_LIST[REQUEST]),
  success: (params, response) =>
    action(ICON_LIST[SUCCESS], {
      response: {
        entities: {
          icons: response,
        },
      },
    }),
  failure: (params, error) => action(ICON_LIST[FAILURE], { error }),
};
export const LOAD_ICON_LIST = 'LOAD_ICON_LIST';
export const requestLoadIconList = data => action(LOAD_ICON_LIST, data);
