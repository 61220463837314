module.exports = {
  signalEvents: {
    disableMic: {
      receive: 'signal:disableMic',
      send: 'disableMic',
    },
    disableVideo: {
      receive: 'signal:disableVideo',
      send: 'disableVideo',
    },
    muteAll: {
      receive: 'signal:muteAll',
      send: 'muteAll',
    },
    hostStartedVV: {
      receive: 'signal:hostStarted',
      send: 'hostStarted',
    },
    switchHost: {
      receive: 'signal:hostSwitched',
      send: 'hostSwitched',
    },
  },
  OTErrorList: {
    OT_AUTHENTICATION_ERROR: { code: '#01', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_BADLY_FORMED_RESPONSE: { code: '#02', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_CHROME_MICROPHONE_ACQUISITION_ERROR: {
      code: '#03',
      showCode: true,
      msg: 'I’m sorry, but we cannot access your microphone.  Please restart your browser.',
    },
    OT_CONNECT_FAILED: { code: '#04', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_CONNECTION_LIMIT_EXCEEDED: { code: '#05', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_CONSTRAINTS_NOT_SATISFIED: { code: '#06', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_CREATE_PEER_CONNECTION_FAILED: { code: '#07', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_DISCONNECTED: { code: '#08', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_EMPTY_RESPONSE_BODY: { code: '#09', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_HARDWARE_UNAVAILABLE: {
      code: '#10',
      showCode: false,
      msg: 'We cannot access your camera or your microphone because they’re in use by another application.',
    },
    OT_ICE_WORKFLOW_FAILED: { code: '#11', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_INVALID_HTTP_STATUS: { code: '#12', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_INVALID_PARAMETER: { code: '#13', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_INVALID_SESSION_ID: { code: '#14', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_MEDIA_ERR_ABORTED: {
      code: '#15',
      showCode: true,
      msg: 'We are unable to connect to your microphone and camera. This may be resolved by using a different browser. Please access a list of supported browsers in Help.',
    },
    OT_MEDIA_ERR_DECODE: { code: '#16', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_MEDIA_ERR_NETWORK: { code: '#17', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_MEDIA_ERR_SRC_NOT_SUPPORTED: { code: '#18', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_MEDIA_ENDED: { code: '#19', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_NO_DEVICES_FOUND: { code: '#20', showCode: true, msg: 'I’m sorry, but we cannot find your camera or microphone to use for video service.' },
    OT_NO_VALID_CONSTRAINTS: {
      code: '#21',
      showCode: true,
      msg: 'I’m sorry, but you denied access for either your camera or microphone.  You will have to enable access to at least one to use the video service.',
    },
    OT_NOT_CONNECTED: { code: '#22', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_NOT_FOUND: { code: '#23', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_NOT_SUPPORTED: { code: '#24', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_PERMISSION_DENIED: { code: '#25', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_RATE_LIMIT_EXCEEDED: { code: '#26', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_REPORT_ISSUE_FAILED: { code: '#27', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED: {
      code: '#28',
      showCode: true,
      msg: 'I’m sorry, but our screen sharing requires the latest version of Chrome, Firefox, Edge, Safari or Opera.',
    },
    OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED: {
      code: '#29',
      showCode: true,
      msg: 'I’m sorry, but our screen sharing requires the latest version of Chrome, Firefox, Edge, Safari or Opera.',
    },
    OT_SCREEN_SHARING_NOT_SUPPORTED: {
      code: '#30',
      showCode: true,
      msg: 'I’m sorry, but our screen sharing requires the latest version of Chrome, Firefox, Edge, Safari or Opera.',
    },
    OT_SET_REMOTE_DESCRIPTION_FAILED: { code: '#31', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_ALREADY_CONNECTED_CONNECTING: { code: '#32', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_ABNORMAL: { code: '#33', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_CONNECT_EXCEPTION: { code: '#34', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_CONNECTIVITY_LOSS: { code: '#35', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_FALLBACK_CODE: { code: '#36', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_INCONSISTENT_DATA: { code: '#37', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_NO_STATUS: { code: '#38', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_POLICY_VIOLATION: { code: '#39', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_GOING_AWAY: { code: '#40', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_PROTOCOL_ERROR: { code: '#41', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_TIMEOUT: { code: '#42', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_TOO_LARGE: { code: '#43', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_UNEXPECTED_CONDITION: { code: '#44', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_SOCKET_CLOSE_UNSUPPORTED: { code: '#45', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_STREAM_CREATE_FAILED: { code: '#46', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_STREAM_DESTROYED: { code: '#47', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_STREAM_LIMIT_EXCEEDED: { code: '#48', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_STREAM_NOT_FOUND: { code: '#49', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_TERMS_OF_SERVICE_FAILURE: { code: '#50', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_TIMEOUT: { code: '#51', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_CANCEL: { code: '#52', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNABLE_TO_CAPTURE_MEDIA: { code: '#53', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNABLE_TO_CAPTURE_SCREEN: { code: '#54', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNEXPECTED_ERROR_CODE: { code: '#55', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNEXPECTED_HTTP_STATUS: { code: '#56', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNEXPECTED_SERVER_RESPONSE: { code: '#57', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNKNOWN_HTTP_ERROR: { code: '#58', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_UNSUPPORTED_BROWSER: {
      code: '#59',
      showCode: true,
      msg: 'I’m sorry, but our screen sharing requires the latest version of Chrome, Firefox, Edge, Safari or Opera.',
    },
    OT_USER_MEDIA_ACCESS_DENIED: {
      code: '#60',
      showCode: false,
      msg: 'I’m sorry, but you denied access for either your camera or microphone.  You will have to enable access to at least one to use the video service.',
    },
    OT_XDOMAIN_OR_PARSING_ERROR: { code: '#61', showCode: true, msg: 'I’m sorry, but we cannot connect to the video service.' },
    OT_API_KEY_DISABLED: { code: '#62', showCode: true, msg: 'Oops, something went wrong' },
    OT_UNABLE_TO_ACCESS_MEDIA_ENGINE: { code: '#63', showCode: true, msg: 'Oops, something went wrong' },

    // Sometimes the same error code is thrown in different cases,
    // for more specific use cases subsections may be used
    sharescreen: {
      OT_USER_MEDIA_ACCESS_DENIED: {
        code: '#s01',
        showCode: false,
        msg: 'Presentation not started.',
      },
    },
  },
};
