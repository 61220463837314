import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load Patient Records
 */
export const PATIENT_RECORDS = createRequestTypes('PATIENT_RECORDS');
export const getPatientRecords = {
  request: () => action(PATIENT_RECORDS[REQUEST]),
  success: (params, response) =>
    action(PATIENT_RECORDS[SUCCESS], {
      response: {
        entities: {
          patientRecords: {
            ...response,
            ...(!response ? { data: [] } : {}),
          },
        },
      },
    }),
  failure: (params, error) => action(PATIENT_RECORDS[FAILURE], { error }),
};
export const LOAD_PATIENT_RECORDS = 'LOAD_PATIENT_RECORDS';
export const requestPatientRecords = data => action(LOAD_PATIENT_RECORDS, data);

/**
 * Create Patient Record
 */
export const CREATE_PATIENT_RECORD = createRequestTypes('CREATE_PATIENT_RECORD');
export const createPatientRecord = {
  request: () => action(CREATE_PATIENT_RECORD[REQUEST]),
  success: () => action(CREATE_PATIENT_RECORD[SUCCESS]),
  failure: (params, error) => action(CREATE_PATIENT_RECORD[FAILURE], { error }),
};
export const LOAD_CREATE_PATIENT_RECORD = 'LOAD_CREATE_PATIENT_RECORD';
export const requestCreatePatientRecord = data => action(LOAD_CREATE_PATIENT_RECORD, data);

/**
 * Remove Patient Record
 */
export const REMOVE_PATIENT_RECORD = createRequestTypes('REMOVE_PATIENT_RECORD');
export const removePatientRecord = {
  request: () => action(REMOVE_PATIENT_RECORD[REQUEST]),
  success: () => action(REMOVE_PATIENT_RECORD[SUCCESS]),
  failure: (params, error) => action(REMOVE_PATIENT_RECORD[FAILURE], { error }),
};
export const LOAD_REMOVE_PATIENT_RECORD = 'LOAD_REMOVE_PATIENT_RECORD';
export const requestRemovePatientRecord = data => action(LOAD_REMOVE_PATIENT_RECORD, data);

export const SET_GRAPH_OPTIONS = 'SET_GRAPH_OPTIONS';
export const setGraphOptions = options => action(SET_GRAPH_OPTIONS, options);

export const UNSET_GRAPH_OPTIONS = 'UNSET_GRAPH_OPTIONS';
export const unsetGraphOptions = options => action(UNSET_GRAPH_OPTIONS, options);

export const REMOVE_ALL_RECORDS = 'REMOVE_ALL_RECORDS';
export const requestRemoveAllPatientRecord = () => action(REMOVE_ALL_RECORDS);
