import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get assessments
export const ASSESSMENTS = createRequestTypes('ASSESSMENTS');
export const getAssessments = {
  request: () => action(ASSESSMENTS[REQUEST]),
  success: (params, response) =>
    action(ASSESSMENTS[SUCCESS], {
      response: {
        entities: {
          assessments: response,
        },
      },
    }),
  failure: (params, error) => action(ASSESSMENTS[FAILURE], { error }),
};
export const LOAD_ASSESSMENTS = 'LOAD_ASSESSMENTS';
export const requestLoadAssessments = data => action(LOAD_ASSESSMENTS, data);

// delete assessment
export const DELETE_ASSESSMENT = createRequestTypes('DELETE_ASSESSMENT');
export const deleteAssessment = {
  request: () => action(DELETE_ASSESSMENT[REQUEST]),
  success: () => action(DELETE_ASSESSMENT[SUCCESS]),
  failure: (params, error) => action(DELETE_ASSESSMENT[FAILURE], { error }),
};

export const LOAD_DELETE_ASSESSMENT = 'LOAD_DELETE_ASSESSMENT';
export const requestDeleteAssessment = id => action(LOAD_DELETE_ASSESSMENT, id);

// revert assessment
export const REVERT_ASSESSMENT = createRequestTypes('REVERT_ASSESSMENT');
export const revertAssessment = {
  request: () => action(REVERT_ASSESSMENT[REQUEST]),
  success: (params, response) =>
    action(REVERT_ASSESSMENT[SUCCESS], {
      response: {
        entities: {
          assessment: response,
        },
      },
    }),
  failure: (params, error) => action(REVERT_ASSESSMENT[FAILURE], { error }),
};

// Store Assessments
export const STORE_ASSESSMENTS = 'STORE_ASSESSMENTS';
export const requestStoreAssessments = data => action(STORE_ASSESSMENTS, data);
