import styled from 'styled-components';
import { Switch as switches } from 'antd';
import propTypes from 'prop-types';

const Switch = styled(switches)``;

Switch.propTypes = {
  /** get focus when component mounted */
  autoFocus: propTypes.bool,
  /** determine whether the Switch is checked */
  checked: propTypes.bool,
  /** content to be shown when the state is checked */
  checkedChildren: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** to set the initial state */
  defaultChecked: propTypes.bool,
  /** Disable switch */
  disabled: propTypes.bool,
  /** loading state of switch */
  loading: propTypes.bool,
  /** the size of the Switch, options: default small */
  size: propTypes.oneOf(['default', 'small']),
  /** content to be shown when the state is unchecked */
  unCheckedChildren: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** trigger when the checked state is changing */
  onChange: propTypes.func,
  /** trigger when clicked */
  onClick: propTypes.func,
  /** additional class to Switch */
  className: propTypes.string,
};

// Switch.defaultProps = {
//   autoFocus: false,
//   checked: false,
//   defaultChecked: false,
//   disabled: false,
//   loading: false,
//   size: 'default',
//   onChange: () => undefined,
//   onClick: () => undefined,
//   className: '',
// };

/** @component */
export default Switch;
