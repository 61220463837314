import client from './client';

export const userLoginApi = credentials => client().post('/authentication', credentials);

export const userLogoutApi = () => client().delete('/authentication');

export const userAuthmanagementApi = credentials => client().post('/authmanagement', credentials);

export const userRegisterApi = credentials => client().post('/users', credentials);

export const usersListApi = ({ offset, limit, search }) => client().get('/users', { params: { $skip: offset, limit, $search: search } });

export const updateUserApi = ({ id, data }) => client().patch(`/users/${id}`, data);

export const checkAuthenticationApi = data => client().post('/check-authentication', data);
export const initAuthenticationApi = () => client().post('/refresh-auth');

export const activateAccountApi = token => client().get(`/activate-account/${token}`);

export const getUserEmailByToken = data => client().get('/activate-account', { params: { resetToken: data.token } });

export const getTermsAndConditionsApi = token => client().get('/terms');

export const editUserApi = ({ id, ...data }) => client().patch(`/users/${id}`, data);

export const updateUserSubscribedApi = data => client().put(`/unsubscribe/${data.token}`, data);

export const unsubscribeApi = token => client().get(`/unsubscribe/${token}`);

export const currentUserApi = token => client().get(`/users/${token}`);

export const twoFaApi = data => client().post('/two-factor-authentication', data);

export const verifyTwoFaApi = ({ code, ...data }) => client().put(`/two-factor-authentication/${code}`, data);

export const setupBackupCodesApi = data => client().patch('/two-factor-authentication', data);
