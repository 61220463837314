import merge from 'deepmerge';
import { appointments } from 'bmiq-common';

const { participantTypes } = appointments;

const defaultState = {
  user: null,
  roles: [],
  userEmailVerificationSent: false,
  rolePermissions: {},
  permissions: [],
  existingPermissions: [],
  createdPatient: {},
  createdInsurancePlan: {},
  users: { data: [] },
  patients: { rows: [] },
  providers: { rows: [] },
  providerOrganizations: [],
  patient: {},
  provider: {
    organization_providers: [],
    providers_users: [],
    provider_specialities: [],
  },
  states: [],
  createdState: {},
  zipCodes: { zips: [] },
  countries: [],
  languages: [],
  language: {},
  createdLanguage: {},
  activeLanguage: {},
  ehrPlatforms: [],
  ehrPlatform: {},
  insuranceAffiliation: {},
  devices: { data: [] },
  device: {},
  selectedType: {},
  profile: {},
  organization: {
    organization_providers: [],
    organization_groups: [],
    organization_programs: [],
  },
  organizations: { rows: [] },
  stateTranslation: {},
  countryTranslation: {},
  organizationUsers: { data: [] },
  providerUsers: { data: [] },
  invitationUsers: [],
  emailTracking: {
    data: [],
  },
  npiProvider: {},
  assessments: [],
  assessment: {
    questions: [],
    logicJumps: [],
  },
  createdAssessment: {},
  recommendations: {
    data: [],
  },
  recommendation: {},
  recommendationReCheckInfo: {},
  appointmentTypes: [],
  appointmentType: {},
  appointment: {
    [participantTypes.PATIENT]: [],
    [participantTypes.PROVIDER]: [],
    [participantTypes.PROVIDER_ADDITIONAL]: [],
  },
  weeklyHours: {},
  dailyHours: {},
  appointments: [],
  uploadedFilesIds: [],
  calendarAuthUrl: '',
  providerAvailability: {},
  systemMessages: { data: [] },
  files: [],
  deduLoginUrl: '',
  deduSites: [],
  medications: { data: [] },
  medication: {},
  createdMedication: {},
  symptoms: { data: [] },
  symptom: {},
  createdSymptom: {},
  systemMessage: {},
  patientAssessments: {
    data: [],
  },
  actionItems: {
    data: [],
    total: 0,
  },
  actionItem: {},
  globalVariables: {
    data: {
      vars: [],
      atVars: [],
    },
    total: 0,
  },
  createdGlobalVariable: [],
  nextQuestion: {
    questionnaire: [],
  },
  patientRecords: {
    data: [],
  },
  patientWeightRecords: {},
  activityLogs: {
    logs: null,
    total: 0,
    triggerCodes: null,
  },
  questionnaires: {
    data: [],
  },
  completedQuestionnaires: {
    data: [],
  },
  patientRecommendations: {
    outputs: [],
    staticVars: {},
  },
  assessmentImageUrl: '',
  programs: [],
  vital: {},
  vitals: [],
  goalCategories: [],
  patientGoals: {
    rows: [],
  },
  medicationsFda: [],
  deduCourses: [],
  programCourses: [],
  providersRoles: [],
  icons: {},
};

const EntitiesSagas = (state = defaultState, action) => {
  if (action.response && action.response.entities) {
    return merge.all([state, action.response.entities], {
      clone: false,
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
  }

  return state;
};
export default EntitiesSagas;
