import { createSelector } from 'reselect';

import { LOAD_ASSESSMENTS, LOAD_DELETE_ASSESSMENT } from 'actions/assessments';

import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

import getEntities from './entities';

// loading selectors

export const getAssessmentsLoading = createLoadingSelector(LOAD_ASSESSMENTS)();

export const deleteAssessmentLoading = createLoadingSelector(LOAD_DELETE_ASSESSMENT)();

// error selectors
export const errorLoadingAssessments = createErrorSelector(LOAD_ASSESSMENTS)();

export const errorDeletingAssessment = createErrorSelector(LOAD_DELETE_ASSESSMENT)();

export const getAssessments = createSelector(getEntities, entities => entities.assessments);

export const getAssessment = createSelector(getEntities, entities => entities.assessment);

export const getCreatedAssessment = createSelector(getEntities, entities => entities.createdAssessment);
