import { takeLatest, call, select, put } from 'redux-saga/effects';

import {
  LOAD_PROVIDERS,
  getProviders,
  LOAD_PROVIDER_AVAILABILITY,
  getProviderAvailability,
  LOAD_PROVIDER,
  getProvider,
  LOAD_REMOVE_PROVIDER,
  removeProvider,
  RESET_PROVIDER,
  LOAD_RELOAD_PROVIDER,
  setFilteredProviders,
  requestLoadProviders,
} from 'actions/providers';
import { getProvidersApi, getProviderAvailabilityApi, getSingleProviderApi, removeProviderApi } from 'services/providers';
import { getProvidersList, getProvidersCount, removeProviderError } from 'selectors/providers';
import { setOffset } from 'actions/pagination';
import { getOffset } from 'selectors/pagination';
import { changePage } from 'utils/pagination';
import { groupedColorProvider } from 'utils/calendar-helper';
import fetchEntity from './fetch-entity';

/**
 * Get providers
 */
const fetchGetProviders = fetchEntity.bind(null, getProviders, getProvidersApi);

export function* loadGetProviders({ type, isScheduling, ...params }) {
  yield call(fetchGetProviders, params);
  if (!params.organizationId) {
    if (isScheduling) {
      const [firstProvider] = yield select(getProvidersList);
      yield put(setFilteredProviders([{ ...firstProvider, color: groupedColorProvider({ key: 0 }), colorKey: 0 }]));
    } else {
      yield put(setFilteredProviders(yield select(getProvidersList)));
    }
  }
}

function* watchLoadGetProviders() {
  yield takeLatest(LOAD_PROVIDERS, loadGetProviders);
}

/**
 * Get single provider
 */
const fetchGetProvider = fetchEntity.bind(null, getProvider, getSingleProviderApi);

export function* loadGetProvider({ id }) {
  yield call(fetchGetProvider, id);
}

function* watchLoadGetProvider() {
  yield takeLatest(LOAD_PROVIDER, loadGetProvider);
}

/**
 * Reset provider
 */
export function* loadResetProvider() {
  yield put(getProvider.success(undefined, null));
}

function* watchResetProvider() {
  yield takeLatest(RESET_PROVIDER, loadResetProvider);
}

/**
 * Remove provider
 */
const fetchRemoveProvider = fetchEntity.bind(null, removeProvider, removeProviderApi);

export function* loadRemoveProvider({ id, organizationId }) {
  yield call(fetchRemoveProvider, id);
  if (!(yield select(removeProviderError))) {
    const count = yield select(getProvidersCount);
    const offset = yield select(getOffset);
    yield put(setOffset(changePage(count, offset)));
    yield put(requestLoadProviders({ organizationId, offset: yield select(getOffset) }));
  }
}

function* watchLoadRemoveProvider() {
  yield takeLatest(LOAD_REMOVE_PROVIDER, loadRemoveProvider);
}

export function* reloadProviderData() {
  yield put(getProvider.success(undefined, null));
}

function* watchReloadProviderData() {
  yield takeLatest(LOAD_RELOAD_PROVIDER, reloadProviderData);
}

/**
 * Get providers availability
 */
const fetchGetProviderAvailability = fetchEntity.bind(null, getProviderAvailability, getProviderAvailabilityApi);

export function* loadGetProviderAvailability({ type, ...params }) {
  yield call(fetchGetProviderAvailability, params);
}

function* watchLoadGetProviderAvailability() {
  yield takeLatest(LOAD_PROVIDER_AVAILABILITY, loadGetProviderAvailability);
}

const ProviderSagas = {
  watchLoadGetProviderAvailability,
  watchReloadProviderData,
  watchLoadRemoveProvider,
  watchResetProvider,
  watchLoadGetProvider,
  watchLoadGetProviders,
};
export default ProviderSagas;
