import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Row } from 'antd';

import Form from '../Form';
import Button from '../Button';

const FormItem = Form.Item;

class BmiqForm extends PureComponent {
  componentDidUpdate = prevProps => {
    const { loading, error, form, onError, onSuccess, validateForm, data, isUpdate, scrollToError } = this.props;
    if (isUpdate && prevProps.data !== data) {
      form.setFieldsValue(data);
    }
    if (!loading && prevProps.loading) {
      if (error && error !== prevProps.error) {
        onError();
        validateForm(form, error);
        scrollToError(error);
        return;
      }
      onSuccess();
    }
  };

  render() {
    const { formProps, fields, submitButton, cancelButton, form, ItemsWrapper, ButtonWrapper, title } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this.handleSubmit} {...formProps}>
        {title}
        {fields && (
          <Row>
            {ItemsWrapper ? (
              // eslint-disable-next-line react/jsx-pascal-case
              <ItemsWrapper.component {...ItemsWrapper.props}>{this.renderFields(fields, getFieldDecorator)}</ItemsWrapper.component>
            ) : (
              this.renderFields(fields, getFieldDecorator)
            )}
          </Row>
        )}
        {ButtonWrapper ? (
          // eslint-disable-next-line react/jsx-pascal-case
          <ButtonWrapper.component {...ButtonWrapper.props}>
            {cancelButton && this.renderCancelButton(cancelButton)}
            {submitButton && this.renderSubmitButton(submitButton)}
          </ButtonWrapper.component>
        ) : (
          [cancelButton && this.renderCancelButton(cancelButton), submitButton && this.renderSubmitButton(submitButton)]
        )}
      </Form>
    );
  }

  renderFields = (fields, getFieldDecorator) =>
    fields.map(field =>
      field.raw ? (
        field.component
      ) : (
        <FormItem key={field.name} {...field.formItemProps} {...this.props.formItemValid(field.name, this.props.form, this.props.error)}>
          {field.prevSibling}
          {getFieldDecorator(field.name, {
            initialValue: field.initialValue || undefined,
            rules: field.rules ? [...field.rules] : [],
            ...field.fieldDecoratorOptions,
          })(field.component)}
          {field.nextSibling}
        </FormItem>
      ),
    );

  renderSubmitButton = submitButton => (
    <Button key='submitButton' {...submitButton.props}>
      {submitButton.text || 'Submit'}
    </Button>
  );

  renderCancelButton = cancelButton => (
    <Button key='cancelButton' {...cancelButton.props} style={{ marginRight: '15px' }}>
      {cancelButton.text || 'Cancel'}
    </Button>
  );

  handleSubmit = e => {
    e.preventDefault();
    const { form, request, resetAfterSubmit, onSubmit, data } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      /**
       * Update field value to null when it is undefined (e.g select box deleted values)
       */
      const undefinedToNullValues = Object.keys(values).reduce((acc, key) => {
        if (key === 'addresses') {
          const addresses = values[key];
          return Object.keys(addresses).reduce((address, addressKey) => {
            address[addressKey] = addresses[addressKey] || null;
            return address;
          }, acc);
        }
        acc[key] = values[key] || null;
        return acc;
      }, {});
      if (!err) {
        request({
          ...data,
          ...undefinedToNullValues,
        });
        resetAfterSubmit && form.resetFields();
      }
    });
    onSubmit();
  };
}

BmiqForm.propTypes = {
  /** the fields of the form.
  a field must be an object with properties
  @param {String} name (required) the unique name of the field to be passed to getFieldDecorator, <br/>
  @param {ReactNode} component (required) a component to render in getFieldDecorator, <br/>
  @param {ReactNode} prevSibling (optional) renders before the original component,<br/>
  @param {ReactNode} nextSibling (optional) renders after the original component,<br/>
  @param initialValue:  (optional) the initial value of the form,<br/>
  @param {Array} rules (optional) validation rules for the component
  see https://ant.design/components/form/#Validation-Rules,<br/>
  @param {Object} formItemProps (optional) the props to be passed to <Form.Item><br/>
  @param {Object} fieldDecoratorOptions (optional) the options to be passed to getFieldDecorator<br/>
  */
  fields: propTypes.array.isRequired,
  /** props to be passed to form's submit Button. */
  submitButton: propTypes.object,
  /** properties of Form element. */
  formProps: propTypes.object,
  /** additional data to be passes to the request function along with form values. */
  data: propTypes.object,
  /** whether to reset form's fields after submitting the form. */
  resetAfterSubmit: propTypes.bool,
  /** loading state of the form. */
  loading: propTypes.bool,
  /** server-side errors. */
  error: propTypes.object,
  /** the wrapper component for all Form.Item components. */
  ItemsWrapper: propTypes.object,
  /** the wrapper component for Submit Button. */
  ButtonWrapper: propTypes.object,
  /** a title component for Form. */
  title: propTypes.string,
  /** a function to be executed when submitting the form. */
  onSubmit: propTypes.func,
  /** a function to be executed when client-side validation passes. */
  request: propTypes.func,
  /** a validation function to be called for custom server-side error. */
  validateForm: propTypes.func,
  /** a validation function to be called for unhandled errors. */
  formItemValid: propTypes.func,
  /** a function to be called on server-side errors. */
  onError: propTypes.func,
  /** a function to be called when request function passes. */
  onSuccess: propTypes.func,
  /** allow update form fields when setting new data. */
  isUpdate: propTypes.bool,
};

BmiqForm.defaultProps = {
  fields: [],
  submitButton: {},
  formProps: {},
  data: {},
  resetAfterSubmit: false,
  loading: false,
  error: null,
  ItemsWrapper: null,
  ButtonWrapper: null,
  title: '',
  onSubmit: () => undefined,
  request: () => undefined,
  validateForm: () => undefined,
  formItemValid: () => undefined,
  onError: () => undefined,
  onSuccess: () => undefined,
  isUpdate: false,
  scrollToError: error => {
    const errors = [].concat(error.data, error.errors).filter(e => !isEmpty(e));
    const [firstError] = errors;
    const fieldId = firstError.path ? firstError.path : Object.keys(firstError)[0];
    const element = document.getElementById(fieldId);
    element &&
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
  },
};

const WrappedComponent = Form.create()(BmiqForm);

/** @component BmiqForm */
export default WrappedComponent;
