import { call, takeLatest, put, select } from 'redux-saga/effects';

import {
  getCreateStatesAction,
  LOAD_CREATE_STATES,
  LOAD_GET_STATES,
  getLoadStatesAction,
  getRemoveStatesAction,
  LOAD_REMOVE_STATES,
  updateStatesTranslationAction,
  LOAD_UPDATE_STATE_TRANSLATION,
} from 'actions/states';
import { createStateApi, getStatesApi, getRemoveStatesApi, updateStateTranslationApi } from 'services/states';
import { getStateCreateError, getUpdateStateError, getStatesTranslations, getRemoveStateError, getCreatedState } from 'selectors/states';
import { DEFAULT_LANGUAGE_ID } from 'constants/admin';
import { getSelectedCountry } from 'selectors/countries';
import fetchEntity from './fetch-entity';

// create state
const fetchCreateStateAction = fetchEntity.bind(null, getCreateStatesAction, createStateApi);

function* loadCreateState({ data }) {
  yield call(fetchCreateStateAction, data);
  if (!(yield select(getStateCreateError))) {
    const states = (yield select(getStatesTranslations)).concat(yield select(getCreatedState));
    yield put(getLoadStatesAction.success(undefined, states));
  }
}

export function* watchLoadCrateState() {
  yield takeLatest(LOAD_CREATE_STATES, loadCreateState);
}

// get states
const fetchStatesStateAction = fetchEntity.bind(null, getLoadStatesAction, getStatesApi);

function* loadStates({ data }) {
  if (!data.countryId) {
    yield put(getLoadStatesAction.success(undefined, []));
  } else {
    yield call(fetchStatesStateAction, data);
  }
}

export function* watchLoadStates() {
  yield takeLatest(LOAD_GET_STATES, loadStates);
}

// remove state
const fetchRemoveStateAction = fetchEntity.bind(null, getRemoveStatesAction, getRemoveStatesApi);

function* loadRemoveStates({ data }) {
  const { id } = data;
  yield call(fetchRemoveStateAction, data);
  if (!(yield select(getRemoveStateError))) {
    const states = (yield select(getStatesTranslations)).filter(state => +state.stateId !== +id);
    yield put(getLoadStatesAction.success(undefined, states));
  }
}

export function* watchLoadRemoveStates() {
  yield takeLatest(LOAD_REMOVE_STATES, loadRemoveStates);
}

// edit state
const fetchUpdateStateAction = fetchEntity.bind(null, updateStatesTranslationAction, updateStateTranslationApi);

function* loadUpdateStatesTranslation({ data }) {
  yield call(fetchUpdateStateAction, { ...data, languageId: DEFAULT_LANGUAGE_ID });
  if (!(yield select(getUpdateStateError))) {
    const countryId = yield select(getSelectedCountry);
    const states = yield select(getStatesTranslations);
    let updatedStates = [];
    if (+countryId !== +data.countryId) {
      updatedStates = states.filter(state => +state.stateId !== +data.stateId);
    } else {
      updatedStates = states.map(state => (+state.stateId !== +data.stateId ? state : data));
    }
    yield put(getLoadStatesAction.success(undefined, updatedStates));
  }
}

export function* watchLoadUpdateStates() {
  yield takeLatest(LOAD_UPDATE_STATE_TRANSLATION, loadUpdateStatesTranslation);
}
