import styled from 'styled-components';

const Iframe = styled('iframe')`
  min-height: 500px;
  width: 100%;
  border-color: transparent !important;
`;

/** @component */
export default Iframe;
