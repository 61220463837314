import React from 'react';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';

import 'slick-carousel/slick/slick.css';

import { Next, Prev } from '../../components/partials/Slider';

const defaultBreakpoints = [480, 567, 768, 992, 1200, 1600];

const ReuseSlick = React.forwardRef(
  (
    {
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
      data = [],
      next = <Next />,
      prev = <Prev />,
      breakpoints = defaultBreakpoints,
      renderer = () => null,
      settings,
      ...props
    },
    ref,
  ) => {
    const collectBreakpoints = [xs, sm, md, lg, xl, xxl]
      .map((val, index) => {
        if (val) {
          return {
            breakpoint: breakpoints[index],
            settings: val,
          };
        }
        return undefined;
      })
      .filter(val => val);
    const passAsProps = {
      ...settings,
      ...{
        nextArrow: next,
        prevArrow: prev,
        responsive: collectBreakpoints,
      },
    };
    return (
      <Slider {...passAsProps} {...props} ref={ref}>
        {data.map(renderer)}
      </Slider>
    );
  },
);

// TODO Find the selector that sets the next arrow, to the right by 10 pixels
const Slick = styled(ReuseSlick)`
  ${props =>
    props.gap &&
    css`
      .slick-slide > div {
        padding-right: ${props.gap}px;
      }
    `}

  .slick-track {
    margin: 0;
  }

  .slick-next {
    right: 0;
  }
`;

export default Slick;
