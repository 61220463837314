module.exports = [
  {
    id: 1,
    name: 'Physician',
  },
  {
    id: 2,
    name: 'Physician Assistant',
  },
  {
    id: 3,
    name: 'Nurse Practitioner',
  },
  {
    id: 4,
    name: 'Nurse',
  },
  {
    id: 5,
    name: 'Dietitian',
  },
  {
    id: 6,
    name: 'Medical Assistant',
  },
  {
    id: 7,
    name: 'Admin Assistant',
  },
];
