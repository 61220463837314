import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import appState from 'reducers';
import rootSaga from 'sagas';
import { saveState } from './localStorage';
import createSocketMiddleware from './socketMiddleware';

export const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();
  const socketMiddleware = createSocketMiddleware();
  // We don't need socketMiddleware in test environment to avoid unnecessary warnings
  const middlewares = process.env.NODE_ENV === 'test' ? [sagaMiddleware] : [sagaMiddleware, socketMiddleware];
  const store = createStore(appState, initialState, composeWithDevTools(applyMiddleware(...middlewares)));

  store.subscribe(() => {
    const data = store.getState();
    const isJwtExpired = Object.values(data.error).some(error => error && error.message === 'jwt expired');
    if (!isJwtExpired) {
      saveState(data);
    }
  });

  sagaMiddleware.run(rootSaga);
  store.close = () => store.dispatch(END);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('reducers', () => {
        store.replaceReducer(appState);
      });
    }
  }

  return store;
};

export default configureStore();
