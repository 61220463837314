import { call, debounce } from 'redux-saga/effects';

import { LOAD_FILTERED_DATA } from 'actions/filters';
import { getPatients } from 'actions/patients';
import { getPatientsApi } from 'services/patients';
import fetchEntity from './fetch-entity';

function* loadGetFilteredData({ type, fetchType, ...data }) {
  let action;
  let api;
  switch (fetchType) {
    case 'patients':
      action = getPatients;
      api = getPatientsApi;
      break;
    default:
      break;
  }
  const fetchGetFilteredData = fetchEntity.bind(null, action, api);
  yield call(fetchGetFilteredData, data);
}

export function* watchLoadGetFilteredData() {
  yield debounce(500, LOAD_FILTERED_DATA, loadGetFilteredData);
}
