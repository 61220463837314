import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load patient recommendations
 */
export const PATIENT_RECOMMENDATIONS = createRequestTypes('PATIENT_RECOMMENDATIONS');
export const getPatientRecommendations = {
  request: () => action(PATIENT_RECOMMENDATIONS[REQUEST]),
  success: (params, response) =>
    action(PATIENT_RECOMMENDATIONS[SUCCESS], {
      response: {
        entities: {
          patientRecommendations: response.data,
        },
      },
    }),
  failure: (params, error) => action(PATIENT_RECOMMENDATIONS[FAILURE], { error }),
};
export const LOAD_PATIENT_RECOMMENDATIONS = 'LOAD_PATIENT_RECOMMENDATIONS';
export const requestPatientRecommendations = data => action(LOAD_PATIENT_RECOMMENDATIONS, data);
