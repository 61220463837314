import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get actions
export const ACTION_ITEMS = createRequestTypes('ACTION_ITEMS');
export const getActionItems = {
  request: () => action(ACTION_ITEMS[REQUEST]),
  success: (params, response) =>
    action(ACTION_ITEMS[SUCCESS], {
      response: {
        entities: {
          actionItems: {
            ...response,
            data: [...(params?.existingData?.data || []), ...response.data],
          },
        },
      },
    }),
  failure: (params, error) => action(ACTION_ITEMS[FAILURE], { error }),
};
export const LOAD_ACTION_ITEMS = 'LOAD_ACTION_ITEMS';
export const requestLoadActionItems = adminId => action(LOAD_ACTION_ITEMS, adminId);

// edit action
export const EDIT_ACTION = createRequestTypes('EDIT_ACTION');
export const editAction = {
  request: () => action(EDIT_ACTION[REQUEST]),
  success: (params, response) =>
    action(EDIT_ACTION[SUCCESS], {
      response: {
        entities: {
          action: response,
        },
      },
    }),
  failure: (params, error) => action(EDIT_ACTION[FAILURE], { error }),
};

export const LOAD_EDIT_ACTION = 'LOAD_EDIT_ACTION';
export const requestEditAction = data => action(LOAD_EDIT_ACTION, data);
