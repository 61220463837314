import { createRequestTypes, action, FAILURE, REQUEST, SUCCESS } from '.';

// states

export const GET_STATE_TRANSLATION = createRequestTypes('GET_STATE_TRANSLATION');
export const getLoadStateTranslationsAction = {
  request: params => action(GET_STATE_TRANSLATION[REQUEST], { params }),
  success: (params, response) =>
    action(GET_STATE_TRANSLATION[SUCCESS], {
      response: {
        entities: {
          stateTranslation: response[0],
        },
      },
    }),
  failure: (params, error) => action(GET_STATE_TRANSLATION[FAILURE], { error }),
};
export const LOAD_GET_STATE_TRANSLATION = 'LOAD_GET_STATE_TRANSLATION';
export const requestLoadStateTranslation = data => action(LOAD_GET_STATE_TRANSLATION, { data });

export const CREATE_STATE_TRANSLATION = createRequestTypes('CREATE_STATE_TRANSLATION');
export const createStateTranslationAction = {
  request: data => action(CREATE_STATE_TRANSLATION[REQUEST], { data }),
  success: (params, response) => action(CREATE_STATE_TRANSLATION[SUCCESS]),
  failure: (params, error) => action(CREATE_STATE_TRANSLATION[FAILURE], { error }),
};
export const LOAD_CREATE_STATE_TRANSLATION = 'LOAD_CREATE_STATE_TRANSLATION';
export const requestCreateStateTranslation = data => action(LOAD_CREATE_STATE_TRANSLATION, { data });

export const UPDATE_STATE_TRANSLATION = createRequestTypes('UPDATE_STATE_TRANSLATION');
export const updateStateTranslationAction = {
  request: data => action(UPDATE_STATE_TRANSLATION[REQUEST], { data }),
  success: (params, response) => action(UPDATE_STATE_TRANSLATION[SUCCESS]),
  failure: (params, error) => action(UPDATE_STATE_TRANSLATION[FAILURE], { error }),
};
export const LOAD_UPDATE_STATE_TRANSLATION = 'LOAD_UPDATE_STATE_TRANSLATION';
export const requestUpdateStateTranslation = data => action(LOAD_UPDATE_STATE_TRANSLATION, { data });
