import styled, { css } from 'styled-components';
import { Layout as layout } from 'antd';

const Layout = styled(layout)`
  ${props =>
    props.$transparentBg === 'light' &&
    css`
      background: #fff;
    `}

  > .ant-layout-content {
    transition: all 0.2s;
    ${props =>
      props.$hasHeader &&
      css`
        padding: 84px 24px 24px;
      `}
    ${props =>
      props.$isMobile &&
      css`
        padding: 0px !important;
      `}

    ${props =>
      props.hasSider &&
      css`
        margin-left: 80px;
        margin-top: 24px;
      `}

    ${props =>
      !props.$collapsed &&
      props.hasSider &&
      css`
        margin-left: 278px;
      `}
    min-height: calc(100vh - 60px) !important;

    @media screen and (min-width: 768px and max-width: 1024px) {
      margin-left: 0;

      &.ant-layout-has-sider {
        padding: 84px 16px 16px;
      }
    }

    @media screen and (max-width: 767.98px) {
      margin-left: 0;

      &.ant-layout-has-sider {
        padding: 84px 8px 80px;
      }
    }
  }
`;

/** @component */
export default Layout;
