import { combineReducers } from 'redux';
import { SELECT_APPOINTMENT, UNSELECT_APPOINTMENT, SET_APPOINTMENTS, UNSET_APPOINTMENTS } from 'actions/appointments';

const getAppointment = () => {
  const initialState = {
    appointment: {},
  };

  return (state = initialState, { type, error, record }) => {
    switch (type) {
      case SELECT_APPOINTMENT:
        return {
          ...initialState,
          appointment: record,
        };
      case UNSELECT_APPOINTMENT:
        return {
          ...initialState,
        };
      default:
        return state;
    }
  };
};

const getAppointments = () => {
  const initialState = {
    filteredAppointments: [],
  };

  return (state = initialState, { type, error, record }) => {
    switch (type) {
      case UNSET_APPOINTMENTS:
        return {
          ...initialState,
        };
      case SET_APPOINTMENTS:
        return {
          ...initialState,
          filteredAppointments: record,
        };
      default:
        return state;
    }
  };
};

export default combineReducers({
  appointment: getAppointment(),
  appointments: getAppointments(),
});
