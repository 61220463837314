import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox as Component, CheckboxProps } from 'antd';

interface StyledCheckboxProps {
  fillColor?: string;
  disabledColor?: string;
}

const StyledCheckbox = styled(Component)<StyledCheckboxProps>`
  font-size: 16px;

  .ant-checkbox-inner,
  .ant-checkbox,
  .ant-checkbox:hover {
    border-radius: 4px !important;
  }

  .ant-checkbox-disabled + span {
    ${props =>
      props.disabledColor &&
      css`
        color: ${props.disabledColor} !important;
      `}
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #137ac9;
    border-color: #137ac9;
    ${props =>
      props.fillColor &&
      css`
        border-color: ${props.fillColor};
        background-color: ${props.fillColor};
      `}
  }

  &:hover .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner {
    border-color: #e0e0e0 !important;
    background: #efefef !important;
    border-radius: 4px !important;
  }

  span:last-child {
    word-break: break-word;
  }
`;

type ForwardRefCheckboxProps = CheckboxProps & StyledCheckboxProps;
const ForwardRefCheckbox = forwardRef<HTMLInputElement, ForwardRefCheckboxProps>((props, ref) => <StyledCheckbox {...props} ref={ref} />);

type CheckboxType = typeof ForwardRefCheckbox & { Group?: typeof Component.Group };
const Checkbox: CheckboxType = ForwardRefCheckbox;
Checkbox.Group = Component.Group;

export default Checkbox;
