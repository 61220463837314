import { takeLatest, call, select, put } from 'redux-saga/effects';

import {
  LOAD_CREATE_ORGANIZATION,
  createOrganization,
  LOAD_ORGANIZATIONS,
  getOrganizations,
  LOAD_ORGANIZATION,
  getOrganization,
  LOAD_UPDATE_ORGANIZATION,
  updateOrganization,
  LOAD_REMOVE_ORGANIZATION,
  removeOrganization,
  requestLoadOrganizations,
} from 'actions/organizations';
import { createOrganizationApi, getOrganizationsApi, getOrganizationApi, updateOrganizationApi, removeOrganizationApi } from 'services/organizations';
import { getSingleOrganization, getOrganizationError, removeOrganizationError, getOrganizationsCount } from 'selectors/organizations';
import { setOffset } from 'actions/pagination';
import { getOffset } from 'selectors/pagination';
import { requestLoadStates } from 'actions/states';
import { requestLoadZipCodes } from 'actions/zipCodes';
import { changePage } from 'utils/pagination';

import fetchEntity from './fetch-entity';

/**
 * Create organization
 */
const fetchCreateOrganization = fetchEntity.bind(null, createOrganization, createOrganizationApi);

function* loadCreateOrganization({ type, ...data }) {
  yield call(fetchCreateOrganization, data);
  yield put(setOffset(0));
  yield put(requestLoadOrganizations({ all: true }));
}

export function* watchLoadCreateOrganization() {
  yield takeLatest(LOAD_CREATE_ORGANIZATION, loadCreateOrganization);
}

/**
 * Load organizations
 */
const fetchLoadOrganizations = fetchEntity.bind(null, getOrganizations, getOrganizationsApi);

function* loadOrganizations(data) {
  yield call(fetchLoadOrganizations, data);
}

export function* watchLoadOrganizations() {
  yield takeLatest(LOAD_ORGANIZATIONS, loadOrganizations);
}

/**
 * Get single organization
 */
const fetchGetOrganization = fetchEntity.bind(null, getOrganization, getOrganizationApi);

function* loadGetOrganization({ id }) {
  yield call(fetchGetOrganization, id);
  if (!(yield select(getOrganizationError))) {
    const organization = yield select(getSingleOrganization);
    const { countryId, stateId } = organization;
    yield put(requestLoadStates({ countryId }));
    yield put(requestLoadZipCodes({ stateId }));
  }
}

export function* watchLoadGetOrganization() {
  yield takeLatest(LOAD_ORGANIZATION, loadGetOrganization);
}

/**
 * Remove organization
 */
const fetchRemoveOrganization = fetchEntity.bind(null, removeOrganization, removeOrganizationApi);

function* loadRemoveOrganization({ id }) {
  yield call(fetchRemoveOrganization, id);
  if (!(yield select(removeOrganizationError))) {
    const count = yield select(getOrganizationsCount);
    const offset = yield select(getOffset);
    yield put(setOffset(changePage(count, offset)));
    yield put(requestLoadOrganizations({ offset: yield select(getOffset) }));
  }
}

export function* watchLoadRemoveOrganization() {
  yield takeLatest(LOAD_REMOVE_ORGANIZATION, loadRemoveOrganization);
}

/**
 * Update organization
 */
const fetchUpdateOrganization = fetchEntity.bind(null, updateOrganization, updateOrganizationApi);

function* loadUpdateOrganization({ type, ...data }) {
  yield call(fetchUpdateOrganization, data);
  yield put(setOffset(0));
  yield put(requestLoadOrganizations({ all: true }));
}

export function* watchLoadUpdateOrganization() {
  yield takeLatest(LOAD_UPDATE_ORGANIZATION, loadUpdateOrganization);
}
