import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Create program
 */
export const CREATE_PROGRAM = createRequestTypes('CREATE_PROGRAM');
export const createProgram = {
  request: () => action(CREATE_PROGRAM[REQUEST]),
  success: () => action(CREATE_PROGRAM[SUCCESS]),
  failure: (params, error) => action(CREATE_PROGRAM[FAILURE], { error }),
};
export const LOAD_CREATE_PROGRAM = 'LOAD_CREATE_PROGRAM';
export const requestCreateProgram = data => action(LOAD_CREATE_PROGRAM, data);

/**
 * Get programs
 */
export const PROGRAMS = createRequestTypes('PROGRAM');
export const getPrograms = {
  request: () => action(PROGRAMS[REQUEST]),
  success: (params, response) =>
    action(PROGRAMS[SUCCESS], {
      response: {
        entities: {
          programs: response,
        },
      },
    }),
  failure: (params, error) => action(PROGRAMS[FAILURE], { error }),
};
export const LOAD_PROGRAMS = 'LOAD_PROGRAMS';
export const requestLoadPrograms = skip => action(LOAD_PROGRAMS, skip);

/**
 * Remove Program
 */
export const REMOVE_PROGRAM = createRequestTypes('REMOVE_PROGRAM');

export const removeProgram = {
  request: () => action(REMOVE_PROGRAM[REQUEST]),
  success: () => action(REMOVE_PROGRAM[SUCCESS]),
  failure: (params, error) => action(REMOVE_PROGRAM[FAILURE], { error }),
};
export const LOAD_REMOVE_PROGRAM = 'LOAD_REMOVE_PROGRAM';
export const requestRemoveProgram = data => action(LOAD_REMOVE_PROGRAM, data);

/**
 * Update Program
 */
export const UPDATE_PROGRAM = createRequestTypes('UPDATE_PROGRAM');
export const updateProgram = {
  request: () => action(UPDATE_PROGRAM[REQUEST]),
  success: () => action(UPDATE_PROGRAM[SUCCESS]),
  failure: (params, error) => action(UPDATE_PROGRAM[FAILURE], { error }),
};
export const LOAD_UPDATE_PROGRAM = 'LOAD_UPDATE_PROGRAM';
export const requestUpdateProgram = data => action(LOAD_UPDATE_PROGRAM, data);
