import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

// get provider appointments
export const APPOINTMENTS = createRequestTypes('APPOINTMENTS');
export const getAppointments = {
  request: () => action(APPOINTMENTS[REQUEST]),
  success: (params, response) =>
    action(APPOINTMENTS[SUCCESS], {
      response: {
        entities: {
          appointments: response,
        },
      },
    }),
  failure: (params, error) => action(APPOINTMENTS[FAILURE], { error }),
};
export const LOAD_APPOINTMENTS = 'LOAD_APPOINTMENTS';
export const requestLoadAppointments = params => action(LOAD_APPOINTMENTS, params);

// create an appointment for provider
export const CREATE_APPOINTMENT = createRequestTypes('CREATE_APPOINTMENT');
export const createAppointment = {
  request: () => action(CREATE_APPOINTMENT[REQUEST]),
  success: () => action(CREATE_APPOINTMENT[SUCCESS]),
  failure: (params, error) => action(CREATE_APPOINTMENT[FAILURE], { error }),
};
export const LOAD_CREATE_APPOINTMENT = 'LOAD_CREATE_APPOINTMENT';
export const requestCreateAppointment = data => action(LOAD_CREATE_APPOINTMENT, { data });

// get single provider appointment
export const APPOINTMENT = createRequestTypes('APPOINTMENT');
export const getAppointment = {
  request: () => action(APPOINTMENT[REQUEST]),
  success: (params, response) =>
    action(APPOINTMENT[SUCCESS], {
      response: {
        entities: {
          appointment: response,
        },
      },
    }),
  failure: (params, error) => action(APPOINTMENT[FAILURE], { error }),
};
export const LOAD_APPOINTMENT = 'LOAD_APPOINTMENT';
export const requestLoadAppointment = id => action(LOAD_APPOINTMENT, { id });

/**
 * Select an Appointment
 */
export const SELECT_APPOINTMENT = 'SELECT_APPOINTMENT';
export const selectAppointment = record => action(SELECT_APPOINTMENT, { record });

/**
 * Unselect an Appointment
 */
export const UNSELECT_APPOINTMENT = 'UNSELECT_APPOINTMENT';
export const unselectAppointment = () => action(UNSELECT_APPOINTMENT);

/**
 * Set Appointments
 */
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const setAppointments = record => action(SET_APPOINTMENTS, { record });

/**
 * Unset Appointments
 */
export const UNSET_APPOINTMENTS = 'UNSET_APPOINTMENTS';
export const unsetAppointments = () => action(UNSET_APPOINTMENTS);
