import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import { LAUNCHDARKLY_CLIENT_ID } from 'configs/env-vars';
import InitData from './InitData';
import IdleTimer from './IsIdle';
import AppRouter from './AppRouter';

// !important App should be a class component in order for hot reloading to work correctly
class App extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <InitData />
        <IdleTimer />
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default withLDProvider({
  clientSideID: LAUNCHDARKLY_CLIENT_ID,
  user: {
    key: 'anonymous',
    anonymous: true,
  },
})(App);
