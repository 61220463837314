module.exports = {
  ENERC_KCAL: 'Calories',
  FAT: 'Fat',
  FASAT: 'Saturated Fat',
  FATRN: 'Trans Fat',
  CHOLE: 'Cholesterol',
  NA: 'Sodium',
  CHOCDF: 'Total Carbohydrates',
  FIBTG: 'Fiber',
  SUGAR: 'Sugar',
  PROCNT: 'Protein',
};
