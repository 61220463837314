module.exports = {
  mp_alte6EO8nA2mb: '28-Day Refresh',
  mp_g650rv3v8fx9b: 'Anti-Inflammatory 3-Day',
  mp_OPDSKR8eqriUY: 'Anti-Inflammatory 5-Day',
  mp_7a90be894e514: 'Diabetes Meal',
  mp_efq1crbxmpt4b: 'Flex',
  mp_3pcwvmrow9swa: 'Low FODMAP 3-Day',
  mp_tLOcss566MOaZ: 'Low FODMAP 5-Day',
  mp_k9j3fjt3r1092: 'Low Histamine',
  mp_r2qkmib3ujzyo: 'Meal Prep Master',
  mp_k7yw9e7hr7723: 'Pantry Power',
  mp_rq7higkhj7n4r: 'PCOS Meal',
  mp_a19xmk19avet0: 'Plant-Based 3-Day',
  mp_j8aPk8PFEOGfy: 'Plant-Based 5-Day',
  mp_2w96ugeyf4387: 'Ramadan Meal',
  mp_xonuga5vpzeoz: 'Renal Diet',
  mp_6a0571e4838e4: 'Simple Eats',
  mp_k9j3ghz4r1642: 'Specific Carbohydrate Diet',
  mp_vwdvgqeiz84fq: 'Sun Basket',
  mp_7zcckasi349f8: 'Total Custom',
  mp_b0g1028nzxcro: 'Functional Nutrition (IFNA)',
};
