const partner = {
  WONDR: 'wondr',
};

const appDetails = {
  minVersion: '5.0.0',
  appStoreId: '1585026163',
  packageName: 'com.intellihealth.evolve',
  skipAppPreview: 1,
};

module.exports = {
  partner,
  appDetails,
};
