import { takeLatest, call, select } from 'redux-saga/effects';

import { LOAD_ICON_LIST, getIconsList } from 'actions/icons';
import { getIconsApi } from 'services/icons';
import { getIcons } from 'selectors/icons';
import fetchEntity from './fetch-entity';

/**
 * Get Icon list
 */
const fetchGetIcons = fetchEntity.bind(null, getIconsList, getIconsApi);

export function* loadGetIcons({ type, ...data }) {
  const icons = yield select(getIcons);
  if (!icons || Object.keys(icons)?.length === 0) {
    yield call(fetchGetIcons, data);
  }
}

function* watchLoadGetIcons() {
  yield takeLatest(LOAD_ICON_LIST, loadGetIcons);
}

const IconSagas = { watchLoadGetIcons };
export default IconSagas;
