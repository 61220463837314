import { createSelector } from 'reselect';
import { LOAD_ZIP_CODES, LOAD_CREATE_ZIP_CODES, LOAD_EDIT_ZIP_CODE, LOAD_REMOVE_ZIP_CODE } from 'actions/zipCodes';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const getZipCodes = createSelector(getEntities, entities => entities.zipCodes);

export const getZipCodesLoading = createLoadingSelector(LOAD_ZIP_CODES)();
export const getZipCodesError = createErrorSelector(LOAD_ZIP_CODES)();

export const getSelectedZipCode = ({ zipCode }) => zipCode.zipCodeId;

export const getCreateZipCodeError = createErrorSelector(LOAD_CREATE_ZIP_CODES)();
export const getUpdateZipCodeError = createErrorSelector(LOAD_EDIT_ZIP_CODE)();
export const getRemoveZipCodeError = createErrorSelector(LOAD_REMOVE_ZIP_CODE)();

export const getOffset = ({ zipCode }) => zipCode.offset;
