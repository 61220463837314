import styled, { css } from 'styled-components';

const OrderedList = styled.ol`
  column-gap: 24px;

  li {
    font-size: 16px;
    margin-bottom: 8px;
  }

  ${props =>
    props.$listWidth &&
    css`
      width: ${props.$listWidth};
    `}
  ${props =>
    props.$columns &&
    css`
      column-count: ${props.$columns};
    `}
  ${props =>
    props.$type === 'none' &&
    css`
      li {
        list-style-type: none;
      }
      padding: ${props.$padding};
    `}
  ${props =>
    props.$type === 'circle' &&
    css`
      li {
        list-style-type: circle;
      }
    `}
`;

const ListVertical = ({ items, ...props }) =>
  items ? (
    <OrderedList {...props}>
      {items.map((l, index) => (
        <li key={index}>{l}</li>
      ))}
    </OrderedList>
  ) : null;

ListVertical.defaultProps = {
  $columns: '1',
  $listWidth: '270px',
  $padding: '8px',
};
export default ListVertical;
