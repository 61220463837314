import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Load Activity Logs
 */
export const GET_ACTIVITY_LOGS = createRequestTypes('GET_ACTIVITY_LOGS');
export const activityLogs = {
  request: () => action(GET_ACTIVITY_LOGS[REQUEST]),
  success: (params, response) =>
    action(GET_ACTIVITY_LOGS[SUCCESS], {
      response: {
        entities: {
          activityLogs: response,
        },
      },
    }),
  failure: (params, error) => action(GET_ACTIVITY_LOGS[FAILURE], { error }),
};
export const LOAD_GET_ACTIVITY_LOGS = 'LOAD_GET_ACTIVITY_LOGS';
export const requestActivityLogs = data => action(LOAD_GET_ACTIVITY_LOGS, data);

export const FILTER_TRIGGERS = 'FILTER_TRIGGERS';
export const filterTriggers = data => action(FILTER_TRIGGERS, { data });

export const CHECK_TRIGGERS = 'CHECK_TRIGGERS';
export const setCheckTriggers = checkedTriggers => action(CHECK_TRIGGERS, { checkedTriggers });
