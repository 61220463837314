import { SET_OFFSET } from 'actions/pagination';

export default (() => {
  const initialState = {
    offset: 0,
  };

  return (state = initialState, { type, offset }) => {
    switch (type) {
      case SET_OFFSET:
        return {
          ...state,
          offset,
        };
      default:
        return state;
    }
  };
})();
