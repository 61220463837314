import React, { useRef } from 'react';

import Slick from 'style-guide/Slick';
import 'slick-carousel/slick/slick.css';

import { useRouterHistory } from 'hooks/routerHooks';
import { setIsFirstTimeStorage } from 'utils/local-storage-helpers';
import Button from '../Button';
import OrientationSlide from './OrientationSlide';
import OrientationWrapper from './OrientationWrapper';

const Slider = ({ data, breakpoints, messages, route }) => {
  const { xxl, xl, lg, md, sm, xs } = breakpoints;

  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };

  const slickSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: 0,
    swipeToSlide: true,
  };

  const renderer = item => (
    <OrientationSlide
      key={item.key}
      next={next}
      data={item}
      message={messages.length && messages.find(message => message.handler === item.handler)?.message}
      route={route}
    />
  );

  return (
    <Slick
      xxl={xxl}
      xl={xl}
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      data={data}
      renderer={renderer}
      settings={slickSettings}
      ref={slider => {
        sliderRef.current = slider;
      }}
    />
  );
};

const OrientSlide = ({ pages, messages }) => {
  const history = useRouterHistory();
  const route = '/';
  return (
    <OrientationWrapper>
      <div className='innerWrapper'>
        <div className='header'> Welcome to Evolve </div>
        <Slider gap={10} data={pages} breakpoints={[480, 567, 768, 992, 1200, 1600]} messages={messages} route={route} />
        <Button
          type='link'
          className='skip'
          onClick={() => {
            history.push(route);
            setIsFirstTimeStorage();
          }}
        >
          Skip
        </Button>
      </div>
    </OrientationWrapper>
  );
};
export default OrientSlide;
