import { call, takeLatest, put, select } from 'redux-saga/effects';

import { LOAD_GET_ACTIVITY_LOGS, activityLogs, filterTriggers } from 'actions/activity-manager';
import { selectTriggerCodes } from 'selectors/activity-logs';

import { activityLogsApi } from 'services/activity-logs';
import fetchEntity from './fetch-entity';

// get activity logs
const fetchGetActivityLogs = fetchEntity.bind(null, activityLogs, activityLogsApi);

function* loadGetActivityLogs({ type, ...data }) {
  yield call(fetchGetActivityLogs, data);
  yield put(filterTriggers(yield select(selectTriggerCodes)));
}

export function* watchGetActivityLogs() {
  yield takeLatest(LOAD_GET_ACTIVITY_LOGS, loadGetActivityLogs);
}
