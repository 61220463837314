import day from 'utils/day';
import { getUserTimeZone } from './local-storage-helpers';

/**
 * Day.js object with logged in user timezone
 * @param {String} date Date
 * @returns {Object} Day.js
 */
export const userDay = date => day(date).tz(getUserTimeZone() || day.tz.guess());
/**
 * Convert Timezones (this function will return date with logged in user timezone)
 * @param {String} date Date format
 * @param {String} currentTz Current date time zone
 * @param format
 * @param {String} destinationTz
 */
export const convertTimezones = (date, currentTz, format, destinationTz) => {
  const currentDate = day(date, format).tz(currentTz, true);

  if (!currentTz) {
    return day(date).format(format);
  }
  return currentDate.clone().tz(destinationTz);
};
