import { takeLatest, call } from 'redux-saga/effects';

import { getStatesApi, createStateTranslationsApi } from 'services/states';

import {
  getLoadStateTranslationsAction,
  LOAD_GET_STATE_TRANSLATION,
  createStateTranslationAction,
  LOAD_CREATE_STATE_TRANSLATION,
} from 'actions/translations';

import fetchEntity from './fetch-entity';

// get state translations
const fetchStateTranslationsAction = fetchEntity.bind(null, getLoadStateTranslationsAction, getStatesApi);

function* loadStateTranslations({ data }) {
  yield call(fetchStateTranslationsAction, data);
}

export function* watchLoadStateTranslations() {
  yield takeLatest(LOAD_GET_STATE_TRANSLATION, loadStateTranslations);
}

// create state translation
const fetchCreateStateTranslationAction = fetchEntity.bind(null, createStateTranslationAction, createStateTranslationsApi);

function* loadCreateStatesTranslation({ data }) {
  yield call(fetchCreateStateTranslationAction, data);
}

export function* watchLoadCreateStateTranslation() {
  yield takeLatest(LOAD_CREATE_STATE_TRANSLATION, loadCreateStatesTranslation);
}
