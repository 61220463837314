import React from 'react';
import styled from 'styled-components';
import { Upload as upload } from 'antd';
import StyledUpload from './StyledUpload';
import Button from '../Button';

const UploadComponent = styled(upload)``;
const Upload = ({ advanced = false, types = null, ...props }) =>
  advanced ? (
    <StyledUpload types={types} {...props} />
  ) : (
    <UploadComponent {...props}>
      <Button>Click to Upload</Button>
    </UploadComponent>
  );
/** @component */
export default Upload;
