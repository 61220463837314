module.exports = [
  'View Patient page as patient views it',
  'View Patient page as provider views it',
  'View Provider page as provider views it',
  'Dashboard summary charts',
  'Insights Page',
  'View Weight Logs (Graph view)',
  'View Weight Logs (Table View)',
  'Connect Scale',
  'Disconnect Scale',
  'View Scale Status',
  'Edit Weight Entry',
  'Delete Weight Entry',
  'View Food Logs (Graph view)',
  'View Food Logs (Table View)',
  'Edit Logged Foods',
  'Delete Logged Foods',
  'View Logged Food for other days',
  'Log Food',
  'View Logged Exercises',
  'Log Exercise',
  'Edit Logged Exercise',
  'Delete logged Exercise',
  'View Wellness Vision',
  'Delete Wellness Vision',
  'Edit Wellness Vision',
  'View Achievements: Completed goals',
  'View Achievements: Total goals',
  'Create new patient-entered goal',
  'View Completed goals',
  'View Incomplete goals',
  'View Available goals',
  'Activate existing goal',
  'Create and activate new goal',
  'Dismiss goal',
  'Complete goal',
  'View completed goal details',
  'View Incomplete goal details',
  'View Questionnaire Achievements',
  'View Available questionnaires',
  'Pass Available Questionnaires',
  'View Completed Questionnaires',
  'View Completed Questionnaire Details',
  'View Recommendation Outputs (Patient view)',
  'Access Learning Center',
  'View List of Conversations',
  'View Conversation Details',
  'Write Message',
  'Send Attachment',
  'View Past Appointments',
  'View Appointment details',
  'View Upcoming Appointments',
  'Edit an appointment',
  'Cancel appointment',
  'Checking patient',
  'Update Appointment Settings',
  'View Appointment Settings',
  'View Provider Availability',
  'Update Provider Availability',
  'Appointment View in Dashboard',
  'Create Progress note',
  'Add Action Item',
  'Edit Action Item',
  'Delete Action Item',
  'Snooze Action Item',
  'Complete Action Item',
  'View List of Conversations',
  'View Conversation Details',
  'Start',
  'Access Learning Center',
  'Send Attachment',
  'Write Message',
  'Edit Draft Note',
  'Delete Draft Note',
  'Sign Note',
  'View Progress notes',
  'View Recommendation Outputs (Provider view)',
  'Start a Virtual Visit',
  'View Medications',
  'Add Vital',
  'Edit Vital',
  'Delete Vital',
  'View Vitals',
  'View Diagnosis',
  'View Symptoms',
  'Add Logging',
];
