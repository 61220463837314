import { takeLatest, call } from 'redux-saga/effects';

import { getProviderOrganizations, LOAD_PROVIDER_ORGANIZATIONS } from 'actions/organization-providers';

import { getProviderOrganizationsApi } from 'services/organization-providers';

import fetchEntity from './fetch-entity';

/**
 * Load provider organizations
 */
const fetchLoadProviderOrganizations = fetchEntity.bind(null, getProviderOrganizations, getProviderOrganizationsApi);

export function* loadProviderOrganizations({ type, ...data }) {
  yield call(fetchLoadProviderOrganizations, data);
}

export function* watchLoadProviderOrganizations() {
  yield takeLatest(LOAD_PROVIDER_ORGANIZATIONS, loadProviderOrganizations);
}

const OrganizationProviderSagas = {
  watchLoadProviderOrganizations,
};
export default OrganizationProviderSagas;
