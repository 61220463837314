import client from './client';

export const createSymptomApi = data => client().post('/diagnosis-symptoms', data);

export const getSymptomsApi = ({ skip, limit, ...data }) => client().get('/diagnosis-symptoms', { params: { $skip: skip, $limit: limit, ...data } });

export const getSymptomApi = id => client().get(`/diagnosis-symptoms/${id}`);

export const disableSymptomApi = data => client().patch(`/diagnosis-symptoms/${data.id}`, data);

export const editSymptomApi = data => client().put(`/diagnosis-symptoms/${data.id}`, data);
