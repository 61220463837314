import styled, { css } from 'styled-components';
import { Input as input } from 'antd';
import propTypes from 'prop-types';

const Input = styled(input)`
  && {
    ${props =>
      props &&
      props.level === 1 &&
      css`
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        .ant-input {
          border-left: none !important;
          border-right: none !important;
          border-top: none !important;
          border-radius: 0 !important;
          box-shadow: none !important;
          ${props.$searchInput ? 'padding-left: 55px !important;' : ''}
        }
      `}
  }
`;

Input.propTypes = {
  /** whether to show border only on top */
  borderTopOnly: propTypes.bool,
  level: propTypes.number,
  /** The label text displayed after (on the right side of) the input field. */
  addonAfter: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** The label text displayed before (on the left side of) the input field. */
  addonBefore: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** The initial input content */
  defaultValue: propTypes.string,
  /** Whether the input is disabled. */
  disabled: propTypes.bool,
  /** The ID for input */
  id: propTypes.string,
  /** The prefix icon for the Input. */
  prefix: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** The size of the input box. Note: in the context of a form,
   *  the large size is used. Available: large default small */
  size: propTypes.string,
  /** The suffix icon for the Input. */
  suffix: propTypes.oneOfType([propTypes.string, propTypes.element]),
  /** The type of input, see: MDN(use Input.TextArea instead of type="textarea") */
  type: propTypes.string,
  /** The input content value */
  value: propTypes.string,
  /** callback when user input */
  onChange: propTypes.func,
  /** The callback function that is triggered when Enter key is pressed. */
  onPressEnter: propTypes.func,
  /** allow to remove input content with clear icon */
  allowClear: propTypes.bool,
};

// Input.defaultProps = {
//   defaultValue: '',
//   disabled: false,
//   id: '',
//   size: '',
//   type: '',
//   value: '',
//   onChange: () => undefined,
//   onPressEnter: () => undefined,
// };

/** @component */
export default Input;
