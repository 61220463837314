import React from 'react';
import styled from 'styled-components';
import DatePicker from '../DatePicker';

const Component = React.forwardRef((props, ref) => <DatePicker {...props} picker='time' mode={undefined} ref={ref} />);

Component.displayName = 'TimePicker';

const TimePicker = styled(({ level, ...props }) => <Component {...props} />)`
  font-size: 16px !important;
  display: block;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  box-shadow: none;

  input {
    padding-top: 4px;
    padding-bottom: 4px;
    height: auto;
  }

  .ant-time-picker-input {
    :hover {
      border-color: #3696d6;
    }
  }
`;

TimePicker.defaultProps = {
  format: 'h:mm A',
};

TimePicker.RangePicker = DatePicker.RangePicker;

/** @component */
export default TimePicker;
