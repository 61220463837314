import { createSelector } from 'reselect';

import { LOAD_CREATE_PROGRAM, LOAD_PROGRAMS, LOAD_REMOVE_PROGRAM, LOAD_UPDATE_PROGRAM } from 'actions/programs';
import getEntities from './entities';
import createLoadingSelector from './create-loading-selector';
import createErrorSelector from './create-error-selector';

export const createProgramLoading = createLoadingSelector(LOAD_CREATE_PROGRAM)();
export const createProgramError = createErrorSelector(LOAD_CREATE_PROGRAM)();

export const getProgramsLoading = createLoadingSelector(LOAD_PROGRAMS)();
export const getProgramsError = createErrorSelector(LOAD_PROGRAMS)();

export const removeProgramLoading = createLoadingSelector(LOAD_REMOVE_PROGRAM)();
export const removeProgramError = createErrorSelector(LOAD_REMOVE_PROGRAM)();

export const updateProgramLoading = createLoadingSelector(LOAD_UPDATE_PROGRAM)();
export const updateProgramError = createErrorSelector(LOAD_UPDATE_PROGRAM)();

export const getProgramsList = createSelector(getEntities, ({ programs }) => programs);

export const getProgramsCount = createSelector(getEntities, ({ programs }) => programs && programs.count);
export const getProgram = createSelector(getEntities, ({ program }) => program);
