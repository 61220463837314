import React from 'react';
import Dropdown from 'style-guide/Dropdown';
import StreamlineIcon from 'style-guide/StreamlineIcon';
import Button from 'style-guide/Button';
import themes from 'style-guide/theme';

const UserMobileDropdown = ({ dropDown }) => (
  <div className='userMobileDropdown'>
    <Dropdown trigger={['click']} overlay={dropDown}>
      <Button
        style={{
          height: '100%',
          display: 'flex',
          padding: 0,
        }}
        type='link'
      >
        <StreamlineIcon icon='single-neutral-circle' size={21} color={themes['stroke-icon']} />
      </Button>
    </Dropdown>
  </div>
);

export default UserMobileDropdown;
