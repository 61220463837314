import styled, { css } from 'styled-components';
import startBackground from './badge-background.svg';

export const CircleWrapper = styled.span`
  display: inline-block;
  box-sizing: content-box;
  border: 2px solid ${props => props.$borderColor};
  padding: 12px;
  ${props =>
    props.$size <= 18 &&
    css`
      padding: 8px;
      border-width: 1px;
    `}
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;
  border-radius: 50%;
`;

export const BadgeWrapper = styled.span`
  display: inline-block;
  height: ${props => props.$size}px;
  width: ${props => props.$size}px;
  background-image: url(${startBackground});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  & > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    * {
      stroke: white;
    }
  }
`;
