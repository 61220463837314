import client from './client';

const headers = {
  'Content-Type': 'multipart/form-data',
  'csrf-token': document.getElementById('csrf-token')?.content,
};

export const getFilesApi = data => client().get('/files', { params: data });

export const getFileUrlApi = ({ id, isAssessment }) => client().get(`/files/${id}`, { params: { isAssessment } });

export const uploadFileApi = ({ file, room, participants }) => {
  const fd = new FormData();
  fd.append('file', file);
  fd.append('conversationId', room);
  if (participants) {
    fd.append('participants', participants);
  }
  return client(headers).post('/files', fd);
};

export const uploadAssessmentImageApi = ({ file, assessmentId }) => {
  const fd = new FormData();
  fd.append('file', file);
  fd.append('assessmentId', assessmentId);
  return client(headers).post('/files', fd);
};

export const uploadGoalCategoriesImagesApi = (file, goalCategoryId, field) => {
  const fd = new FormData();
  fd.append('file', file);
  fd.append('goalCategoryId', goalCategoryId);
  fd.append('field', field);
  return client(headers).post('/files', fd);
};

export const removeGoalCategoryImageApi = (id, isGoalCategory, field) => client().delete(`/files/${id}`, { params: { isGoalCategory, field } });

export const getGoalCategoryImageUrlsApi = (id, isGoalCategory) => client().get(`/files/${id}`, { params: { isGoalCategory } });

export const getGoalFilesApi = data => client().get('/files', { params: data });

export const removeAssessmentImageApi = ({ id }) => client().delete(`/files/${id}`);
