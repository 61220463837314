import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

export const PERMISSIONS = createRequestTypes('PERMISSIONS');
export const permissionsAction = {
  request: () => action(PERMISSIONS[REQUEST]),
  success: (params, response) =>
    action(PERMISSIONS[SUCCESS], {
      response: {
        entities: {
          permissions: response,
        },
      },
    }),
  failure: (params, error) => action(PERMISSIONS[FAILURE], { error }),
};
export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS';
export const requestLoadPermissions = () => action(LOAD_PERMISSIONS);

export const EXISTING_PERMISSIONS = createRequestTypes('EXISTING_PERMISSIONS');
export const existingPermissionsAction = {
  request: () => action(PERMISSIONS[REQUEST]),
  success: (params, response) =>
    action(EXISTING_PERMISSIONS[SUCCESS], {
      response: {
        entities: {
          existingPermissions: response,
        },
      },
    }),
  failure: (params, error) => action(EXISTING_PERMISSIONS[FAILURE], { error }),
};
export const LOAD_EXISTING_PERMISSIONS = 'LOAD_EXISTING_PERMISSIONS';
export const requestLoadExistingPermissions = () => action(LOAD_EXISTING_PERMISSIONS);

export const SAVE_PERMISSIONS = createRequestTypes('SAVE_PERMISSIONS');
export const savePermissionsAction = {
  request: () => action(SAVE_PERMISSIONS[REQUEST]),
  success: (params, response) => action(SAVE_PERMISSIONS[SUCCESS]),
  failure: (params, error) => action(SAVE_PERMISSIONS[FAILURE], { error }),
};

export const LOAD_SAVE_PERMISSION = 'LOAD_SAVE_PERMISSION';
export const requestSavePermissions = data => action(LOAD_SAVE_PERMISSION, { data });

export const REMOVE_PERMISSIONS = createRequestTypes('REMOVE_PERMISSIONS');
export const removePermissionsAction = {
  request: () => action(REMOVE_PERMISSIONS[REQUEST]),
  success: (params, response) =>
    action(REMOVE_PERMISSIONS[SUCCESS], {
      response: {
        entities: {
          permissions: response,
        },
      },
    }),
  failure: (params, error) => action(REMOVE_PERMISSIONS[FAILURE], { error }),
};

export const LOAD_REMOVE_PERMISSION = 'LOAD_REMOVE_PERMISSION';
export const requestRemovePermissions = id => action(LOAD_REMOVE_PERMISSION, { id });
