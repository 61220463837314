import client from './client';

export const createVitalApi = data => client().post('/patient-vitals', data);

export const getVitalApi = params => client().get(`/patient-vitals/${params.id}`, { params });

export const getVitalsApi = params => client().get('/patient-vitals', { params });

export const removeVitalApi = ({ id, patientId }) => client().delete(`/patient-vitals/${id}`, { params: { patientId } });

export const updateVitalApi = ({ id, ...data }) => client().put(`/patient-vitals/${id}`, data);
