import { SELECT_STATE, UNSELECT_STATE } from 'actions/states';

export default (() => {
  const initialState = {
    stateId: undefined,
  };

  return (state = initialState, { type, stateId }) => {
    switch (type) {
      case SELECT_STATE:
        return {
          ...state,
          stateId,
        };
      case UNSELECT_STATE:
        return initialState;
      default:
        return state;
    }
  };
})();
