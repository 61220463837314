import styled from 'styled-components';
import { Divider as divider } from 'antd';

const Divider = styled(divider)`
  margin-top: 16px;
  margin-bottom: 16px;

  &::before,
  &::after {
    border-top: 1px solid #d0d0d0 !important;
  }
`;

/** @component */
export default Divider;
