import { action, createRequestTypes, FAILURE, REQUEST, SUCCESS } from './index';

/**
 * Get Next Question
 */
export const NEXT_QUESTION = createRequestTypes('NEXT_QUESTION');
export const getNextQuestion = {
  request: () => action(NEXT_QUESTION[REQUEST]),
  success: (params, response) =>
    action(NEXT_QUESTION[SUCCESS], {
      response: {
        entities: {
          nextQuestion: response,
        },
      },
    }),
  failure: (params, error) => action(NEXT_QUESTION[FAILURE], { error }),
};
export const LOAD_NEXT_QUESTION = 'LOAD_NEXT_QUESTION';
export const requestNextQuestion = data => action(LOAD_NEXT_QUESTION, data);

/**
 * Load Questionnaires
 */
export const QUESTIONNAIRES = createRequestTypes('QUESTIONNAIRES');
export const getQuestionnaires = {
  request: () => action(QUESTIONNAIRES[REQUEST]),
  success: (params, response) =>
    action(QUESTIONNAIRES[SUCCESS], {
      response: {
        entities: {
          questionnaires: response,
        },
      },
    }),
  failure: (params, error) => action(QUESTIONNAIRES[FAILURE], { error }),
};
export const LOAD_QUESTIONNAIRES = 'LOAD_QUESTIONNAIRES';
export const requestQuestionnaires = data => action(LOAD_QUESTIONNAIRES, data);

/**
 * Load Completed Questionnaires
 */
export const COMPLETED_QUESTIONNAIRES = createRequestTypes('COMPLETED_QUESTIONNAIRES');
export const getCompletedQuestionnaires = {
  request: () => action(COMPLETED_QUESTIONNAIRES[REQUEST]),
  success: (params, response) =>
    action(COMPLETED_QUESTIONNAIRES[SUCCESS], {
      response: {
        entities: {
          completedQuestionnaires: response,
        },
      },
    }),
  failure: (params, error) => action(COMPLETED_QUESTIONNAIRES[FAILURE], { error }),
};
export const LOAD_COMPLETED_QUESTIONNAIRES = 'LOAD_COMPLETED_QUESTIONNAIRES';
export const requestCompletedQuestionnaires = data => action(LOAD_COMPLETED_QUESTIONNAIRES, data);
