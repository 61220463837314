module.exports = {
  fractions: ['1/4', '1/3', '1/2', '2/3', '3/4'],
  mealTypes: ['Breakfast', 'Lunch', 'Dinner', 'Snack'],
  calories: {
    FAT_CALORIES: 9,
    CARBS_CALORIES: 4,
    PROTEIN_CALORIES: 4,
  },
  macronutrientNames: ['calories', 'protein', 'fat', 'carbs', 'fiber', 'saturatedFat', 'transFat', 'cholesterol', 'sugar', 'sodium'],
  modalViews: {
    INITIAL: 'INITIAL',
    DETAILS: 'DETAILS',
    LOGGING: 'LOGGING',
    RECIPE_CREATE: 'RECIPE_CREATE',
    RECIPE_DETAILS: 'RECIPE_DETAILS',
    RECIPE_EDIT: 'RECIPE_EDIT',
  },
  getPercents: (fat, carbs, protein) => {
    const fatGrams = fat * 9; // calories.FAT_CALORIES
    const carbsGrams = carbs * 4; // calories.CARBS_CALORIES
    const proteinGrams = protein * 4; // calories.PROTEIN_CALORIES
    const total = fatGrams + carbsGrams + proteinGrams;
    const fatPercent = total ? Math.round((fatGrams / total) * 100) : 0;
    const carbsPercent = total ? Math.round((carbsGrams / total) * 100) : 0;
    const proteinPercent = total ? 100 - (fatPercent + carbsPercent) : 0;
    return {
      fatPercent,
      carbsPercent,
      proteinPercent,
    };
  },
};
