import React from 'react';
import styled from 'styled-components';
import imageCircle from 'style-guide/assets/circle-3.png';
import { Menu } from 'antd';
import { clearExpTimeFromStorage, clearExpClosed, clearExpTimeFromStorage2FA, clearIsFirstTimeFromStorage } from 'utils/local-storage-helpers';
import Layout from '../Layout';
import Img from '../Img';
import StreamlineIcon from '../StreamlineIcon';

const { Sider: sider } = Layout;

const Component = styled(sider)`
  background: linear-gradient(180deg, #0c2340 0%, #137ac9 100%);
  min-height: calc(100vh - 60px);
  overflow: hidden;
  position: relative;
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 999;

  .ant-menu {
    .logout {
      display: none;

      @media (max-width: 767.98px) {
        display: block;
      }
    }

    background: none;

    &.ant-menu-inline {
      border-right: none;
    }

    .ant-menu-item {
      margin: 0;
      padding: 16px;
      height: 55px;
      position: relative;
      z-index: 9999;
      width: 100%;

      svg {
        color: #fff;
      }

      &.collapse-menu {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 999999;

        @media (max-width: 767.98px) {
          display: none;
        }
      }

      &.accent-item {
        background: #fea61a;
      }
    }
  }

  .ant-menu-title-content {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  :hover {
    background-color: rgb(19, 122, 201) !important;
  }

  .circle-decoration {
    position: absolute;
    width: 600px !important;
    height: 600px;
    left: -350px;
    bottom: -350px;
    max-width: unset;
  }

  @media (max-width: 767.98px) {
    left: -300px;
    ${props =>
      props.$burgerVisible &&
      `
      left: 0px;
      `}
  }
`;

const StyledNavigation = ({ ...props }) => <Component {...props} />;

const SideNavigation = ({
  history,
  items,
  userType,
  patientId,
  asAdmin,
  onCollapse,
  collapsed,
  burgerVisible,
  forceClose,
  clientUrl,
  isWidget,
  linkData,
}) => (
  <StyledNavigation collapsed={forceClose || collapsed} width={278} id='main-navigation' $burgerVisible={burgerVisible}>
    <Menu mode='inline' defaultSelectedKeys={[window.location.pathname]}>
      {items?.map(item => {
        let { url } = item;
        if (asAdmin) {
          url = item.name !== 'Help' ? `/patient${item.url}?patientId=${patientId}&asAdmin=${asAdmin}` : item.url;
        }
        if (userType === 'admin' && history.location.pathname.indexOf('/provider/') >= 0) {
          url = `/${history.location.pathname.split('/')[1]}/${history.location.pathname.split('/')[2]}${item.url}`;
        }
        const goTo = () =>
          item.absolute || isWidget ? window.open(isWidget ? `${clientUrl.replace(/\/$/, '')}${url}` : url, '_blank') : history.push(url);
        return (
          <Menu.Item onClick={goTo} key={item.key} icon={<StreamlineIcon icon={item.icon} size='20px' />}>
            {item.name}
          </Menu.Item>
        );
      })}
      {!forceClose && (
        <Menu.Item
          key='9999'
          onClick={onCollapse}
          className='collapse-menu'
          icon={<StreamlineIcon icon={collapsed ? 'arrow-right' : 'arrow-left-1'} size={20} />}
        >
          <div>{collapsed ? 'Expand' : 'Collapse'} Menu </div>
        </Menu.Item>
      )}
      {asAdmin ? (
        <Menu.Item
          key='back-to-dashboard'
          onClick={() => history.push('/')}
          className='accent-item'
          icon={<StreamlineIcon icon='arrow-left-1' size={20} />}
        >
          Back to IH Dashboard
        </Menu.Item>
      ) : (
        ''
      )}
      <Menu.Item
        className='logout'
        icon={<StreamlineIcon icon='logout' size={20} />}
        key='logout'
        onClick={() => {
          clearExpTimeFromStorage();
          clearExpClosed();
          clearExpTimeFromStorage2FA();
          clearIsFirstTimeFromStorage();
          // For some reason, we aren't handling log out using reducers/actions and instead performing log out with calls to the API that redirects us to the sign-in page.
          if (linkData) {
            linkData.logout();
          } else {
            window.location.assign(`${clientUrl}api/logout`);
          }
        }}
      >
        Log out
      </Menu.Item>
    </Menu>

    <Img src={imageCircle} className='circle-decoration' />
  </StyledNavigation>
);
/** @component */
export default SideNavigation;
