import styled, { css } from 'styled-components';
import { Tag as tag } from 'antd';

interface StyledAlertProps {
  isEllipsis?: false;
}

const Component = styled(tag)<StyledAlertProps>`
  ${props =>
    props.isEllipsis &&
    css`
      white-space: nowrap;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const Tag = ({ ...props }) => <Component {...props} />;
/** @component */
export default Tag;
